import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Tokeattetel() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Tőkeáttétel - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a tőkeáttétel jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>


                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Tőkeáttétel</h1>
                            <p>Ezen az oldalon megismerheted a tőkeáttétel jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A tőkeáttétel egy módszer, amely lehetővé teszi a kereskedők számára, hogy nagyobb összeget fektessenek be, mint a rendelkezésükre álló tőke.</p>
                            <p className="majonator">A tőkeáttétes kereskedés esetén a brókertől kölcsönbe kapott pénzzel megnöveljük a letétként elhelyezett összeget, így nagyobb piaci pozíciót nyitva. A kereskedőnek a befektetett tőke teljes értékével nem kell rendelkeznie, csak egy bizonyos összeget kell fedezetként elhelyeznie. A tőkeáttétel felerősíti mind a nyereségeket, mind a veszteségeket.</p>
                            <hr />
                            <h2>Működése</h2>
                            <p className="majonator">A kereskedők a tőkeáttételt a kereskedésből elérhető hozam növelésére használják. A vállalatok tőkeáttételt használhatnak például az eszközeik finanszírozására. Ahelyett, hogy részvényesi tőkét használnának a tőkebevonás érdekében, a vállalatok hitelből való finanszírozást használhatnak a beruházásokhoz, hogy így növeljék a vállalat méretét.</p>
                            <p className="majonator">A tőkeáttétel mértékét egy arányszámmal fejezik ki. Például az 1:10 arány azt jelenti, hogy a pozíció 10%-át kell csak letétben elhelyezni. Minél kisebb a pozíció fedezete, annál kockázatosabb a tranzakció. A tőkeáttétel a <Link to='/fogalomtar/volatilitas' className='dezord'>volatilitásból</Link> és a sokszorosára növekedett vesztési lehetőségből kifolyólag sokkal kockázatosabb, mint egy hagyományos tranzakció.</p>
                            <br />
                            <p className="majonatora azam">Példa</p>
                            <p className="majonator">1:10 tőkeáttét mellet befektetünk 100$-t. Az árfolyam 3%-ot emelkedik, majd ekkor lezárjuk a pozíciónkat. Hagyományos esetben a tranzakció egy 3 dolláros nyereséget jelentene, viszont a példában a tőkeáttét miatt a profit valójában 30 dollár lesz. Fontos megjegyezni, hogy az árfolyam akár 3%-ot is csökkenhetett volna, ami az ügylet ellenkezőjét eredményezte volna.</p>
                            <hr />
                            <h2>Margin</h2>
                            <p className="majonator">A margin az a biztosíték, amelyet a kereskedőnek a <Link to='/fogalomtar/broker' className='dezord'>brókernél</Link> kell letétbe helyeznie, hogy fedezze a tulajdonos által a bróker számára jelentett hitelkockázatot. A kereskedő úgy teremthet hitelkockázatot, hogy kölcsönt vesz fel a brókertől, tőkeáttétes ügyletek végzése céljából. A margin a befektetés teljes értéke és a kölcsön összege közötti különbség. Vagyis tőkeáttételes kereskedés esetén nem szükséges az adott pozíció teljes értékével megegyező tőkével rendelkeznünk, hanem elég csupán a fedezeti összeget, vagyis a margint elhelyeznünk.</p>
                            <br />
                            <p className="majonator azam">Margin call</p>
                            <p className="majonator">Amikor egy kereskedő árfolyamvesztesége következtében a margin számláján található tőke és a kölcsönzött pénz együttes összege a bróker által meghatározott minimális marginkövetelmény alá esik, a bróker margin call-t hajt végre. Kedvezőbb esetben a kereskedő egy figyelmeztetést kap arról, hogy pótolnia kell a tőkehiányát. Rosszabb esetben a bróker azonnal zárolja a pozíciót és a kereskedő elveszti a pénzét. A margin call elkerülése érdekében a kereskedőnek figyelemmel kell kísérnie az eszközök árfolyamának alakulását és gondoskodnia kell arról, hogy mindig elegendő fedezete legyen a pozícióihoz.</p>
                            <hr />
                            <h2>Vállalati tőkeáttétel</h2>
                            <p className="majonator">A vállalati tőkeáttétel lehetővé teszi a vállalatok számára, hogy nagyobb beruházásokat finanszírozzanak, mint amennyit a saját forrásaikból tudnának. Egy vállalatnak fizetnie kell mindazért, amit birtokol és ezt kötelezettségek vállalásával, illetve részvényesi tőke bevonásával teheti meg. A vállalati tőkeáttét mérésére használatos mutatók például a <Link to='/fogalomtar/debt-equity' className='dezord'>Debt/Equity</Link>, a Total Debt/Total Assets vagy a Debt/EBITDA.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Tokeattetel