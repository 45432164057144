import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Opcio() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Opció - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted az opció jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Opció</h1>
                            <p>Ezen az oldalon megismerheted az opció jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">Az opció egy olyan származtatott ügylet, amely során  egy eszközt egy előre meghatározott jövőbeli időpontban egy meghatározott áron vásárolhatunk meg vagy eladhatunk el.</p>
                            <p className="majonator">Azért fontos feltételes módban írni a vásárlást és az eladást, mert az opciók esetében a befektető nem köteles a vételi vagy az eladási megállapodást végrehajtani. Az opció származtatott ügylet, vagyis derivatíva. Az opciók gyakran <Link to='/fogalomtar/tokeattetel' className='dezord'>tőkeáttéttel</Link> működnek. Az ügylet során egy mögöttes eszköz jövőbeli árára spekulálnak.</p>
                            <hr />
                            <h2>Az opció egy derivatíva</h2>
                            <p className="majonator">A derivatíva, másnéven származtatott ügylet, olyan befektetési eszköz, mely árfolyama egy másik termék árfolyamából származtatott. A derivatíváknak több fajtája is van: Futures, Forwards, Option, Swap. A derivatívákról részletesebben <Link to='/fogalomtar/derivativa' className='dezord'>itt olvashatsz</Link>.</p>
                            <hr />
                            <h2>Opció fajtái</h2>
                            <p className="majonatora azam">Call</p>
                            <p className="majonatora">A call, vagyis a vételi opció jogot ad a tulajdonosnak, de nem kötelezi őt arra, hogy a lejáratkor vagy azt megelőzően a kötési áron megvásárolja a mögöttes eszközt. A vételi opció a mögöttes eszköz árfolyamának emelkedésével értékesebbé válik.</p>
                            <br />
                            <p className="majonatora azam">Put</p>
                            <p className="majonatora">A put, vagyis az eladási opció jogot ad a tulajdonosnak, de nem kötelezi őt arra, hogy a lejárat napján vagy azt megelőzően a kötési áron eladja a mögöttes eszközt. Az eladási opció értéket nyer, ha a mögöttes eszköz árfolyama csökken.</p>
                            <hr />
                            <h2>Opciós díj</h2>
                            <p className="majonator">Az opciós díjat a kereskedő fizeti a kibocsátónak a vételi vagy az eladási jog ellenében. A kereskedőnek attól függetlenül kötelessége van befizetnie a díjat, hogy él-e az eladási vagy a vételi pozíció végrehajtásával, mivel a díjat az ügylet megkötésekor kell kifizetni.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Opcio