import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Tozsdeindex() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Tőzsdeindex - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a tőzsdeindex jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Tőzsdeindex</h1>
                            <p>Ezen az oldalon megismerheted a tőzsdeindex jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A tőzsdeindex egy piaci rész árfolyamainak átlagolt változását méri.</p>
                            <p className="majonator">A tőzsdeindex a piac egy adott részének az eszközeiből álló portfólió. Az index árfolyamát a magába foglalt eszközök árai képzik. Az elemek egy indexen belül más-más súlyozással lehetnek számítva. A tőzsdeindexeket használják a befektetési alapok referencia indexeként, illetve általános gazdasági áttekintésként is. A két legnépszerűbb index az S&P 500 és a Dow Jones. </p>
                            <hr />
                            <h2>Működése</h2>
                            <p className="majonator">A tőzsdeindexek számításakor megkülönböztetünk normál és súlyozott átlagolást. Normál átlagolás esetén a tőzsdeindex minden egyes elemének árváltozása ugyanakkora hatást gyakorol az index árfolyamára. Súlyozott számításnál bizonyos eszközök árváltozása erősebben hat az árfolyamra. A legtöbb esetben úgy súlyozzák az eszközöket, hogy a legnagyobb <Link to='/fogalomtar/piaci-kapitalizacio' className='dezord'>piaci kapitalizációjú</Link> elemek teszik ki a portfólió legnagyobb részét. Általában azok az indexek, amikben nagyság szerinti növekvő sorrendben súlyozzák az eszközöket, jobban teljesítenek, mint a normál súlyozásúak. Bizonyos indexek esetében figyelembe veszik az részvények osztalékfizetését is és beleszámítják az index árába.</p>
                            <p className="majonator">A tőzsdeindexeket befektetési alapok <Link to='/fogalomtar/benchmark' className='dezord'>benchmarkjaiként</Link> is szokták használni. A benchmark (másnéven referencia index) egy alapkezelőknek meghatározott túlteljesítendő cél a hozamra vonatkozóan. Vagyis a benchmark egy viszonyítási alapként szolgál. Segítségével összehasonlíthatóvá válnak a különböző <Link to='/fogalomtar/befektetesi-alap' className='dezord'>befektetési alapok</Link> teljesítményei.</p>
                            <hr />
                            <h2>Tőzsdeindex befektetés</h2>
                            <p className="majonator">Lehetőség van közvetlenül megvásárolni az adott indexet alkotó részvényeket külön-külön, bár ezt szinte senki se csinálja, mivel rendkívül időigényes és költséges folyamat. Egy hatékony módja az indexekbe való befektetésnek az ETF-ek. Az ETF-ek általában egy indexet, szektort vagy egy adott árukategóriát követnek. Az ETF-ek egyfajta eszközkosarak. Annyiban különböznek a befektetési alapoktól, hogy ugyanúgy megvásárolhatók vagy eladhatók a tőzsdén, mint a hagyományos részvények.</p>
                            <hr />
                            <h2>Népszerű indexek</h2>
                            <p className="majonatora">A legnépszerűbb tőzsdeindex az S&P 500. Az S&P 500 az USA tőzsdéin jegyzett 500 legnagyobb vállalat részvényeinek teljesítményét követi nyomon. Az S&P 500 egy piaci kapitalizáció szerint súlyozott index. Ezt használják az amerikai befektetési alapok referencia indexeként.</p>
                            <p className="majonatora">A Dow Jones Industrial Average (DJIA), 30 nagy, nyilvánosan kereskedett amerikai vállalat teljesítményét méri. A Dow Jones kosara úgy lett összeállítva, hogy átfogó képet adjon a teljes amerikai részvénypiac állapotáról.</p>
                            <p className="majonatora">A Nasdaq Composite Index a Nasdaq tőzsdén jegyzett több mint 2500 részvény piaci kapitalizációval súlyozott indexe. A Nasdaq Composite a technológiai szektort is nagyobb súllyal számítja.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Tozsdeindex