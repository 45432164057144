import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Jutalek() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Jutalék - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a jutalék jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Jutalék</h1>
                            <p>Ezen az oldalon megismerheted a jutalék jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A jutalék a bróker által az értékpapír tranzakció lebonyolításáért cserébe felszámított szolgáltatási díj.</p>
                            <p className="majonator">A brókercégek nyereségük jelentős részét a tranzakciókra felszámított jutalékokból nyerik. A jutalék mértéke brókerek és különböző szolgáltatások között is eltérő. A nyereség és a veszteség meghatározásakor fontos figyelembe venni a jutalék mértékét. A befektetőknek fontos, hogy ne csak a hozamot vegyék figyelembe, hanem a vele járó fizetendő díjakat és jutalékokat is, hogy reális képet kapjanak a befektetésük valós hozamáról.</p>
                            <hr />
                            <h2>Működése</h2>
                            <p className="majonator">A jutalékok felszámíthatók, ha egy megbízás teljesül, törlésre vagy módosításra kerül, és akkor is, ha lejár. A legtöbb esetben, amikor egy befektető olyan piaci megbízást ad, amely nem teljesül, nem számítanak fel jutalékot. Ha azonban a megbízás törlésre vagy módosításra kerül, a <Link to='/fogalomtar/broker' className='dezord'>bróker</Link> a jutalékhoz további díjakat is hozzáadhat.</p>
                            <p className="majonator">A brókerek által felszámított jutalékoknak két fő típusa van: vételi és eladási. Vételi jutalék a befektetőnek vásárláskor kell fizetnie, míg az eladási jutalék az értékpapír eladásakor kerül elszámolásra. A piacon számos online bróker érhető el, mely nem számol fel jutalékot az értékpapír vásárlásáért és eladásáért. Az ilyen brókerek általában más díjstruktúrával működnek</p>
                            <hr />
                            <h2>Jutalék vs Díj</h2>
                            <p className="majonator">Megkülönböztetünk díj- és jutalékalapú brókereket, illetve tanácsadókat. A különbség a kettő között az, hogy míg a díjalapú bróker fix költséget számít, addig a jutalékalapú a tranzakciókból szerez bevételt a befektetett összeg mértékétől függően.</p>
                            <br />
                            <p className="majonatora azam">Példa</p>
                            <p className="majonatora">Egy befektető 1 millió forint értékben vásárolt értékpapírokat egy brókernél. A befektetésein 10%-os hozamot ért el, ami 100.000 forintot jelent. Tegyük fel, hogy a bróker 2%-os eladási jutalékot számít fel. Ebből könnyen ki lehet számolni, hogy a hozam mekkora részét veszítjük el. 100.000 <span>&#215;</span> 0,02 = 2000 forint.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Jutalek