import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Forgalom() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Forgalom - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a forgalom jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Forgalom</h1>
                            <p>Ezen az oldalon megismerheted a forgalom jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A forgalom (volumen) egy eszköz azon mennyisége, amely egy bizonyos idő alatt gazdát cserél. </p>
                            <p className="majonator">Minden egyes tranzakció, amely egy eszköz vevője és eladója között létrejön, hozzájárul az adott eszköz volumenének számához. A volumen értékét általában egy valutában kifejezve jelenítik meg, de az is előfordulhat, hogy darabszámmal találkozunk. A volument a kereskedők a technikai elemzésben használják.</p>
                            <hr />
                            <h2>Értelmezése</h2>
                            <p className="majonator">Ha a forgalom növekszik, akkor abból arra lehet következtetni, hogy a jelenlegi trend erősödik és valószínűleg folytatódni fog. Ha növekszik a forgalom, az azt jeleni, hogy egyre több befektető érkezik és nő a népszerűsége az adott eszköznek. Ugyanez igaz fordítva is.</p>
                            <p className="majonator">Amikor egy trend kifullad, a kereskedési mennyiség jelentősen megnő. Az emelkedő trend csúcspontján általában kiemelkedő forgalmat tapasztalunk, viszont a csökkenő trend mélypontján gyakrabban nő a forgalom intenzitása. Ennek oka részben az lehet, hogy az emelkedő trendben a kereskedők gyakran realizálják nyereségüket az új csúcsoknál. Emellett azok, akiknek short pozíciójuk van, vagy olyan stratégiákat alkalmaznak, amelyek a trendfordulókra épülnek, gyakran kötnek stop megbízásokat az új csúcsokhoz. Ennek eredményeként az ilyen pozíciók zárásával újabb nagy volumenű kereskedés jöhet létre. Ez a jelenség fordítva is igaz, mivel a csökkenő árfolyamból fakadóan rengetegen eladják pozícióikat, illetve a short kereskedők is ekkor realizálnak profitot.</p>
                            <hr />
                            <h2>Volumen kereskedés</h2>
                            <p className="majonator">A kereskedők számára a volumenszintek azért fontosak, mert jelzést adnak arra vonatkozóan, hogy hol lehetnek a legjobb be- és kiszállási pontok. A forgalom az eszköz árfolyammozgásának jelentőségét is méri. Ha az árfolyam felfelé vagy lefelé történő mozgása során megnő a volumen, akkor az erős ármozgásnak tekinthető. A volument önmagában általában az árfolyamgrafikon alatt lehet megtalálni oszlopdiagrammok formájában. Az oszlop magassága és színe (zöld, piros) jelöli a volument.</p>
                            <hr />
                            <h2>Szignálok és indikátorok</h2>
                            <p className="majonator">A volumen számos kereskedési stratégia alapját képezi. Például a "Volume Spread Analysis" (VSA) egy <Link to='/fogalomtar/technikai-elemzes' className='dezord'>technikai elemzési</Link> módszer, amely a volumen és az árak közötti összefüggéseket vizsgálja, hogy kereskedési szignálokat és trendváltozásokat azonosítson. Ezen túlmenően, a Relative Volume vagy On-Balance Volume (OBV) és számos más <Link to='/fogalomtar/indikator' className='dezord'>indikátor</Link> is segíthet a kereskedőknek, hogy a volumen változása alapján alkossanak előrejelzéseket.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Forgalom