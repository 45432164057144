import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import './footer.css'
import { useLocation } from 'react-router-dom';

function Footer() {

    const location = useLocation();
    const isOnSzabalyok = location.pathname === '/alapszabalyok';
    const isOnKonyvek = location.pathname === '/konyvek';
    const isOnRoadmap = location.pathname === '/roadmap';
    const isOnGyagy = location.pathname === '/gyik';
    const isOnKakao = location.pathname === '/kapcsolat';
    const isOnAdnem1 = location.pathname === '/impresszum';
    const isOnAdnem2 = location.pathname === '/adatkezelesi-tajekoztato';
    const isOnAdnem3 = location.pathname === '/jogi-nyilatkozat';


    const isOnTanfoly = location.pathname === '/tanfolyam';



    const isOnRoadmap1 = location.pathname === '/roadmap/elso';
    const isOnRoadmap2 = location.pathname === '/roadmap/masodik';
    const isOnRoadmap3 = location.pathname === '/roadmap/harmadik';
    const isOnRoadmap4 = location.pathname === '/roadmap/negyedik';
    const isOnRoadmap5 = location.pathname === '/roadmap/otodik';
    const isOnRoadmap6 = location.pathname === '/roadmap/hatodik';

    const isOnKvvv = location.pathname === '/fogalomtar/kviz';

    return (
        <>
            <footer className={`${isOnSzabalyok ? 'szabaly5' : 'fter'} ${isOnKonyvek ? 'kony5' : ''} ${isOnRoadmap ? 'roadtrip' : ''} ${isOnRoadmap1 ? 'roadtralt' : ''} ${isOnRoadmap2 ? 'roadtralt' : ''} ${isOnRoadmap3 ? 'roadtralt' : ''} ${isOnRoadmap4 ? 'roadtralt' : ''} ${isOnRoadmap5 ? 'roadtralt' : ''} ${isOnRoadmap6 ? 'roadtralt' : ''} ${isOnKvvv ? 'roadtralt' : ''} ${isOnGyagy ? 'roadtralt' : ''} ${isOnKakao ? 'roadtralt' : ''} ${isOnAdnem1 ? 'roadtralt' : ''} ${isOnAdnem2 ? 'roadtralt' : ''} ${isOnTanfoly ? 'roadtralt' : ''} ${isOnAdnem3 ? 'roadtralt' : ''}`.trim()}>
                <div className="container">
                    <div className="row">
                        <div className="footer-col">
                            <h3>befektetésközpont</h3>
                            <ul>
                                <li><Link className='href' to='/impresszum'>Impresszum</Link></li>
                                <li><Link className='href' to='/adatkezelesi-tajekoztato'>Adatkezelési tájékoztató</Link></li>
                            </ul>
                        </div>
                        <div className="footer-col">
                            <h3>segítség</h3>
                            <ul>
                                <li><Link className="href" to='/gyik'>GYIK</Link></li>
                                <li><Link className="href" to='/jogi-nyilatkozat'>Jogi nyilatkozat</Link></li>
                            </ul>
                        </div>
                        <div className="footer-col">
                            <h3>Kapcsolat</h3>
                            <ul>
                                <li><Link className="href" to='https://discord.gg/7YyQckXAxY'>Discord közösség</Link></li>
                                <li><Link className="href" to='/kapcsolat'>Kapcsolatfelvétel</Link></li>
                            </ul>
                        </div>
                        <div className="footer-col">
                            <h3>Kövess minket!</h3>
                            <div class="social-links">
                                <Link className="href minilogo" to='https://www.instagram.com/befekteteskozpont/' aria-label="instagram" ><i class="fab fa-instagram"></i></Link>
                                <Link className="href minilogo" to='https://www.facebook.com/befekteteskozpont/' aria-label="facebook" ><i class="fab fa-facebook-f"></i></Link>
                                <Link className="href minilogo" to='https://twitter.com/befkozpont' aria-label="twitter" ><i class="fab fa-x-twitter"></i></Link>
                                <Link className="href minilogo" to='https://discord.gg/7YyQckXAxY' aria-label="discord" ><i class="fab fa-discord"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer