import React from 'react'
import './Road.css'
import Footer from "../footer/footer";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';


function Otodikallomas() {
  return (
    <>
    <Helmet>
        <title>5. Befektetési stratégiák és portfóliókezelés | Befektetésközpont</title>
        <meta name="description" content="Ezen az állomáson megismerkedhetsz különböző befektetési stratégiákkal és a portfóliók kialakítsával. Megtalálhatod a számodra legmegfelelőbb befektetési stratégiát, majd képessé válhatsz a portfóliód kiépítésére, optimalizálására és értékelésére." data-react-helmet="true" />
      </Helmet>
      <div className='allomascontent'>
        <div className='allomassection'>
          <div className='allcontentheader'>
            <h1>Befektetési stratégiák és portfóliókezelés</h1>
            <p>Ezen az állomáson megismerkedhetsz különböző befektetési stratégiákkal és a portfóliók kialakítsával. Megtalálhatod a számodra legmegfelelőbb befektetési stratégiát, majd képessé válhatsz a portfóliód kiépítésére, optimalizálására és értékelésére.</p>
          </div>
          <div className='allcontentcontent'>
            <h2>Befektetési stratégiák</h2>
            <p>Befektetési stratégiának nevezzük azokat az alapelveket, amik alapján egy befektető meghozza a döntéseit. Ahány befektető, annyi befektetési stratégia létezik. Nincsen általánosan elfogadott jó vagy rossz stratégia, mivel minden ember számára más az ideális. Az, hogy a mi alapelveinket mi határozza meg, azt a kockázatvállalásai hajlandóságunk, a rendelkezésre álló tőként, az időtáv, amiben gondolkozunk, illetve a befektetési stílusunk és célunk határozza meg.</p>
            <br />
            <p>Megkülönböztetünk konzervatív és agresszív befektetési stratégiákat. Minél konzervatívabb egy megközelítés, annál inkább az alacsony kockázat, illetve a stabil és állandó hozam felé hajlik. Az agresszív stratégia esetén pedig a gyors és magas szintű tőkenövekedés áll inkább a középpontban, ami viszont magasabb kockázattal is jár. A kockázat mértéke a befektetési stratégiánknak egy fontos összetevője. A kockázat, amit egy befektető beépít a stratégiájába, a személyes helyzetétől függ. Például egy 4 gyerekes családapának nem ajánlott eladnia a házat és berakni a pénzt Bitcoinba. Fontos megjegyezni, hogy egy befektetőnek csak akkora kockázatot szabad vállalnia, amekkorát megengedhet magának, hogy elveszítsen. A példánkban szereplő 40 éves apuka, Ferenc, aki nyugdíjas korára szeretne pénzt megtakarítani, érdemesebb lehet kötvénybe, állampapírba, esetleg egy befektetési alapba fektetnie, ami stabil hozamot biztosít számára, alacsony kockázatok mellett. A kockázatvállalási hajlandóságunkat nagyrészt az érzelmeink határozzák meg. Ha a jelenlegi stratégiánk mellett folyamatosan aggódunk az esetleges veszteségek miatt, akkor valószínűleg túl nagy kockázatot vállaltunk és érdemes biztonságosabb útra terelni a tervünket.</p>
            <br />
            <p>A befektetési stratégiánkat felhasználhatjuk a saját portfóliónk kialakításához, vagy megbízhatunk egy szakértőt, aki a preferenciáink szerint összeállítja nekünk a palettát. Azonban egy stratégia nem feltétlenül állandó, ugyanis előfordulhat, hogy a körülményeink változásával a stratégiánkat is meg kell változtatni. Például a befektetőknek koruk előrehaladtával át kell értékelniük és meg kell változtatniuk a stratégiáikat, hogy a portfóliójukat az aktuális helyzetükhöz igazítsák.</p>
            <br />
            <br />
            <img src="/imgvideo/nomadeletmod.jpg" alt="befektetési stratégiák" width='100%'/>
            <br />
            <br />
            <h3>Értékalapú befektetés</h3>
            <p className="h3alter">Az értékalapú befektetés során olyan befektetéseket keresünk, amikről úgy gondoljuk, hogy alulértékeltek. A stratégia lényege, hogy egy eszközön fundamentális elemzést hajtunk végre, ami alapján meghatározzuk a valódi értékét. Amennyiben ez az érték magasabb, mint az ár, akkor az eszköz befektetésre érdemes. Az értékalapú befektetés részben azon az elképzelésen alapul, hogy a piacon fennáll egy bizonyos fokú irracionalitás, ami lehetőséget kínál arra, hogy egy befektetéshez kedvezményes áron juthassunk hozzá. Vagyis egy befektetés értéke változatlan maradhat miközben az árfolyama fel-alá futkározik. Egy eszköz olyan tényezőknek köszönhetően válhat alul- vagy túlértékeltté, mint egy piaci összeomlás, a csordaösztön, a ciklikusság vagy az eszközt érintő hírek.</p>
            <br />
            <p className="h3alter">Az értékalapú befektetés hosszas kutatást és elemzést igényel a legmegfelelőbb portfólió felépítéséhez. Szerencsére most már nagyon könnyen hozzáférhetünk bármilyen eszköz pénzügyi adataihoz, ami kényelmesebbé teszi ezt az elemzést. Az, hogy milyen szempontok vizsgálandóak egy befektetés értékének meghatározásánál, azt már a 3. állomásnál megtárgyaltuk. Ez a módszer egy abszolút hosszú távú megközelítést igényel, illetve rengeteg türelem, érzelmi fegyelmezettség és szaktudás szükséges hozzá.</p>
            <br />
            <h3>Growth befektetés</h3>
            <p className="h3alter">A growth befektetés olyan lehetőségek kereséséről szól, amik magas növekedési potenciállal rendelkeznek. Ez a stratégia ugyanúgy elemzést igényel, viszont a kutatás során a hangsúly az alulértékeltség helyett a magas növekedési kilátáson van. Számos befektető ötvözi a stratégiájában az értékalapú és a growth befektetést. A growth befektetés nem számít spekulációnak, de kétségkívül spekulatívabb, mint egy értékalapú megközelítés.</p>
            <br />
            <p className="h3alter">A growth befektetés részben a fennálló trendek meglovagolásán alapul. Egy növekedési részvényekből álló portfólió osztalékfizetés tekintetében elég szegény. Ez azért van, mert az ilyen részvények esetében az osztalékot inkább visszaforgatják a vállalatba a gyorsabb növekedés reményében. Az alacsony osztalék miatt, azoknak a befektetőknek, akik egy biztonságosabb, jövedelemtermelő portfóliót szeretnének kiépíteni, nem érdemes növekedési részvényekbe fektetni, már csak ha a kockázatosságát vizsgáljuk sem. Egy piaci szakadás első áldozatai általában a növekedési részvények szoktak lenni.</p>
            <br />
            <p className="h3alter">A növekedési részvényeket könnyen felismerhetjük az gyors ütemű EPS növekedésről, illetve a gyakran magas P/E rátáról. A growth befektetők nem nagyon aggódnak a magas P/E érték miatt, ugyanis a növekedés mértéke kompenzálja a magas P/E rátát, vagyis a PEG alacsony. Hasonlóképpen nincs ok aggodalomra P/B vagy bármely más hasonló mutató esetében, egészen addig amíg növekedés üteme megfelelő.</p>
            <br />
            <p className="h3alter">A growth befektetési lehetőségek kockázatosabbak, és általában csak bizonyos gazdasági körülmények között virágoznak. Ez a befektetési módszer leginkább azoknak ajánlott, akik rövid idő alatt szeretnének jelentős hozamokat elérni, miközben képesek vállalni az ezzel járó magasabb kockázatot.</p>
            <br />
            <h3>Jövedelemtermelő befektetés</h3>
            <p className="h3alter">A jövedelemtermelő stratégia lényege, hogy olyan eszközökbe fektetünk, amiknek rendszeres kifizetései vannak. Ilyen eszköz például a kötvény, az osztalékfizető részvény vagy a kincstárjegy. Azoknak ajánlott követniük ezt a stratégiát, akik a stabil, állandó hozamra törekszenek. Úgy is fel lehet fogni ezt a módszert, mint egyfajta második fizetés, mivel a kamatot és az osztalékot rendszeresen, pénz formájában kapjuk meg, annak a tőkének a fialásával, ami amúgy a bankban ülne.</p>
            <br />
            <h3>Momentum befektetés</h3>
            <p className="h3alter">Vagy inkább momentum kereskedés, egy olyan módszer, aminek a célja, hogy kihasználja a meglévő piaci trend folytatódását. A stratégia lényege, hogy a befektető olyan eszközöket vásárol, amelyek már emelkedőben vannak, és akkor próbálják eladni őket, amikor úgy tűnik, hogy elérik a csúcspontjukat. A momentum befektetők alapelgondolása, hogy ami növekszik az továbbra is növekedni fog, ami pedig csökken, az továbbra is csökkenni fog. A momentum kereskedés abban is különbözik az előző két stratégiától, hogy nem feltétlenül szükséges hozzá fundamentális elemzés. Helyette technikai elemzést szoktak alkalmazni, ami az árfolyamgrafikon elemzéséről szól. Úgy alkalmazzák a technikai elemzést, hogy az árfolyamon különböző mintákat keresnek, amik a vásárlási döntéseket irányítják.</p>
            <br />
            <p className="h3alter">A momentum befektetés az erősen spekulatív jellege miatt a legkockázatosabb stratégiák közé sorolható. Inkább azok számára alkalmas, akiknek van olyan tőkéjük, amelynek esetleges elvesztése nem okoz nagy gondot. Amilyen nagyon lehet nyerni ezzel a módszerrel, legalább olyan nagyot lehet veszíteni is.</p>
            <br />
            <h3>Költségátlagolásos befektetés</h3>
            <p className="h3alter">Ez a stratégia már említésre került az előző állomáson is. A módszer lényege, hogy ártól függetlenül, megszabott időközönként mindig ugyanakkora összeget szánunk befektetésre, ezzel számos pszichológiai csapdát elkerülve. Először egy másik stratégia segítségével fel kell építenünk egy portfóliót, vagy ki kell választanunk egy eszközkosáron alapuló befektetést (pl. ETF), majd ezen alkalmazni a költségátlagolást.</p>
            <br />
            <p className="h3alter">A költségátlagolásos befektetés hasznos lehet azok számára, akik ki szeretnék használni az előnyeit, amik között ott van például a rendszeres automatikus befektetés vagy annak a stressznek az elkerülése, hogy esetleg rossz döntést hozunk. A módszer különösen hasznos lehet kezdő befektetők számára, akik még nem rendelkeznek kellő tudással vagy tapasztalattal ahhoz, hogy megtalálják a vásárlás legmegfelelőbb pillanatát, illetve azoknak, akik valamilyen okból kifolyólag nem tudnak időt szánni a befektetés időzítésére.</p>
            <br />
            <h3>Buy and hold befektetés</h3>
            <p className="h3alter">A buy and hold befektetés, ami az értékalapú befektetés részét is képezi, egy olyan stratégia, amely során befektetünk egy eszközbe, majd az árfolyam kilengéseitől függetlenül, hosszú ideig tartjuk. Mivel a stratégia csak egy vételi pozíciót igényel, ezért nem alkalmaz kereskedést, ami a legtöbb befektető számára sose jövedelmező. Az így összeállított portfólió eredménye kizárólag a megvásárolt eszköz teljesítményétől függ. Egy nagy előnye ennek a stratégiának, hogy mivel a nagy képet nézzük és nem próbáljuk meg legyőzni az amúgy is a legtöbbek számára legyőzhetetlen piacot, keveset kell foglalkoznunk a portfóliónkkal.</p>
            <br />
            <h3>Aktív vagy passzív befektetés</h3>
            <p className="h3alter">Befektetésben megkülönböztetünk aktív, illetve passzív megközelítést. Az aktív stratégia lényege, hogy a befektető megpróbálja felülteljesíteni a piacot. Ez lényegében azt jelenti, hogy nagyobb hozamot próbál elérni a befektetésein, mint amit akkor érne el, ha csak megvásárolná az eszközt és nem kereskedne vele. Befektetési alapok között vannak aktív, illetve passzív alapok. Az aktív alapoknak szükségük van vagyonkezelőkre, akik elvégzik az elemzést, majd szerkesztik a befektetési alap portfóliójának összetételét. A passzív alapok egy bizonyos eszközkosarat, az az indexet követnek, ami a piacot vagy a piac egy szegmensét reprezentálja. Az, hogy te melyik lehetőséget választod, attól függ, hogy le szeretnéd-e győzni a piacot, vagy inkább haladsz vele együtt.</p>
            <br />
            <h2>Portfólió kialakítása</h2>
            <p>A <Link to='/fogalomtar/portfolio' className='dezord'>portfólió</Link> olyan befektetési eszközök gyűjteménye, melyet egy befektető a pénzügyi céljai elérése érdekében kezel, vagy kezeltet. Ezt a ,,gyűjteményt" az általunk kiválasztott befektetési stratégia szerint építjük fel és módosítjuk az idő elteltével. Egy befektetési portfólió felépítése előtt meg kell határoznunk a saját befektetési stratégiánkat. Miután ezzel megvagyunk, belevághatunk a portfólió felépítésébe. Először fel kell kutatnunk és ki kell választanunk azokat a befektetési lehetőségeket, amelyek a leginkább megfelelnek számunkra, majd elhelyezni őket a portfóliónkban. Azonban érdemes odafigyelni arra, hogy az eszközök, amiket kiválasztunk, hogyan befolyásolják a portfólió struktúráját. Elengedhetetlen szempont például a diverzifikáció.</p>
            <br />
            <h3>Diverzifikáció</h3>
            <p className="h3alter">A <Link to='/fogalomtar/diverzifikacio' className='dezord'>diverzifikáció</Link> egy a portfóliók kockázatosságának csökkentésére használt módszer. Úgy csökkenti a portfólió kockázatosságát, hogy különböző eszközökben, eszközosztályokban, iparágakban és egyéb kategóriák szerinti bontásban osztja szét a tőkét. Minden eszköz máshogy reagál egy eseményre, ezért érdemes a tőkénket úgy megosztani a különböző területek között, hogy valamelyik befektetésünk mindig termeljen hozamot. Minden portfóliónak az eszközösszetételétől függetlenül tartalmaznia kell bizonyos fokú diverzifikációt, aminek illeszkednie kell a befektető saját pénzügyi helyzetéhez.</p>
            <br />
            <p className='h3alter'>Előfordul befektetők között az a hiba, hogy 1-2 részvényben osztják meg a tőkéjüket, majd amikor az egyik befektetésük csődbe megy, vagy drasztikus zuhanáson megy keresztül, akkor elvesztik akár a befektetett tőkéjük egészét. Ezért is érdemes minimum 7-8 eszközbe szétosztani a pénzünket. Persze ha például passzív befektetés mellett döntünk és mondjuk az S&P 500 indexbe fektetünk, akkor nem kell aggódnunk a diverzifikáltság miatt.</p>
            <br />
            <h3>Átsúlyozás</h3>
            <p className='h3alter'>Az átsúlyozás lényege, hogy a portfóliónkat megszabott időközönként (általában évente) visszaállítjuk az eredeti eszközmegosztás szerint. Például ha van egy 60% kötvény, 40% részvény portfóliónk, de egy év elteltével ez az arány elcsúszik 50-50%-ra, mivel a részvények hozama magasabb volt, akkor alkalmazhatjuk az átsúlyozást, hogy a portfólió szerkezetét normál helyzetbe állítsuk. Az átsúlyozásra azért lehet szükség, mert a portfóliónk bizonyos elemei úgy írhatják át a portfóliónk kockázatosságának mértékét, hogy az nem kedvez a mi preferenciáinknak.</p>
            <br />
            <br />
            <img src="/imgvideo/tarogatogep.jpg" alt="két portfólió" width='100%'/>
            <br />
            <br />
            <p>A legnépszerűbb portfólió típus a részvény-kötvény portfólió, aminek a lényege, hogy a részvények kockázatosságát kötvényekkel hígítjuk fel a mi kockázatvállalási preferenciáink szerint. Viszont egy ilyen portfólió nem mindenkinek megfelelő. A szélesebb diverzifikáltság érdekében hozzáadhatunk befektetési jegyeket, ETF-eket vagy nemesfémeket a portfóliónkhoz.</p>
            <br />
            <h3>Portfólió értékelése</h3>
            <p className='h3alter'>A portfóliónkat érdemes nem csak az alapján értékelni, hogy mekkora hozamot termelt. Figyelembe kell vennünk azt is, hogy mekkora kockázatot vállaltunk egységnyi hozam elérése érdekében. Erre a célra kiváló eszköz lehet a <Link to='/fogalomtar/sharpe-mutato' className='dezord'>Sharpe</Link>, a Treynor, illetve a Jensen mutató.</p>
            <br />
            <p className='h3alter'>Példa: &nbsp; Treynor mutató = <span class="frac"><sup>Portfólió hozama - kockázatmentes hozam</sup><span>&frasl;</span><sub>Béta mutató</sub></span></p>
            <br />
            <p className='h3alter'>A treynor mutató számítása során azt vizsgáljuk, hogy a kockázatmentes hozam felett elért hozam mekkora része származik magasabb kockázatvállalásból. Minél magasabb a treynor érték, annál sikeresebb a portfólió, mivel annál nagyobb hozamot tudtunk elérni a vállalt kockázatért cserébe. A treynor ráta esetében a kockázatot a portfólió <Link to='/fogalomtar/beta-mutato' className='dezord'>béta mutatójával</Link> mérjük, míg a sharpe mutatónál a portfólió hozamának szórása kerül a nevezőbe.</p>
            <br />
            <p>A portfólió összeállításakor és későbbi módosításai során is ügyelnünk kell arra, hogy tükrözze a saját pénzügyi céljainkat és kockázattűrő képességünket. Az, hogy milyen gyakran vizsgáljuk felül és változtatjuk meg a portfóliónkat az eléggé egyénfüggő. Általánosságban elmondható, hogy minél kockázatosabb egy portfólió, érdemes annál gyakrabban felülvizsgálni. Amennyiben nem vagyunk biztosak abban, hogy a portfóliónk hogyan nézzen ki, ajánlott szakember segítségét kérni.</p>
            <br />
            <div className='lowernavbar'>
              <button className='btn btn0 success' onClick={() => { window.location.href = '/roadmap'; }}>Vissza a Roadmap-re</button>
              <button className='btn btn0 success' onClick={() => { window.location.href = '/roadmap/hatodik'; }}>Következő állomás</button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Otodikallomas
