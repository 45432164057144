import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Volatilitas() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Volatilitás - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a volatilitás jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>



                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Volatilitás</h1>
                            <p>Ezen az oldalon megismerheted a volatilitás jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A volatilitás egy eszköz áringadozásának a mértéke.</p>
                            <p className="majonator">A magas volatilitás azt jelenti, hogy egy eszköz értéke nagyobb értéktartományban szóródhat, vagyis az eszköz árfolyama nagyobb mértékben változhat bármelyik irányba. Alacsony volatilitás esetén az eszköz árfolyama kevésbé ingadozik, inkább stabil, egyenletes. A volatilitást az instrumentum átlagos hozamától mért átlagos eltérés négyzetével mérjük, vagyis a hozam szórásával. A magas volatilitású eszközök általában nagyobb hozamot kínálnak, de magasabb kockázattal is járnak.</p>
                            <hr />
                            <h2>Kiszámítása</h2>
                            <img src="/imgvideo/blankszam.png" alt="nem" width='250px' />
                            <p className="majonatora">n = értékek száma &nbsp; | &nbsp; x<sub>i</sub> = egy érték &nbsp; | &nbsp; x̄ = értékek átlaga</p>
                            <p className="majonatora">A volatilitás mérését általában egy évre vetítve, napi árfolyamadatokkal végzik. Példánkban 5db hozamértékünk van: 3%, 4%, 5%, 6% 10%. (Használhatnánk egy eszköz árfolyamának értékeit egy meghatározott időszakon belül is) Ezeknek a hozamoknak az átlaga 5,6%. Az értékek átlagtól való eltéréseinek kiszámítása után új értékeket kapunk: -2,6%, -1,6%, -0,6%, 0,4%, 4,4%, melyeket négyzetre emelünk és összeadunk: (-2,6<sup>2</sup>) + (-1,6<sup>2</sup>) + (-0,6<sup>2</sup>) + 0,4<sup>2</sup> + 4,4<sup>2</sup> = 9,84 &nbsp; Ezt az értéket elosztjuk 4-gyel, majd gyököt vonunk belőle, így az eredmény 1,56.</p>
                            <hr />
                            <h2>Befektetési stratégia</h2>
                            <p className="majonator">Minél magasabb a volatilitás, annál kockázatosabb az eszközbe való befektetés. Azok a befektetők, akik hajlandóak nagyobb kockázatot vállalni, magas volatilitású eszközöket is felvehetnek a portfóliójukba. Azon befektetők számára, akik inkább a stabilitást és a biztonságot részesítik előnyben, érdemesebb lehet alacsony volatilitású eszközöket választani.</p>
                            <hr />
                            <h2>Volatilitás fajtái</h2>
                            <p className="majonator">Az implikált volatilitást az eszköz jövőbeli volatilitásának becslésére használják. A jövőbeli volatilitást kiszámítását statisztikai becslések alapján lehet elvégezni. A volatilitás az <Link to='/fogalomtar/opcio' className='dezord'>opciók</Link> árazásának egyik legfontosabb eleme, amely azt becsüli meg, hogy az eszköz hozama milyen mértékben fog ingadozni a jelen pillanat és az opció lejárata között. A magasabb volatilitás magasabb opciós árat jelent. Fontos észben tartani, hogy semmilyen jövőn alapuló mutató nem ad pontos előrejelzést.</p>
                            <p className="majonator">A statisztikai volatilitás esetében a már megtörtént árfolyamváltozás felhasználásával végzik a számítást. Ha az eszköz árfolyama a szokásosnál nagyobb kilengéseket mutat, akkor a statisztikai volatilitás emelkedik. Ez a befektetők bizonytalanságából, esetleg egy jövőbeli esemény várakozásából vagy az eszköz kockázatosságából fakadhat. Ha viszont csökken a statisztikai volatilitás, akkor az a bizonytalanság megszűnését, a dolgok normál helyzetbe való visszaállását jelentheti.</p>
                            <hr />
                            <h2>Béta mutató</h2>
                            <p className="majonatora">A <Link to='/fogalomtar/beta-mutato' className='dezord'>béta mutató</Link> azt fejezi ki, hogy az adott instrumentum mennyire volatilis a piaci átlaghoz képest. A béta mutató egy adott indexhez viszonyítva fejezi ki, hogy mennyire kockázatos egy eszköz. Például az amerikai részvényeket az S&P 500 volatilitásához hasonlítják. Ha a béta értéke 1, akkor az eszköz volatilitása megegyezik a piacéval.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Volatilitas