import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Recesszio() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Recesszió - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a recesszió jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Recesszió</h1>
                            <p>Ezen az oldalon megismerheted a recesszió jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A recesszió a gazdaság jelentős visszaesése, amely legalább két egymást követő negyedévben zajlik.</p>
                            <p className="majonator">A recesszió hosszát az előző bikapiac csúcspontjától a visszaesés mélypontjáig mérik. A recesszió akár néhány hónapig is tarthat, de előfordulhat, hogy a gazdaság csak évek múlva éri el a recesszió mélypontját. Az utóbbi években a recessziók ritkábbak lettek, és nem tartanak olyan sokáig. Egy recesszió esetleges okozói közé tartozhat a túlzott infláció, egy háború, természeti katasztrófa, világjárvány, spekuláció stb.</p>
                            <hr />
                            <h2>Recesszió hatásai</h2>
                            <p className="majonator">A recesszió a gazdasági tevékenység csökkenésével és a munkanélküliség növekedésével jár. A csökkenő fogyasztói kereslet arra késztetheti a <Link to='/fogalomtar/vallalat' className='dezord'>vállalatokat</Link>, hogy elbocsátásokat hajtsanak végre, ami kihat a vásárlóerőre, és tovább gyengítheti a fogyasztói keresletet. A munkanélküliség gyakran a gazdasági fellendülés során is magas marad, így a fellendülés korai szakasza sokak számára még recessziónak tűnhet.</p>
                            <p className="majonator">Az alkalmazottak számára a recesszió azt jelentheti, hogy elveszítik az állásukat, kevesebb pénzt keresnek, és többet kell költeniük a szükséges dolgokra. Recesszió esetén a kormányoknak csökkennek az adóbevételeik, és többet kell költeniük a munkanélküli segélyekre, a szociális támogatásra és a gazdaság ösztönzésére.</p>
                            <p className="majonator">A vállalatok számára a recesszió a nyereségük csökkenését jelentheti, illetve nagyobb költekezést a működésük fenntartására. Ez veszteségeket és nagyobb eladósodást okozhat, és egyes vállalatok akár <Link to='/fogalomtar/csod' className='dezord'>csődbe</Link> is mehetnek. Emellett a recesszió a hitelhez jutást is korlátozza. Ha a profitcsökkenés különösen meredek, egyes vállalatok kénytelenek lehetnek csökkenteni vagy megszüntetni a részvényesek <Link to='/fogalomtar/osztalek' className='dezord'>osztalékát</Link>. Minél kisebb egy vállalat, annál sebezhetőbb.</p>
                            <hr />
                            <h2>Recesszió alatt jól teljesítő iparágak</h2>
                            <p className="majonator">Recesszió alatt azok a vállalatok teljesítenek jól, amik termékei és szolgáltatásai minden helyzetben nélkülözhetetlenek. Azt, hogy melyik iparág fog jobban teljesíteni egy recesszió alatt, az is befolyásolja, hogy mi volt a kiváltó ok. Például egy világjárvány esetén nagy eséllyel az egészségügyi vállalatok fognak a legjobban járni. &nbsp;|&nbsp; Recesszió alatt általában jól teljesítő iparágak: egészségügy, ingatlan, kommunikáció, élelmiszer</p>
                            <hr />
                            <h2>Recesszió előnye</h2>
                            <p className="majonator">Minél régebben volt a legutolsó recesszió, annál több negatív hatást fog magával hozni az új recesszió. A recesszió egy olyan időszak, amikor rengeteg gyengén gazdálkodó vállalat megy csődbe. Hosszabb távon több lufit lehet felfújni, ami majd a recessziót követően kilyukat. Vagyis a gyenge vállalatok kiválogatásában fontos szerepet játszik a recesszió.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Recesszio