import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Bankbetet() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Bankbetét - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted az bankbetét jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Bankbetét</h1>
                            <p>Ezen az oldalon megismerheted az bankbetét jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A betét a banknál betétszámlán vagy betéti okirat ellenében elhelyezett pénzösszeg, ami után a bank kamatot fizet.</p>
                            <p className="majonator">A bankbetét egy olyan pénzügyi termék, amelyet bankok kínálnak ügyfeleik számára. Ez az egyik leggyakoribb és legbiztonságosabb módja annak, hogy pénzt tároljunk és kamatot kapjunk a megtakarításainkért cserébe. A bankok a betétekben lekötött pénzt különböző beruházásokra használják fel és ezt kamattal hálálják meg a befektetőnek. A bankbetétek általában rögzített lejáratúak, és a kamatokat a lejáratig rendszeresen fizetik.</p>
                            <hr />
                            <h2>Fajtái</h2>
                            <p className="majonator azam">Lekötött vagy látra szóló</p>
                            <p className="majonatora">Lekötött betét esetében csak a lekötési idő lejártakor lehet kivenni a pénzt. Ez magasabb <Link to='/fogalomtar/hozam' className='dezord'>hozamot</Link> eredményez. A látra szóló betétnél bármikor hozzáférhetünk az összeghez, amiből az következik, hogy sokkal alacsonyabb lesz a kamatkifizetés.</p>
                            <br />
                            <p className="majonator azam">Fix vagy változó kamatozású</p>
                            <p className="majonatora">Fix kamatozásnál a teljes futamidő alatt állandó és meghatározott a kamatfizetés, míg változó kamatozásnál módosulhat a hozam mennyisége. Fix és változó kamatozáson belül megkülönböztetünk három másik kamatozási módot. Lépcsős kamatozásról beszélünk, ha az elhelyezett összeg függvényében változik a kamatfizetés. Sávos kamatozásnál a befektethető összegek szerint sávokat szabnak meg, ahol minden sávban más a kamatfizetés mértéke. Nominális kamatozás esetében az elhelyezett összeg mértéke nem befolyásolja a kamatkifizetést.</p>
                            <br />
                            <p className="majonator azam">Egyszeri vagy ismétlődő</p>
                            <p className="majonatora">Egyszeri elhelyezés esetén a futamidő végén a lekötött összeg a kamattal együtt kifizetésre kerül. Viszont, ha ismétlődő elhelyezést választunk, akkor a futamidő végén a betét újra lekötik. Betétkötés előtt ki lehet választani, hogy a lejárat után szerzett bevételt visszaforgassa-e a bank a betétbe.</p>
                            <hr />
                            <h2>Kockázat és hozam</h2>
                            <p className="majonator">A bankbetétek nagyon alacsony kockázatú és könnyen kiszámítható eszközök. Az állami betétbiztosítási rendszerek védelmet nyújtanak a bankokban elhelyezett összegekre. Ez azt jelenti, hogy ha a bank <Link to='/fogalomtar/csod' className='dezord'>csődbe</Link> menne, az állam a meghatározott összegig megtéríti a betétbe fektetett tőkét. Mivel a betétek alacsony kockázatúak, ebből következik, hogy a hozam is alacsonyabb. Minél hosszabb futamidőre kötelezzünk el magunkat egy bankbetét mellett, annál magasabb lesz a kamatozás mértéke.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Bankbetet