import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Peg() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>PEG mutató - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a PEG jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>PEG mutató</h1>
                            <p>Ezen az oldalon megismerheted a PEG jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A PEG mutató a P/E mutató egy olyan módosítása, amely figyelembe veszi a vállalat nyereségnövekedését.</p>
                            <p className="majonator">A PEG mutató egy olyan eszköz, amelyet a vállalatok részvényeinek értékelésére használnak a befektetők. A magas PEG túlértékeltségre, míg az alacsony érték alulértékeltségre utalhat. Egy részvény PEG értéke különböző források között eltérhet, mivel az elemzők különböző jövőbeli EPS adatokat és számítási módszereket használhatnak.</p>
                            <hr />
                            <h2>Kiszámítása</h2>
                            <p className="majonator">PEG = részvény piaci ára / EPS / EPS növekedés</p>
                            <p className="majonator">Vagyis úgy is ki lehet számolni, hogy ,, P/E / EPS növekedés ". Az <Link to='/fogalomtar/eps' className='dezord'>EPS</Link> a vállalat egy részvényére jutó nyereségét fejezi ki. Segítségével megtudhatjuk, hogy egy bizonyos időintervallum alatt a vállalat mekkora nyereséget ért el egy részvényre vetítve. A PEG nem ad vissza értéket olyan vállalatok esetében, amelyek veszteségesek vagy negatív a jövőbeli EPS-ük. (Ha jövőbeli EPS-el számolunk.)</p>
                            <p className="majonator">Amennyiben egy vállalat <Link to='/fogalomtar/pe' className='dezord'>P/E mutatója</Link> magas értéket ad vissza, de a növekedési üteme kellőképpen nagy, akkor nem feltétlenül túlértékelt a részvény. Ha két vállalat P/E mutatói azonosak, akkor érdemes lehet összehasonlítani a PEG mutatóikat, annak érdekében, hogy meg tudjuk határozni, hogy melyik részvény az olcsóbb. Mint minden másik fundamentális mutatóra, a PEG mutatóra is igaz, hogy nem szabad csak ez alapján értékelni egy részvényt, hanem számos más értékelési szempontot is fegyelembe kell venni.</p>
                            <p className="majonator">Például ha egy vállalat P/E mutatója 15 és a várható ötéves EPS növekedés mértéke 10%, akkor a PEG mutató 1,5 lesz. Egy másik vállalatnak a P/E mutatója csak 10, viszont a növekedési ütem is kisebb, 5%-os, így a PEG értéke 2 lesz. Ha csak ennyi információ áll rendelkezésünkre, akkor kimondhatjuk, hogy az első részvény olcsóbb, mint a második.</p>
                            <p className="majonator">Csak mint a P/E mutató esetében, a PEG-nél is megkülönböztetünk forward és trailing számítási módszereket. A forward esetében a nyereségnövekedést a következő öt év várható eredménye alapján számítjuk, míg trailing esetében a múltbeli EPS adatokra támaszkodunk.</p>
                            <hr />
                            <h2>PEG értelmezése</h2>
                            <p className="majonator">Lehet, hogy egy részvény a P/E mutató alapján jó vételnek tűnik, de ha beleszámítjuk a vállalat növekedési kilátásait és összehasonlítjuk az iparági átlagokkal, a gazdasági környezettel és egyéb vállalati tényezőkkel, akkor máris más képet kapunk. A vállalat növekedési mértékének hozzáadása a P/E mutatóhoz segít kiigazítani az eredményt azon vállalatok esetében, amelyeknek a nagy növekedési potenciálból fakadóan magas a P/E mutatójuk. Minél alacsonyabb a PEG mutató, annál inkább alulértékelt lehet egy részvény.</p>
                            <p className="majonator">A PEG mutató egy hibája, hogy az elemzők nem képesek pontosan megjósolni az EPS jövőbeli változását, így különböző forrásokban más-más értéket adhat.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Peg