import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Broker() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Bróker - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted az bróker szó jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Bróker</h1>
                            <p>Ezen az oldalon megismerheted az bróker szó jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A bróker (alkusz) olyan ügynök, aki megbízás alapján értékpapírokkal kereskedik.</p>
                            <p className="majonator">A pénzügyi szolgáltató tevékenységet általában nem egyes személyek, hanem erre jogosult brókercégek végzik. A bróker olyan személy vagy cég, amely közvetítőként jár el a befektető és az tőzsde között. A tőzsdék csak olyan személyektől vagy szervezetektől fogadnak el megbízásokat, akik tagjai az adott tőzsdének. A tranzakciók kezelése mellett a bróker számos más befektetési szolgáltatást nyújthat az ügyfélnek.</p>
                            <hr />
                            <h2>Brókercégek működése</h2>
                            <p className="majonator">A brókercégek lehetővé teszik ügyfeleik számára értékpapírok és más pénzügyi eszközök kereskedelmét a <Link to='/fogalomtar/tozsde' className='dezord'>tőzsdén</Link> vagy más pénzügyi piacokon. A brókercégek közvetítőként működnek az ügyfelek és a pénzpiac között, lehetőséget teremtve az ügyfeleknek különböző befektetési lehetőségek kihasználására.</p>
                            <p className="majonator">Számos brókercég működik úgy, hogy nem teremt közvetlen kapcsolatot a tőzsdével, hanem más brókereken keresztül jut hozzá eszközökhöz, szolgáltatásokhoz. A brókercégek tranzakciós díjat vagy átalánydíjat számolnak el költségként. A díj mértéke brókerenként eltér.</p>
                            <hr />
                            <h2>Brókercég választás</h2>
                            <p className="majonator">Érdemes alaposan megvizsgálni a brókercégek hírnevét és hátterét. Keressünk olyan brókert, amely megbízható múlttal rendelkezik. Érdemes megkeresni az interneten található véleményeket, ügyfél visszajelzéseket, és szakmai értékeléseket is. Törekedjünk arra, hogy olyan brókercéget válasszunk, amelyik szigorú felügyelet alatt áll. Továbbá érdemes leellenőrizni a kártalanítás mértékét is egy esetleges csőd gyanánt.</p>
                            <p className="majonator">A díjak és költségek nagy mértékben befolyásolják a hozamunk mértékét. Érdemes összehasonlítani a különböző brókercégek által felszámított díjakat, mint például kereskedési jutalékok, tranzakciós díjak, inaktivitási díjak, számlavezetési költségek vagy pénzügyi tranzakciókért felszámított jutalékok. Válasszunk olyan brókercéget, amelynek költségei elfogadhatók és könnyen átláthatók.</p>
                            <p className="majonator">Fontos figyelembe venni a brókercég által kínált szolgáltatásokat és termékeket. Néhány brókercég csak bizonyos típusú befektetéseket kínál, míg mások szélesebb körű lehetőségeket biztosítanak, mint például részvények, kötvények, befektetési alapok, devizapárok vagy kriptovaluták kereskedését. Fontos utánajárni, hogy a brókercég mely tőzsdéket éri el, mivel mellé lehet nyúlni és választani egy olyan brókert, aminél nincs lehetőség befektetni a kívánt instrumentumba.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Broker