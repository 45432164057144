import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function PS() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>P/S mutató - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a P/S jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>P/S mutató</h1>
                            <p>Ezen az oldalon megismerheted a P/S jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A P/S egy olyan mutató, ami a vállalat részvényárát a részvényenkénti árbevételhez viszonyítja.</p>
                            <p className="majonator">A P/S megmutatja, hogy a befektetők mennyit hajlandóak fizetni a vállalat árbevételéért. A P/S mutató egy olyan eszköz, melyet a befektetők arra használhatnak, hogy egy szempont szerint megállapítsák egy részvény túl- vagy alulértékeltségét. Minél magasabb értékét ad a P/S mutató, annál többe kerül az egy részvényre vetített árbevétel, vagyis annál túlértékeltebb a részvény.</p>
                            <hr />
                            <h2>Kiszámítása</h2>
                            <p className="majonator">P/S = Részvény piaci ára / Egy részvényre jutó árbevétel</p>
                            <p className="majonator">A P/S arányt úgy számítják ki, hogy a részvényárfolyamot elosztják a vállalat részvényenkénti árbevételével. Például ha egy vállalat részvényének ára 100 dollár, és az egy részvényre jutó árbevétele 10 dollár, akkor a P/S mutató 10. Ez azt jelenti, hogy a befektetők 10-szer annyit fizetnek a részvényért, mint az egy részvényre jutó árbevétel.</p>
                            <p className="majonator">Érdemes tisztában lenni az árbevétel és a nyereség közötti különbséggel. Az árbevétel az egy vállalat által egy bizonyos időszak alatt értékesített termékekből és szolgáltatásokból származó bevétel. A nyereség az a pénzösszeg, amelyet egy vállalat egy bizonyos időszak alatt keresett, miután levonták belőle az olyan költségeket, mint például az adózás. A két mérőszám közötti fő különbség az, hogy az árbevétel nem veszi figyelembe a költségeket, míg a nyereség igen. Az árbevétel tehát csak azt mutatja meg, hogy mennyi pénzt keresett a vállalat az értékesítéseiből, a nyereség pedig azt, hogy mennyi pénz maradt a vállalatnál az értékesítés után a költségek levonását követően.</p>
                            <hr />
                            <h2>Értelmezése</h2>
                            <p className="majonator">Mint minden vállalatelemzési arányszám, a P/S mutató is akkor a leghasznosabb, ha az azonos <Link to='/fogalomtar/szektor' className='dezord'>befektetési szektorban</Link> működő vállalatok összehasonlítására használjuk. Az alacsony érték azt jelezheti, hogy a részvény e szempont szerint alulértékelt, míg az átlagot jelentősen meghaladó érték túlértékeltségre utalhat. A mutató átlagos értéke szektoronként jelentősen eltérhet, a teljes piacra vetített átlag érték 2-3 körül szokott mozogni.</p>
                            <p className="majonator">A P/S arány egyik hátránya, hogy nem veszi figyelembe egy vállalat nyereségtermelésének mértékét, vagy hogy fog-e valaha nyereséget termelni. Ez azért van mert a képletben árbevétel, nem pedig nyereség szerepel. Ha nyereség lenne a képletben, akkor az onnantól a <Link to='/fogalomtar/pe' className='dezord'>P/E mutató</Link> lenne, ami a legnépszerűbb, szintén fundamentális elemzéshez használatos arányszám.</p>
                            <p className="majonator">A P/S ráta nem veszi figyelembe a vállalat tőkeszerkezetét, ezért két eltérő adósságállománnyal rendelkező vállalatot ugyanolyan értékűnek mutathat. Egy szinte adósság nélküli vállalat vonzóbb lehet a befektetők számára, mint egy azonos P/S-aránnyal rendelkező, erősen tőkeáttételes vállalat. Amennyiben figyelembe szeretnénk venni a vállalat adósságát, használhatjuk az EV/Sales mutatót, aminek a számlálójában nem a <Link to='/fogalomtar/piaci-kapitalizacio' className='dezord'>piaci kapitalizáció</Link> szerepel, hanem az Enterprise Value, amit úgy kapunk meg, hogy a piaci kapitalizációhoz hozzáadjuk a teljes adósságot és kivonjuk belőle a készpénzt.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default PS