import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Csod() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Csőd - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a csőd jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Csőd</h1>
                            <p>Ezen az oldalon megismerheted a csőd jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A csőd azt jelenti, amikor egy vállalat nem képes teljesíteni az kötelezettségeit, fizetésképtelennél válik és felszámolják.</p>
                            <p className="majonator">Csőd általában akkor fordul elő, amikor a vállalkozás vagy az egyén hosszú ideig folyamatosan veszteséges, és nem tudja fizetni a tartozásait és kötelezettségeit. A csőd bejelentése lehetőséget adhat a vállalkozásnak, hogy restrukturálják a pénzügyeiket annak érdekében, hogy visszanyerjék pénzügyi stabilitásukat és folytassák a tevékenységüket.</p>
                            <hr />
                            <h2>Csődeljárás</h2>
                            <p className="majonator">A csődnek megkülönböztetjük két formáját. A vállalat számára kedvezőbb formát hívjuk csődvédelmi eljárásnak. A csődvédelmi eljárás egy módja annak, hogy a vállalat elkerülhesse a teljes felszámolást. Amennyiben a vállalat csődvédelmet kér rendkívül sok negatív hatás zúdul mind a vállalatra és annak <Link to='/fogalomtar/reszveny' className='dezord'>részvényeire</Link>, de ez az ára annak, hogy a társaság megmeneküljön. A részvények drasztikus esésen mennek keresztül, a legtöbb esetben kivezetésre kerülnek a <Link to='/fogalomtar/tozsde' className='dezord'>tőzsdéről</Link> is. Csődeljárás alatt a vállalat <Link to='/fogalomtar/kotveny' className='dezord'>kötvényeit</Link> leminősítik, ami a kötvény árfolyamának esését eredményezi és a kamatfizetés is leállhat.</p>
                            <p className="majonator">A rosszabb kimenetel a felszámolási eljárás. Ebben az esetben a vállalat összes eszközét arra fordítják, hogy kifizessék az eljárás költségét. A fennmaradó összegből a hitelezőket, részvényeseket és kötvényeseket kárpótolják. A hitelezők elsőbbséget élveznek a kötvényesek és részvényesek felett. A kötvényesek elsőbbséget élveznek a részvényesek felett, vagyis a részvényes jár a legrosszabbul.</p>
                            <hr />
                            <h2>Csőd jelei</h2>
                            <p className="majonator">Utaló jel lehet, ha a vállalat kötvény- és részvényárfolyama folyamatosan csökken, illetve állandóan veszteséges és hatalmas hitelállománnyal rendelkezik. Aggodalomra adhat okot, ha a vállalat nem tudja fizetni a tartozásait határidőre, ha el kell adnia az eszközeit, hogy fedezze a tartozásait, vagy ha jelentős mértékben kell csökkentenie a munkavállalók számát.</p>
                            <br />
                            <p className="majonator azam">Altman Z-Score</p>
                            <p className="majonator">Az Altman Z-Score segítségével meg lehet mérni, hogy mekkora csődkockázat fenyeget egy vállalatot. A mutató kiszámításakor a vállalat nyereségességét, tőkeáttételét, likviditását és fizetőképességét vizsgáljuk. Minél alacsonyabb ez az érték, annál nagyobb a valószínűsége, hogy csőd következzen be. A 3-nál magasabb érték esetén a vállalat csőd szempontjából biztonságosnak számít.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Csod