import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Inflacio() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Infláció - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted az infláció jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Infláció</h1>
                            <p>Ezen az oldalon megismerheted az infláció jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">Az infláció a pénz vásárlóerejének romlása, az általános árszínvonal emelkedése.</p>
                            <p className="majonator">Csökken a pénz vásárlóereje, vagyis ugyanannyi pénzért egyre kevesebb árut lehet vásárolni. A túlzott infláció negatív hatást gyakorolhat a gazdaságra. Az inflációt általában százalékos formában fejezik ki, és az inflációs ráta mutatja meg, hogy az árak mennyire emelkedtek vagy csökkentek egy adott időszak alatt.</p>
                            <hr />
                            <h2>Infláció alakulása</h2>
                            <p className="majonator">Az infláció egyik okozója lehet a pénzmennyiség növekedése. A folyamatos pénznyomtatás folyamatos inflációt eredményez, de ez nem feltétlenül rossz. Ha a pénznyomtatás mértéke akkora, hogy hatalmas inflációt okoz, akkor negatív hatással van a gazdaságra. Fontos azonban megjegyezni, hogy az infláció nem rossz, amíg normális értékeken belül van. A <Link to='/fogalomtar/jegybank' className='dezord'>jegybank</Link> az infláció kordában tartása érdekében alapkamatot emelhet, viszont ennek az ára a gazdaság lassulása, mivel kevesebb pénz jut be a piacokra és a <Link to='/fogalomtar/hitel' className='dezord'>hitelek</Link> drágábbak lesznek. Minél magasabb az alapkamat, annál drágább a kereskedelmi bankoknak hitelt felvenni. Így kevesebb hitelt vesznek fel, vagyis kevesebb pénz jut be a gazdaságba, ami dezinflációt eredményez. A <Link to='/fogalomtar/vallalat' className='dezord'>vállalatok</Link> drágább hitelek mellett kisebb profitot érnek el. A lakosságnak pedig csökken a szabadon elkölthető jövedelme.</p>
                            <p className="majonator">A pénz érteke csökkenhet, ha nő a pénzmennyiség vagy ha csökken a gazdaság teljesítménye. Egy gazdaságban minél kevesebb termék van, annál többe kerülnek, vagyis annál értéktelenebb a pénz. Inflációhoz vezethet az is, ha a vállalatoknak valamilyen körülmény miatt extra költségekkel kell számolnia, ami miatt növelik a termékek és szolgáltatások árait. Továbbá az is inflációhoz vezethet, ha egy bizonyos termék vagy szolgáltatás után megnő a kereslet, viszont a kínálat nem tudja felvenni vele az ütemet.</p>
                            <hr />
                            <h2>Számítása</h2>
                            <p className="majonator">Az infláció mérésére a fogyasztóiár-indexet, fogyasztói kosarat használják. A fogyasztói kosár nagyjából 1000 terméket tartalmaz, melyeknek az árváltozásáról statisztikát vezetnek. A kosárban lévő termékeket és szolgáltatásokat árreprezentánsoknak nevezzük. Van a kosárban kakaós csiga, farmernadrág, Opel Astra vagy akár repülőjegy is. A statisztikusok lejegyzik, hogy egy éve mikor mennyibe kerültek a termékek, majd az ebből képzett bázisárral osztják el az idei árindexet. Ebből kapnak egy százalékos értéket, ami az inflációnak felel meg.</p>
                            <hr />
                            <h2>Gonosz vagy barát</h2>
                            <p className="majonator">A minimális infláció elengedhetetlen egy növekvő gazdaságban. Az infláció akkor barát, ha az árak emelkedése lassú és stabil. Az ideális infláció 2% körül mozog, ezen a szinten elég alacsony a fogyasztói kényelemhez és elég nyugodt ahhoz, hogy a gazdaság virágozzon. Ha az infláció mértéke túlságosan magas és az árak gyorsan emelkednek, akkor az instabilitást okozhat a gazdaságban.</p>
                            <hr />
                            <h2>Infláció, defláció, dezinfláció</h2>
                            <p className="majonator">A <Link to='/fogalomtar/deflacio' className='dezord'>defláció</Link> az infláció ellentéte. Fontos nem összekeverni a deflációt a dezinflációval. A dezinfláció az infláció csökkenését jelenti, a pozitív tartományban lévő infláció visszaesését. Defláció alatt csökkennek az árak, míg dezinfláció alatt nőnek az árak, csak lassabban, mint az infláció esetében.</p>
                            <hr />
                            <h2>Infláció és a befektetés</h2>
                            <p className="majonator">Az infláció növekedése csökkentheti a befektetések reálhozamát. A reálhozam a nominális hozam és az infláció különbségét jelenti. Ha a befektetés nominális hozama nem haladja meg az inflációt, akkor a reálhozam negatív lesz, és a befektetés értéke csökkenni fog a vásárlóerő csökkenése miatt.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Inflacio