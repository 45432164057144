import React, { useState } from 'react';
import './kviz.css'
import Footer from "../footer/footer";
import { Helmet } from 'react-helmet-async';

const Kviz = () => {
    const questions = [
        {
            question: 'A(z) ............... egy olyan értékpapír, ami hitelviszonyt testesít meg az adott szervezet és a befektető között.',
            options: ['Részvény', 'Kötvény', 'ETF', 'Törzsrészvény'],
            correctAnswerIndex: 1,
        },
        {
            question: 'A(z) ............... egy fundamentális mutató, ami információt nyújt arról, hogy egy vállalat mekkora nyereséget képes termelni az eszközeiből.',
            options: ['ROE', 'ROA', 'ROI', 'RSI'],
            correctAnswerIndex: 1,
        },
        {
            question: 'Az alábbiak közül melyik a P/E mutató kiszámítására használt képlet?',
            options: ['Részvény piaci ára / Piaci kapitalizáció', 'Piaci kapitalizáció / EPS', 'BVPS / Piaci kapitalizáció', 'Részvény piaci ára / EPS'],
            correctAnswerIndex: 3,
        },
        {
            question: 'Az alábbiak közül melyik nem tartozik a három alapvető vállalati kimutatás közé?',
            options: ['Tőzsdeindex', 'Mérleg', 'Cash Flow kimutatás', 'Eredménykimutatás'],
            correctAnswerIndex: 0,
        },
        {
            question: 'A(z) ............... olyan ügylet, amelyben a befektető egy adott befektetési eszköz, két különböző piacon kialakult eltérő árából hajt hasznot.',
            options: ['Tőkeáttétel', 'Stop megbízás', 'Stop-limit megbízás', 'Arbitrázs'],
            correctAnswerIndex: 3,
        },
        {
            question: 'Az alábbiak közül melyik mutató a legalkalmasabb arra, hogy megmérjük egy vállalatra nehezedő csődkockázat mértékét?',
            options: ['P/S mutató', 'Debt/Equity', 'RSI indikátor', 'Altman Z-Score'],
            correctAnswerIndex: 3,
        },
        {
            question: 'Az alábbi befektetési eszközök közül általánosságban melyik a legkevésbé kockázatosabb?',
            options: ['Elsőbbségi részvény', 'Vállalati kötvény', 'Kincstárjegy', 'Kriptovaluta'],
            correctAnswerIndex: 2,
        },
        {
            question: 'Miben különbözik a sortino mutató a sharpe mutatótól?',
            options: ['A képlete nevezőjében a béta mutató van', 'A képlete nevezőjében a portfólió hozamának szórása van', 'Csak a negatív árfolyamváltozásra fókuszál', 'Csak a pozitív árfolyamváltozásra fókuszál'],
            correctAnswerIndex: 2,
        },
        {
            question: 'Ezen a napon jár le az osztalékra való jogosultság. Aki e nap után vásárol részvényt, az nem kap osztalékot.',
            options: ['Announcement date', 'Payment date', 'Ex-dividend date', 'Record date'],
            correctAnswerIndex: 2,
        },
        {
            question: 'Mit számolunk ki a ,,Részvény piaci ára / Egy részvényre jutó könyv szerinti érték" képlettel?',
            options: ['P/B mutató', 'P/S mutató', 'P/E mutató', 'Forward P/E mutató'],
            correctAnswerIndex: 0,
        },
        {
            question: 'Hogyan nevezzük azokat a befektetési alapokat, amik az abszolút hozam elérésére törekszenek?',
            options: ['Nyíltvégű alap', 'Pénzpiaci alap', 'Aktív alap', 'Hedge fund'],
            correctAnswerIndex: 3,
        },
        {
            question: 'Az alábbi fogalmak közül melyik nem játszik szerepet kockázatcsökkentésben?',
            options: ['Diverzifikáció', 'Akvizíció', 'Rebalancing', 'Stop-limit order'],
            correctAnswerIndex: 1,
        },
        {
            question: 'Mit jelent az, ha egy eszköz béta mutatója 1-nél magasabb értéket mutat?',
            options: ['Az eszköz volatilitása megegyezik a piacéval', 'Az eszköz volatilitása nagyobb a piacénál', 'Az eszköz volatilitása kisebb a piacénál', 'Az eszköz ára és a piaci ár között nincs korreláció'],
            correctAnswerIndex: 1,
        },
        {
            question: 'Egy felszámolási eljárás során a vállalat kiket kárpótol utoljára?',
            options: ['A hitelezőket', 'Az elsőbbségi részvényeseket', 'A kötvényeseket', 'A törzsrészvényeseket'],
            correctAnswerIndex: 3,
        },
        {
            question: 'Az alábbiak közül melyik nem egy derivatív ügylet?',
            options: ['Contrast', 'Futures', 'Forward', 'Opció'],
            correctAnswerIndex: 0,
        },
        {
            question: 'A(z) ............... olyan mutató, ami a vállalat egy részvényére jutó nyereségét fejezi ki.',
            options: ['PEG', 'EPS', 'ROE', 'ROI'],
            correctAnswerIndex: 1,
        },
        {
            question: 'A(z) ............... a nemzetközi pénzügyi együttműködést és stabilitást elősegítő, tagállamokból álló nemzetközi szervezet.',
            options: ['SEC', 'IMF', 'NYSE', 'SDR'],
            correctAnswerIndex: 1,
        },
        {
            question: 'A(z) ............... az infláció csökkenését jelenti, a pozitív tartományban lévő infláció visszaesését.',
            options: ['Recesszió', 'Defláció', 'Dezinfláció', 'Duráció'],
            correctAnswerIndex: 2,
        },
        {
            question: 'Mi a current ratio kiszámítására használt képlet?',
            options: ['forgóeszközök / rövid lejáratú kötelezettségek', '(készpénz + pénzeszköz-egyenértékesek) / rövid lejáratú kötelezettségek', '(készpénz + pénzeszköz-egyenértékesek + rövid lejáratú befektetések + követelések) / rövid lejáratú kötelezettségek', 'Összes adósság / összes saját tőke'],
            correctAnswerIndex: 0,
        },
        {
            question: 'Melyik mozgóátlag esetében számítjuk az adott periódus alatt jegyzett árak közül nagyobb súllyal az új, és kisebb súllyal a régi árakat?',
            options: ['Egyszerű mozgóátlag (SMA)', 'Exponenciális mozgóátlag (EMA)', 'Súlyozott mozgóátlag (WMA)', 'Csökkentett mozgóátlag (RMA)'],
            correctAnswerIndex: 2,
        },
        {
            question: 'Mi történik, ha a P/E mutató képletében az EPS-t kicseréljük az egy részvényre jutó árbevételre?',
            options: ['P/B mutató lesz belőle', 'P/S mutató lesz belőle', 'P/C mutató lesz belőle', 'Semmi'],
            correctAnswerIndex: 1,
        },
        {
            question: 'Minél magasabb egy eszköz árfolyamának volatilitása...',
            options: ['...annál kevésbé kockázatos befektetni', '...annál kockázatosabb befektetni', '...annál olcsóbb a befektetés', '...annál drágább a befektetés'],
            correctAnswerIndex: 1,
        },
        {
            question: 'Melyik portfólió típusba illenek leginkább a növekedési részvények?',
            options: ['Agresszív portfólió', 'Defenzív portfólió', 'Spekulatív portfólió', 'Jövedelemtermelő portfólió'],
            correctAnswerIndex: 0,
        },
        {
            question: 'Melyik a legnagyobb piaci kapitalizációjú befektetési szektor?',
            options: ['Egészségügy', 'Ipar', 'Ingatlan', 'Technológia'],
            correctAnswerIndex: 3,
        },
        {
            question: 'Melyik a legnagyobb tőzsde a világon?',
            options: ['NASDAQ', 'Shanghai Stock Exchange', 'NYSE', 'EURONEXT'],
            correctAnswerIndex: 2,
        },

    ];

    const [answers, setAnswers] = useState(Array(questions.length).fill(-1));
    const [showResults, setShowResults] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const handleAnswerChange = (index, value) => {
        const newAnswers = [...answers];
        newAnswers[index] = value;
        setAnswers(newAnswers);
        setShowResults(false);
        setSubmitted(false);
    };

    const evaluateQuiz = () => {
        setShowResults(true);
        setSubmitted(true);
    };

    const resetQuiz = () => {
        setShowResults(false);
        setAnswers(Array(questions.length).fill(-1));
        setSubmitted(false);
    };

    const markCorrectAnswers = () => {
        const correctAnswers = questions.map(question => question.correctAnswerIndex);
        setAnswers(correctAnswers);
    };

    const totalScore = answers.reduce((acc, answer, index) => {
        return acc + (answer === questions[index].correctAnswerIndex ? 1 : 0);
    }, 0);

    return (
        <>
            <Helmet>
                <title>Fogalomtár kvíz | Befektetésközpont</title>
            </Helmet>
            <div className='kvizcontent'>
                <div className='kvizsection'>
                    <div className='upformer'>
                        <h1 className='kvizc'>Fogalomtár kvíz</h1>
                    </div>
                    <div className='outformer'>
                        {questions.map((question, index) => (
                            <>
                                <div key={index} className='former'>
                                    <p>{question.question}</p>
                                    <div className='optflexer'>
                                        {question.options.map((option, optionIndex) => (
                                            <div
                                                key={optionIndex}
                                                className={`informer ${answers[index] === optionIndex ? 'selected' : ''}`}
                                                onClick={() => handleAnswerChange(index, optionIndex)}
                                                style={{
                                                    background: showResults && submitted && answers[index] === optionIndex && optionIndex === question.correctAnswerIndex
                                                        ? '#4f9c3a'
                                                        : showResults && submitted && answers[index] !== question.correctAnswerIndex && answers[index] === optionIndex
                                                            ? '#9e3f3f'
                                                            : ''
                                                }}
                                            >
                                                <label htmlFor={`q${index}_option${optionIndex}`}>{option}</label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <br />
                                <br />
                            </>
                        ))}
                    </div>
                    <br />
                    <br />
                    <div className='gombkollekcio'>
                        <button onClick={evaluateQuiz} disabled={submitted} className='btn btn0 success'>Kiértékelés</button>
                        <button onClick={resetQuiz} className='btn btn0 success'>Újrakezdés</button>
                        <button onClick={markCorrectAnswers} disabled={submitted} className='btn btn0 success'>Megoldások</button>
                    </div>
                    {showResults && (
                        <div>
                            <p className='totscore'>Elért pontszám: {totalScore}/25</p>
                        </div>
                    )}
                </div>
            </div>
            <br />
            <br />
            <Footer />
        </>
    );
};

export default Kviz;