import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Keszpenz() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Készpénz - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a készpénz jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Készpénz</h1>
                            <p>Ezen az oldalon megismerheted a készpénz jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">Készpénz alatt fizikai, kézzel fogható pénz értünk.</p>
                            <p className="majonator">A legtöbb esetben papírpénz és érme formájában jelenik meg. A készpénz fogalmát az adott ország fizetőeszközére értjük. A pénznyomtatást általában egy ország központi bankja vagy kincstára szabályozza annak érdekében, hogy a pénzáramlás összhangban legyen a monetáris politikával. A készpénz a leglikvidebb eszköz, a likviditás lényegében azt fejezi ki, hogy mennyire könnyen és gyorsan lehet egy eszközt átalakítani készpénzzé.</p>
                            <hr />
                            <h2>Tudnivalók</h2>
                            <p className="majonator">Számos ősi civilizáció használt nemesfémből, többek között rézből, bronzból, ezüstből és <Link to='/fogalomtar/arany' className='dezord'>aranyból</Link> vert érméket, míg más korai civilizációk kagylót vagy például sót és cukrot használtak. A papírpénz a készpénz legújabb formája, értékét a polgároknak a valutát fedező kormányba vetett hite határozza meg. Jelenleg a legtöbb állam azon van, hogy próbálja csökkenteni a készpénzhasználatot a digitális pénzre való áttérés érdekében.</p>
                            <p className="majonator">Az előrehaladó technológiai és pénzügyi innovációk következtében egyre népszerűbbek az olyan fizetési módszerek, mint például a mobilfizetés vagy a bankkártyák használata. Ezek a digitális formák egyre inkább elterjednek, mivel gyorsabbá és kényelmesebbé teszik a tranzakciókat.</p>
                            <hr />
                            <h2>Készpénz előnyei</h2>
                            <p className="majonatora">A készpénzes tranzakciók nehezen vagy egyáltalán nem lekövethetők. Ez valakinek előny, valakinek pedig kevésbé, de az biztos, hogy az államnak hátrány. További előnye, hogy készpénzt szinte mindenhol elfogadnak, mivel nincs szükség speciális eszközökre vagy technológiára a fizetéshez. Mivel a <Link to='/fogalomtar/likviditas' className='dezord'>leglikvidebb</Link> eszközről beszélünk, ezért azonnal elkölthető.</p>
                            <hr />
                            <h2>Készpénz hátrányai</h2>
                            <p className="majonatora">A készpénz egyik hátránya az, hogy mivel materiális, ezért képes elhasználódni és elszakadni. Ha valaki elég ügyes, akkor még hamisítani is képes lehet. A készpénz nem termel hozamot, így hosszú távon a megtakarítások értéke <Link to='/fogalomtar/inflacio' className='dezord'>inflációból</Link> eredően csökkenhet. Készpénzzel utazva vagy nemzetközi tranzakciókat bonyolítva nehézségekbe ütközhetsz, mivel a helyi valuta és az átváltási költségek problémákat okozhatnak. A készpénz hordozása és tárolása kockázatos lehet, elvesztése vagy ellopása esetén a pénzt nehéz visszaszerezni.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Keszpenz