import React from 'react';
import Cards from '../cards/cards';
import Topvideo from '../topvideo/Topvideo';
import { Helmet } from 'react-helmet-async';

function Home() {
    return (
        <>
        <Helmet>
        <title>Befektetésközpont</title>
        <meta name="description" content="A Befektetésközpont egy olyan weboldal, ami számos szolgáltatásával segít az embereknek bevezetni őket a befektetések világába. Az oldal fő célja, hogy támogassa a befektetőket, és hogy egy közösséget teremtsen nekik." data-react-helmet="true"/>
        </Helmet>
            <Topvideo />
            <Cards />
        </>
    )
}

export default Home;