import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function NYSE() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>NYSE - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a NYSE jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>NYSE</h1>
                            <p>Ezen az oldalon megismerheted a NYSE jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A NYSE a New York-i értéktőzsde, ami a világ legnagyobb tőzsdéje.</p>
                            <p className="majonator">A legnagyobb <Link to='/fogalomtar/tozsde' className='dezord'>tőzsde</Link> alatt az értendő, hogy a NYSE-en jegyzett értékpapírok teljes <Link to='/fogalomtar/piaci-kapitalizacio' className='dezord'>piaci kapitalizációja</Link> összesen a legnagyobb a tőzsdék közül. Ez volt az első hivatalos tőzsde, amelyet az Egyesült Államokban hoztak létre. A NYSE jelenlegi anyavállalata az Intercontinental Exchange Inc. (ICE)</p>
                            <hr />
                            <h2>Tudnivalók</h2>
                            <p className="majonator">A NYSE a világ legnagyobb tőzsdéje. A jegyzett vállalatok teljes piaci kapitalizációja alapján 2023-ban 22,649 billió dollárt mértek. A listázott vállalatok száma 2400 volt.</p>
                            <p className="majonator">A NYSE hétfőtől péntekig, New York-i idő szerint (ET) 9:30 és 16:00 között van nyitva. A pre-market általában 8 és 9:30 között van, míg az after-hours 16:00 és 20:00 között. A tőzsde nyitását és zárását a NYSE harangja jelzi. Mára a harangot a NYSE-re újonnan bevezetett vállalatok vezetői is megcsengethetik, sőt sokszor híres emberek is megjelennek, hogy kipróbálják milyen megcsengetni a híres harangot. A tőzsde ünnepnapokon és hétvégén zárva tart. Ha az ünnepnap hétvégére esik, akkor gyakran a hétvégi naphoz leközelebbi munkanapon zárva van a tőzsde. Példák ünnepnapokra: újév, Martin Luther King Jr. nap, nagypéntek, emlékezet napja, függetlenség napja, hálaadás, karácsony stb.</p>
                            <p className="majonator">A tőzsde 2 épülete Lower Manhattan, Wall Street 11 és Broad Street 18 szám alatt található meg.</p>
                            <hr />
                            <h2>Tőzsdék működése</h2>
                            <p className="majonator">A befektetők és kereskedők számítógépeken keresztül köthetnek megbízásokat, amiket a tőzsde nyomon követ, és a központi számítógépes rendszere segítségével a kereslet és a kínálat által meghatározott árfolyamon párosítja a vevői és eladói megbízásokat. Így a tőzsdék lehetővé teszik a vállalatok számára, hogy hozzáférjenek a tőkehez, amelyre szükségük van a növekedéshez, a befektetőknek pedig lehetőséget nyújtanak a tőkéjük fialására.</p>
                            <p className="majonator">A <Link to='/fogalomtar/broker' className='dezord'>brókercégek</Link> segítségével lehetséges az értékpapírok és más befektetési eszközök kereskedelme a tőzsdén. A brókerek közvetítőként működnek az ügyfelek és a piac között.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default NYSE