import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Konyvszerintiertek() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Könyv szerinti érték - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a könyv szerinti érték jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Könyv szerinti érték</h1>
                            <p>Ezen az oldalon megismerheted a könyv szerinti érték jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A könyv szerinti érték egy vállalat összes eszközének és összes kötelezettségének nettó különbsége.</p>
                            <p className="majonator">A könyv szerinti érték a vállalat mérlegében a saját tőke összes sorának összege. A könyv szerinti érték szinte mindig eltér a piaci ártól, mivel az előbbibe nincsenek beleszámítva az immateriális javak és a vállalat jövőbeli növekedése.</p>
                            <hr />
                            <h2>Vállalati könyv szerinti érték</h2>
                            <p className="majonator">A könyv szerinti érték a vállalat eszközeinek teljes értékét tükrözi, amelyet a vállalat részvényesei kapnának, ha <Link to='/fogalomtar/csod' className='dezord'>felszámolnák</Link> azt. Egy vállalat könyv szerinti értéke a legtöbb esetben kisebb, mint a <Link to='/fogalomtar/piaci-kapitalizacio' className='dezord'>piaci kapitalizációja</Link>. A könyv szerinti érték felett fizetett összeget <Link to='/fogalomtar/goodwill' className='dezord'>goodwill-nek</Link> nevezzük.</p>
                            <p className="majonator">Amikor egy eszközről beszélünk, akkor a könyv szerinti érték az eszköz olyan nettó értéke, aminek a bekerülési értékéből levonjuk az értékcsökkenést is. Vagyis egy eszköz könyv szerinti értéke olyan nettó összeg, amit nem a bruttó ár áfa nélküli változataként kell értelmezni, hanem bele kell számítani az értékcsökkenést is. Míg egy eszköz könyv szerinti értéke idővel változatlan maradhat, addig egy vállalat könyv szerinti értéke együttesen növekedhet az eszköz használata révén keletkező nyereség felhalmozódásából. Mivel egy vállalat könyv szerinti értéke a részvények értékét képviseli, a könyv szerinti érték és a részvények piaci árának összehasonlítása hatékony értékelési technikaként szolgálhat annak eldöntésére, hogy a részvények ára tisztességes-e.</p>
                            <hr />
                            <h2>Mutatók</h2>
                            <p className="majonator azam">P/B mutató</p>
                            <p className="majonator">A P/B (price / book value) mutató a részvény könyv szerinti értékét viszonyítja a részvény árfolyamához. Ez talán a legelterjedtebb mutató, amiben a könyv szerinti értéket alkalmazzák. Az 1-es P/B azt jelenti, hogy a piaci ár és a könyv szerinti érték megegyezik. Ez az egyik leggyakrabban használt mutató az értékalapú befektetők körében. A P/B mutatóról bővebben <Link to='/fogalomtar/pb' className='dezord'>itt olvashatsz</Link>.</p>
                            <br />
                            <p className="majonator azam">BVPS</p>
                            <p className="majonator">A BVPS (Book value per share) megmutatja az egy részvényre jutó könyv szerinti értéket. A BVPS a P/B mutatóban ,,B" részt jelképezi. Amennyiben a BVPS folyamatosan nő az azt jelenti, hogy a vállalat részvényei egyre értékesebbé válnak, vagyis az árfolyamnak növekednie kellene. Ez azonban nem mindig történik így ugyanis egy részvény árát számos más tényező befolyásolja, például a növekedési kilátások vagy a vállalat hírneve. A banki részvények esetében a társaság tevékenységéből eredő magasabb kockázat miatt gyakran találkozhatunk olyan esetekkel, amikor a BVPS magasabb, mint a részvény piaci ára.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Konyvszerintiertek