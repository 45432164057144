import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Piacikapitalizacio() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Piaci kapitalizáció - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a piaci kapitalizáció jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Piaci kapitalizáció</h1>
                            <p>Ezen az oldalon megismerheted a piaci kapitalizáció jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A piaci kapitalizáció (market cap) egy vállalat összértéke, amely a forgalomban lévő részvények számának és a részvény árának szorzata.</p>
                            <p className="majonator">A piaci kapitalizáció azt fejezi ki, hogy egy vállalat mennyit ér a részvénypiac szerint. A befektetők ezt a számot használják egy vállalat méretének meghatározására. A befektetésben a nagyobb piaci kapitalizációjú vállalatok gyakran biztonságosabb befektetések, mivel ezek a vállalatok megalapozottabb és általában hosszabb üzleti múlttal rendelkeznek.</p>
                            <hr />
                            <h2>Értelmezése</h2>
                            <p className="majonator">A piaci kapitalizáció önmagában nem alkalmas egy <Link to='/fogalomtar/vallalat' className='dezord'>vállalat</Link> értékelésére, mivel nem tükrözi annak valós értékét. A <Link to='/fogalomtar/reszveny' className='dezord'>részvényeket</Link> a piac gyakran túlértékeli vagy alulértékeli, vagyis a piaci ár csak azt határozza meg, hogy a befektetők mennyit hajlandóak fizetni a vállalatban való részesedésért.</p>
                            <p className="majonator">A nagy kapitalizációjú vállalatokba történő befektetés általában nem hoz rövid időn belül hatalmas hozamot, viszont hosszú távon ezek a vállalatok gyakran alacsonyabb kockázat mellet, folyamatos és jelentős növekedéssel jutalmazzák a befektetőket.</p>
                            <hr />
                            <h2>Kiszámítása</h2>
                            <p className="majonator">Piaci kapitalizáció = Részvény piaci ára <span>&#215;</span> Részvények száma</p>
                            <p className="majonator">Például van két vállalatunk: ,,A" vállalatnak van 400 millió részvénye, aminek az ára 600 dollár, vagyis a piaci kapitalizációja 240 milliárd dollár. ,,B'' vállalatnak 4 milliárd részvénye van, viszont az áruk csak 50 dollár, amiből kiszámolható, hogy 200 milliárd dollár a piaci kapitalizációja.</p>
                            <p className="majonator">Egy vállalat piaci kapitalizációját először az <Link to='/fogalomtar/ipo' className='dezord'>IPO</Link> során állapítják meg. A tőzsdei bevezetés előtt a tőzsdére lépni kívánó vállalat egy befektetési bankot vesz igénybe, amely értékelési technikákat alkalmazva meghatározza egy vállalat értékét és azt, hogy hány részvényt és milyen áron kínáljanak bevezetéskor. Miután egy vállalat tőzsdére lép, a részvények árát a kereslet és a kínálat határozza meg a piacon, ami a befektetői tömeg értékítéletétől függ.</p>
                            <hr />
                            <h2>Méretek</h2>
                            <p className="majonatora">Mega piaci kapitalizáció: 200 milliárd dollár felett &nbsp; | &nbsp; Large piaci kapitalizáció: 10 és 200 milliárd dollár között</p>
                            <p className="majonatora">Mid piaci kapitalizáció: 2 és 10 milliárd dollár között &nbsp; | &nbsp; Small piaci kapitalizáció: 0,3 és 2 milliárd dollár között</p>
                            <p className="majonatora">Micro piaci kapitalizáció: 0,05 és 0,3 milliárd dollár között &nbsp; | &nbsp; Nano piaci kapitalizáció: 50 millió dollár alatt</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Piacikapitalizacio