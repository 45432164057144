import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Margin() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Margin - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a margin szó jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Margin</h1>
                            <p>Ezen az oldalon megismerheted a margin szó jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A margin az a biztosíték, amelyet a befektetőnek a brókernél kell letétbe helyeznie, hogy fedezze a tulajdonos által a bróker számára jelentett hitelkockázatot.</p>
                            <p className="majonator">A kereskedő úgy teremthet hitelkockázatot, hogy kölcsönt vesz fel a brókertől, tőkeáttétes ügyletek végzése céljából. A margin a befektetés teljes értéke és a kölcsön összege közötti különbség. Vagyis tőkeáttételes kereskedés esetén nem szükséges az adott pozíció teljes értékével megegyező tőkével rendelkeznünk, hanem elég csupán a fedezeti összeget, vagyis a margint elhelyeznünk. Például az 1:2 és 1:10 margin-arányokkal azt fejezi ki a bróker, hogy a befektetés teljes értékének mekkora részét kell elhelyezni letétként.</p>
                            <hr />
                            <h2>Margin kereskedés</h2>
                            <p className="majonator">A margin kereskedés (tőkeáttétes kereskedés) esetén a <Link to='/fogalomtar/broker' className='dezord'>brókertől</Link> kölcsönbe kapott pénzzel megnöveljük a letétként elhelyezett összeget, így nagyobb piaci pozíciót nyitva. A margin aránya által megszabott tőkeáttétel felerősíti mind a nyereségeket, mind a veszteségeket. Veszteség esetén a margin call arra kötelezheti a brókert, hogy előzetes beleegyezés nélkül likvidálja az eszközöket. A tőkeáttétes kereskedésről részletesebben <Link to='/fogalomtar/tokeattetel' className='dezord'>itt olvashatsz</Link>.</p>
                            <hr />
                            <h2>Margin tudnivalók</h2>
                            <p className="majonator">A tőkeáttétes számlánkra kiutalt pénzt használjuk a felvett kölcsön fedezeteként. Ezzel a pénzzel legfeljebb a teljes befektetés 50%-át fedezhetjük (1:2 margin). Vagyis például egy 100 ezer forintos fedezettel a kereskedő pozíciójának teljes értéke minimum 200 ezer forint.</p>
                            <br />
                            <p className="majonator azam">Margin szint</p>
                            <p className="majonatora">A margin szint a befektetői számla fedezettségi szintjét méri. Megmutatja, hogy mekkora kockázat nehezedik a számlára. A margin kereskedésben a kereskedők általában csak egy kisebb részét használják fel a saját tőkéjüknek a pozíciók megnyitásához. Árcsökkenés esetén a pozíció zárolásának elkerülése érdekében a kereskedők gyakran pótolni szokták a letétben elhelyezett összeget a számlán lévő saját tőke segítségével. Ha eléri a margin szint a 100%-ot, akkor az azt jelenti, hogy a számlán lévő teljes tőkénket felhasználtuk a pozíció nyitására. 100%-nál a bróker már nem enged új pozíciót nyitni, alatta pedig beindul a margin call.</p>
                            <p className="majonatora">Margin szint = saját tőke / Margin <span>&#215;</span> 100 &nbsp; | &nbsp; Saját tőke: a számlán lévő összeg, melyet nem foglalt le nyitott pozíció</p>
                            <hr />
                            <h2>Margin call</h2>
                            <p className="majonator">Amikor egy kereskedő árfolyamvesztesége következtében a margin számláján található tőke és a kölcsönzött pénz együttes összege a bróker által meghatározott minimális marginkövetelmény alá esik, a bróker margin call-t hajt végre. Kedvezőbb esetben a befektető egy figyelmeztetést kap arról, hogy pótolnia kell a tőkehiányát. Rosszabb esetben a bróker azonnal zárolja a pozíciót és a befektető elveszti a pénzét.</p>
                            <p className="majonator">A margin call elkerülése érdekében a befektetőnek figyelemmel kell kísérnie az eszközök árfolyamának alakulását és gondoskodnia kell arról, hogy mindig elegendő fedezete legyen a pozícióihoz.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Margin