import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function CFD() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>CFD - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a CFD jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>CFD</h1>
                            <p>Ezen az oldalon megismerheted a CFD jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A CFD olyan eszköz, amely lehetővé teszi a kereskedők számára, hogy pénzügyi eszközök árfolyamváltozásaira spekuláljanak anélkül, hogy ténylegesen birtokolnák a mögöttes eszközt.</p>
                            <p className="majonator">A befektető az eszköz birtoklása helyett szerződést köt egy brókerrel, amely kifizeti az eszköz nyitó- és záróárfolyamának különbségét. Egy ilyen ügylet két fél között jön létre: a bróker és az ügyfél. Az ügyfél a bróker ellen kereskedik. Amit nyer az ügyfél azt a bróker elveszíti, és ugyanez fordítva. Vagyis ha nem megbízható brókert választunk, akkor könnyen meglehet, hogy nem az ügyfél fog kereskedni a bróker ellen, hanem a két fél egymás ellenfele lesz. Ezt a bróker az árfolyam manipulálásával érheti el.</p>
                            <hr />
                            <h2>Működése</h2>
                            <p className="majonator">A CFD-kereskedés során lényegében arra fogadunk, hogy a mögöttes eszköz ára emelkedni vagy csökkenni fog. A befektetőkkel ellentétben a CFD-kereskedők nem birtokolják az eszközt, amibe befektetnek. Ehelyett az ármozgásokra <Link to='/fogalomtar/spekulacio' className='dezord'>spekulálnak</Link>, ami a CFD-ket rövid távú kereskedésre és spekulációra teszi alkalmasabbá.</p>
                            <p className="majonator">A CFD-k <Link to='/fogalomtar/tokeattetel' className='dezord'>tőkeáttételes</Link> eszközök, ami azt jelenti, hogy a kereskedőnek csupán egy töredékét kell letétként befizetnie annak az összegnek, amelyből pozíciót szeretne nyitni. A tőkeáttétel potenciálisan felerősítheti mind a nyereséget, mind a veszteséget, vagyis a kockázatot.</p>
                            <hr />
                            <h2>Előnyök és hátrányok</h2>
                            <p className="majonator">A CFD-kkel tőkeáttétellel kereskednek, ami miatt sokkal nagyobb nyereséget lehet elérni. Viszont ebből egy hátrány is fakad, ami a magasabb kockázat. Pozitívumnak számít, hogy a CFD-k segítségével számos olyan befektetési eszközzel van lehetőségünk kereskedni, ami nélkülük nehezen vagy egyáltalán nem lenne elérhető. Hátrány viszont, hogy a magas <Link to='/fogalomtar/volatilitas' className='dezord'>volatilitás</Link> miatt nagy bid-ask különbségek alakulhatnak ki, illetve hogy a CFD szolgáltatók számos országból ki vannak tiltva és sokszor túlságosan vagy nem eléggé vannak szabályozva az adott országban.</p>
                            <hr />
                            <h2>Veszélyek</h2>
                            <p className="majonator">A CFD-k kereskedése jelentős kockázatokat rejt magában a magas tőkeáttétel végett, ezért elengedhetetlen, hogy szilárd kockázatkezelési stratégiával rendelkezzen a kereskedő, és csak olyan pénzzel kereskedjen, amelyet megengedhet magának, hogy elveszítsen.</p>
                            <p className="majonator">Sok helyen a CFD termékek időnként lejárnak és újakkal raknak a helyükre. Erről a <Link to='/fogalomtar/broker' className='dezord'>bróker</Link> folyamatosan informálja a kereskedőt. Fontos meggyőződni arról, hogy az adott CFD-vel folyamatosan lehet-e kereskedni, vagy bizonyos időközönként új terméket hoz létre a bróker. Nevetséges módja elveszíteni a pénzt úgy, hogy nem vesszük észre, hogy eltűnt a termék, amibe befektettünk.</p>
                            <p className="majonator">A brókercég minden esetben közli a kereskedési időt. Ezt fontos figyelembe venni, mert az inaktív időben nem lehet kereskedni, ami egy ilyen volatilis piacon komoly következményeket vonhat maga után. Továbbá ne felejtsük el megnézni, hogy az adott CFD-nél mekkora értéket képvisel egy Lot.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default CFD