import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Tozsde() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Tőzsde - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a tőzsde jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>



                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Tőzsde</h1>
                            <p>Ezen az oldalon megismerheted a tőzsde jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A tőzsde olyan piac, ahol értékpapírokat és egyéb befektetési eszközöket lehet vásárolni és eladni.</p>
                            <p className="majonator">A tőzsde olyan piacként működik, ahol a különböző tranzakciók összekapcsolódnak. A befektetőnek szüksége van egy brókercégre, aminek kapcsolata van azzal a tőzsdével, amin elérhető a megvásárolni kívánt instrumentum. A vállalatok részvénykibocsátást hajthatnak végre egy tőzsdén, így befektetőket vonzva magukhoz, akik a tőkéjükkel elősegítik a vállalat növekedését. Mára a tőzsdei kereskedelem digitális formában zajlik.</p>
                            <hr />
                            <h2>Tőzsde működése</h2>
                            <p className="majonator">A befektetők és kereskedők számítógépeken keresztül köthetnek megbízásokat, amiket a tőzsde nyomon követ, és a központi számítógépes rendszere segítségével a kereslet és a kínálat által meghatározott árfolyamon párosítja a vevői és eladói megbízásokat. Így a tőzsdék lehetővé teszik a vállalatok számára, hogy hozzáférjenek a tőkéhez, amelyre szükségük van a növekedéshez.</p>
                            <p className="majonator">A <Link to='/fogalomtar/broker' className='dezord'>brókercégek</Link> lehetővé teszik ügyfeleik számára értékpapírok és más pénzügyi eszközök kereskedelmét a tőzsdén. A brókercégek közvetítőként működnek az ügyfelek és a tőzsde között. Számos brókercég működik úgy, hogy nem teremt közvetlen kapcsolatot a tőzsdével, hanem más brókereken keresztül jut hozzá eszközökhöz, szolgáltatásokhoz.</p>
                            <hr />
                            <h2>Értéktőzsde / Árutőzsde</h2>
                            <p className="majonator">Az értéktőzsdén értékpapírok és valuták kereskedelme folyik. Feladatai közé tartozik az eszköz bevezetése, árjegyzése és a tranzakciók lebonyolítása. A legnagyobb értéktőzsdék közé tartozik a <Link to='/fogalomtar/nyse' className='dezord'>New York Stock Exchange</Link> (NYSE), a NASDAQ, a Shanghai Stock Exchange és az EURONEXT.</p>
                            <p className="majonator">Az árutőzsde egy olyan piac, ahol árupiaci termékekkel kereskednek. A kereskedők ritkán kötnek ügyletet fizikai árukkal az árutőzsdén keresztül, ehelyett határidős ügyletekkel kereskednek. Árupiaci termékek közé tartoznak agrártermékek, energiahordozók, fémek és egyéb nyersanyagok. Például: Olaj, arany, ezüst, réz, búza, kukorica, kőolaj, kávé, cukor vagy rizs.</p>
                            <hr />
                            <h2>IPO</h2>
                            <p className="majonator">Az <Link to='/fogalomtar/ipo' className='dezord'>IPO</Link> (Initial Public Offering) az a folyamat amikor egy magánvállalat részvényeit először kínálják fel a nyilvánosságnak egy új részvénykibocsátás keretében. A tőzsdei bevezetés lehetővé teszi egy vállalat számára, hogy saját tőkét vonjon be a befektetőkön keresztül. Az IPO során a vállalat egy részét értékesíti a nyilvánosság számára részvények formájában, amibe lehetősége van befektetni az embereknek. A befektetőknek lehetőségük adódik részvényeket vásárolni még a tőzsdei kereskedés kezdete előtt.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Tozsde