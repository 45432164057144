import React from 'react'
import './Road.css'
import Footer from "../footer/footer";
import TradingViewWidget3 from './spx3';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';


function Hatodikallomas() {
  return (
    <>
    <Helmet>
        <title>6. Hogyan maradjunk naprakészek | Befektetésközpont</title>
        <meta name="description" content="Ez az állomás befektetéshez szükséges, hasznos eszközöket mutat be. Megismerhetsz olyan oldalakat és hírportálokat, melyek segíthetnek, hogy tájékozott és naprakész maradj a befektetések világában, illetve megtudhatod, hogy a hírek milyen hatást gyakorolnak az árfolyamra." data-react-helmet="true" />
      </Helmet>
      <div className='allomascontent'>
        <div className='allomassection'>
          <div className='allcontentheader'>
            <h1>Hogyan maradjunk naprakészek</h1>
            <p>Ez az állomás befektetéshez szükséges, hasznos eszközöket mutat be. Megismerhetsz olyan oldalakat és hírportálokat, melyek segíthetnek, hogy tájékozott és naprakész maradj a befektetések világában, illetve megtudhatod, hogy a hírek milyen hatást gyakorolnak az árfolyamra.</p>
          </div>
          <div className='allcontentcontent'>
            <h2>TradingView</h2>
            <p>Minden befektetőnek szüksége van egy platformra, amin megtekintheti a befektetési eszközök árfolyamait. Az erre a célra fejlesztett legnépszerűbb oldal a <Link to='https://www.tradingview.com/' className='dezord'>TradingView</Link>, ami elérhető weboldal és alkalmazás formájában is. A TradingView-on szinte az összes technikai elemzéshez szükséges eszközhöz hozzá lehet férni és könnyen személyre lehet szabni, ami jól jöhet a kereskedőknek.</p>
            <br />
            <br />
            <TradingViewWidget3 />
            <br />
            <p>Ha nem is a TradingView-ot választjuk, muszáj megismerkednünk egy árfolyamkövető oldallal. Azok mellett, hogy ez a helye a technikai elemzésnek, fundamentális oldalról nézve is rengeteg következtetést lehet levonni egy árfolyamból. Például megvizsgálhatjuk, hogy hogyan reagált egyes eseményekre az árfolyam, és hogy milyen múlttal rendelkezik az adott eszköz. Egy grafikon olyan dolgokat is átláthatóbbá tesz, mint például a vállalati kimutatások vagy az osztalékfizetés időpontjai.</p>
            <br />
            <h2>Finviz</h2>
            <p>A <Link to='https://finviz.com/' className='dezord'>Finviz</Link> egy részvénybefektető számára a legalapvetőbb, leghasznosabb weboldal. Számos funkcióval rendelkezik, mint például a portfólió megfigyelés vagy egy hírfal, amin össze vannak gyűjtve a legfontosabb gazdasági hírek. Ami viszont a két legfontosabb funkció, az a Maps és a Screener. A Maps lényegében egy olyan heatmap, amin számos szempont szerint jeleníthetjük meg a kívánt eszközöket, így egy jobb rálátást kapva a piac jelenlegi állapotára és megkönnyítve a megfelelő részvény kiválasztását. Íme a két map: egy heatmap és egy bubblemap.</p>
            <br />
            <br />
            <img src="/imgvideo/kereszteshadjarat.png" alt="finviz heatmap" width='90%' className='dsfgdfgsdhg55' />
            <br />
            <br />
            <img src="/imgvideo/szappan.png" alt="bubble map" width='90%' className='dsfgdfgsdhg55' />
            <br />
            <br />
            <p>A Finviz másik hasznos funkciója a Screener, ami egy olyan felület, ahol 70 különböző fundamentális és technika mutató alapján szűrhetjük a számunkra legmegfelelőbb részvényeket. Miután találtunk egy keresési kritériumainknak megfelelő részvényt, rákattintva számos, az eszközzel kapcsolatos információhoz férhetünk hozzá. Az oldal elején egy árfolyamgrafikon lesz látható, amin már elvégezték helyettünk a technikai elemzés egy részét. Ezt követően egy kis összefoglalót találunk a részvény legfontosabb pénzügyi mutatóiról, majd a vele kapcsolatos hírekről. Az oldal végén megvizsgálhatjuk a vállalat három eredménykimutatását és a belső tranzakcióit is. Rengeteg részvényelemzéshez szükséges információt szolgáltatnak csupán egy oldalon.</p>
            <br />
            <br />
            <img src="/imgvideo/ropogos.png" alt="finviz screener" width='90%' className='dsfgdfgsdhg55' />
            <br />
            <br />
            <img src="/imgvideo/billentyu.png" alt="részvény információk" width='90%' className='dsfgdfgsdhg55' />
            <br />
            <br />
            <h2>Portfólió figyelők</h2>
            <div className='listdivle'>
              <div className='listdivelem elsoked' onClick={() => { window.location.href = 'https://app.getquin.com/dashboard'; }}>
                <p>1.</p>
                <p>Getquin</p>
              </div>
              <hr className='baronar' />
              <div className='listdivelem' onClick={() => { window.location.href = 'https://seekingalpha.com/'; }}>
                <p>2.</p>
                <p>Seeking Alpha</p>
              </div>
              <hr className='baronar' />
              <div className='listdivelem' onClick={() => { window.location.href = 'https://finance.yahoo.com/portfolios/'; }}>
                <p>3.</p>
                <p>Yahoo Finance</p>
              </div>
              <hr className='baronar' />
              <div className='listdivelem lastked' onClick={() => { window.location.href = 'https://www.google.com/finance/'; }}>
                <p>4.</p>
                <p>Google Finance</p>
              </div>
            </div>
            <br />
            <h2>Külföldi hírportálok</h2>
            <div className='listdivle'>
              <div className='listdivelem elsoked' onClick={() => { window.location.href = 'https://www.cnbc.com/world/?region=world'; }}>
                <p>1.</p>
                <p>CNBC</p>
              </div>
              <hr className='baronar' />
              <div className='listdivelem' onClick={() => { window.location.href = 'https://www.wsj.com/'; }}>
                <p>2.</p>
                <p>The Wall Street Journal</p>
              </div>
              <hr className='baronar' />
              <div className='listdivelem' onClick={() => { window.location.href = 'https://finance.yahoo.com/portfolios/'; }}>
                <p>3.</p>
                <p>Yahoo Finance</p>
              </div>
              <hr className='baronar' />
              <div className='listdivelem' onClick={() => { window.location.href = 'https://www.marketwatch.com/'; }}>
                <p>4.</p>
                <p>MarketWatch</p>
              </div>
              <hr className='baronar' />
              <div className='listdivelem lastked' onClick={() => { window.location.href = 'https://www.reuters.com/'; }}>
                <p>5.</p>
                <p>Reuters</p>
              </div>
            </div>
            <br />
            <h2>Magyar hírportálok</h2>
            <div className='listdivle'>
              <div className='listdivelem elsoked' onClick={() => { window.location.href = 'https://www.portfolio.hu/'; }}>
                <p>1.</p>
                <p>Portfolio</p>
              </div>
              <hr className='baronar' />
              <div className='listdivelem' onClick={() => { window.location.href = 'https://hvg.hu/gazdasag'; }}>
                <p>2.</p>
                <p>HVG Gazdaság</p>
              </div>
              <hr className='baronar' />
              <div className='listdivelem lastked' onClick={() => { window.location.href = 'https://www.penzcentrum.hu/'; }}>
                <p>3.</p>
                <p>Pénzcentrum</p>
              </div>
            </div>
            <br />
            <h2>Hasznos oldalak</h2>
            <h3>Infinite Market Cap</h3>
            <p className='h3alter'>Az <Link to='https://8marketcap.com/' className='dezord'>Infinite Market Cap</Link> egy olyan weboldal, amin több mint 18000 befektetési eszköz piaci kapitalizáció szerinti sorrendjét lehet megtekinteni. Az oldal segítségével könnyen átláthatjuk a vállalatok, az ETF-ek, a kriptovaluták és a nemesfémek piaci méret szerinti sorrendjeit.</p>
            <br />
            <h3>GuruFocus</h3>
            <p className='h3alter'>A <Link to='https://www.gurufocus.com/' className='dezord'>GuruFocus</Link> oldalán számos hasznos pénzügyi adat megtalálható. Hozzáférhetünk például intézményi befektetők és vállalatvezetők kereskedési előzményeihez vagy számos vállalat pénzügyi mutatóihoz és azok versenytársaihoz képest elfoglalt helyükhöz. Nagy előnye ennek az oldalnak, hogy olyan fundamentális mutatókhoz is könnyen hozzá lehet férni, amikhez más oldalakon nem lehet.</p>
            <br />
            <h3>TipRanks</h3>
            <p className='h3alter'>A <Link to='https://www.tipranks.com/dashboard' className='dezord'>TipRanks</Link> még egy befektetési eszközök elemzéséhez használt felület. Az oldal számos olyan funkcióval rendelkezi, ami a legtöbb hasonló platformon nem érhető el. Megnézhetjük rajta például a szakképzett elemzők előrejelzéseit, a befektetési eszközök fundamentális és technikai mutatóit, vagy hogy az adott eszköznek mekkora kitettsége van egyes ETF-ekben és befektetési alapokban.</p>
            <br />
            <h3>Macrotrends</h3>
            <p className='h3alter'>A <Link to='https://www.macrotrends.net/' className='dezord'>Macrotrends</Link> egy olyan befektetésstatisztikai platform, amely lehetővé teszi részvények, nyersanyagok, gáz, olaj és egyéb mutatók szűrését és kutatását. Olyan grafikonokat találhatunk az oldalon, amelyek jelentősen megkönnyítik a befektetési döntéshozatalt.</p>
            <br />
            <h3>Moody's</h3>
            <p className='h3alter'>A <Link to='https://www.moodys.com/' className='dezord'>Moody's</Link> egy pénzügyi elemzéssel és hitelminősítéssel foglalkozó társaság. Ami egy befektető számára hasznos lehet azok az általuk fenntartott hitelképességet mérő rangsorok. A hitelminősítés arról ad információt, hogy egy szervezet képes-e a pénzügyi kötelezettségeit maradéktalanul és meghatározott időn belül teljesíteni. A hitelminősítés az adós nemteljesítésének valószínűségét is jelzi.</p>
            <br />
            <h3>Sec.gov - EDGAR</h3>
            <p className='h3alter'>Az <Link to='https://www.sec.gov/edgar/searchedgar/companysearch' className='dezord'>EDGAR</Link> egy adatgyűjtési és elemzési rendszer, amelyet a SEC működtet. Az EDGAR célja, hogy biztosítsa a befektetők számára a nyilvánosan jegyzett vállalatok pénzügyi jelentéseinek és egyéb, a SEC által előírt dokumentumainak hozzáférhetőségét. Az oldalon könnyedén lekérhetjük egy vállalat kimutatásait.</p>
            <br />
            <h3>CoinMarketCap</h3>
            <p className='h3alter'>A <Link to='https://coinmarketcap.com/' className='dezord'>CoinMarketCap</Link>-en majdnem minden megtalálható, amire egy kriptovaluta befektetőnek szüksége van. A főoldalon egy hosszú lista található, ami rengeteg kriptovalutát piaci kitettség szerint helyez sorrendbe. A kívánt eszközre kattintva minden adat rendelkezésünkre áll, ami a befektetéshez szükséges. Ezen kívül felépíthetjük a saját portfóliónkat is vagy elolvashatjuk az aktuális kripto híreket.</p>
            <br />
            <h2>Hírek hatása az árfolyamra</h2>
            <p>A befektetési eszközök árfolyamait az aktuális hírek jelentős mértékben befolyásolják. Tegyük fel, hogy egy vállalat negyedéves jelentése minden téren jelentős növekedést mutat. Ez egy jó hír, viszont mivel a piaci várakozás szerint a vállalatnak ennél is nagyobbat kellett volna nőnie, ezért az árfolyam csökkenni fog. Ezt követően az árfolyam elér egy alsó határt, ami alatt a piac már alulértékeltnek tekintené a részvényt, majd elkezd lassan újra növekedni. Nem sokkal később érkezik egy bejelentés az alapkamat emeléséről, ami egy újabb mélypontot alkot az árfolyamban.</p>
            <br />
            <p>A befektetők a már ismert buy and hold stratégiával küszöbölik ki az árfolyam ehhez hasonló rövidtávú változásait. A kereskedők pedig nem a hírekre reagálva fektetnek be, hanem megpróbálják előre jelezni a híreket. Vannak hírek, melyek a piac egy bizonyos részét pozitívan, míg egy másikat negatívan érintenek. Jó példa erre a covid járvány alatt az egészségügyi szektor növekedése és majdnem minden más csökkenése. Hírek olvasásakor fontos, hogy mindig megbízható forrásból tájékozódjunk, és hogy semmilyen pletykával ne foglalkozzunk.</p>
            <br />
            <br />
            <br />
            <div className='centerer'>
              <p><em>Gratulálok és köszönöm, hogy eljutottál idáig! Mostanra sikerült elsajátítanod a befektetéshez szükséges alapokat és készen állsz, hogy betekintést nyerj a befektetés mélyebb bugyraiba.</em></p>
            </div>
            <br />
            <div className='lowernavbar'>
              <button className='btn btn0 success' onClick={() => { window.location.href = '/roadmap'; }}>Vissza a Roadmap-re</button>
              <button className='btn btn0 success' onClick={() => { window.location.href = '/'; }}>Irány a főoldal</button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Hatodikallomas
