import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Vallalat() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Vállalat - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a vállalat jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Vállalat</h1>
                            <p>Ezen az oldalon megismerheted a vállalat jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">Egy vállalat olyan gazdasági szervezet, amely termékeket és/vagy szolgáltatásokat állít elő és értékesít profitszerzés céljából.</p>
                            <p className="majonator">A vállalatok a gazdaság legfontosabb szereplői. A termékek és szolgáltatások előállításával hozzájárulnak a gazdasági növekedéshez, a munkahelyteremtéshez és a fogyasztói jóléthez. A vállalatokat adózási és pénzügyi szempontból különböző módokon lehet megszervezni. A vállalat által képviselt üzletág általában meghatározza, hogy milyen üzleti struktúrát választ. Egy vállalat tulajdonosa lehet magánszemély, egy másik vállalat vagy az állam. A befektetők a vállalatok <Link to='/fogalomtar/reszveny' className='dezord'>részvényeit</Link> megvásárolva részesedést szerezhetnek azokban és profitot érhetnek el tőkenyereség vagy <Link to='/fogalomtar/osztalek' className='dezord'>osztalékfizetés</Link> útján.</p>
                            <hr />
                            <h2>Tudnivalók</h2>
                            <p className="majonator">A vállalatok azért szerveződnek, hogy üzleti tevékenységükből profitot termeljenek, amit a céljaik elérésre használhatnak fel. Minden vállalathoz elengedhetetlen egy ötlet, amire majd alapozni lehet az üzleti tevékenységet. A vállalatok terméket vagy szolgáltatást nyújtanak magánszemélyeknek, az államnak vagy más szervezeteknek. Minden vállalatnak más a struktúrája. Számos részleg alkotja őket: beszerzés, gyártás, marketing, menedzsment, hr, ügyfélszolgálat, értékesítés, könyvelés, kutatás, fejlesztés stb.</p>
                            <p className="majonator">Tulajdonforma szerint megkülönböztetünk magánvállalatokat, állami vállalatokat, önkormányzati vállalatokat és szövetkezeteket. <Link to='/fogalomtar/szektor' className='dezord'>Befektetési szektor</Link> szerint megkülönböztetünk technológiai, pénzügyi, kommunikációs, egészségügyi, fogyasztási, ipari, ingatlan, közmű, energia és alapanyag vállalatokat. A multinacionális vállalat olyan vállalat, ami legalább 2 országban végez üzleti tevékenységet. A vállalatcsoport több, egymással együttműködő vállalat összesége. A konglomerátum olyan vállalatcsoport, melyet különböző iparágakban tevékenykedő vállalatok alkotnak.</p>
                            <hr />
                            <h2>Leányvállalat</h2>
                            <p className="majonator">A <Link to='/fogalomtar/leanyvallalat' className='dezord'>leányvállalat</Link> egy másik vállalat tulajdonában lévő vállalat. Azt a vállalatot, amihez a leányvállalat tartozik anyavállalatnak vagy holding vállalatnak nevezik. Az anyavállalat akkora részesedét birtokol a leányvállalatból, hogy ellenőrzést gyakorol felette. Ez a részesedés amerikai vállalatok estében 50% vagy annál nagyobb. A leányvállalatok pénzügyi adatait az anyavállalat pénzügyi kimutatásában tüntetik fel.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Vallalat