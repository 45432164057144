import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Ebitda() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>EBITDA - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted az EBITDA jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>EBITDA</h1>
                            <p>Ezen az oldalon megismerheted az EBITDA jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">Az EBITDA (Earnings Before Interest, Taxes, Depreciation and Amortization) a kamatok, az adózás és az értékcsökkenési leírás előtti eredményt fejezi ki.</p>
                            <p className="majonator">Ez egy olyan pénzügyi mutatószám, amelyet egy vállalat működési teljesítményének mérésére használnak. Eltünteti a vállalatok közötti eltérő adózásból, kamatfizetésből és beruházásokból származó különbségeket, így lehetővé téve, hogy közvetlenül az üzleti folyamatok váljanak összehasonlíthatóvá. Az EBIT (Earnings Before Interest and Taxes) és az EBITDA közötti különbség az, hogy az előbbi nem veszi figyelembe az értékcsökkenést.</p>
                            <hr />
                            <h2>Kiszámítása</h2>
                            <p className="majonator">EBITDA = nyereség + kamat + fizetendő adó + értékcsökkenés + amortizáció</p>
                            <p className="majonator">Kamat alatt a felvett hitelek után fizetendő kamat értendő. Az értékcsökkenés és az amortizáció működési költségeket takarnak. A különbség köztük az, hogy az értékcsökkenés a tárgyi eszközökhöz kapcsolódik, míg az amortizáció az immateriális javak értékének csökkenését fejezi ki. Az EBIT számítása során az értékcsökkenést és az amortizációt nem adjuk hozzá.</p>
                            <hr />
                            <h2>Használata</h2>
                            <p className="majonator">Az adó- és kamatfizetés, illetve a beruházások szempontjából is minden <Link to='/fogalomtar/vallalat' className='dezord'>vállalatnak</Link> más a felépítése és különböző mennyiségű pénz forog bennük. Gyakran előfordul, hogy egy vállalat a kedvezőbb adókörülmények miatt jobb eredményeket ér el egy másiknál, pedig valójában nem bánik ügyesebben a pénzzel és nem végzi a tevékenységeit hatékonyabban. Az EBITDA az ilyen esetek kiszűrésére szolgál.</p>
                            <p className="majonator">A mutató segítségével kiszűrhetők azok az esetek, amikor egy vállalat magas osztalékfizetés miatt fest rosszabb pénzügyi képet. Nagyobb beruházás, nagyobb amortizációt jelent, vagyis az eredmény kisebb lesz. Elképzelhető, hogy két vállalat között csak azért lesz különbség az eredménykimutatásban, mert az egyik vállalat sokkal nagyobb amortizációt számol el, ami nem feltétlenül jelent rossz hírt a befektetőknek.</p>
                            <hr />
                            <h2>EBITDA problémák</h2>
                            <p className="majonator">A mutató meghatározására nincs egységes szabály. Az EBITDA a számviteli törvényekben nincs pontosan meghatározva. Sem a US GAAP, sem az IFRS nem írja elő az EBITDA számítást. Ebből az következik, hogy a mutató számítása cégenként eltérhet.</p>
                            <p className="majonator">Annak ellenére, hogy az EBITDA rálátást ad egy vállalat működési hatékonyságára, nekünk mint befektetőknek elengedhetetlen figyelembe venni, hogy mekkora bevételt tud az adott társaság generálni az adózás, kamatfizetések, valamint az működési költségek levonásával együtt.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Ebitda