import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Novekedesireszveny() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Növekedési részvény - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a növekedési részvény jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Növekedési részvény</h1>
                            <p>Ezen az oldalon megismerheted a növekedési részvény jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A növekedési részvény olyan részvény, ami általában jóval gyorsabb növekedést produkál, mint a piac.</p>
                            <p className="majonator">A növekedési részvények mögött álló vállalatok általában a felhalmozott nyereséget jelentős részét újra befektetik, hogy felgyorsítsák a növekedést. A növekedési részvények jellemzői közé tartozik az, hogy általában nem fizetnek osztalékot, magas a P/E mutatójuk és az árfolyamuk sokkal volatilisebb a piacénál, vagyis nagy a bétájuk. Kockázatosabbak, mint a hagyományos <Link to='/fogalomtar/reszveny' className='dezord'>részvények</Link>, de rövid távon várhatóan magasabb hozamot generálnak.</p>
                            <hr />
                            <h2>Értelmezése</h2>
                            <p className="majonator">Az <Link to='/fogalomtar/osztalek' className='dezord'>osztalékfizetés</Link> hiányában a növekedési részvényekbe fektetőknek csak a vételi és eladási ár különbségéből van lehetőségük profitot realizálni. Mivel a befektetők magasabb árat fizetnek egy növekedési részvényért, ami a várakozásokon alapul, akkor ha ezek a várakozások nem valósulnak meg, a növekedési részvények jelentős csökkenést szenvedhetnek el.</p>
                            <p className="majonator">A növekedési vállalatok általában egyedi termékekkel, valamilyen technológiai vagy más előnnyel, esetleg monopóliummal rendelkeznek. A legtöbb esetben valamilyen területen jóval előrébb járnak, mint ugyanabban az iparágban más vállalatok. Annak érdekében, hogy a versenytársak előtt maradhassanak és fenntartsák a gyors növekedést, a nyereséget visszaforgatják a vállalatba és újabb technológiák és szabadalmak előállítását próbálják elvégezni.</p>
                            <hr />
                            <h2>Növekedési részvény befektetés</h2>
                            <p className="majonator">A befektetők a növekedési részvényektől azt várják, hogy a vállalat erőteljes növekedésének eredményeként jelentős tőkenyereséget érjenek el. Ez a várakozás azt eredményezheti, hogy ezek a részvények könnyen túlértékeltnek tűnhetnek vagy akár azzá is válhatnak. A növekedési részvényeket magas <Link to='/fogalomtar/pe' className='dezord'>P/E mutató</Link> jellemzi, ami azért alakul ki, mert a befektetők a vállalat gyors növekedését beárazzák a részvényekbe. Ha egy részvény P/E mutatója magas, de a vállalat az <Link to='/fogalomtar/eps' className='dezord'>EPS-ét</Link> képes folyamatosan jelentős mértékben növelni, akkor a részvény akár még alulértékelt is lehet. Érdemes minden esetben számolni a vállalat növekedési kilátásaival. A túlértékelt növekedési részvényeknek gyakran van túl magas <Link to='/fogalomtar/pb' className='dezord'>P/B</Link> és alacsony <Link to='/fogalomtar/roe' className='dezord'>ROE</Link> értékük.</p>
                            <p className="majonator">2023-ban jó példa a növekedési részvényekre az Nvidia, a Tesla, a Salesforce vagy az Adobe.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Novekedesireszveny