import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Merleg() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Mérleg - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a mérleg jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Mérleg</h1>
                            <p>Ezen az oldalon megismerheted a mérleg jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A mérleg a vállalat egyik pénzügyi kimutatása.</p>
                            <p className="majonator">A vállalati mérleg információt nyújt egy vállalat eszközeiről és forrásairól egy adott időszakban. A mérleg számos fundamentális mutató kiszámításának az alapjául is szolgál. A mérleg egyike a három alapvető vállalati kimutatásnak, amelyeket egy vállalat értékeléséhez használnak. (Mérleg, <Link to='/fogalomtar/cash-flow' className='dezord'>Cash Flow kimutatás</Link>, Eredménykimutatás)</p>
                            <hr />
                            <h2>Értelmezése</h2>
                            <p className="majonator">A befektetők számos, a mérlegből levezethető mutatószám segítségével képet kaphatnak egy <Link to='/fogalomtar/vallalat' className='dezord'>vállalat</Link> pénzügyi állapotáról. Minden vállalat egyedi finanszírozási megközelítéssel rendelkezik és a mérleg összetétele iparáganként jelentősen eltérhet, ezért egy részvény értékelésekor érdemes a hasonló vállalatok kimutatásait összevetni. Szintén fontos kiemelni, hogy egy vállalat mérlegét össze kell hasonlítani az előző mérlegeivel, hogy rálátást kapjunk a társaság pénzügyi helyzetének a változására.</p>
                            <p className="majonator">A mérlegnek két oldala van. Az egyik oldalon a források, míg a másik oldalon az eszközök állnak. Az eszközoldalnak mindig meg kell egyeznie a mérleg másik oldalával, ahol a kötelezettségek és a saját tőke foglal helyet, vagyis a mérlegnek egyensúlyban kell lennie. Az egyenlőtlen mérleg mögött esetleges számítási hiba vagy helytelen adatok megadása állhat. Például, ha egy vállalat kölcsönt vesz fel, akkor az eszközoldal és a kötelezettségek ugyanannyit nőnek, vagyis a mérleg egyensúlyban van.</p>
                            <p className="majonator">Eszközök = Kötelezettségek + Saját tőke &nbsp; | &nbsp; Egy vállalatnak fizetnie kell mindazért, amit birtokol és ezt kötelezettségek vállalásával, illetve részvényesi tőke bevonásával teheti meg.</p>
                            <hr />
                            <h2>Mérleg elemei</h2>
                            <p className="majonatora azam">Eszköz</p>
                            <p className="majonatora">Befektetések (részvények, kötvények stb.), ingatlanok, felszerelések, immateriális javak, készpénz, készpénz-egyenértékesek, árukészlet, követelések.</p>
                            <br />
                            <p className="majonatora azam">Forrás</p>
                            <p className="majonatora">Kötelezettségek: Adózás, halaszott bevétel, vásárlói előleg, kölcsönök és hitelek. Saját tőke: törzsrészvények, elsőbbségi részvények, nyereségtartalék.</p>
                            <hr />
                            <h2>Mérleg használata</h2>
                            <p className="majonator">A mérleg számos vállalatértékelési mutató kiszámításhoz használatos, amik segítségével képet kaphatunk a vállalat pénzügyi egészségéről és megkönnyíthetjük a befektetési döntéseink meghozatalát. Például le lehet olvasni, hogy a vállalat finanszírozásának mekkora hányada származik adósságból a saját tőkéhez viszonyítva. Ezt az arányt hívjuk <Link to='/fogalomtar/debt-equity' className='dezord'>Debt/Equity-nek</Link>, ami kulcsfontosságú eleme egy vállalat kockázati profiljának az elemzésének.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Merleg