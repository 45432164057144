import React from 'react'
import './Road.css'
import Footer from "../footer/footer";
import TradingViewWidget from './spx';
import TradingViewWidget2 from './spx2';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

function Elsoallomas() {
  return (
    <>
      <Helmet>
        <title>1. Bevezetés a befektetésbe | Befektetésközpont</title>
        <meta name="description" content="Ezen az állomáson megtanulhatod a befektetés fogalmát és a spekulációtól való különbségét. Megismerheted a különböző befektetési eszközöket és megtudhatod hogyan kereskedhetsz velük. Továbbá feltárjuk, hogy mi történik a színfalak mögött amikor rányomsz a vétel gombra." data-react-helmet="true" />
      </Helmet>
      <div className='allomascontent'>
        <div className='allomassection'>
          <div className='allcontentheader'>
            <h1>Bevezetés a befektetésbe</h1>
            <p>Ezen az állomáson megtanulhatod a befektetés fogalmát és a spekulációtól való különbségét. Megismerheted a különböző befektetési eszközöket és megtudhatod hogyan kereskedhetsz velük. Továbbá feltárjuk, hogy mi történik a színfalak mögött amikor rányomsz a vétel gombra.</p>
          </div>
          <div className='allcontentcontent'>
            <h2>Mi az a befektetés?</h2>
            <p>A befektetés az a tevékenység, mely során a befektetésre szánt pénzedért, vagyis a tőkédért cserébe hozzájutsz egy eszközhöz, ami remélhetőleg hasznot termel neked. Amikor befektetsz, a célod az, hogy a lehető legnagyobb nyereséget érd el a saját befektetési preferenciáid szerint. Ahány ember, annyi kockázatvállalási hajlandóság, stratégia és terv létezik, de ezekről majd csak a későbbiekben...</p>
            <br />
            <p>A pénz szinte állandóan ki van téve az inflációnak, így folyamatosan veszít az értékéből. Akár az inflációtól függetlenül, a jövőben kapott pénz nem annyit ér, mint a mai, vele azonos összeg. Ez abból fakad, hogy a mai pénz ma befektethető, viszont amit a jövőben kapunk azt csak akkor fektethetjük be. Erre mondjuk, hogy a pénznek időértéke van. Ez megválaszolja azt a kérdést, hogy ,,mikor vágjak bele a befektetésbe?". Sajátíts el egy olyan tudásszintet, amin nyugodt szívvel kezdhetsz bele a befektetésbe, majd amilyen hamar csak tudod, kezdd el!</p>
            <br />
            <p>Nem túl bölcs döntés minden pénzünket valutában tartani. Már egy minimális tanulással is bárki kialakíthat egy olyan portfóliót, amellyel legalább a inflációt megakadályozhatja. Ahhoz, hogy befektetővé válj, nem kell gazdagnak lenned és semmilyen végzettségre nincs szükséged. Bármikor, bármekkora tőkével elkezdheted, a legjobb viszont minél hamarabb belevágni. Az utóbbit kiegészítve a kamatos kamat is igazolja ezt az állítást. De mi is az a kamatos kamat?</p>
            <br />
            <p className="h3alter">Kamatos kamatot akkor tudunk előidézni, amikor a befektetésen elért nyereséget visszaforgatjuk az eszközbe, így elérve a hozam exponenciális növekedését. - Tegyük fel, hogy 1.000.000 forintot fektetünk be 7%-os éves kamatozású befektetési alapba. A kamatot minden évben visszaforgatjuk az alapba. Ez esetben 10 év után 1.967.150 forintot kapunk vissza, vagyis a befektetés hozama 967.150 forint. Ha a kamatot nem forgattuk volna vissza, akkor a hozam csak 700.000 Ft lett volna. - A kamatos kamatnak hosszú távon elképesztő ereje van. A hozam exponenciálisan nő és minél hamarabb kezdünk el befektetni, annál nagyobb hozamot érhetünk el hosszú távon.</p>
            <br />
            <p>A befektetéssel két úton juthatsz nyereséghez, kereshetsz pénzt. Az egyik út a tőkenyereség, vagyis az, hogy az adott eszköz árának változásából érsz el eredményt. A másik út a kifizetések, mint például az osztalék vagy a kamat. Az utóbbi általában egy kockázatmentesebb befektetési politikára utal, amit sokan egy stabil jövedelemtermelő portfólió kialakítására használnak. De persze mint bármely területen, itt is fel lehet rakni mindent egy lapra. Vannak olyan eszközök, amikkel egyszerre mind a két út járhatóvá válik. Ilyen például a részvény, mivel egyszerre fizethetnek nekünk osztalékot, illetve realizálhatunk nyereséget az árváltozásból.</p>
            <br />
            <br />
            <TradingViewWidget />
            <br />
            <p>Az alábbi árfolyamgrafikonon a nasdaq-100 nevű tőzsdei index látható, ami a világ második legnagyobb tőzsdéjén, a Nasdaq-on jegyzett 100 legnagyobb (nem pénzügyi szektorba tartozó) vállalat által kibocsájtott részvényeket tartalmazza. A grafikonról jól leolvasható, hogy bár vannak visszaesések, az árfolyam összességében egy szép, stabil, hosszú távú növekedést mutat. Ugyanez elmondható a legnépszerűbb tőzsdei indexről az S&P 500-ról. Ez azt mutatja meg, hogy a hosszú távú növekedés a piac természetes állapota. Vagyis ha betartjuk a befektetés alapvető szabályait és hosszú távra tervezünk, akkor könnyen sikeressé válhatunk.</p>
            <br />
            <p>Ne lépjük át a saját határainkat nagyobb kockázat vállalásával, és olyan dolgokat csinálásával, amelyeket nem értünk teljesen, annak figyelembevétele nélkül, hogy ilyenkor akár a teljes tőkénket is elveszíthetjük. Mindig mérd fel az adott befektetés kockázatosságát, és annak tudatában döntsd el, hogy mekkora összeget szánsz rá.</p>
            <br />
            <h2>Spekuláció</h2>
            <p>Benjamin Graham, akit a legtöbben máig az egyik, ha nem a legnagyobb befektetőnek tartanak így különböztette meg a befektetést a spekulációtól: A befektetők az érték elfogadott szabályai alapján ítélik meg a piaci árat, míg a spekulánsok piaci árra alapozzák az értékre vonatkozó szabályaikat. A befektetés mélyreható fundamentális elemzés alapján a befektetett tőke biztonságát és megfelelő hozamát ígéri. Az ezektől különb műveleteket spekulatívnak tekintjük.</p>
            <br />
            <p>A spekuláció jelentősen magasabb kockázattal jár, mint a befektetés. Az eszköz tartásának ideje is különbözik a két módszer között. A spekuláció sokkal rövidebb tartási időszakkal jár. A kockázat legfontosabb mérőszáma az árfolyam ingadozásának mértéke, vagyis a volatilitás. Volatilitás tekintetében (is) hatalmas különbség van például egy pénzügyileg stabil, folyamatos növekedést mutató vállalat részvényei és egy kisebb kriptovaluta között. Ha a két lehetőség közül a részvényt választom és részletes elemzést követően több éven keresztül tartom, akkor az inkább minősül befektetésnek, mint az, hogy veszek egy rendkívül volatilis eszközt annak reményében, hogy pár napon belül nyereséget érek el rajta.</p>
            <br />
            <p>A megkülönböztetést azért fontos a legelején megtenni, mivel sokan és nagyon könnyen a spekuláció útjára léphetnek, ami a piacra nézve nem feltétlenül okozna gondot, mivel a spekulánsok nélkülözhetetlenek a rendszer működéséhez, az általános likviditás biztosításához. Az viszont már más kérdés, hogy a spekuláció rendkívül kockázatos és nagyon kevés az esélyed, hogy nyereséges legyél vele. A befektetésközpont igyekszik a valódi befektetés képességének átadására, de emellett tárgyalja, vagyis egyáltalán nem veti el a spekulatívabb eszközöket, stratégiákat stb.</p>
            <br />
            <h2>Eszközök</h2>
            <h3>Részvény</h3>
            <p className='h3alter'>A részvények a befektetési lehetőségek közül a legnépszerűbbek. A részvény egy olyan értékpapír, mely egy vállalatban való tulajdoni részesedést testesít meg. Amikor részvényt vásárolsz, lényegében tulajdonosává válsz az adott vállalatnak. A vállalatok azért bocsátanak ki részvényeket, hogy tőkét biztosítsanak a működésükhöz. Viszont nem csak a vállalat jár jól, mivel te mint befektető tőkenyereséget érhetsz el a részvényen, illetve sok esetben osztalékot is kaphatsz. Bizonyos részvények szavazati joggal is felruházhatnak téged, így részben a te kezedben is van a döntés a vállalat ügyeit tekintve. A részvényekről részletesebben <Link to='/fogalomtar/reszveny' className="dezord">itt olvashatsz</Link>.</p>
            <br />
            <h3>Kötvény</h3>
            <p className='h3alter'>A kötvények a részvényeknél egy fokkal kevésbé kockázatosabb eszközök. A kötvény egy olyan stabil hozamú értékpapír, ami hitelviszonyt testesít meg az adott szervezet és a befektető között. Amikor megvásárlod, akkor tulajdonképpen kamatért cserébe kölcsönadod a pénzed az adott szervezetnek. A kötvényeket általában államok és vállalatok bocsájtják ki projektek és a fejlődés finanszírozásának érdekében. A kötvények azok számára lehetnek kedvező befektetési lehetőség, akik a biztonságot és az alacsonyabb kockázatot preferálják. A kötvényekről részletesebben <Link to='/fogalomtar/kotveny' className="dezord">itt olvashatsz</Link>.</p>
            <br />
            <h3>Befektetési jegy</h3>
            <p className="h3alter">A befektetők befektetési jegyeket vásárolnak az alapkezelőtől, melyek a befektetési alapban való részesedésüket határozzák meg. A befektetési alap célja, hogy összegyűjtse a befektetők tőkéjét, amit pénzügyi eszközök, például részvények, kötvények és egyéb értékpapírok portfólióján keresztül fektet be. Az alapok általában biztosítják a kellő diverzifikációt, a tőke pedig szakemberekre van bízva, így egy alacsonyabb kockázatú és hozamú eszközt alkotva. A hozamról viszont az is elmondható, hogy a legtöbb esetben kifejezetten stabil. A befektetési alapokról részletesebben <Link to='/fogalomtar/befektetesi-alap' className="dezord">itt olvashatsz</Link>.</p>
            <br />
            <h3>ETF</h3>
            <p className="h3alter">Az ETF-ek egyfajta eszközkosarak. Annyiban különböznek a befektetési alapoktól, hogy ugyanúgy megvásárolhatók vagy eladhatók a tőzsdén, mint a hagyományos részvények. Egy ETF mindenféle befektetést tartalmazhat, például részvényeket, kötvényeket vagy árupiaci termékeket. Az ETF-ek gyakran egy indexet, szektort vagy egy adott árukategóriát követnek. Előnyük, hogy kisebb költséggel járnak és kevesebb brókeri jutalékot számítanak fel, mint a részvények egyenkénti megvásárlása. A befektetési alapokkal ellentétben az ETF-ek árfolyama folyamatosan változik, mivel azoknak nem csak naponta jegyzik az árát.</p>
            <br />
            <h3>Egyéb eszközök</h3>
            <p className="h3alter">A fent felsorolt 4 eszközt fogjuk a jövőben a leginkább körüljárni. Persze a lista nem ennyire rövid, ugyanis nem szabad megfeledkezni az ingatlanokról, a kincstárjegyekről, a bankbetétekről, a különböző nemesfémekről vagy akár a kriptvalutákról. A befektetés olyan formáival is találkozni fogunk, mint például a derivatívák, az árupiaci termékek, az értéktárgyak a devizák vagy startupok.</p>
            <br />
            <h2>Befektetés a gyakorlatban</h2>
            <p>Miután beleássuk magunkat a befektetés világába, eljön az idő amikor el kell döntenünk, hogy egy vagyonkezelőre bízzuk a tőkénk vagy mélyebbre ásunk és saját magunk kezeljük a portfóliónk apró részleteit is. Akik saját maguk kezelik a tőkéjüket, gyakran egy online brókerhez regisztrálnak, ahol minimális díjakért kezelhetik a befektetéseiket. A befektetésnek ez az oldala magas fokú képzettséget, elkötelezettséget és az érzelmek helyes módon való kezelésének képességét igényli. Az ehhez szükséges képességeket nem könnyű elsajátítani, viszont egy rendkívül hasznos skillnek bizonyul az élet minden területén.</p>
            <br />
            <p>A másik lehetőség, hogy valaki mást bízunk meg a tőkénk kezelésével. A vagyonkezelők professzionális befektetők, akik általában egy folytonos stabil hozamot biztosítanak az ügyfeleiknek, cserébe pedig díjakat számítanak fel. Sokaknak megéri fizetni azért a kényelemért, hogy a befektetési döntéshozatalt egy szakértőre bízzák. Amennyiben nem szeretnénk magas díjakat fizetni, lehetőségünk van mesterséges intelligencia, illetve algoritmusok által működtetett vagyonkezelőket is alkalmazni.</p>
            <br />
            <p>Amennyiben tőzsdén kereskedett eszközbe szeretnél fektetni, lehetőséged van bankon keresztül értékpapírszámlát nyitni és azon keresztül elvégezni a tranzakciót. Ez nem egy túl népszerű megoldás, mivel a bankok általában nagyon magas díjakat számítanak fel. A népszerűbb választás általában egy brókercég. A bróker megbízások alapján kereskedik értékpapírokkal, egyfajta közvetítőként jár el a befektető és az tőzsde között. A befektető megbízza a brókert, hogy vásároljon vagy adjon el eszközöket az ő nevében. A bróker ezt követően a tőzsdén keresztül lebonyolítja a tranzakciót.</p>
            <br />
            <p>Befektetés előtt fontos meghatározni a stratégiád, a preferenciáid és a kockázattűrő képességed. Ezek persze idővel folyamatosan változhatnak, a tapasztalatid és a te személyed függvényében. A tőke elosztása előtt fontos a lehető legrészletesebben elemezni a célbefektetést és megbizonyosodni arról, hogy összhangban van a stratégiáddal, illetve hogy ismered és azonosulni tudsz az eszközzel. Ezt a témát későbbi állomásokon részletesebben körüljárjuk.</p>
            <br />
            <h3>Példa a megtérülés kiszámítására</h3>
            <p className="h3alter">Tegyük fel, hogy vettünk 10 részvényt, 120 dollárt fizetve darabjáért. Miután az árfolyam eléri a 175 dollárt, úgy ítéljük, hogy érdemes megválni a részvénytől, ezért eladjuk azt. A nyereségünket az alábbi módon számíthatjuk ki: ( (175 - 120) / 120 ) <span>&#215;</span> 100 = 45,83%.</p>
            <p className="h3alter">A vállalat részvényenként 1 dollár osztalékot fizetett nekünk 8 negyedéven keresztül. Ez összesen 80 dollár osztalékot tesz ki. (80 / 1200) <span>&#215;</span> 100 = 6,66% | Ha összeadjuk a 45,83%-ot és a 6,66%-ot, akkor megkapjuk, hogy a befektetésen elért tőkenyereség 52,49%.</p>
            <br />
            <h2>Az árfolyam</h2>
            <TradingViewWidget2 />
            <br />
            <p>Az árfolyam megmutatja, hogy egy adott eszköz ára hogyan változik egy másik eszközhöz képest az idő múlásával. Részvények vizsgálatakor általában a ,,másik eszköz" szerepét az amerikai dollár tölti be. A leggyakoribb módszer az ábrázolására a japán gyertyás árfolyam grafikon, amit e szöveg felett is láthatsz.</p>
            <br />
            <p>Minden egyes gyertya egy időegység ármozgásait ábrázolja, és két fő részből áll: a testből és a kanócból. A gyertya testének hossza az adott időszak nyitó és záró árának különbségét mutatja. Ha a záróár magasabb, mint az nyitóár, akkor a gyertya testét általában zöldszínnel jelölik, míg ha a nyitóár magasabb, mint a záróár, akkor a gyertya piros színű lesz. A kanócok a gyertya testének kiterjesztéseiként jelennek meg, és az adott időszak legmagasabb és legalacsonyabb árát mutatják. Az alsó kanóc a legalacsonyabb árat, míg a felső kanóc a legmagasabb árat mutatja.</p>
            <br />
            <p>A grafikonoknak köszönhetően az ármozgásokat nyomon követhetjük, ami lehetővé teszi olyan következtetések levonását, amik kulcsfontosságúak lehetnek egy eszköz értékelésekor. Továbbá az árfolyam grafikon biztosítja a <Link to='/fogalomtar/technikai-elemzes' className='dezord'>technikai elemzés</Link> alapját.</p>
            <br />
            <p>Egy eszköz árát a kereslet és kínálat határozza meg. A keresletet és a kínálatot a befektetői tömeg értékítélete határozza meg, amit eszköztől függően olyan tényezők befolyásolhatnak, mint például a vállalati teljesítmény, az infláció, a kamatláb, a politikai és gazdasági események vagy a piaci hangulat.</p>
            <br />
            <h2>Ajánlott irodalom</h2>
            <div className="irodalom">
              <div className="halfirodalom">
                <img src="/imgvideo/gpszp-jol.jpg" alt="" />
              </div>
              <div className="halfirodalom">
                <p>Az első állomás ,,házi feladata" a Gazdag papa, Szegény papa című könyv elolvasása, ami talán a legideálisabb első könyv valakinek, aki befektetővé szeretne vállni. Egy olyan műről van szó, ami a pénzügyek és azon beül is leginkább a befektetések legalapvetőbb szabályait írja le. A könyv egyik legfontosabb tanulsága, hogy a gazdagság nem a magas jövedelemtől függ, hanem a pénzügyi intelligenciától. A könyv abból indul ki, hogy a gazdagok nem azért gazdagok, mert sok pénzt keresnek, hanem azért, mert tudják, hogyan kell a pénzüket dolgoztatni, hogy mégtöbb pénzt termeljenek. A könyv ezt fejti ki részlétesebben, lépésről lépésre bontva. </p>
              </div>
            </div>
            <br />
            <br />
            <div className='lowernavbar'>
              <button className='btn btn0 success' onClick={() => { window.location.href = '/roadmap'; }}>Vissza a Roadmap-re</button>
              <button className='btn btn0 success' onClick={() => { window.location.href = '/fogalomtar'; }}>Következő állomás</button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Elsoallomas