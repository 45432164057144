import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Fedezetipont() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Fedezeti pont - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a fedezeti pont jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Fedezeti pont</h1>
                            <p>Ezen az oldalon megismerheted a fedezeti pont jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A fedezeti pont (breakeven) azt a mennyiségeket és árat fejezi ki, amelyen a vállalat költségei egyenlők a bevételeivel, vagyis a profit zérus.</p>
                            <p className="majonator">A fedezeti pont segítségével kiszámolható, hogy mekkora termékmennyiség kell ahhoz, hogy a vállalat nyereségesen működjön. Ha a vállalat pozitívan átlépi a fedezeti pontot, akkor profitról beszélünk, míg ha alá kerül, akkor veszteség lép fel. Minél alacsonyabb/magasabb a fedezeti pont, annál könnyebb/nehezebb nyereségesen működni. A fedezeti pont egy olyan pont, ahol a vállalat működése se nem veszteséges, se nem nyereséges. Ez a pont egyfajta képzeletbeli határ, ami a veszteség és a nyereség között áll.</p>
                            <hr />
                            <h2>Kiszámítása</h2>
                            <p className="majonator">Fedezeti pont = fix költség / (termék eladási ára - termék változó költsége)</p>
                            <p className="majonatora">Fix költségek: olyan költség, ami nincs összefüggésben a termék előállítási mennyiségével. Például munkabérek, közmű díjak. Változó költségek: olyan költség, ami összefügg a termék mennyiségével. Például: alapanyagok.</p>
                            <br />
                            <p className="majonator azam">Példa</p>
                            <p className="majonatora">Van egy társasjátékokat gyártó cég. A cég fix költsége 2,5 millió forint. Egy társasjátékot 6000 forintért árulnak, aminek a változó költsége 2500 forint. &nbsp; | &nbsp; 2.500.000 / (6.000 - 2.500) = 715 db &nbsp; | &nbsp; A cég 715 társasjáték értékesítése esetén működne a fedezeti ponton.</p>
                            <hr />
                            <h2>Fedezeti pont kereskedésben</h2>
                            <p className="majonator">Kereskedésben a fedezeti pont alatt az az ár értendő, ahol a kereskedő a pénzénél van, vagyis se vesztesége, se nyeresége nincs, ha realizálja a pozícióját. Ebbe az értékbe bele kell számítani a tranzakció költségeit. Általában erre pontra szokták beállítani a stop megbízást, mivel így nem lehet veszteséges a pozíció. <Link to='/fogalomtar/opcio' className='dezord'>Opciós</Link> kereskedésben is használatos a kifejezés: azt az árat hívjuk fedezeti pontnak, amit el kell érnie a mögöttes eszköznek a vele járó díjakkal együtt, hogy a kereskedő ne érjen el veszteséget az opción.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Fedezetipont