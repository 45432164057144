import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Benchmark() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Benchmark - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted az benchmark szó jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Benchmark</h1>
                            <p>Ezen az oldalon megismerheted az benchmark szó jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A benchmark (másnéven referencia index) egy alapkezelőknek meghatározott túlteljesítendő cél a hozamra vonatkozóan.</p>
                            <p className="majonator">A referencia index egy adott piacot, szektort, eszközosztályt vagy befektetési stratégiát reprezentál, és a befektetési alapok célja általában ennek felülmúlása. A benchmarkokat nem csak befektetési alapok, hanem bármilyen portfólió teljesítményének méréséhez használhatják. A portfólió összetétele és egyéb tulajdonságai alapján szokás megszabni a megfelelő benchmarkot.</p>
                            <hr />
                            <h2>Mire használható?</h2>
                            <p className="majonator">A benchmark viszonyítási alapként használatos. Segítségével összehasonlíthatóvá válnak a különböző <Link to='/fogalomtar/portfolio' className='dezord'>portfóliók</Link> teljesítményei. Meg van szabva, hogy melyik alapnak mi a referencia indexe. Például az amerikai részvényalapoknak az S&P 500 index. Vagyis az amerikai részvényalapok célja az, hogy felülmúlják az S&P 500 teljesítményét.</p>
                            <p className="majonator">Amennyiben az S&P 500 10%-ot emelkedett, míg ugyanennyi idő alatt az amerikai részvényalap 20% hasznot hajt, akkor elmondható, hogy jól végezte a dolgát. Viszont fontos megjegyezni, hogy az alapkezelő abban az esetben is megfelelt az elvárásoknak, ha az S&P 500 20%-ot csökken, míg az alapkezelő csak 10% veszteséget szenvedett el.</p>
                            <hr />
                            <h2>Benchmark példák</h2>
                            <p className="majonator">Amerikai részvényalap: S&P 500 index &nbsp; - &nbsp; Az S&P 500 az USA tőzsdéin jegyzett 500 legnagyobb vállalat részvényeinek teljesítményét követi nyomon. Az S&P 500 egy <Link to='/fogalomtar/piaci-kapitalizacio' className='dezord'>kapitalizáció</Link> szerint súlyozott <Link to='/fogalomtar/tozsdeindex' className='dezord'>index</Link>. Ez használják az amerikai befektetési alapok referencia indexeként.</p>
                            <p className="majonator">Magyar részvényalap: BUX index &nbsp; - &nbsp; A BUX a Budapesti Értéktőzsde szekciójában szereplő legnagyobb tőkeértékű és forgalmú részvények árainak átlagos változását tükrözi. A BUX is egy kapitalizáció szerint súlyozott index.</p>
                            <p className="majonator">Európai részvényalap: Euro Stoxx 50 &nbsp; - &nbsp; Az Euro Stoxx 50 index egy piaci kapitalizációval súlyozott részvényindex, amely az euróövezet országaiban működő 50 blue-chip európai vállalatot tartalmaz.</p>
                            <hr />
                            <h2>Megfelelő benchmark választása</h2>
                            <p className="majonator">Fontos, hogy a referencia index jól tükrözze a portfólióhoz tartozó piacokat. A referencia indexek között találunk földrajzi terület piacát reprezentáló indexeket, szektorok vagy piaci kapitalizáció szerint besorolt indexeket. A legjobb benchmark egy olyan index, amely a leginkább van kapcsolatban a portfóliónkban szerepló eszközökkel.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Benchmark