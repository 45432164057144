import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Ertekpapir() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Értékpapír - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted az értékpapír jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>


                {/* masodlagos ertekpapir */}

                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Értékpapír</h1>
                            <p>Ezen az oldalon megismerheted az értékpapír jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">Az értékpapír vagyoni jogot vagy követelést megtestesítő okirat.</p>
                            <p className="majonator">Az értékpapír bizonyos jogokkal ruházza fel tulajdonosát, mint például az osztalékhoz való jog vagy a szavazati jog. Az értékpapírok átruházható eszközök, pénzért eladhatóak és megvásárolhatóak. Hagyományosan papírra nyomtatták őket, mára viszont már elterjedtek a dematerializált értékpapírok. Értékpapír lehet részvény, kötvény, kincstárjegy, befektetési jegy, letéti jegy, csekk, jelzáloglevél.</p>
                            <hr />
                            <h2>Kategorizálás</h2>
                            <p className="majonator azam">Részesedést megtestesítő értékpapírok</p>
                            <p className="majonator">Az ilyen értékpapírok tulajdonosai hozzájárulnak egy vállalat alaptőkéjéhez. Az értékpapír bizonyos tulajdonosi jogokat biztosít a befektetőnek. Ezeknek az értékpapíroknak a tulajdonosai a vételi és eladási ár különbségéből, illetve <Link to='/fogalomtar/osztalek' className='dezord'>osztalékból</Link> realizálnak hozamot. Legnépszerűbb formája a részvény.</p>
                            <br />
                            <p className="majonator azam">Hitelviszonyt megtestesítő értékpapírok</p>
                            <p className="majonator">Ennek az értékpapír típusnak az esetében az egyik fél elismeri a másik fél féle fennálló tartozást, amit az idő folyamán törleszt, vagy egy adott időpontban visszafizet. Jellemzően határozott futamidőre kerülnek kibocsátásra, melynek lejártával a kibocsátó visszaválthatja azokat. Legnépszerűbb formája a kötvény.</p>
                            <br />
                            <p className="majonator azam">Hibrid értékpapírok</p>
                            <p className="majonator">A hibrid értékpapírok, egyesítik a hitelviszonyt megtestesítő és a részesedést megtestesítő értékpapírok egyes jellemzőit. Fizethetnek kamatot és osztalékot is. A hibrid értékpapírok közé tartoznak például az átváltható kötvények és az <Link to='/fogalomtar/elsobbsegi-reszveny' className='dezord'>elsőbbségi részvények</Link>.</p>
                            <br />
                            <p className="majonator azam">Derivatív értékpapírok</p>
                            <p className="majonator">A <Link to='/fogalomtar/derivativa' className='dezord'>származtatott ügylet</Link> olyan típusú pénzügyi szerződés, amelynek árát valamilyen mögöttes eszköz, például részvény vagy kötvény értéke határozza meg. A derivatív ügyletekben a mögöttes eszköz jövőbeni értékére spekulálnak. A leggyakrabban forgalmazott származtatott ügyletek közé tartoznak az <Link to='/fogalomtar/opcio' className='dezord'>opciók</Link>.</p>
                            <hr />
                            <h2>A három fő értékpapír</h2>
                            <p className="majonator azam">Részvény</p>
                            <p className="majonator">A részvény egy olyan értékpapír, amely egy vállalatban való tulajdoni részesedést testesít meg. A részvények olyan lejárat nélküli értékpapírok, amelyekkel az IPO-t követően brókerek segítségével a tőzsdén lehet kereskedni. A részvény a tulajdonost a vállalat vagyonának és nyereségének a befektető tulajdonában lévő részvények mennyiségével megegyező hányadára jogosítja fel. A részvényről részletesebben <Link to='/fogalomtar/reszveny' className='dezord'>itt olvashatsz</Link>.</p>
                            <br />
                            <p className="majonator azam">Kötvény</p>
                            <p className="majonator">A <Link to='/fogalomtar/kotveny' className='dezord'>kötvény</Link> egy olyan értékpapír, ami hitelviszonyt testesít meg az adott szervezet és a befektető között. A kötvény egy fix hozamú eszköz, amely a befektető által a hitelfelvevőnek nyújtott kölcsönt képvisel. A kötvényt úgy kell elképzelni, mint egy, a hitelező és a hitelfelvevő közötti, a kölcsön és annak kifizetéseinek részleteit tartalmazó dokumentumot. A kötvényről részletesebben itt olvashatsz.</p>
                            <br />
                            <p className="majonator azam">Befektetési jegy</p>
                            <p className="majonator">A befektetési jegyek összege a <Link to='/fogalomtar/befektetesi-alap' className='dezord'>befektetési alapban</Link> való részesedésünk mértékét határozzák meg. A befektetési alap olyan befektetési eszköz, melyet azzal a céllal hoztak létre, hogy összegyűjtsék a befektetők tőkéjét, és ezt a tőkét közösen fektessék be pénzügyi eszközök, például részvények, kötvények és egyéb instrumentumok portfólióján keresztül. A befektetési alapról részletesebben itt olvashatsz.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Ertekpapir