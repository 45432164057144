import React from 'react'
import './Road.css'
import Footer from "../footer/footer";
import { Helmet } from 'react-helmet-async';

function Negyedikallomas() {
  return (
    <>
    <Helmet>
        <title>4. A személyiség és a befektetés kapcsolata | Befektetésközpont</title>
        <meta name="description" content="Ezen az állomáson megtudhatod, hogy mi az összefüggés az ember személyisége és a befektetés között, illetve hogy az érzelmek és a tapasztalat hogyan befolyásolja a döntéseinket. Megismerkedhetsz a hozam és a kockázat kapcsolatával, majd a különböző kockázatvállalási hajlandóságokkal." data-react-helmet="true" />
      </Helmet>
      <div className='allomascontent'>
        <div className='allomassection'>
          <div className='allcontentheader'>
            <h1>A személyiség és a befektetés kapcsolata</h1>
            <p>Ezen az állomáson megtudhatod, hogy mi az összefüggés az ember személyisége és a befektetés között, illetve hogy az érzelmek és a tapasztalat hogyan befolyásolja a döntéseinket. Megismerkedhetsz a hozam és a kockázat kapcsolatával, majd a különböző kockázatvállalási hajlandóságokkal.</p>
          </div>
          <div className='allcontentcontent'>
            <h2>A befektető személyisége</h2>
            <p>A befektetésben az egyik legérdekesebb jelenség az, hogy elsősorban nem az intelligencia dönti el azt, hogy milyen befektetők vagyunk. Ami igazából leginkább meghatároz egy befektetőt, az a jellem. Tagadhatatlan, hogy az intelligencia is fontos szerepet játszik egy befektető tevékenységeiben, viszont akár a  legostobább ember is képes felülmúlni a legintelligensebbet, ha a jelleme megfelelő a befektetéshez. A jó befektető türelmes, fegyelmezett és képes az érzelmeit kordában tartani. Valaki, aki határozott, realista és képes kiállni a tömegből.</p>
            <br />
            <p>Az emberek egy gyakori hibája, hogy amikor érkezik az optimizmus vagy a pesszimizmus egy újabb hulláma, akkor hagyják magukat elsodródni az árral és minden szabályt félredobva olyan felelőtlen döntéseket hoznak, amik megpecsételik a sorsukat mind a befektetésben, mind az életben. Befektetővé válni részben azt jelenti, hogy képessé válunk ellenállni annak, hogy a tömeg harsogása felülírja a saját ítéleteiket. A befektetés nem mások legyőzésről, hanem inkább saját magunk kontrollálásáról szól.</p>
            <br />
            <p>Az emberi agy a felépítéséből fakadóan, ha egy esemény sorozatban többször is bekövetkezik, akkor automatikusan arra számít, hogy újra meg fog történni. Ebből következik, hogy egy eszköz árfolyamának egymás után folyamatosan bekövetkező növekedése vagy csökkenése azt a várakozást kelti bennünk, hogy az adott esemény újra be fog következni, így csapdát állítva saját magunknak. Ha pedig újra bekövetkezik, akkor az agy a dopamin nevű hormon termelése útján függővé teszi saját magát az újabb és újabb előrejelzések megalkotására. Továbbá az árfolyam csökkenése hatással van az agy amigdalának nevezett részére, ami az emberekben az ,,üss vagy fuss” stresszválaszt váltja ki, ami a befektetésben a panic sellnek nevezett jelenséghez vezethet.</p>
            <br />
            <h2>Érzelmek és tapasztalat</h2>
            <p>Annak, aki sikeres befektetővé szeretne válni, az egyik első dolog, amit meg kell tennie, az a csordaszellem elhullajtása. Az emberek többségének a valódi kihívást nem a befektetésre alkalmas eszközök megtalálása jelenti, hanem a tömeg lelkesedésének és túlzott bizalmának felülírása. Komoly akaraterőre lehet szükségünk, hogy ne kövessük a tömeget. Nem számít, hogy hányan szeretnének hozzájutni egy eszközhöz, nekünk csak akkor szabad, hogy érdekeltségünk legyen benne, ha az értéke alapján jogos áron kaparinthatjuk meg.</p>
            <br />
            <p>Számos befektetőnél megfigyelhető, hogy állandóan ugyan annak az egy eszköznek az árfolyamgrafikonját bújja, és hagyja, hogy a grafikon gyertyái felemésszék az ítélőképességét. Csak akkor fektessünk be, ha akkor is kényelmesen érzenénk magunkat, ha az adott eszköznek nem tudnánk a napi árfolyamát. Egy befektetőnek csak olyan mértékben szabadna figyelnie az árfolyamot, hogy képes legyen megfelelő árszinten megválni az adott eszköztől.</p>
            <br />
            <p>Vannak olyan befektetési módszerek, amik segítségül szolgálnak olyan befektetőknek, akiknek nehezükre esik kizárni az érzelmeiket befektetéskor. Az érzelmi alapú döntéshozatal elkerülésének érdekében használható például a költségátlagolásos módszer, aminek a lényege, hogy a befektető megszabott időközönként ugyanakkora tőkét szán befektetésre. Ez egy kifejezetten népszerű módszer, nem biztos, hogy a leghatékonyabb, de sok embernek ez nyújthat megoldást a befektetéshez.</p>
            <br />
            <p>Mint a legtöbb területre, általában a befektetésre is igaz, hogy a tapasztalat jelentősen meghatározza egy befektető sikerességét. Számos pénzügyi tanácsadótól hallhatjuk, hogy érdemes demo számlán kipróbálni a befektetést mielőtt beugranánk a mély vízbe. A demo számlák hasznos eszközök lehetnek, viszont óriási hátrányuk, hogy a befektető nem tapasztalja meg a saját bőrén a veszteséget és kevesebb felelősséget érez a befektetett tőke iránt. Ezért talán jobb megoldásnak bizonyulhat, ha előbb csak egy kisebb mennyiséget fektetünk be, majd idővel folyamatosan növeljük az összeget. Szintén fontos az, hogy a tapasztalatainkat ne érzelmi alapon vonjuk le, hanem elemezzük ki, hogy valójában mik a kiváltó okok. Ha rosszul áll a befektetett tőként, akkor nem szabad elkeseredni. Érdemes úgy felfogni a veszteséget, mintha tapasztalatot vásárolnánk.</p>
            <br />
            <h2>Pszichológiai csapdák</h2>
            <h3>Megerősítési torzítás</h3>
            <p className='h3alter'>Megerősítési torzításnak nevezzük azt, amikor túlzott mértékben csak olyan információkat keresünk és veszünk figyelembe, amik megfelelnek a saját meggyőződéseinknek és megerősítik az álláspontunkat. Ez azért alakul ki egyes embereknél, mivel a számunkra pozitív információt sokkal könnyebb befogadni és nem okoz kognitív terhet. Az effajta torzítás komoly problémákat szülhet, mivel a befektető hajlamos a negatív híreket figyelmen kívül hagyni, a pozitív hírek esetén pedig megerősíteni a meggyőződését, így egy hamis képet alkotva.</p>
            <br />
            <h3>Hozzáférhetőségi heurisztika</h3>
            <p className="h3alter">A hozzáférhetőségi heurisztika az a jelenség, amikor egy esemény okait vagy bekövetkezésének valószínűségét az alapján ítéljük meg, hogy mennyire könnyű előidéznünk a vele kapcsolatos emlékeinket. Ilyenkor az esemény bekövetkezésének esélyét a meglévő tapasztalatink alapján valószínűsítjük, ami szintén torz képet szülhet. Például ha a hírekben egy újabb repülőgép balesetről hallunk, akkor hajlamosak vagyunk túlbecsülni a hasonló balesetek bekövetkezésének valószínűségét és esetleg pont ez lesz az oka annak, hogy legközelebb nemet mondunk a repülésnek.</p>
            <br />
            <h3>Veszteségkerülés</h3>
            <p className="h3alter">Az emberek a veszteséget nagyobb fájdalomként élik meg, mint a vele megegyező összegű nyereséget, így a legtöbb esetben nagyobb hangsúlyt helyeznek a veszteségek elkerülésére, mint a nyereségszerzésre. Gyakran megfigyelhető az a jelenség, hogy amikor a befektető egy pozíciója veszteséges, akkor addig nem válik meg az eszközeitől, amíg azok nyereségesek nem lesznek vagy vissza nem mennek a kezdeti árfolyamhoz. Ha elkövetünk egy befektetési hibát és jelentős veszteséget érünk el, akkor még megmenthetjük magunkat attól, hogy teljesen hülyét csináljunk magunkból, ha azonnal megválunk a túlárazott eszköztől. A probléma az, hogy hajlamosak vagyunk egy befektetés teljesítményét aszerint mérni, hogy milyen messze áll a kezdeti (vásárláskori) árfolyamtól. A befektetők veszteség esetén vonakodnak beismerni, hogy befektetési hibát követtek el.</p>
            <br />
            <h3>Lehorgonyzási heurisztika</h3>
            <p className="h3alter">Lehorgonyozáskor abba a csapdába esünk bele, hogy nagymértékben hagyatkozzunk a témáról kapott első információra. Amikor becsléseket készítünk, akkor az újabb információkat a horgonyunk referenciapontjából értelmezzük, ahelyett, hogy objektíven elemeznénk. Befektetésben horgonyként viselkedhet például egy eszköz vételi ára, viszonyítási alapként szolgálva a vele kapcsolatos későbbi döntések meghozatalához.</p>
            <br />
            <h3>Túlzott önbizalom</h3>
            <p className="h3alter">Az emberek általában úgy gondolják, hogy a képességeik átlag felettiek. A legtöbben túlértékelik a tudásuk pontosságát és felsőbbrendűségét másokéhoz képest. A befektetésben az ilyen hozzáállás mindig fájdalmas következményekkel jár. Egy befektető nem lehet arrogáns. Kulcsfontosságú felismernünk, hogy milyen keveset tudunk mondani a jövőről és elfogadnunk a saját tudatlanságunkat. Számos tanulmány kimutatta, hogy a túlzottan magabiztos befektetők gyakrabban hajtanak végre tranzakciókat és a gyakoribb kereskedési tevékenység az átlagnál alacsonyabb hasznokat eredményez.</p>
            <br />
            <h3>Csordaösztön</h3>
            <p className="h3alter">Amikor egy piac felfelé vagy lefelé mozog, a befektetők attól félnek, hogy mások több információval rendelkeznek, esetleg azt hiszik, hogy a tömeg már elvégezte helyettük a befektetés elemzését. Ennek következtében erős késztetést éreznek arra, hogy azt tegyék, amit mások. A profitszerzési lehetőség elszalasztásától való félelem tartósabb motiváló tényező, mint a megtakarítások elvesztésétől való félelem, ami részben a kimaradástól való félelmet (FOMO) képezi. A FOMO az, ami a döntő többséget hajtja, az attól való félelem, hogy a körülöttünk lévő emberek nehogy lehagyjanak minket, nehogy mi is kimaradjunk valamiből. A befektetésben a tartós siker kulcsa egy egyedi, független rendszer kialakítása, amely racionális elemzésen és a szigorúan fegyelmezett végrehajtáson alapul.</p>
            <br />
            <h3>A kontroll illúziója</h3>
            <p className="h3alter">Az emberek hajlamosak túlbecsülni a képességüket az események befolyásolását illetően. Jó példa erre a torzításra amikor a befektetők azt hiszik, hogy képesek olyan eszközöket kiválasztani, amelyekkel hosszú távon magasabb hozamot tudnak elérni, mint a piac. Hiszen ha elég időt töltenek az egyes instrumentumok elemzésével, akkor nehogy már ne tudják megmondani, hogy melyik portfólió a legideálisabb és legsikeresebb. Hasonló jelenség, amikor a lottószámok kiválasztásánál az emberek szerencseszámokat alkalmaznak, annak ellenére, hogy minden számkombinációnak ugyanakkora esélye van a telitalálatra.</p>
            <br />
            <h3>Birtoklási hatás</h3>
            <p className="h3alter">A birtoklási hatás akkor lép fel, amikor a már tulajdonunkban lévő befektetési eszközöket értékesebbek tartjuk és elkötelezettebbek vagyunk irántuk, mint más eszközök iránt. Ez ahhoz vezethet, hogy nehezen vagy egyáltalán nem leszünk hajlandóak megválni azoktól a befektetéseinktől, amelyek idővel a túlárazott kategóriába kerültek, és nem akarjuk lecserélni őket valami olyanra, ami valóban befektetésre érdemes. Ne hagyjuk, hogy érzelmileg kötődjünk bármilyen befektetésünkhöz.</p>
            <br />
            <h3>Representative bias</h3>
            <p className="h3alter">A representative bias egy olyan kognitív torzítás, amelyet valószínűségek becslésekor használunk. Egy esemény bekövetkezési esélyének becslésekor gyakran úgy alkotunk ítéletet, hogy megpróbáljuk társítani egy meglévő mintázattal. Ezt alkalmazzunk például amikor az utcán meglátunk egy férfit öltönyben, aktatáskával a kezében és egyből arra asszociálunk, hogy ő egy ügyvéd, csak mert hasonlít rá. Vagyis ennek a torzításnak a bekövetkezésekor az alapján hozunk döntést, hogy milyen tapasztalataink voltak az adott dologgal a múltban. Például ha egy részvény kiválasztása előtt állunk, akkor könnyen alkothatunk ítéletet az alapján, hogy a közelmúltban mekkorát emelkedett az árfolyama.</p>
            <br />
            <h3>Hindsight bias</h3>
            <p className="h3alter">Hindsight bias-ról akkor beszélünk, amikor egy esemény megtörténése után meggyőzzük magunkat arról, hogy pontosan előre tudtuk jelezni a bekövetkezését még mielőtt megtörtént volna. Ez arra engedheti következtetni az embereket, hogy más eseményeket is pontosan meg fognak tudni jósolni a jövőben. Ebből az következik, hogy kevésbé leszünk kritikusak a befektetéseinkben, ami később rossz döntéshozatalhoz vezethet.</p>
            <br />
            <h3>Regret aversion bias</h3>
            <p className="h3alter">A regret aversion bias a megbánástól való félelemről szól. Bizonyos emberek azért kerülik a határozott lépéseket, mert attól tartanak, hogy utólag bármilyen döntést is hoztak, nem lesznek vele megelégedve. Ez a torzítás arra játszik, hogy elkerülje a rossz döntésekkel járó megbánás érzelmi fájdalmát. Ez lehet például az oka annak, ha nem merünk betörni olyan pénzügyi piacokra, amelyek a közelmúltban veszteségesek voltak.</p>
            <br />
            <h3>Status quo bias</h3>
            <p className="h3alter">A status quo bias a dolgok jelenlegi módjának elfogult preferálása. Ilyenkor az adott helyzet változatlanságára törekszünk, annak ellenére is, ha nem az a legmegfelelőbb választás számunkra. A változás sok ember számára ijesztő lehet, gyakran veszteségként vagy hátrányként fogják fel. Ez a torzítás ellenállóvá tesz bennünket a változással szemben, ami a döntéshozatalunkra - főleg a befektetésben - negatív hatással van.</p>
            <br />
            <h2>Hozam és kockázat</h2>
            <p>Minél nagyobb a hozam, annál nagyobb a kockázat. Ez fordítva is igaz, vagyis minél alacsonyabb a hozam, annál alacsonyabb a kockázat. Vagyis a hozam és a kockázat egyenesen arányos egymással. Ebből következik, hogy ha nagyobb hozamot szeretnénk elérni, akkor be kell vállalnunk a vele járó nagyobb kockázatot is. Minden befektető egyedi kockázati profillal rendelkezik, amely meghatározza a kockázatvállalási hajlandóságát. Elengedhetetlen, hogy mindenki megtalálja a számára optimális egyensúlyt a hozam és a kockázat között, miközben figyelembe veszi a saját pénzügyi céljait és preferenciáit. Fontos azonban észben tartani, hogy se a túl kevés, se túl sok kockázat nem kifizetődő egy befektető számára. Befektetés előtt érdemes mindenkinek felmérni, hogy a céljai eléréséhez mekkora hozamra van szüksége, majd ennek függvényében a lehető legkisebb kockázatot vállalni.</p>
            <br />
            <br />
            <img src="/imgvideo/riskchart.jpg" alt="riskreturn" width='100%'/>
            <br />
            <br />
            <p>A fenti ábrán jól látható a különböző eszközosztályok átlagos hozam-kockázat megoszlása. Azért fontos kiemelni, hogy átlagos, mivel minden osztály között van egy minimális átfedés. Például van olyan részvény, amibe kockázatosabb befektetni, mint egy kriptovalutába. Befektetéskor érdemes olyan eszközosztályt választani, ami a leginkább illik a saját kockázatvállalási hajlandóságunkhoz. Például egy olyan személynek, aki egy stabil, jövedelemtermelő, alacsony kockázatú portfóliót szeretne kiépíteni, annak érdemes lehet kötvényekbe, befektetési alapokba, esetleg magas osztalékfizető blue-chip részvényekbe fektetni.</p>
            <br />
            <br />
            <img src="/imgvideo/volatilitas.jpg" alt="volatilitas" width='100%'/>
            <br />
            <br />
            <p>Az eszközök kockázatosságának kifejezésére használatos egyik fontos szempont a volatilitás, vagyis az árfolyam ingadozásának, változékonyságának mértéke. Részben az ár túlzott ingadozása teremti meg a bizonytalanságot a befektetőben, ami végül a kognitív torzítások alapjául szolgál. Minél magasabb egy instrumentum volatilitása, annál inkább próbálja beleterelni a befektetőt a különböző pszichológiai csapdákba.</p>
            <br />
            <p>A fent látható két ábra egy-egy eszköz árfolyam grafikonját jeleníti meg. A középső, felfelé ívelő vonalak az átlagokat, míg az fel-le mozgó vonalak az árfolyamokat jelölik. Jól kivehető az ábrákból, hogy a bal oldali eszköz kevésbé volatilis, mint a jobb oldali, viszont mind a két árfolyam átlaga megegyezik. Ebből az következik, hogy nekünk mindegy, hogy melyik befektetést választjuk, a hozam mind a két esetben ugyanakkora lesz. Amikor az interneten különböző eszközök hozamai után kutakodunk, könnyen belebotolhatunk olyan esetekbe, hogy két eszköznek a hozamai az átlag alapján megegyeznek, viszont volatilitás tekintetében jelentősen eltérnek. Ha ilyenkor csak a hozamot vizsgálnánk, akkor azzal elrejtenénk saját magunk elől a kockázatot, amit magával hordoz a volatilitás. Ezért is kulcsfontosságú megvizsgálni a hozamot és a kockázatot is, majd összevetni a kettőt egymással.</p>
            <br />
            <p>Fontos azonban megjegyezni, hogy a kockázat nem csak a volatilitás mértékétől függ. Az esetleges kockázati tényezők eszközönként eltérhetnek. Minden instrumentum alapvetően ki vannak téve (társadalom)politikai, makrogazdasági, likviditási és ország szerinti kockázatnak. Ezeket soroljuk a szisztematikus kockázatok közé. A másik kategória a specifikus kockázat, ahol olyan szempontokkal találkozhatunk, mint a vállalat alapvető életképessége, a hitelkockázat, a nemfizetési kockázat vagy a devizakockázat.</p>
            <br />
            <h3>Időtáv</h3>
            <p className="h3alter">Ha egy befektetőnek szüksége van arra, hogy azonnal hozzáférhessen a tőkéjéhez, akkor kevésbé valószínű, hogy olyan befektetést fog választani, aminek magas a kockázata vagy alacsony a likviditása. Ilyen esetben sokkal ajánlatosabb egy kockázatmentesebb nyílt végű alap vagy egy kötvény választása. A fiatalabb befektetőknek, akiknek több idejük van vagyont felhalmozni, hajlandóak lehetnek magasabb kockázatú és ezáltal potenciálisan magasabb hozamú befektetéseket választani, míg az idősebb korosztály általában a stabilabb eszközök mellett kötelezik el magukat, hogy bármikor hozzáférhessenek a pénzükhöz.</p>
            <br />
            <div className='lowernavbar'>
              <button className='btn btn0 success' onClick={() => { window.location.href = '/roadmap'; }}>Vissza a Roadmap-re</button>
              <button className='btn btn0 success' onClick={() => { window.location.href = '/roadmap/otodik'; }}>Következő állomás</button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Negyedikallomas