import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function PB() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>P/B mutató - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a P/B jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>P/B mutató</h1>
                            <p>Ezen az oldalon megismerheted a P/B jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A P/B egy olyan mutató, ami egy vállalat egy részvényre jutó könyv szerinti értékét viszonyítja a papír piaci árához.</p>
                            <p className="majonator">A P/B rátát a befektetők a befektetésre érdemes eszközök azonosítására használják. Főként értékbefektetők használják alulértékelt vállalatok kereséséhez. A részvények piaci ára jellemzően magasabb, mint a vállalat részvényeire jutó könyv szerinti érték, vagyis a mutató legtöbbször 1 feletti értéket ad vissza. Egy vállalat elemzésekor a P/B arányt érdemes összevetni az adott iparágban jelenlévő, hasonló felépítésű vállalatok P/B mutatóival.</p>
                            <hr />
                            <h2>Kiszámítása</h2>
                            <p className="majonator">P/B = Piaci ár / BVPS</p>
                            <p className="majonator">A könyv szerinti érték a vállalat összes eszközének és összes kötelezettségének nettó különbsége. A BVPS (Book Value per Share) az egy részvényre jutó könyv szerinti értéket jelenti, amit úgy számolhatunk ki, hogy a vállalat könyv szerinti értékét elosztjuk a részvényeinek számával. P betű alatt a részvény piaci árat értjük.</p>
                            <hr />
                            <h2>Értelmezése</h2>
                            <p className="majonator">Ha a P/B mutató értéke 1, akkor az azt jelenti, hogy a <Link to='/fogalomtar/reszveny' className='dezord'>részvény</Link> ára megegyezik a könyv szerinti értékével. A legtöbb esetben a piaci ár meghaladja a könyv szerinti értéket, mert a piac előre beárazza a vállalat növekedését, illetve beleszámítja az immateriális javakat is. Ezen kívül a piac értékítélete is nagyban meghatározza egy részvény árát.</p>
                            <p className="majonator">A mutató átlagos értéke a különböző <Link to='/fogalomtar/szektor' className='dezord'>befektetési szektorok</Link> között jelentős eltérést mutathat. Ez abból fakad, hogy minden vállalat struktúrája és működése eltér. Egy vállalatot ugyanannak az iparágnak a hasonló felépítésű vállalataival kell összehasonlítani, hogy pontos képet kapjunk. Minél nagyobb a P/B mutató, annál túlértékeltebb egy vállalat ebből az értékelési szempontból. Túlértékelt részvények között gyakran előfordul, hogy a P/B magas értéket mutat miközben a vállalat <Link to='/fogalomtar/roe' className='dezord'>ROE mutatója</Link> alacsony. Egy megfelelően értékelt részvény esetében a ROE és P/B mutatók nagyjából együtt mozognak. Ez azért van, mert a magasabb hozamtermelésre képes vállalatok bevonzzák a befektetőket, ami jelentős árfolyamnövekedést eredményez.</p>
                            <p className="majonator">Tegyük fel, hogy egy vállalat részvényének piaci ára 100 dollár és az egy részvényre jutó könyv szerinti értéke 50 dollár. Ebben az esetben a P/B mutató értéke 2, ami azt jelenti, hogy a részvény ára kétszerese a könyv szerinti értékének. Ha a vállalat iparágának átlagos P/B mutatója 1,5 akkor ez a részvény ebből a szempontból túlértékeltnek tekinthető. Ha azonban a vállalatnak kellőképpen nagy a növekedési potenciálja, akkor akár a magasabb P/B mutató esetén is lehet alulértékelt a vállalat.</p>
                            <hr />
                            <h2>Könyv szerinti érték</h2>
                            <p className="majonator">Mint ahogy feljebb is le lett írva, a könyv szerinti érték egy vállalat összes eszközének és összes kötelezettségének nettó különbsége. A könyv szerinti érték a vállalat mérlegében a saját tőke összes sorának összege. A könyv szerinti érték a vállalat eszközeinek teljes értékét tükrözi, amelyet a vállalat részvényesei kapnának, ha <Link to='/fogalomtar/csod' className='dezord'>felszámolnák</Link> azt. A könyv szerinti értékről részletesebben <Link to='/fogalomtar/konyv-szerinti-ertek' className='dezord'>itt olvashatsz</Link>.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default PB