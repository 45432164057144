import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Indikator() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Indikátor - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted az indikátor jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Indikátor</h1>
                            <p>Ezen az oldalon megismerheted az indikátor jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">Az indikátor olyan technikai elemzésben használt eszköz, amely a múltbeli áradatokból nyer információkat, hogy előrejelzéseket készítsen a jövőbeli ármozgásokról.</p>
                            <p className="majonator">Az indikátorok az eszközök árán és/vagy volumenén alkalmazott matematikai műveletek, melyek eredményeiből a kereskedők következtetéseket vonhatnak le, amiket felhasználhatnak a jövőbeli árak előrejelzésére. A technikai elemzés, és ezzel együtt az indikátorok is abban különböznek a fundamentális elemzéstől, hogy míg az utóbbi az eszköz valós értéke alapján próbálja meghatározni, hogy érdemes-e befektetni az adott eszközbe, addig az előbbi az árfolyam és volumen tanulmányozásából von le következtetéseket.</p>
                            <hr />
                            <h2>Működése</h2>
                            <p className="majonator">Az indikátorok a múltbeli árfolyamadatokból és a <Link to='/fogalomtar/forgalom' className='dezord'>forgalomból</Link> levezetett információkat használják a piac jövőbeli mozgásainak előrejelzésére. Az indikátorok széles skálája áll rendelkezésre, melyek mind különböző tényezőket vesznek figyelembe, például az árfolyam mozgásának irányát, a volatilitást vagy a trend erejét. Példák: RSI, MACD, OBV, ADX, Aroon.</p>
                            <p className="majonator">A <Link to='/fogalomtar/technikai-elemzes' className='dezord'>technikai elemzés</Link> abból a feltételezésből indul ki, hogy egy eszköz múltbeli árváltozása értékes mutatója lehet a jövőbeli ármozgásnak, ha megfelelő kereskedési szabályokkal párosul. Kizárólag indikátorok alapján következtetéseket levonni és befektetni rendkívül kockázatos. Amennyiben mégis vállaljuk ezt a kockázatot, csak annyi pénzt fektessünk be, aminek elvesztése nem okoz túl nagy gondot.</p>
                            <hr />
                            <h2>Népszerű indikátorok</h2>
                            <p className="majonator aza">Mozgóátlag</p>
                            <p className="majonator">A mozgóátlag az árfolyam egy adott időtartamra vetített átlaga. Általában az árfolyam grafikonon egy vonal formájában tüntetik fel, de gyakran lehet vele találkozni részvényelemző oldalakon, olyan formában, mint ,,mozgóátlagtól való távolság". Általában ellenállásként funkcionál az árfolyam számára. Pozitív jelzésként értelmezhető, ha egy rövid távú mozgóátlag egy hosszabb távú fölé emelkedik. Ezt hívják bullis crossovernek, aminek a bearish crossover az ellentéte. A mozgóátlagokról részletesebb <Link to='/fogalomtar/mozgoatlag' className='dezord'>itt olvashatsz</Link>.</p>
                            <br />
                            <p className="majonator aza">RSI</p>
                            <p className="majonator">Az RSI egy értékpapír közelmúltbeli árfolyamváltozásának sebessége és nagysága alapján értékeli az adott értékpapír árfolyamának túlértékeltségét vagy alulértékeltségét. Az indikátor egy vonalas grafikonként jelenik meg egy nullától 100-ig terjedő skálán. Hagyományosan a 70-es vagy annál magasabb RSI érték túlvételt jelez. A 30-as vagy az alatti érték túladott állapotot jelez. Az RSI-ről részletesebben <Link to='/fogalomtar/rsi-indikator' className='dezord'>itt olvashatsz</Link>.</p>
                            <br />
                            <p className="majonator aza">MACD</p>
                            <p className="majonator">A MACD egy olyan momentum indikátor, mely az árfolyam két exponenciális mozgóátlaga közötti kapcsolatot vizsgálja. A MACD alapbeállítások mellett a 12- és 26 napos exponenciális mozgóátlagot használja. Ha a rövidebb mozgóátlag keresztezi a hosszút, akkor az indikátor értéke nulla. Minél nagyobb távolság van a két mozgóátlag között, annál távolabb kerül az indikátor vonala a nullától. Ha a vonal nulla fölött van, akkor emelkedő trend van kialakulóban, ilyenkor a kereskedők befektetnek. Ha a vonal nulla alatt van, akkor csökkenő trend van kialakulóban, ilyenkor a kereskedők eladnak.</p>
                            <br />
                            <p className="majonator aza">OBV</p>
                            <p className="majonator">Az OBV indikátor egy volumenen alapuló technikai indikátor, amely az árfolyam és a volumen közötti kapcsolatot méri. A OBV célja, hogy előre jelezze a nagyobb piaci mozgásokat. Az indikátor kitalálója szerint, ha a forgalom jelentősen növekszik, de az eszköz árfolyamán nem történik nagyobb változás, akkor az az ár kitörését jelzi valamelyik irányba. Az emelkedő OBV érték emelkedő trendet jelez, míg a csökkenő érték csökkenő trendet. A kitaláló szerint, ha az ár stagnál vagy csökken és az indikátor nő, akkor emelkedő árat jelez előre, míg ha stagnál vagy emelkedik az ár, az OBV pedig egyre kisebb, akkor az ár is csökken.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Indikator