import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Derivativa() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Derivatíva - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a derivatíva jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Derivatíva</h1>
                            <p>Ezen az oldalon megismerheted a derivatíva jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A pénzügyekben a derivatíva, másnéven származtatott ügylet olyan pénzügyi eszköz, mely árfolyama egy másik termék árfolyamából származtatott.</p>
                            <p className="majonator">A derivatív ügyletekben a mögöttes eszköz jövőbeli értékére spekulálnak. A derivatívák általában tőkeáttételes eszközök, ami növeli a potenciális kockázatot és a hasznot. Segítségükkel fedezhető a kockázat és be lehet fektetni eszközökbe azok fizikai tulajdonlása nélkül. Származtatott ügyletekkel tőzsdén és tőzsdén kívül is lehetőség van kereskedni.</p>
                            <hr />
                            <h2>Fajtái</h2>
                            <p className="majonator azam">Futures</p>
                            <p className="majonator">A futures egy olyan megállapodás, amely egy eszköz megvásárlására vonatkozik, egy előre meghatározott áron és időpontban. Az érintett felek a kijelölt időpontban és áron kötelesek teljesíteni a mögöttes eszköz megvásárlására vagy eladására vonatkozó kötelezettségeket.</p>
                            <br />
                            <p className="majonator azam">Forward</p>
                            <p className="majonator">A forward hasonló a futures-höz, annyi különbséggel, hogy nem tőzsdén kereskednek velük, hanem a tranzakció kizárólag tőzsdén kívül történik. A forward szerződés létrehozásakor a vevő és az eladó testreszabhatja a feltételeket és az elszámolási folyamatot. A derivatíváknak ez a fajtája a tőzsdétől való elszigetelődés miatt kockázatosabb.</p>
                            <br />
                            <p className="majonator azam">Options</p>
                            <p className="majonator"> Az <Link to='/fogalomtar/opcio' className='dezord'>opciós szerződés</Link> hasonlít a futures szerződéshez. Mind a kettő egy két fél közötti megállapodás arról, hogy egy eszközt egy előre meghatározott jövőbeli időpontban egy meghatározott áron megvásárolnak vagy eladnak. A különbség abban van, hogy az opciós szerződés esetében a vevő nem köteles a vételi vagy eladási megállapodást végrehajtani, vagyis csak egy lehetőséget vásárol meg.</p>
                            <br />
                            <p className="majonator azam">Swap</p>
                            <p className="majonator">A swap olyan szerződés, amelynek keretében két fél <Link to='/fogalomtar/cash-flow' className='dezord'>cash flow-kat</Link> vagy kötelezettségeket cserél. A leggyakoribb swapügylet a kamatláb-swap. Swapokkal nem kereskednek a tőzsdéken, és általában magánszemélyek sem kötnek ilyen ügyleteket. A swapok inkább olyan tőzsdén kívüli szerződések, amelyek elsősorban vállalkozások vagy pénzügyi intézmények között jönnek létre.</p>
                            <hr />
                            <h2>Példa</h2>
                            <p className="majonator">Tegyük fel, hogy egy <Link to='/fogalomtar/vallalat' className='dezord'>vállalatnak</Link> két hónap múlva szüksége lesz búzára, de aggódik amiatt, hogy a búza árfolyama addigra megemelkedik. A vállalat futures szerződést köt a búzára, 6 dollárt fizet egy vékáért, így elkerülve az árfolyam emelkedéséből származó kockázatot. Két hónappal később a búza ára 8 dollára nőtt vékánként. A vállalat ekkor dönthet, hogy végrehajtja a vételi tranzakciót és így hozzájut a búzához kedvezményes áron, vagy eladhatja a szerződését másnak, hogy realizálja a 2 dollár/véka profitot.</p>
                            <hr />
                            <h2>Előnyök és hátrányok</h2>
                            <p className="majonator">A derivatívák olyan termékek kereskedését teszik lehetővé, melyek sok esetben más módon nem lehetnének elérhetőek. A derivatívák felhasználhatók a <Link to='/fogalomtar/portfolio' className='dezord'>portfóliónk</Link> kockázatának csökkentésére. Például, ha aggódunk a részvénypiac <Link to='/fogalomtar/volatilitas' className='dezord'>volatilitása</Link> miatt, opciót vásárolhatunk a részvényen, ami lehetővé teszi, hogy megvásároljuk a részvényt egy előre meghatározott áron a jövőben, függetlenül attól, hogy a részvény ára ekkor magasabb vagy alacsonyabb lesz. A <Link to='/fogalomtar/tokeattetel' className='dezord'>tőkeáttéttel</Link> működő derivatívák magas kockázatot hordoznak magukban. A derivatívák gyakran nagyon összetettek, és alaposan meg kell érteni őket, mielőtt befektetésre szánjuk magunkat.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Derivativa