import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Befektetesikockazat() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Befektetési kockázat - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a befektetési kockázat jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Befektetési kockázat</h1>
                            <p>Ezen az oldalon megismerheted a befektetési kockázat jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A befektetés kockázatának nevezzük egy befektetés jövőbeli megtérülésének bizonytalanságát.</p>
                            <p className="majonator">Befektetéskor fontos alaposan megvizsgálni a potenciális kockázatokat, és az egyéni befektetési céloknak és tolerancia szintnek megfelelően dönteni azokról az eszközökről és stratégiákról, amelyek meghatározzák a portfóliónkat. A befektetésben egy alapvető szabály, hogy a nagyobb hozam nagyobb kockázattal jár, vagyis, ha nagyobb hozamot szeretnénk elérni, akkor be kell vállalnunk az vele járó nagyobb kockázatot is.</p>
                            <hr />
                            <h2>Típusai</h2>
                            <p className="majonator">Megkülönböztetünk árfolyamkockázatot, fizetésképtelenségi kockázatot és hozamkockázatot.</p>
                            <p className="majonator">Árfolyamkockázatról beszélünk, ha az értékpapír árfolyamának jövőbeli alakulása bizonytalan. Minél <Link to='/fogalomtar/volatilitas' className='dezord'>volatilisebb</Link> az árfolyam, annál nagyobb az árfolyamkockázat. A volatilitás mérésére kiváló eszköz lehet a <Link to='/fogalomtar/beta-mutato' className='dezord'>béta mutató</Link>. A fizetésképtelenségi kockázat akkor lép fel, ha az eladósodott szervezet nem tudja visszafizetni a kötelezettségeit. Szerencsére a potenciálisan csődbemenő vállalatokat könnyű elkerülni. A hozamkockázat azt a veszélyt jelenti, hogy a piaci hozam meghaladja a befektetés által biztosított hozamot.</p>
                            <hr />
                            <h2>Kockázatcsökkentés</h2>
                            <p className="majonator azam">Diverzifikáció</p>
                            <p className="majonatora">A <Link to='/fogalomtar/diverzifikacio' className='dezord'>diverzifikáció</Link> egy olyan befektetési stratégia, amelynek célja a kockázat csökkentése és az eredmények stabilizálása azáltal, hogy többféle termékbe fektetünk be. Ez azért hasznos, mert ha az egyik instrumentum árfolyama lezuhan, akkor kisebb veszteséget szenvedünk el. Érdemes lehet befektetési eszközök, piaci szektorok és földrajzi elhelyezkedés szerint is diverzifikálni.</p>
                            <br />
                            <p className="majonator azam">Kevésbé kockázatos eszközök vásárlása</p>
                            <p className="majonatora">Ha biztonságosabb portfóliót szeretnék, akkor lehetőség van felhígítani a meglévő eszközeinket kevésbé kockázatosakkal. Például ha túl kockázatos számunkra egy 100%-ban részvényekből álló portfólió, akkor lehetőség van arra, hogy kötvényekkel vagy befektetési alapokkal kompenzáljunk.</p>
                            <br />
                            <p className="majonator azam">Rebalancing</p>
                            <p className="majonatora">A rebalancing azt a folyamatot jelenti, amikor meghatározott időközönként alapértelmezett helyzetbe állítjuk vissza a portfóliónkban lévő eszközök arányát. Például, ha összeállítottunk egy 30% kötvény 70% részvény portfóliót, akkor ez az arány könnyen elcsúszhat mondjuk 10%-90%-ra. A rebalancing alatt ennek az aránynak a visszaállítását értjük.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Befektetesikockazat