import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Betamutato() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Béta mutató - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a béta mutató jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Béta mutató</h1>
                            <p>Ezen az oldalon megismerheted a béta mutató jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A béta mutató azt méri, hogy egy instrumentum mennyire volatilis a piaci átlaghoz képest.</p>
                            <p className="majonator">A béta mutató egy eszköz kockázatosságát egy piacot reprezentáló tőzsdeindexhez viszonyítva fejezi ki. Például az amerikai részvényeket volatilitását az S&P 500 indexhez hasonlítják. A béta mutató a CAPM modellben is alkalmazzák, ami a befektetési eszközök hozamának kiszámítására használt módszer. Minél magasabb a volatilitása egy eszköznek, annál kockázatosabb. A magas volatilitású eszközök általában nagyobb hozamot kínálnak, viszont nagyobb kockázattal is járnak.</p>
                            <hr />
                            <h2>Működése</h2>
                            <p className="majonator">A magasabb <Link to='/fogalomtar/volatilitas' className='dezord'>volatilitással</Link>, vagyis magasabb béta értékkel rendelkező értékpapírok jellemzően nagyobb hozamot termelnek. A nagyobb hozam viszont nagyobb kockázattal is jár. Vagyis, ha nagyobb hozamot szeretnénk elérni, akkor be kell vállalnunk a vele járó nagyobb kockázatot is. A béta mutató utalhat arra, hogy mekkora visszaesésre számíthatunk, illetve hogy mennyivel lehet nagyobb a hozamunk a piacéhoz képest. A béta mutató nem csak egy adott eszköz, hanem egy eszközkosár, vagyis <Link to='/fogalomtar/portfolio' className='dezord'>portfólió</Link> kockázatosságának a mérésére is használható.</p>
                            <br />
                            <p className="majonatora">β &gt; 1 esetén az eszköz volatilitása nagyobb a piacénál</p>
                            <p className="majonatora">β = 1 esetén az eszköz volatilitása megegyezik a piacéval</p>
                            <p className="majonatora">β &lt; 1 esetén az eszköz volatilitása kisebb a piacénál</p>
                            <p className="majonatora">β = 0 esetén az eszköz ára és a piaci ár között nincs korreláció</p>
                            <p className="majonatora">β &lt; 0 esetén az eszköz ára ellentétesen mozog a piaccal</p>
                            <hr />
                            <h2>Kiszámítása</h2>
                            <p className="majonator">Béta mutató = <span class="frac"><sup>Cov(egy eszköz hozama, a piaci hozam)</sup><span>&frasl;</span><sub>Var(a piaci hozam)</sub></span></p>
                            <p className="majonator">A Cov a kovarianciát jelöli, megmutatja két egymástól különböző változó együttmozgását. A Var a varianciát jelöli, megmutatja, hogy egy valószínűségi változó milyen mértékben szóródik a várható érték körül.</p>
                            <hr />
                            <h2>Ideális béta érték</h2>
                            <p className="majonator">Azok a befektetők, akiknek a biztonság az egyik fő szempont, általában alacsonyabb bétájú papírokat szoktak vásárolni. Az alacsony bétájú értékpapírok árfolyama kevésbé ingadozik és általában lassú, de stabil növekedést mutatnak. Ezzel szemben a magasabb bétájú eszközök inkább olyan befektetőknek ajánlottak, akik rövid idő alatt szeretnének nagy hozamot elérni és képesek vállalni az ezzel járó magasabb kockázatot. A magas béta mutató esetében a jövőbeli árfolyamváltozások is nehezebben megjósolhatóak.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Betamutato