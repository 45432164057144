import React from "react";
import './szabalyok.css'
import Footer from "../footer/footer";


function Szabalyok() {

    return (
        <>
            <div className="covering">
                <div className="titldiv">
                    <h1>Befektetési alapszabályok</h1>
                </div>
                <hr />
                <hr />
                <main className="szabalyokmain">
                    <div className="egyszabaly kivetel122">
                        <h1>1. szabály: A jó befektető realista</h1>
                        <p>,,A realista befektető az optimistáknak ad el, és a pesszimistáktól vásárol.” - Benjamin Graham</p>
                        <p>A realista befektető nem hagyatkozik az érzelmeire, hanem racionális úton méri fel és értékeli a lehetőségeit. A pesszimista és az optimista gondolkodás egyaránt fantáziavilágba helyezi az elmét. Arra késztet minket, hogy vagy a legjobb vagy a legrosszabb dologra gondoljunk, ami történhet, így figyelmen kívül hagyva a valóságot.</p>
                    </div>
                    <br />
                    <div className="egyszabaly">
                        <h1>2. szabály: A személyiség dönt</h1>
                        <p>Elsősorban a jellem, nem pedig az intelligencia határozza meg, hogy milyen befektetők vagyunk.</p>
                        <p>A magas IQ és az egyetemi végzettség önmagában senkit nem tesz jó befektetővé. Ami viszont igen, az a türelem, a fegyelmezettség, a tudásszomj és az érzelmek kordában tartása. A világ legintelligensebb emberei közül rengetegen sorra buktak bele a tőzsdén folyó őrületbe. Egytől egyig hagyták, hogy a tömeg irányítsa őket, és a befektetéshez szükséges érzelmi fegyelem hiányában képesek voltak végzetes hibákat elkövetni.</p>
                    </div>
                    <br />
                    <div className="egyszabaly">
                        <h1>3. szabály: Diverzifikálj</h1>
                        <p>Fontos egy portfólióban az eszközök és az eszközosztályok diverzifikáltsága.</p>
                        <p>A kockázatcsökkentés egyik hatékony eszköze a diverzifikáció. Érdemes több különböző eszközből álló és befektetési szektorok szerint is szerteágazó portfóliót kiépíteni. Fontos azonban megjegyezni, hogy érdemesebb kevesebb eszközre koncentrálni a portfóliónk, amennyiben olyan elemek alkotják melyekről tudjuk, hogy kellőképpen jövedelmezőek lesznek. Így nem kényszerítjük csupán a diverzifikálás kedvéért kisebb hozamra a portfóliónk.</p>
                    </div>
                    <br />
                    <div className="egyszabaly">
                        <h1>4. szabály: Tudd hol a pénzed </h1>
                        <p>Tetőtől talpig elemezd a befektetést mielőtt a tulajdonosává válsz.</p>
                        <p>Részben ez az, ami megkülönböztet egy befektetőt egy spekulánstól. Nem szabad csak azért befektetünk valamibe mert nő az árfolyama, vagy mert csak simán tetszik. Egy befektető számára elengedhetetlen a részletes fundamentális elemzést és az eszköz árát befolyásoló tényezők megvizsgálása.</p>
                    </div>
                    <br />
                    <div className="egyszabaly">
                        <h1>5. szabály: Akkor vásárolj, amikor vér folyik az utcákon</h1>
                        <p>Vagyis akkor vásárolj, amikor mások félelemből, kétségbeesésükben adnak el.</p>
                        <p>Ezt a tanácsot sokkal könnyebb mondani, mint megfogadni. Bár logikusnak tűnik, nagyon kevesen követik. A kezdők attól tartanak, hogy a piac tovább süllyedhet, és ezért nem biztos, hogy ez a megfelelő idő a befektetésre. Itt azonban a probléma az, hogy még a legtapasztaltabb befektetők sem tudják előre jelezni a piac mozgásait. Ha a lehető legalacsonyabb áron próbálunk meg befektetni, akkor kudarcot fogunk vallani. A jó megközelítés az lenne, ha olcsót vásárolnánk, nem a legolcsóbbat.</p>
                    </div>
                    <br />
                    <div className="egyszabaly">
                        <h1>6. szabály: Nincs ingyenebéd</h1>
                        <p>Ha egy befektetés túl szépnek tűnik ahhoz, hogy igaz legyen, akkor valószínűleg az is.</p>
                        <p>Ha túlságosan csábító befektetési lehetősséggel találjuk szemben magunkat, akkor nagy valószínűséggel nem vettünk valamit figyelembe az eszköz elemzése során. Persze előfordulhat, hogy egy szélsőségesen alulértékelt befektetéssel találkozunk, viszont ennek az esélye elég csekély, mivel a piac nem szokott csak úgy otthagyni óriási vagyonokat a piacon.</p>
                    </div>
                    <br />
                    <div className="egyszabaly">
                        <h1>7. szabály: Tervezz</h1>
                        <p>Készíts egy olyan befektetési tervet, ami illeszkedik a pénzügyi céljaidhoz.</p>
                        <p>Befektetés előtt érdemes meghatározni azt a célt, amit el szeretnénk érni a tőke elhelyezésével, majd ehhez a célhoz igazítva felállítani egy tervet. A terv felállítása segít eldönteni, hogy mekkora kockázatot vállaljunk egységnyi szükséges hozamért és egy biztos alapot nyújt nekünk a vagyonteremtéshez.</p>
                    </div>
                    <br />
                    <div className="egyszabaly">
                        <h1>8. szabály: Ne próbáld meg megjósolni a piac mozgását</h1>
                        <p>A piac kiszámíthatatlan, jövőlátók pedig nem léteznek.</p>
                        <p>A legtapasztaltabb, legkiválóbb befektetők is gyakran tévednek és nem képesek előre jelezni semminek sem az árfolyamát. Nem az tesz egy befektetőt profivá, hogy pontosan meg tudja ítélni mikor mi fog történni. Profi az, aki képes megtalálni a valódi értéket, majd kellő fegyelmezettség mellett el tudja érni a befektetési céljait a maga kockázatvállalási hajlandósága és időhorizontja szerint.</p>
                    </div>
                    <br />
                    <div className="egyszabaly">
                        <h1>9. szabály: Nézd át a portfóliód rendszeresen</h1>
                        <p>Minél kockázatosabb egy portfólió, érdemes annál gyakrabban átnézni.</p>
                        <p>Azoknak, akik nem dolgoznak együtt egy befektetési tanácsadóval, érdemes évente minimum egyszer felülvizsgálniuk a portfóliójukat. Ennek az időtávnak a portfólió kockázatosságával arányosan kell növekednie. Például egy részvényportfólió esetében jó, ha van egy Excel táblázatunk, amiben heti rendszerességgel át tudjuk nézni a vállalatok fundamentális mutatóit. Míg például egy spekuláns portfólió esetében a legjobb, ha le se vesszük róla a szemünket.</p>
                    </div>
                    <br />
                    <div className="egyszabaly">
                        <h1>10. szabály: Fektess be hosszú távra</h1>
                        <p>A vagyonépítés leghatékonyabb formája, ha hosszú távra fektetünk be.</p>
                        <p>A piac hosszú távú tendenciája a növekedés, így a hosszabb időszakok általában kiegyenlítik a rövid távú piaci ingadozásokat. Ha megpróbáljuk kiaknázni a rövid távú árfolyamváltozásokat, akkor azzal nagyon könnyen túl nagy kockázatot vállalhatunk. A magas rövid távú hozamok gyakran elcsábítják az újoncokat, viszont a nagyobb sikerhez elengedhetetlen a hosszú távú befektetés. Bár a rövid távú aktív kereskedéssel nagyot lehet kaszálni, sokkal nagyobb kockázattal jár, mint a ,,Buy and Hold” stratégiák. Ahelyett, hogy egy befektetés rövid távú mozgásai miatt pánikba esnénk, jobb, ha inkább hátradőlünk és a nagy képet nézzük.</p>
                    </div>
                    <br />
                    <div className="egyszabaly">
                        <h1>11. szabály: Sose veszíts pénzt</h1>
                        <p>Ez valójában a befektetői gondolkodásmódra utal: Ne légy komolytalan, ne játszadozz a tőkéddel.</p>
                        <p>A szabály lényege, hogy ne válasszunk olyan befektetést, ami esetében eleve fel vagyunk készülve az esetleges veszteségekre. Végezzünk alapos kutatást, majd fektessünk be olyan dologba, amit jól átlátunk, értjük a működését és ami annyiba kerül, amennyit ér. Ne hagyd, hogy egy befektetés potenciális hozamai elbűvöljenek. Fontos először a befektetés hátrányaira koncentrálni.</p>
                    </div>
                    <br />
                    <div className="egyszabaly">
                        <h1>12. szabály: Használd ki a győztest</h1>
                        <p>Ne válj meg egy befektetéstől csak azért, mert óriásit nőtt az árfolyama.</p>
                        <p>Biztos veled is előfordult már, vagy elő fog fordulni, hogy egy befektetésed akár a sokszorosára nőtt és ezért úgy érezted, hogy itt az ideje megválni tőle. Valójában ha az általad elvégzett elemzés továbbra is az mondja, hogy az eszköz árfolyama megfelel az értékéhez képest, akkor nem feltétlenül érdemes eladni. Bölcsebb döntés kiszipolyozni a befektetésből annyi hasznot, amennyit csak lehet.</p>
                    </div>
                    <br />
                    <div className="egyszabaly">
                        <h1>13. szabály: Hajítsd el a vesztest</h1>
                        <p>Semmilyen befektetésedhez se ragaszkodj.</p>
                        <p>Sok befektető beleesik abba a hibába, hogy amikor egy pozíciójával vesztésre áll, akkor addig nem akarja eladni, amíg nyereséges nem lesz vagy vissza nem megy a vételi árhoz. Ha az elemzésünk azt mutatja ki, hogy az eszköz nem befektetésre érdemes, akkor nyugodtan váljunk meg a befektetéstől, ha kell akkor veszteségesen. Bár a veszteség elismerése rosszul eshet és kudarcként élhetjük meg, nem szégyen a hibák felismerése és a befektetések eladása a további veszteségek megakadályozása érdekében.</p>
                    </div>
                    <br />
                    <div className="egyszabaly">
                        <h1>14. szabály: Ne hallgass a tanácsokra</h1>
                        <p>Soha ne fogadj el semmilyen eszközre vonatkozó befektetési tanácsot.</p>
                        <p>Mindig végezd el a saját elemzésedet mielőtt befektetnél. Az interneten olvasható tippek (,,Vedd ezt, vedd azt”) néha beválnak, a forrás megbízhatóságától függően, de a hosszú távú sikerhez mélyreható kutatásra van szükség. Ha gondolkozol egy befektetésen, majd az interneten összefutsz egy cikkel, amiben ajánlják neked azt az eszközt, akkor ne hagyd, hogy a döntéshozatalodat bármilyen formában is befolyásolja, ha nem képes kellő bizonyítékot nyújtani arról, hogy miért érdemes befektetni.</p>
                    </div>
                    <br />
                    <div className="egyszabaly">
                        <h1>15. szabály: Ne egy szempontra fókuszálj</h1>
                        <p>Egy eszköz értékét számos tényező befolyásolja, vizsgáld meg mindet.</p>
                        <p>Különösen a kezdő befektetők szoktak beleesni abba a hibába, hogy egy befektetés értékelésekor kizárólag 1-2 mutatót vesznek figyelembe, mert annyit ismernek, és így az alapján alkotnak ítéletet. A befektetés egy összetett folyamat, egy mutató csupán egy szempont lehet a több száz közül, amit figyelembe kell vennünk döntésünk meghozatalakor.</p>
                    </div>
                    <br />
                    <div className="egyszabaly">
                        <h1>16. szabály: Hozam-kockázat</h1>
                        <p>Minél nagyobb a potenciális hozam, annál nagyobb a kockázat.</p>
                        <p>És fordítva… Vagyis a hozam és a kockázat egyenesen arányosak egymással. Fontos, hogy ennek a figyelembevételével fektessünk be. Ez egy olyan szabály, amit előbb vagy utóbb minden befektető a saját bőrén tapasztal meg. Gondold át alaposan, hogy miként viszonyulsz a kockázathoz, majd idővel alakítsd ki a saját hozam-kockázat preferenciád.</p>
                    </div>
                    <br />
                    <div className="egyszabaly">
                        <h1>17. szabály: Állj ki a tömegből</h1>
                        <p>A tartós siker kulcsa az egyedi, független befektetési rendszer kialakítása.</p>
                        <p>Ami a legtöbb ember számára kihívást jelent, az a tömeg bizalmának és szélsőséges optimizmusának vagy pesszimizmusának a figyelmen kívül hagyása. Nem szabad foglalkoznunk azzal, hogy hányan szeretnének befektetni egy adott eszközbe, nekünk csak az számít, hogy az értéke alapján egy előnyös árszinten juthassunk hozzá. Fontos megjegyezni, hogy a tömeg a legtöbbször téved, nekünk pedig nem szabad a FOMO rabjává esnünk.</p>
                    </div>
                    <br />
                    <div className="egyszabaly lastszabaly"> {/* current last */}
                        <h1>18. szabály: Légy türelmes</h1>
                        <p>,,A piac rövid távon szavazógép, hosszú távon azonban precíz mérleg.” - Bejamin Graham</p>
                        <p>A túl- vagy alulértékelt befektetés meglepően hosszú ideig maradhat túl- vagy alulértékelt. Ha nem kezd el egy befektetés egyből hozamot generálni, akkor sincs semmi ok az aggodalomra. Az nem feltétlenül azért történik, mert elrontottunk valamit az elemzésünk során. Csak várd ki a végét.</p>
                    </div>

                    {/*  
                    <div className="miniad">
                        <div>
                            <h1>Egy jó szappanadagoló</h1>
                        </div>
                        <hr />
                        <div>
                            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tempore ipsum excepturi optio nulla assumenda dolor obcaecati est,
                                illum, eius voluptatem sequi omnis nisi. Quae, saepe excepturi tenetur blanditiis modi dignissimos, recusandae quo veritatis, ex rerum quas vero?</p>
                        </div>
                    </div>
                    */}

                </main>
            </div>
            <Footer />
        </>
    )
}

export default Szabalyok