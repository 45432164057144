import React from 'react'
import Road from '../roadmap/Road'
import { Helmet } from 'react-helmet-async'

function Roadmap() {
    return (
        <>
        <Helmet>
        <title>Roadmap | Befektetésközpont</title>
        <meta name="description" content="A roadmap egy olyan tanulási terv, amin végighaladva elsajátíthatod a befektetéshez szükséges alapvető ismereteket, így közelebb kerülve ahhoz, hogy te is befektetővé válhass." data-react-helmet="true"/>
        </Helmet>
            <Road />
        </>
    )
}

export default Roadmap