import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Greenchip() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Green chip - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a green chip jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Green chip</h1>
                            <p>Ezen az oldalon megismerheted a green chip jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">Green chipnek nevezzük a környezetbarát vállalatok részvényeit.</p>
                            <p className="majonator">Green chipnek számítanak az olyan vállalatok részvényei, melyeknek egyáltalán nincs, vagy nagyon minimális a környezetre gyakorolt negatív hatása. Illetve azok a vállalatok is ebbe a kategóriába tartoznak, melyeknek nemhogy nincs negatív hatásuk, de még jót is tesznek a környezetnek. Ilyenek például az újrahasznosítással vagy megújuló energiával foglalkozó vállalatok.</p>
                            <hr />
                            <h2>Jellemzői</h2>
                            <p className="majonator">A green chip <Link to='/fogalomtar/reszveny' className='dezord'>részvények</Link> mögött álló vállalatok célja az üzleti tevékenység környezetbarát módon való kivitelezése. A green chip az olyan befektetők körében a legnépszerűbb, akiknek a nyereségszerzés mellett elsődleges szempont, hogy mindezt egy zöld úton tegyék meg. Ezek a részvények részben a társadalmilag felelős befektetés (SRI) alkotóelemi. Az interneten számos forrást találunk arról, hogy különböző vállalatok mennyire környezetbarátok.</p>
                            <hr />
                            <h2>Ágazatok</h2>
                            <p className="majonator">Bármilyen vállalat környezetbaráttá vállhat, ha valamilyen úton képes fedezni a környezetszennyezését. Igen gyakorinak számítanak a green chip részvények az olyan területeken, mint a megújuló energia, az elektromos járművek, az újrahasznosítás, a talajmegújító mezőgazdaság vagy a karbonkereskedés. Fontos kiemelni, hogy kiderülhet egy vállalatról, hogy annak ellenére, hogy egy zöld szektorban helyezkedik el, a működési tevékenységeit mégsem végzi annyira környezetbarát módon.</p>
                            <hr />
                            <h2>Blue chip VS Green chip</h2>
                            <p className="majonator">A green chip elnevezés a blue chip-ből származik. A nevük hasonló, de a két fogalom más jelentést hordoz magával.</p>
                            <p className="majonator">Blue chipnek nevezzük a nagy piaci kapitalizációval és forgalommal rendelkező vállalatok részvényeit. A blue chip kifejezést a magas színvonalú, jól megalapozott és pénzügyileg stabil, hosszú múltra visszatekintő, megbízható teljesítményt nyújtó vállalatok részvényeinek leírására használják.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Greenchip