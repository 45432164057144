import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Jegybank() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Jegybank - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a jegybank jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Jegybank</h1>
                            <p>Ezen az oldalon megismerheted a jegybank jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A jegybank egy ország pénzügyi rendszerének központi bankja, amely a pénzkínálat szabályozásáért és a gazdasági stabilitás fenntartásáért felelős.</p>
                            <p className="majonator">A jegybank fő feladata a monetáris politika meghatározása és megvalósítása. Célja az árstabilitás fenntartása, ami az infláció egy előre meghatározott céltartományon belül tartását jelenti. A központi bank a bajba jutott pénzintézetek és kormányok végső hitelezője.</p>
                            <hr />
                            <h2>Működése</h2>
                            <p className="majonator">A jegybankok feladatai közé tartozik ellenőrizni a nemzeti pénzkínálatot, <Link to='/fogalomtar/valuta' className='dezord'>valutát</Link> kibocsátani és meghatározni az alapkamatot. A jegybankok általában az <Link to='/fogalomtar/inflacio' className='dezord'>infláció</Link> elkerülése vagy csökkentése érdekében emelik a jegybanki alapkamatot. A növekedés és a fogyasztói kiadások ösztönzése érdekében pedig csökkentik azokat. A cél általában a 2%-os infláció stabilizálása.</p>
                            <p className="majonator">A jegybankok szabályozzák, hogy a kereskedelmi bankok mennyit hitelezhetnek az ügyfeleiknek, és hogy mennyi készpénzt kell tartaniuk. A jegybankok maguk is nyújtanak <Link to='/fogalomtar/hitel' className='dezord'>hitelt</Link> és egyéb szolgáltatásokat, de nem magánszemélyeknek, hanem a kormánynak és a kereskedelmi bankoknak. Ezalól kivétel az egyszintű bankrendszer, ami esetében a jegybank egyben kereskedelmi bankként is funkcionál.</p>
                            <hr />
                            <h2>Jegybanki alapkamat</h2>
                            <p className="majonator">A jegybank a pénzügyi stabilitást a jegybanki alapkamat szabályozásával próbálja elérni.</p>
                            <p className="majonator">A jegybank az infláció kordában tartása érdekében alapkamatot emelhet, viszont ennek az ára a gazdaság lassulása, mivel a hitelek drágábbak lesznek és kevesebb pénz jut be a piacokra. Minél magasabb az alapkamat, annál drágább a kereskedelmi bankoknak hitelt felvenni. Így kevesebb hitelt vesznek fel, vagyis kevesebb pénz jut be a gazdaságba, ami dezinflációt eredményez. A vállalatok drágább hitelek mellett kisebb profitot érnek el. A lakosságnak pedig csökken a szabadon elkölthető jövedelme. A <Link to='/fogalomtar/deflacio' className='dezord'>defláció</Link> elkerülése érdekében az alapkamat csökkentésére van szükség.</p>
                            <hr />
                            <h2>Jegybankok</h2>
                            <p className="majonator">A legnépszerűbb közülük a Fed (Federal Reserve System) az Egyesült Államok központi bankja. Ez egyben a legbefolyásosabb központi bank is, ami az USA domináns gazdasági helyzetéből fakad. Az euró jegybankja az Európai Központi Bank (EKB), székhelye Frankfurt. Magyarország központi bankja a Magyar Nemzeti Bank (MNB).</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Jegybank