import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';


function Arutozsde() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Árutőzsde - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted az árutőzsde szó jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Árutőzsde</h1>
                            <p>Ezen az oldalon megismerheted az árutőzsde szó jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">Az árutőzsde egy olyan piac, ahol árupiaci termékekkel kereskednek.</p>
                            <p className="majonator">Az árutőzsdék a termelők, feldolgozók és végfelhasználók közötti kereskedelmet és az áruk árainak alakulását szabályozza. A kereskedők ritkán kötnek ügyletet fizikai árukkal az árutőzsdén keresztül, ehelyett határidős ügyletekkel kereskednek. A legnagyobb árutőzsdék a Chicago Mercantile Exchange (CME), New York Mercantile Exchange (NYMEX), Intercontinental Exchange (ICE) és London Metal Exchange (LME).</p>
                            <hr />
                            <h2>Árupiaci termékek</h2>
                            <p className="majonator">Árupiaci termékek közé tartoznak az agrártermékek, az energiahordozók, a fémek és egyéb nyersanyagok. Példák: Olaj, arany, ezüst, réz, búza, kukorica, kőolaj, földgáz, szén, kávé, cukor, rizs, pamut, zsiradékok, hús, tejtermékek, zöldségek, gyümölcsök, vas, alumínium, ólom, réz, sertés, marha, baromfi, gyapjú, bőr</p>
                            <hr />
                            <h2>Árutőzsdei árak</h2>
                            <p className="majonator">Növekvő gazdaság hatására egyre nagyobb a nyersanyagigény, ami növeli az árutőzsdei árakat. A gazdaság növekedésének számos oka van, mint például technológiai és társadalmi fejlődés, népességnövekedés vagy az <Link to='/fogalomtar/inflacio' className='dezord'>infláció</Link>. Bizonyos árupiaci termékeknél a kereslet a magasabb árak ellenére is megmaradhat, mert az embereknek szükségük van rájuk.</p>
                            <p className="majonator">Viszont számos tényező csökkenti az árutőzsdei árakat. Ilyen tényező lehet például az előállítási költségek csökkenése, kedvező éghajlati vagy környezeti változások vagy ha esetleg megjelentik a piacon egy helyettesítő termék.</p>
                            <hr />
                            <h2>Hogyan fektessünk be árutőzsdén?</h2>
                            <p className="majonator">Az árutőzsdén folyó kereskedelem esetén nem mindig konkrét árupiaci termékekkel, hanem határidős ügyleteken keresztül, az árváltozásukat tükröző pénzügyi termékekkel folyik a kereskedelem. Az árupiacokon csak ritkán kötnek spot ügyleteket. Többségében a kereskedés határidős ügyletek formájában történik, amik azért vannak, hogy a termelők fedezeti ügyleteket tudjanak kötni. Mivel az árupiaci termékeknek ugyanúgy van árfolyamuk, mint más befektetési eszköznek, ezért a termelők határidős ügyetekkel csökkentik az alapanyag árának változásából származó kockázatot. Lehetőség van árutőzsdén való befektetésre például a határidős piacon, árupiaci ETF-en keresztül, illetve árupiaci CFD-k segítségével.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Arutozsde