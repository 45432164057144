import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Szektor() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Szektor - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a szektor jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Szektor</h1>
                            <p>Ezen az oldalon megismerheted a szektor jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A szektor a gazdaság területének egy része, amelyen belül a vállalkozások azonos vagy kapcsolódó üzleti tevékenységet végeznek.</p>
                            <p className="majonator">Egy adott szektoron belül működő vállalatok hasonló termékeket és szolgáltatásokat nyújtanak. A gazdaság szektorokra való felosztásával átláthatóvá válik, hogy a gazdaság egyes területei hogyan változnak. Továbbá lehetővé teszi a portfólió szektorok szerinti diverzifikálását, ami fontos a befektetők számára.</p>
                            <hr />
                            <h2>Értelmezése</h2>
                            <p className="majonator">Mivel egy szektoron belül az összes vállalat hasonló tevékenységet folytat, ezért az adott szektor által befogott vállalatok pénzügyi eredményeinek összesítésével képet kapunk az adott ágazat teljesítményéről. Elkülönítünk gazdasági szektorokat és befektetési szektorokat. A gazdasági szektor 4 elemből áll: primer szektor (mezőgazdaság), szekunder szektor (ipar), tercier szektor (szolgáltatások), kvaterner szektor (kutatás és fejlesztés). Ami viszont a befektetőknek fontosabb, azok a befektetési szektorok, amiket azért hoztak létre, hogy a befektetők könnyebben összehasonlíthassák a hasonló üzleti tevékenységet folytató vállalatokat.</p>
                            <hr />
                            <h2>Befektetési szektorok fajtái</h2>
                            <p className="majonator azam">Technológia</p>
                            <p className="majonator">A technológiai szektor a gazdaság azon része, amely a technológiával kapcsolatos termékeket és szolgáltatásokat gyárt és szolgáltat. Ez magában foglalja az információs technológiát (IT), elektronikai termékeket, hardvert és szoftvert. A technológiai szektor a legnagyobb az összes közül. Olyan vállalatok tartoznak ide, mint az Apple, Microsoft, NVIDIA vagy TSM.</p>
                            <br />
                            <p className="majonator azam">Pénzügy</p>
                            <p className="majonator">A pénzügyi szektor olyan vállalatokat foglal magában, amely pénzügyi termékeket és szolgáltatásokat nyújt. Ez magában foglalja a bankokat, a biztosítókat, a befektetési vállalatokat, a pénzügyi tanácsadókat és a pénzügyi közvetítőket. Olyan vállalatok tartoznak ide, mint a Visa, Mastercard, JPMorgan vagy Berkshire Hathaway.</p>
                            <br />
                            <p className="majonator azam">Kommunikáció</p>
                            <p className="majonator">A kommunikációs szektor vállalatai információs és kommunikációs szolgáltatásokat nyújtanak. Ez magában foglalja a telekommunikációt és a médiaipart. Olyan vállalatok tartoznak ide, mint az Alphabet, Meta, Netflix vagy Disney.</p>
                            <br />
                            <p className="majonator azam">Egészségügy</p>
                            <p className="majonator">Az egészségügyi szektor vállalatai a betegségeket, valamint azok megelőzését, diagnosztizálását, kezelését és gyógyítását vizsgálja, illetve nyújt szolgáltatásokat és termékeket hozzá. Ez magában foglalja a gyógyszergyártókat, a biotechnológiát, az egészségügyi szolgáltatókat és a felszerelések gyártóit. Olyan vállalatok tartoznak ide, mint a Johnson & Johnson, UnitedHealth, Eli Lilly vagy Pfizer.</p>
                            <br />
                            <p className="majonator azam">Fogyasztás (Cyclical)</p>
                            <p className="majonator">Ennek a szektornak a vállalatai fogyasztói cikkek forgalmazásával foglalkoznak. Ide tartoznak a járműgyártók, webshopok, bútorzati és kertészeti vállalatok, ruhagyártók stb. Olyan vállalatok tartoznak ide, mint az Amazon, Tesla, McDonald's vagy Adidas.</p>
                            <br />
                            <p className="majonator azam">Fogyasztás (Defensive)</p>
                            <p className="majonator">Ez annyiban különbözik a cyclical kategóriától, hogy kevésbé korrelál a piaci volatilitással. Ezeknek a vállalatoknak nem növekedési részvényeik vannak. Általában lassan, de stabilan növekvő vállalatok. Olyan vállalatok tartoznak ide, mint a Procter & Gamble, Walmart, Coca Cola vagy Mondelez.</p>
                            <br />
                            <p className="majonator azam">Ipar</p>
                            <p className="majonator">Az ipari szektorba tartoznak a mezőgazdasági és építkezési gépek gyártói, egyéb ipari gépek gyártói, nemzetközi logisztikai cégek, különböző alkatrészek gyártói stb. Sok vállalat ebből a kategóriából más vállalatokat szolgál ki. Olyan vállalatok tartoznak ide, mint a Caterpillar, UPS, General Electric vagy Boeing.</p>
                            <br />
                            <p className="majonator azam">Ingatlan</p>
                            <p className="majonator">Az ingatlanszektor vállalatai az ingatlanok vásárlásával, eladásával, bérbeadásával és fejlesztésével foglalkoznak. Ez magában foglalja a lakóingatlanokat, az ipari ingatlanokat, a kereskedelmi ingatlanokat és az ingatlanfejlesztést. Olyan vállalatok tartoznak ide, mint a Prologis, American Tower, Equinix vagy Public Storage.</p>
                            <br />
                            <p className="majonator azam">Közmű</p>
                            <p className="majonator">Magában foglalja azokat a vállalatokat, melyek víz, áram és gáz termelésével és/vagy szolgáltatásával foglalkoznak. Olyan vállalatok tartoznak ide, mint a NextEra, Atmos Energy, American Water Works vagy Duke Energy.</p>
                            <br />
                            <p className="majonator azam">Energia</p>
                            <p className="majonator">Ez a szektor nem megújuló energiaforrásokkal foglalkozó vállalatok foglal magában. Leginkább olaj- és gázipari vállalatok találhatóak itt, de vannak, amik például urániummal foglalkoznak. Bányászat, felderítés, kinyerés, szállítás, előállítás, szolgáltatás mind beletartozhat a foglakozási köreikbe. Olyan vállalatok tartoznak ide, mint az Exxon Mobil, Chevron, Shell vagy BP.</p>
                            <br />
                            <p className="majonator azam">Alapanyagok</p>
                            <p className="majonator">E szektor vállalatai alapanyagokat bányásznak, dolgoznak fel és értékesítenek. A szektorban leginkább különböző fémek előállítása (arany, ezüst, réz) a legelterjedtebb, de találunk olyan vállalatokat is, amik fával, acéllal vagy bármilyen ipari alapanyaggal foglalkoznak. A kémiai anyagok és a vetőmagok is ide tartoznak. Olyan vállalatok tartoznak ide, mint a Linde, BHP, Corteva vagy Nucor.</p>
                            <hr />
                            <h2>Befektetés és szektorok</h2>
                            <p className="majonator">A kockázat csökkentésére kiváló módszer a befektetési szektorok szerinti <Link to='/fogalomtar/diverzifikacio' className='dezord'>diverzifikáció</Link>. A befektetési alapok gyakran specializálódnak egy adott befektetési szektorra. Amennyiben valaki egy adott szektorba szeretne befektetni, akkor a befektetési alapok mellett lehetősége van a saját <Link to='/fogalomtar/portfolio' className='dezord'>portfólióját</Link> aszerint kialakítani, vagy választhatja a legnépszerűbb opciót, egy adott szektort követő ETF-be való befektetést.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Szektor