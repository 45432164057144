import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Capmmodell() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>CAPM modell - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a CAPM modell jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>CAPM modell</h1>
                            <p>Ezen az oldalon megismerheted a CAPM modell jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A CAPM modell egy befektetések hozamának kiszámítására kitalált módszer.</p>
                            <p className="majonator">Egy eszköz hozama egyenlő a kockázatmentes hozam plusz a piac kockázati prémiuma szorozva a béta mutatóval. A piac kockázati prémiumát úgy kapjuk meg, ha a várható piaci hozamból kivonjuk a kockázatmentes hozamot. Béta mutató alatt az adott portfólió vagy befektetési eszköz bétáját értjük.</p>
                            <hr />
                            <h2>Használata</h2>
                            <p className="majonator">A CAPM modellt a hozam mértékének előrejelzésére és ezáltal a befektetési eszközök fair árának meghatározására használják. A CAPM célja részben annak értékelése, hogy egy eszköz mennyire alul vagy felülértékelt a hozam-kockázat páros szempontjából. A CAPM segítségével felmérhető, hogy egy eszköz jelenlegi ára összhangban van-e a valószínű hozamával.</p>
                            <hr />
                            <h2>A képlet</h2>
                            <p className="majonator">ER<sub>i</sub>&nbsp;=&nbsp;R<sub>f</sub>&nbsp;+&nbsp;β<sub>i</sub> <span>&#215;</span>&nbsp;(&nbsp;ER<sub>m&nbsp;</sub>-&nbsp;R<sub>f</sub>&nbsp;)</p>
                            <br />
                            <p className="majonatora">ER<sub>i</sub> &nbsp;Befektetés hozama</p>
                            <p className="majonatora">R<sub>f</sub> &nbsp;Kockázatmentes hozam</p>
                            <p className="majonatora">β<sub>i</sub> &nbsp;Eszköz béta mutatója</p>
                            <p className="majonatora">(&nbsp;ER<sub>m&nbsp;</sub>-&nbsp;R<sub>f</sub>&nbsp;) &nbsp;Piac kockázati prémiuma</p>
                            <p className="majonatora">ER<sub>m</sub> &nbsp;Várható piaci hozam</p>
                            <br />
                            <p className="majonator">Példa: feltételezzük azt, hogy a kockázatmentes hozam 2%, amit osztalék formájában értünk el. A termék béta mutatója 1,2 vagyis az eszköz <Link to='/fogalomtar/volatilitas' className='dezord'>volatilitása</Link> nagyobb a piacénál. A várakozásaink alapján a befektetésen elért hozam 10% lesz. Ezeknek az információknak a birtokában már ki is számíthatjuk a CAPM értékünket. 2% + 1,2 <span>&#215;</span> ( 10% - 2% ) = 11,6% </p>
                            <hr />
                            <h2>Módosítások</h2>
                            <p className="majonator">Mivel az eredeti CAPM modell a gyakorlatban nem állta meg a helyét, ezért az évek során több módosításon is átesett.</p>
                            <br />
                            <p className="majonator">Először kiegészítették az SMB és HML faktorokkal. Az SMB (Small Minus Big) a kisebb <Link to='/fogalomtar/piaci-kapitalizacio' className='dezord'>piaci kapitalizációjú</Link> vállalatok többlethozama a nagyobb vállalatokhoz képest. A HML (High Minus Low) a magas <Link to='/fogalomtar/konyv-szerinti-ertek' className='dezord'>könyv szerinti értékkel</Link> rendelkező vállalatok és az alacsony könyv szerinti értékkel bíró vállalatok közötti hozamkülönbséget jelenti.</p>
                            <p className="majonator">ER<sub>i</sub>&nbsp;=&nbsp;R<sub>f</sub>&nbsp;+&nbsp;β<sub>i</sub>&nbsp;(&nbsp;ER<sub>m&nbsp;</sub>-&nbsp;R<sub>f</sub>&nbsp;) + SMB + HML</p>
                            <br />
                            <p className="majonator">Majd később megint változtattak és hozzáadták a CMA és az RMW faktort is. A CMA (Conservative Minus Aggressive) a konzervatív és az agresszív befektetési politikájú vállalatok hozamai közötti különbség. Az RMW (Robust Minus Weak) az erős és gyenge működési jövedelmezőséggel rendelkező vállalatok hozamai közötti különbség.</p>
                            <p className="majonator">ER<sub>i</sub>&nbsp;=&nbsp;R<sub>f</sub>&nbsp;+&nbsp;β<sub>i</sub>&nbsp;(&nbsp;ER<sub>m&nbsp;</sub>-&nbsp;R<sub>f</sub>&nbsp;) + SMB + HML + CMA + RMW</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Capmmodell