import React from 'react'
import Footer from '../footer/footer';
import './footerpages.css'
import { Helmet } from 'react-helmet-async';

function Impresszum() {
    return (
        <>
            <Helmet>
                <title>Impresszum | Befektetésközpont</title>
            </Helmet>
            <div className='szovegwrappener'>
                <div className='cimwrappener'>
                    <h1>Impresszum</h1>
                </div>
                <div className='contentumwrap'>
                    <p>A Befektetésközpont egy befektetést tanító platform és közösség. Az oldal célja, hogy bevezesse az embereket a befektetésbe, és hogy egy közösséget teremtsen nekik, ahol megoszthatják egymással a tudásukat és közösen tanulhatnak tovább.</p>
                    <br />
                    <h2>Szolgáltató adatai</h2>
                    <p>Név: László Miklós</p>
                    <p>Cím: 1048 Budapest, Ügető utca 8</p>
                    <p>E-mail: befekteteskozpont@gmail.com</p>
                    <p>Telefon: +36303355394</p>
                    <p>Adószám: 8401961033</p>
                    <br />
                    <h2>Tárhelyszolgáltató adatai</h2>
                    <p>Név: Rackhost Zrt.</p>
                    <p>Cím: 6722 Szeged, Tisza Lajos körút 41.</p>
                    <p>Telefon: +36 1 445 1200 &nbsp; | &nbsp; Fax: +36 1 445 1201</p>
                    <p>Honlap:	www.rackhost.hu</p>
                    <p>E-mail: info@rackhost.hu</p>
                    <p>Adószám:	25333572-2-06</p>
                    <p>Cégjegyzékszám:	06-10-000489</p>
                    <p>Megismert adatok köre: befekteteskozpont.hu domain és aldomain-eken található weboldalak tartalma.</p>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Impresszum