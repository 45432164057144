import React from 'react'
import './fogmain.css'
import { Link } from 'react-router-dom'

function Fogfooter() {
    return (
        <footer className='footer-ab'>
            <div className="container-ab">
                <div className="row-ab">
                    <div className="footer-col-ab">
                        <h4>befektetésközpont</h4>
                        <ul>
                            <li><Link className='href-ab' to='/impresszum'>Impresszum</Link></li>
                            <li><Link className='href-ab' to='/adatkezelesi-tajekoztato'>Adatkezelési tájékoztató</Link></li>
                        </ul>
                    </div>
                    <div className="footer-col-ab">
                        <h4>segítség</h4>
                        <ul>
                            <li><Link className="href-ab" to='/gyik'>GYIK</Link></li>
                            <li><Link className="href-ab" to='/jogi-nyilatkozat'>Jogi nyilatkozat</Link></li>
                        </ul>
                    </div>
                    <div className="footer-col-ab">
                        <h4>Kapcsolat</h4>
                        <ul>
                            <li><Link className="href-ab" to='https://discord.gg/7YyQckXAxY'>Discord közösség</Link></li>
                            <li><Link className="href-ab" to='/kapcsolat'>Kapcsolatfelvétel</Link></li>
                        </ul>
                    </div>
                    <div className="footer-col-ab">
                        <h4>Kövess minket!</h4>
                        <div class="social-links-ab">
                            <div>
                                <Link className="href-ab" to='https://www.instagram.com/befekteteskozpont/' aria-label="instagram"><i class="fab fa-instagram"></i></Link>
                                <Link className="href-ab" to='https://www.facebook.com/befekteteskozpont/' aria-label="facebook"><i class="fab fa-facebook-f"></i></Link>
                            </div>
                            <div>
                                <Link className="href-ab" to='https://twitter.com/befkozpont' aria-label="twitter"><i class="fab fa-x-twitter"></i></Link>
                                <Link className="href-ab" to='https://discord.gg/7YyQckXAxY' aria-label="discord"><i class="fab fa-discord"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Fogfooter
