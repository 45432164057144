import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Hedgefund() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Hedge fund - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a hedge fund jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Hedge fund</h1>
                            <p>Ezen az oldalon megismerheted a hedge fund jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A hedge fund olyan befektetési alap, amely az abszolút hozam elérésére törekszik.</p>
                            <p className="majonator">Az hedge fund-okhoz csak akkreditált személyek férnek hozzá. A hedge fundok célja, hogy abszolút hozamot biztosítsanak a befektetőknek. Ez ellentétben áll a hagyományos befektetési alapokkal, amelyek célja az, hogy egy adott referenciaindexet múljanak felül. A befektetési alapok olyan befektetési eszközök, amelyeket azzal a céllal hoztak létre, hogy összegyűjtsék a befektetők tőkéjét, és ezt a tőkét közösen fektessék be pénzügyi eszközök portfólióján keresztül.</p>
                            <hr />
                            <h2>Működése</h2>
                            <p className="majonator">A hedge fundok magasabb kockázatvállalással próbálnak nagyobb profitot elérni. Nem kell annyira szigorú szabályokat betartaniuk, mint a hagyományos <Link to='/fogalomtar/befektetesi-alap' className='dezord'>befektetési alapoknak</Link>. A hedge fundok szinte bármilyen pénzügyi eszközt vásárolhatnak, akár tőkeáttételes short pozíciókat is felvehetnek vagy korlátlan arányban adhatnak és vehetnek instrumentumokat.</p>
                            <p className="majonator">Ha egy hagyományos befektetési alap <Link to='/fogalomtar/benchmark' className='dezord'>referencia indexe</Link> negatív eredményt ér el, akkor az alapkezelők már sikeresnek számítanak, ha ennél az indexnél kisebb csökkenést érnek el. Vagyis az is jó eredménynek számít, ha az alapba fektetők pénze fogy, de kevésbé, mintha a referencia indexben lenne fektetve. Ezzel ellentétben a hedge fundoktól elvárják a befektetők, hogy a piac esése esetén is nyereségesek legyenek.</p>
                            <p className="majonator">Elmondható, hogy általában a minimum befektetendő összeg egy hedge fund-ba 100.000 dollár. Ritkán látni olyat, hogy 500 befektetőnél több van egy adott hedge fund-ban.</p>
                            <hr />
                            <h2>Díjak</h2>
                            <p className="majonator">A hedge fund-ba fektetőknek alapkezelési és sikerdíjat kell fizetniük az alapkezelőknek. Az alapkezelési díj 3%, míg a sikerdíj 20% körül szokott lenni.</p>
                            <p className="majonator">A hedge fundok általában nem a magas hozam elérésének köszönhetően gazdagodnak meg, hanem a díjak miatt. Hedge fundokban egyáltalán nem nehéz milliós nagyságrendű befektetésekkel találkozni. És ha sokan ekkora, vagy ennél nagyobb összeget szánnak befektetése, akkor az alapkezelőknek nem nehéz megszedniük magukat pénzzel. Nem számít, hogy a hedge fund hogyan teljesít, a díjakat így is, úgy is kifizettetik a befektetővel, aki így már a legtöbb kockázatot maga viseli.</p>
                            <hr />
                            <h2>Hedge fund kisbefektetőknek</h2>
                            <p className="majonator">A magas belépési határ miatt a kisbefektetőknek nincsen lehetőségük közvetlenül hedge fundokba fektetni. Viszont különböző ETF-ek segítségével lehetőség adódik olyan indexeket követni, amik hedge fundok által vásárolt eszközök alapján súlyozzák a portfóliójukat.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Hedgefund