import React, { useState, useEffect, useRef } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Gap() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    let tvScriptLoadingPromise;
    const onLoadScriptRef = useRef();
    useEffect(
        () => {
            onLoadScriptRef.current = createWidget;

            if (!tvScriptLoadingPromise) {
                tvScriptLoadingPromise = new Promise((resolve) => {
                    const script = document.createElement('script');
                    script.id = 'tradingview-widget-loading-script';
                    script.src = 'https://s3.tradingview.com/tv.js';
                    script.type = 'text/javascript';
                    script.onload = resolve;

                    document.head.appendChild(script);
                });
            }

            tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

            return () => onLoadScriptRef.current = null;

            function createWidget() {
                if (document.getElementById('tradingview_2fa3b') && 'TradingView' in window) {
                    new window.TradingView.widget({
                        width: 'auto',
                        height: 400,
                        symbol: "NYSE:TSM",
                        interval: "1",
                        timezone: "Etc/UTC",
                        theme: "dark",
                        style: "1",
                        locale: "en",
                        enable_publishing: false,
                        allow_symbol_change: true,
                        container_id: "tradingview_2fa3b"
                    });
                }
            }
        },
        []
    );

    return (
        <>
            <Helmet>
                <title>Gap - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a gap jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Gap</h1>
                            <p>Ezen az oldalon megismerheted a gap jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A gap egy eszköz árfolyamában fellépő rés, ami nagy árfolyammozgás vagy a nyitóár és az előző záróár közötti jelentős eltérés hatására lép fel.</p>
                            <p className="majonator">A gap az árfolyam grafikonon egy üres résként jelenik meg, mint ahogy arra neve is utal. A gapek a japángyertyás grafikonokon jelennek meg. A vonalgrafikonok esetében rés keletkezése helyett a vonal összeköti a nyitó és záróárakat. A gapeket technikai elemzésben is használják a kereskedők.</p>
                            <hr />
                            <div className='tradingview-widget-container'>
                                <div id='tradingview_2fa3b' />
                                <div className="tradingview-widget-copyright">
                                    <a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank"><span className="blue-text">Track all markets on TradingView</span></a>
                                </div>
                            </div>
                            <hr />
                            <h2>Gap okai</h2>
                            <p className="majonator">A gap egyik oka a jelentős árfolyammozgás. Minél kisebb időtávon tekintjük a gyertyákat, annál több gappel találkozhatunk, aminek az az oka, hogy rövidebb időtávra könnyebben jut több hirtelen tranzakció. Például két 1 órás gyertya esetén kevés az esély arra, hogy pont akkor történjen nagy árfolyammozgás, amikor új gyertya keletkezik.</p>
                            <p className="majonator">A gap másik oka lehet az az eset, amikor nincs lehetőség kereskedni egy eszközzel. A legtöbb <Link to='/fogalomtar/tozsde' className='dezord'>tőzsdén</Link> nincsen lehetőség hétvégén kereskedni. Ilyenkor nem jegyzik az eszköz árfolyamát, viszont események ugyanúgy történnek az eszköz és a gazdaság életében, így az ár és az érték változik, csak nem az árfolyamon. Amikor újra mozgásnak indul az árfolyam, akkor ezt az eltérést látjuk gap formájában. Ilyen időbeli eltérés lehet tőzsdei ebédszünet, ünnepnapok, napi zárás vagy hétvége.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Gap