import React from 'react'
import Footer from '../footer/footer';
import './footerpages.css'
import { Helmet } from 'react-helmet-async';

function Adatkezelesi() {
  return (
    <>
      <Helmet>
        <title>Adatkezelési tájékoztató | Befektetésközpont</title>
      </Helmet>
      <div className='szovegwrappener'>
        <div className='cimwrappener'>
          <h1>Adatkezelési tájékoztató</h1>
        </div>
        <div className='contentumwrap'>
          <h2>1. Az adatkezelési tájékoztató célja</h2>
          <p>László Miklós mint adatkezelő, magára nézve kötelezőnek ismeri el jelen jogi közlemény tartalmát.</p>
          <p>Kötelezettséget vállal arra, hogy tevékenységével kapcsolatos minden adatkezelés megfelel a jelen szabályzatban és a hatályos nemzeti jogszabályokban, valamint az Európai Unió jogi aktusaiban meghatározott elvárásoknak.</p>
          <p>Jelen adatkezelési tájékoztató az alábbi domain-re és azok aldomain-jeire terjed ki: befekteteskozpont.hu</p>
          <p>Adatkezelő adatkezeléseivel kapcsolatosan felmerülő adatvédelmi irányelvek folyamatosan elérhetők a https://befekteteskozpont.hu/adatkezelesi-tajekoztato címen.</p>
          <p>Adatkezelő fenntartja magának a jogot jelen tájékoztató bármikori módosítására. A változásokról az érintettek kellő időben tájékoztatásra kerülnek.</p>
          <p>Amennyiben kérdése lenne jelen közleményhez kapcsolódóan, kérjük, írja meg és megválaszoljuk kérdését.</p>
          <p>Adatkezelő elkötelezett ügyfelei és partnerei személyes adatainak védelmében, kiemelten fontosnak tartja ügyfelei információs önrendelkezési jogának tiszteletben tartását. Adatkezelő a személyes adatokat bizalmasan kezeli, és megtesz minden olyan biztonsági, technikai és szervezési intézkedést, mely az adatok biztonságát garantálja.</p>
          <p>Adatkezelő az alábbiakban ismerteti adatkezelési gyakorlatát.</p>
          <br />
          <h2>2. Az adatkezelő adatai</h2>
          <p>Amennyiben megkeresné a magánszemélyt, az befekteteskozpont@gmail.com email címen és a +36303355394 telefonszám elérhetőségeken léphet kapcsolatba az adatkezelővel.</p>
          <p>Név: László Miklós</p>
          <p>Cím: 1048 Budapest, Ügető utca 8</p>
          <p>E-mail: befekteteskozpont@gmail.com</p>
          <p>Telefon: +36303355394</p>
          <p>Adószám: 8401961033</p>
          <h2>2.1 Adatvédelmi tisztviselő</h2>
          <p>Adatkezelő nem végez olyan tevékenységet, mely indokolttá tenné adatvédelmi tisztviselő kijelölését.</p>
          <br />
          <h2>3. A kezelt személyes adatok köre</h2>
          <h2>3.1 Technikai adatok</h2>
          <p>Adatkezelő a személyes adatok kezeléséhez a szolgáltatás nyújtása során alkalmazott informatikai eszközöket úgy választja meg és üzemelteti, hogy a kezelt adat: - az arra feljogosítottak számára hozzáférhető (rendelkezésre állás); - hitelessége és hitelesítése biztosított (adatkezelés hitelessége); - változatlansága igazolható (adatintegritás); - a jogosulatlan hozzáférés ellen védett (adat bizalmassága) legyen.</p>
          <p>Adatkezelő az adatokat megfelelő intézkedésekkel védi a jogosulatlan hozzáférés, megváltoztatás, továbbítás, nyilvánosságra hozatal, törlés vagy megsemmisítés, valamint a véletlen megsemmisülés ellen.</p>
          <p>Adatkezelő olyan műszaki, szervezési és szervezeti intézkedésekkel gondoskodik az adatkezelés biztonságának védelméről, amely az adatkezeléssel kapcsolatban jelentkező kockázatoknak megfelelő védelmi szintet nyújt.</p>
          <p>Adatkezelő az adatkezelés során megőrzi a titkosságot: megvédi az információt,
            hogy csak az férhessen hozzá, aki erre jogosult; a sértetlenséget: megvédi az
            információnak és a feldolgozás módszerének a pontosságát és teljességét; a
            rendelkezésre állást: gondoskodik arról, hogy amikor a jogosult használónak
            szüksége van rá, valóban hozzá tudjon férni a kívánt információhoz, és
            rendelkezésre álljanak az ezzel kapcsolatos eszközök.</p>
          <h2>3.2 Cookie-k (Sütik)</h2>
          <h2>3.2.1 A sütik feladata</h2>
          <p>A sütik információkat gyűjtenek a látogatókról és eszközeikről; megjegyzik a látogatók egyéni beállításait, amelyek felhasználásra kerül(het)nek pl. online tranzakciók igénybevételekor, így nem kell újra begépelni őket; megkönnyítik a weboldal használatát; minőségi felhasználói élményt biztosítanak, továbbá részt vesznek néhány látogatói statisztikai információ gyűjtésében.</p>
          <p>A testre szabott kiszolgálás érdekében a felhasználó számítógépén kis adatcsomagot, ún. sütit (cookie) helyez el és a későbbi látogatás során olvas vissza.
            Ha a böngésző visszaküld egy korábban elmentett sütit, a sütit kezelő szolgáltatónak
            lehetősége van összekapcsolni a felhasználó aktuális látogatását a korábbiakkal, de
            kizárólag a saját tartalma tekintetében.</p>
          <p>A sütik egy része nem tartalmaz az egyéni felhasználó azonosításra alkalmas, személyes információkat, egy részük tartalmaz egy titkos, véletlenszerűen generált számsort, melyet a felhasználó eszköze eltárol és a felhasználó azonosíthatóságát biztosítja.</p>
          <h2>3.2.2 Feltétlenül szükséges, munkamenet (session) cookie-k</h2>
          <p>Ezen sütik célja, hogy a látogatók maradéktalanul és zökkenőmentesen böngészhessék a befekteteskozpont.hu weboldalát, használhassák annak funkcióit, és az ott elérhető szolgáltatásokat. Az ilyen típusú sütik érvényességi ideje a munkamenet (böngészés) befejezéséig tart, a böngésző bezárásával a sütik e fajtája automatikusan törlődik a számítógépről, illetve a böngészésre használt más eszközről.</p>
          <h2>3.2.3 Harmadik fél által elhelyezett cookie-k (Analitika)</h2>
          <p>A befekteteskozpont.hu és aldomain-jei alkalmazza a Google Analytics mint harmadik fél sütijeit is. A Google Analytics statisztikai célú szolgáltatás használatával a befekteteskozpont.hu és aldomain-jei információkat gyűjtenek azzal kapcsolatban, hogy a látogatók hogyan használják a weboldalt. Az adatot a honlap fejlesztésének és a felhasználói élmény javításának céljával használja fel. Ezen sütik szintén lejáratukig a látogató számítógépén vagy böngészésre használt más eszközén, annak böngészőjében maradnak, illetve amíg a látogató nem törli őket.</p>
          <h2>3.2.4 A cookie kezelés jogalapja</h2>
          <p>A cookie kezelés jogalapja a weboldal látogatójának hozzájárulása, a vonatkozó Rendelet 6. cikk (1) bekezdés a) pontja alapján.</p>
          <p>Amennyiben nem fogadja el a cookie-k használatát, akkor a 3.2.3. pontban felsorolt weboldalak bizonyos funkciói nem lesznek elérhetőek a weboldalak használata során, illetve előfordulhat, hogy bizonyos funkciók hibásan működnek.</p>
          <p>A cookie-k törléséről bővebb információt a gyakoribb böngészők tekintetében az alábbi linkeken találhat: Firefox: https://support.mozilla.org/hu/kb/weboldalak-altal-elhelyezett-sutiktorlese-szamito Chrome: https://support.google.com/accounts/answer/32050?co=GENIE.Platform%3DDesktop&hl=en Safari: https://support.apple.com/en-gb/guide/safari/manage-cookies-and-websitedatasfri11471/mac</p>
          <h2>3.2.5 Az adatkezelő weboldalain szereplő cookie-k listája</h2>
          <p>A süti, a kiszolgáló által meghatározott adatokat hordozó információcsomag, amit a szerver küld a böngészőnek, majd a böngésző a szervernek minden kérés alkalmával. A böngészők általában egyszerű szöveges fájl(ok)ban tárolják a sütiket (sütifájlok), így egyes sütik a böngésző kikapcsolása és újraindítása után is elérhetőek lesznek.</p>
          <p>Ezen sütik célja, hogy a látogatók maradéktalanul és zökkenőmentesen böngészhessék a befekteteskozpont.hu weboldalát, használhassák annak funkcióit, és az ott elérhető szolgáltatásokat. Az ilyen típusú sütik érvényességi ideje a munkamenet (böngészés) befejezéséig tart, a böngésző bezárásával a sütik e fajtája automatikusan törlődik a számítógépről, illetve a böngészésre használt más eszközről.</p>
          <p>A befekteteskozpont.hu és aldomain-jeik weboldalain alkalmazza a Google Analytics mint harmadik fél sütijeit is. A Google Analytics statisztikai célú szolgáltatás információt gyűjt azzal kapcsolatban, hogy a látogatók hogyan használják a weboldalt. Az adatot a honlap fejlesztésének és a felhasználói élmény javításának céljával használja fel. Ezen sütik szintén lejáratukig a látogató számítógépén vagy böngészésre használt más eszközén, annak böngészőjében maradnak, illetve amíg a látogató nem törli őket.</p>
          <br />
          <h2>4. Általános adatkezelési irányelvek, adatkezelés neve, felhasználása, jogalap és megőrzési idő</h2>
          <p>Adatkezelő tevékenységének adatkezelései önkéntes hozzájáruláson, illetve törvényi felhatalmazáson alapulnak. Az önkéntes hozzájáruláson alapuló adatkezelések esetében az érintettek e hozzájárulásukat az adatkezelés bármely szakában visszavonhatják.</p>
          <p>Bizonyos esetekben a megadott adatok egy körének kezelését, tárolását, továbbítását jogszabályok teszik kötelezővé, melyről külön értesítjük ügyfeleinket. Felhívjuk Adatkezelő részére adatközlők figyelmét, hogy amennyiben nem saját személyes adataikat adják meg, az adatközlő kötelessége az érintett hozzájárulásának beszerzése. Adatkezelési alapelvei összhangban vannak az adatvédelemmel kapcsolatos hatályos jogszabályokkal, így különösen az alábbiakkal: 2011. évi CXII. törvény - az információs önrendelkezési jogról és az információ-szabadságról (Infotv.);</p>
          <p>Az Európai Parlament és a Tanács (EU) 2016/679 rendelete (2016. április 27.) – a
            természetes személyeknek a személyes adatok kezelése tekintetében történő
            védelméről és az ilyen adatok szabad áramlásáról, valamint a 95/46/EK rendelet
            hatályon kívül helyezéséről (általános adatvédelmi rendelet, GDPR); 2013. évi V.
            törvény – a Polgári Törvénykönyvről (Ptk.); 2000. évi C. törvény – a számvitelről
            (Számv. tv.); 2017. évi LIII. törvény – a pénzmosás és terrorizmus finanszírozása
            megelőzéséről és megakadályozásáról (Pmt.); 2013. évi CCXXXVII. törvény – a
            hitelintézetekről és a pénzügyi vállalkozásokról (Hpt.). </p>
          <p>Az adatkezelő adattérképek készített, ennek alapján meghatározásra került a kezelt adatok köre, azok felhasználása, jogalapja és megőrzési ideje.</p>
          <h2>4.1 Kapcsolatfelvétel során megadandó személyes adatok</h2>
          <p>A weboldal kapcsolat moduljában e-mail cím és üzenet megadására van lehetőség, mely lehetővé teszi a szolgáltatás igénybevételét. A kapcsolatfelvétel során kezelt személyes adatok:</p>
          <p>E-mail cím (kötelező mező)</p>
          <p>Üzenet (kötelező mező)</p>
          <p>Az adatkezelés célja, a kezelt adatok tervezett felhasználása: a weboldalon a megadott adatok alapján történő kapcsolatfelvétel, válaszadás.</p>
          <p>Az adatkezelés jogalapja önkéntes hozzájárulás. A szolgáltatás igénybevételére az adatkezelési szabályzat megismerését és elfogadását követően van lehetőség. Az adatkezelési szabályzat elfogadása előre ki nem töltött, kötelező jelölőnégyzet elfogadásával történik.</p>
          <p>Megőrzési idő: 1 év, vagy törlési kérelem.</p>
          <br />
          <h2>5. Az adatok fizikai tárolási helyei</h2>
          <p>Személyes adatai (vagyis azok az adatok, amelyek az Ön személyével kapcsolatba hozhatók) a következő módon kerülhetnek a kezelésünkbe:</p>
          <p>-Egyfelől az internetes kapcsolat fenntartásával összefüggésben az Ön által használt számítógéppel, böngészőprogrammal, internetes címmel, a látogatott oldalakkal kapcsolatos technikai adatok automatikusan képződnek számítógépes rendszerünkben, - másfelől Ön is megadhatja nevét, elérhetőségét vagy más adatait, ha a honlap használata során személyes kapcsolatba kíván lépni velünk. A rendszer működtetése során technikailag rögzítésre kerülő adatok: az érintett bejelentkező számítógépének azon adatai, melyeket a befekteteskozpont.hu domain és aldomain-jeik rendszerei a technikai folyamatok automatikus eredményeként rögzít.</p>
          <p>Az automatikusan rögzítésre kerülő adatot a rendszer az érintett külön nyilatkozata vagy cselekménye nélkül a belépéskor, illetve kilépéskor automatikusan naplózza.</p>
          <p>Ezen adat egyéb személyes felhasználói adatokkal – törvény által kötelezővé tett esetek kivételével – össze nem kapcsolható. Az adathoz kizárólag a befekteteskozpont.hu domain és aldomain-jeik férnek hozzá.</p>
          <br />
          <h2>6. Adattovábbítás, adatfeldolgozás, az adatokat megismerők köre</h2>
          <p>Adatkezelő tevékenysége keretében az alábbi adatfeldolgozókat veszi igénybe:</p>
          <p><b>Tárhelyszolgáltatás:</b></p>
          <p>Név: Rackhost Zrt.</p>
          <p>Cím: 6722 Szeged, Tisza Lajos körút 41.</p>
          <p>Telefon: +36 1 445 1200 &nbsp; | &nbsp; Fax: +36 1 445 1201</p>
          <p>Honlap:	www.rackhost.hu</p>
          <p>E-mail: info@rackhost.hu</p>
          <p>Adószám:	25333572-2-06</p>
          <p>Cégjegyzékszám:	06-10-000489</p>
          <p>Megismert adatok köre: befekteteskozpont.hu domain és aldomain-eken található weboldalak tartalma.</p>
          <p><b>Google Analytics:</b></p>
          <p>Google Inc., Mountain View, California, USA</p>
          <p>Megismert adatok köre: a befekteteskozpont.hu weboldal látogatóinak – anonimizált, személyhez nem köthető – IP címe.</p>
          <h2>6.1 Adattovábbítás harmadik országba</h2>
          <p>Adattovábbítás az Amerikai Egyesült Államokba történik, mellyel megfelelőségi határozat született 2016. július 12-én</p>
          <p>https://ec.europa.eu/info/law/law-topic/dataprotection/data-transfers-outside-eu/euus-privacyshield_en</p>
          <p>A megfelelőségi határozat a Google adatkezelők esetében is érvényes.</p>
          <p>https://policies.google.com/privacy/frameworks</p>
          <br />
          <h2>7. Érintett jogai és jogérvényesítési lehetőségei</h2>
          <p>Az érintett tájékoztatást kérhet személyes adatai kezeléséről, valamint kérheti személyes adatainak helyesbítését, illetve – a kötelező adatkezelések kivételével – törlését, visszavonását, élhet adathordozási-, és tiltakozási jogával az adat felvételénél jelzett módon, illetve az adatkezelő fenti elérhetőségein.</p>
          <h2>7.1 Tájékoztatáshoz való jog</h2>
          <p>Adatkezelő megfelelő intézkedéseket hoz annak érdekében, hogy az érintettek részére a személyes adatok kezelésére vonatkozó, a GDPR 13. és a 14. cikkben említett valamennyi információt és a 15–22. és 34. cikk szerinti minden egyes tájékoztatást tömör, átlátható, érthető és könnyen hozzáférhető formában, világosan és közérthetően megfogalmazva nyújtsa.</p>
          <h2>7.2 Az érintett hozzáféréséhez való joga</h2>
          <p>Az érintett jogosult arra, hogy az adatkezelőtől visszajelzést kapjon arra vonatkozóan, hogy személyes adatainak kezelése folyamatban van-e, és ha ilyen adatkezelés folyamatban van, jogosult arra, hogy a személyes adatokhoz és a következő információkhoz hozzáférést kapjon:</p>
          <p>- az adatkezelés céljai; - az érintett személyes adatok kategóriái; - azon címzettek vagy címzettek kategóriái, akikkel, illetve amelyekkel a személyes adatokat közölték vagy közölni fogják, ideértve különösen a harmadik országbeli címzetteket, illetve a nemzetközi szervezeteket; - a személyes adatok tárolásának tervezett időtartama; - a helyesbítés, törlés vagy adatkezelés korlátozásának és a tiltakozás joga; - a felügyeleti hatósághoz címzett panasz benyújtásának joga; - az adatforrásokra vonatkozó információ; - az automatizált döntéshozatal ténye, ideértve a profilalkotást is, valamint az alkalmazott logikára és arra vonatkozó érthető információk, hogy az ilyen adatkezelés milyen jelentőséggel bír, és az érintettre nézve milyen várható következményekkel jár.</p>
          <p>Az adatkezelő a kérelem benyújtásától számított legfeljebb egy hónapon belül adja meg a tájékoztatást.</p>
          <h2>7.3 Helyesbítés joga</h2>
          <p>Az érintett kérheti Adatkezelő által kezelt, rá vonatkozó pontatlan személyes adatok helyesbítését és a hiányos adatok kiegészítését.</p>
          <h2>7.4 Törléshez való jog</h2>
          <p>Az érintett az alábbi indokok valamelyikének fennállása esetén jogosult arra, hogy kérésére Adatkezelő indokolatlan késedelem nélkül törölje a rá vonatkozó személyes adatokat: személyes adatokra már nincs szükség abból a célból, amelyből azokat gyűjtötték vagy más módon kezelték; az érintett visszavonja az adatkezelés alapját képező hozzájárulását, és az adatkezelésnek nincs más jogalapja; az érintett tiltakozik az adatkezelés ellen, és nincs elsőbbséget élvező jogszerű ok az adatkezelésre; a személyes adatokat jogellenesen kezelték; a személyes adatokat az adatkezelőre alkalmazandó uniós vagy tagállami jogban előírt jogi kötelezettség teljesítéséhez törölni kell; a személyes adatok gyűjtésére információs társadalommal összefüggő szolgáltatások kínálásával kapcsolatosan került sor.</p>
          <p>Az adatok törlése nem kezdeményezhető, ha az adatkezelés szükséges: a véleménynyilvánítás szabadságához és a tájékozódáshoz való jog gyakorlása céljából; a személyes adatok kezelését előíró, az adatkezelőre alkalmazandó uniós vagy tagállami jog szerinti kötelezettség teljesítése, illetve közérdekből vagy az adatkezelőre ruházott közhatalmi jogosítvány gyakorlása keretében végzett feladat végrehajtása céljából; a népegészség-ügy területét érintő, vagy archiválási, tudományos és történelmi kutatási célból vagy statisztikai célból, közérdek alapján; vagy jogi igények előterjesztéséhez, érvényesítéséhez, illetve védelméhez.</p>
          <h2>7.5 Az adatkezelés korlátozásához való jog</h2>
          <p>Az érintett kérésére Adatkezelő korlátozza az adatkezelést, ha az alábbi feltételek valamelyike teljesül: az érintett vitatja a személyes adatok pontosságát, ez esetben a korlátozás arra az időtartamra vonatkozik, amely lehetővé teszi, a személyes adatok pontosságának ellenőrzését; az adatkezelés jogellenes, és az érintett ellenzi az adatok törlését, és ehelyett kéri azok felhasználásának korlátozását; az adatkezelőnek már nincs szüksége a személyes adatokra adatkezelés céljából, de az érintett igényli azokat jogi igények előterjesztéséhez, érvényesítéséhez vagy védelméhez; vagy az érintett tiltakozott az adatkezelés ellen; ez esetben a korlátozás arra az időtartamra vonatkozik, amíg megállapításra nem kerül, hogy az adatkezelő jogos indokai elsőbbséget élveznek-e az érintett jogos indokaival szemben.</p>
          <p>Ha az adatkezelés korlátozás alá esik, a személyes adatokat a tárolás kivételével csak az érintett hozzájárulásával, vagy jogi igények előterjesztéséhez, érvényesítéséhez vagy védelméhez, vagy más természetes vagy jogi személy jogainak védelme érdekében, vagy az Unió, illetve valamely tagállam fontos közérdekéből lehet kezelni.</p>
          <h2>7.6 Adathordozáshoz való jog</h2>
          <p>Az érintett jogosult arra, hogy a rá vonatkozó, általa az adatkezelő rendelkezésére bocsátott személyes adatokat tagolt, széles körben használt, géppel olvasható formátumban megkapja, és ezeket az adatokat egy másik adatkezelőnek továbbítsa.</p>
          <h2>7.7 Tiltakozás joga</h2>
          <p>Az érintett jogosult arra, hogy a saját helyzetével kapcsolatos okokból bármikor tiltakozzon személyes adatainak közérdekű vagy az adatkezelőre ruházott közhatalmi jogosítvány gyakorlásának keretében végzett feladat végrehajtásához szükséges adatkezelés, vagy az adatkezelő vagy egy harmadik fél jogos érdekeinek érvényesítéséhez szükséges kezelése ellen, ideértve az említett rendelkezéseken alapuló profilalkotást is. Tiltakozás esetén az adatkezelő a személyes adatokat nem kezelheti tovább, kivéve, ha azt olyan kényszerítő erejű jogos okok indokolják, amelyek elsőbbséget élveznek az érintett érdekeivel, jogaival és szabadságaival szemben, vagy amelyek jogi igények előterjesztéséhez, érvényesítéséhez vagy védelméhez kapcsolódnak.</p>
          <h2>7.8 Automatizált döntéshozatal egyedi ügyekben, beleértve a profilalkotást</h2>
          <p>Az érintett jogosult arra, hogy ne terjedjen ki rá az olyan, kizárólag automatizált adatkezelésen – ideértve a profilalkotást is – alapuló döntés hatálya, amely rá nézve joghatással járna vagy őt hasonlóképpen jelentős mértékben érintené.</p>
          <h2>7.9 Visszavonás joga</h2>
          <p>Az érintett jogosult arra, hogy hozzájárulását bármikor visszavonja.</p>
          <h2>7.10 Bírósághoz fordulás joga</h2>
          <p>Az érintett a jogainak megsértése esetén az adatkezelő ellen bírósághoz fordulhat. A bíróság az ügyben soron kívül jár el. 8.11 Adatvédelmi hatósági eljárás Panasszal a Nemzeti Adatvédelmi és Információszabadság Hatóságnál lehet élni: Név: Nemzeti Adatvédelmi és Információszabadság Hatóság Székhely: 1125 Budapest, Szilágyi Erzsébet fasor 22/C. Levelezési cím: 1530 Budapest, Pf.: 5</p>
          <p>Telefon: 0613911400 Fax: 0613911410</p>
          <p>E-mail: ugyfelszolgalat@naih.hu Honlap: http://www.naih.hu</p>
          <br />
          <h2>8. Egyéb rendelkezések</h2>
          <p>E tájékoztatóban fel nem sorolt adatkezelésekről az adat felvételekor adunk tájékoztatást. Tájékoztatjuk ügyfeleinket, hogy a bíróság, az ügyész, a nyomozó hatóság, a szabálysértési hatóság, a közigazgatási hatóság, a Nemzeti Adatvédelmi és Információszabadság Hatóság, a Magyar Nemzeti Bank, illetőleg jogszabály felhatalmazása alapján más szervek tájékoztatás adása, adatok közlése, átadása, illetőleg iratok rendelkezésre bocsátása végett megkereshetik az adatkezelőt. Adatkezelő a hatóságok részére – amennyiben a hatóság a pontos célt és az adatok körét megjelölte – személyes adatot csak annyit és olyan mértékben ad ki, amely a megkeresés céljának megvalósításához elengedhetetlenül szükséges.</p>
          <p>(Jelen adatkezelési tájékoztató a Budapesti Kereskedelmi és Iparkamara ajánlása alapján készült.)</p>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Adatkezelesi