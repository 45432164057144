import React, { useState, useEffect, useRef } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Rsiindikator() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    let tvScriptLoadingPromise;
    const onLoadScriptRef = useRef();
    useEffect(
        () => {
            onLoadScriptRef.current = createWidget;

            if (!tvScriptLoadingPromise) {
                tvScriptLoadingPromise = new Promise((resolve) => {
                    const script = document.createElement('script');
                    script.id = 'tradingview-widget-loading-script';
                    script.src = 'https://s3.tradingview.com/tv.js';
                    script.type = 'text/javascript';
                    script.onload = resolve;

                    document.head.appendChild(script);
                });
            }

            tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

            return () => onLoadScriptRef.current = null;

            function createWidget() {
                if (document.getElementById('tradingview_6b663') && 'TradingView' in window) {
                    new window.TradingView.widget({
                        width: 'auto',
                        height: 400,
                        symbol: "NASDAQ:GOOGL",
                        interval: "D",
                        timezone: "Etc/UTC",
                        theme: "dark",
                        style: "1",
                        locale: "en",
                        enable_publishing: false,
                        allow_symbol_change: true,
                        studies: ["STD;RSI"],
                        container_id: "tradingview_6b663"
                    });
                }
            }
        },
        []
    );

    return (
        <>
            <Helmet>
                <title>RSI - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted az RSI indikátor jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>RSI</h1>
                            <p>Ezen az oldalon megismerheted az RSI indikátor jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">Az RSI egy olyan indikátor, amely a piaci túlvásároltság és túladottság szintjét méri.</p>
                            <p className="majonator">Az RSI a relative strength index rövidítése és egy <Link to='/fogalomtar/technikai-elemzes' className='dezord'>technikai elemzésben</Link> használatos indikátor. Az RSI egy értékpapír múltbeli árfolyamváltozásának mértéke alapján értékeli az adott értékpapír árfolyamának túlértékeltségét vagy alulértékeltségét. Az indikátort a kereskedők trendfordulók megállapítására is használják. Általában az instrumentum árfolyamgrafikonja alatt szokták ábrázolni, ahogy az lent is látható az Alphabet Inc. esetében. Az RSI indikátor számítását elvégzi helyettünk a használt árfolyamgrafikon platform.</p>
                            <hr />
                            <div className='tradingview-widget-container'>
                                <div id='tradingview_6b663' />
                                <div className="tradingview-widget-copyright">
                                    <a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank"><span className="blue-text">Track all markets on TradingView</span></a>
                                </div>
                            </div>
                            <hr />
                            <h2>Működése</h2>
                            <p className="majonator">Az RSI a kereskedőknek jelezheti, hogy mikor érdemes vásárolni és eladni. Az indikátor egy vonalas grafikonként jelenik meg egy nullától 100-ig terjedő skálán. Hagyományosan a 70-es vagy annál magasabb RSI érték túlvételt jelez. A 30-as vagy az alatti érték túladott állapotot jelez. &nbsp; | &nbsp; RSI = 100 - 100 / (1 + átlagos növekedés / átlagos csökkenés)</p>
                            <p className="majonator">Az RSI-t több időtávra vetítve is használják. Példánkban vegyünk egy 14 napos időszakot, majd osszuk el a nyereséges és veszteséges záróárak átlagát erre időszakra vetítve. A nyereség átlaga 3%, a veszteségé 2%. Eredményül az RSI 60-as értéket ad vissza.</p>
                            <p className="majonator">RSI = 100 - 100 / (1 + <span class="frac"><sup><span class="frac"><sup>3%</sup><span>&frasl;</span><sub>14</sub></span></sup><span>&frasl;</span><sub><span class="frac"><sup>2%</sup><span>&frasl;</span><sub>14</sub></span></sub></span>) = 60</p>
                            <hr />
                            <h2>Használata</h2>
                            <p className="majonator">A 70-es vagy a feletti érték túlvételt, míg a 30-as vagy az alatti alulvételt jelez. Egy emelkedő trend során az RSI általában 30 felett marad, gyakran eléri a 70-et. Csökkenő trend során ritka, hogy az RSI értéke meghaladja a 70-et. Tartós csökkenő trend esetén a kereskedőknek érdemes lehet a túladott jelzést 70-es értékről lejjebb helyezni. Ez fordítva ugyanúgy igaz a növekvő trendekre is.</p>
                            <p className="majonator">RSI divergencia akkor következik be, amikor az ár és az RSI ellentétes irányba mozognak. Amikor egy eszköz stabil hosszú távú trendben van, akkor ritkák lehetnek a divergencia jelzések. Ilyenkor érdemes inkább az RSI általános túlvett, túladott jelzéseire fókuszálni. Bullish divergenciáról beszélünk amikor az RSI túladott jelzést ad, melyet egy magasabb mélypont követ, az árban pedig csökkenő mélypontok alakulnak ki. A nevéből is utal rá, hogy ez egy pozitív jelzés, vételre szólíthatja a kereskedőt. Az ellentéte a bearish divergencia, ami esetén az RSI túlvett jelzést ad, melyet egy alacsonyabb csúcs követ, az árban pedig növekvő csúcspontok alakulnak ki.</p>
                            <hr />
                            <h2>RSI korlátai</h2>
                            <p className="majonator">Az RSI valódi fordulatjelzései ritkák, sok esetben nyújt téves jelzést a vételre vagy eladásra. Mivel az RSI a lendületet mutatja, hosszú ideig túlvásárolt vagy túladott maradhat, ha egy eszköz jelentős lendülettel rendelkezik bármelyik irányba. Az RSI-t más technikai mutatókkal együtt kell használni a pontosabb becslés elérése érdekében.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Rsiindikator