import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import axios from 'axios';
import { Toaster } from 'react-hot-toast';

import { UserContextProvider } from './context/userContext';

import Header from './components/header/header'
import Home from './components/A-pages/Home';
import Befszabalyok from './components/A-pages/Befszabalyok';
import Fogalomtar from './components/A-pages/Fogalomtar';
import Roadmap from './components/A-pages/Roadmap';
import Konyvek from './components/A-pages/Konyvek';
import Tanfolyam from './components/A-pages/Tanfolyam';

import Akvizicio from './components/fogalomtar-paths/akvizicio';
import Alapokalapja from './components/fogalomtar-paths/Alapok-alapja';
import Allamkotveny from './components/fogalomtar-paths/Allamkotveny';
import Arany from './components/fogalomtar-paths/Arany';
import Arbitrazs from './components/fogalomtar-paths/Arbitrazs';
import Arutozsde from './components/fogalomtar-paths/Arutozsde';

import Befektetesialap from './components/fogalomtar-paths/Befektetesi-alap';
import Befektetesikockazat from './components/fogalomtar-paths/Befektetesi-kockazat';
import Benchmark from './components/fogalomtar-paths/Benchmark';
import Betamutato from './components/fogalomtar-paths/Beta-mutato';
import Bankbetet from './components/fogalomtar-paths/Bankbetet';
import Broker from './components/fogalomtar-paths/Broker';

import Capmmodell from './components/fogalomtar-paths/CAPM-modell';
import Cashflow from './components/fogalomtar-paths/Cash-flow';
import CFD from './components/fogalomtar-paths/CFD';
import Csod from './components/fogalomtar-paths/Csod';

import Debtequity from './components/fogalomtar-paths/Debt-Equity';
import Deflacio from './components/fogalomtar-paths/Deflacio';
import Derivativa from './components/fogalomtar-paths/Derivativa';
import Diverzifikacio from './components/fogalomtar-paths/Diverzifikacio';
import Duracio from './components/fogalomtar-paths/Duracio';

import Ebitda from './components/fogalomtar-paths/Ebitda';
import Elsobbsegireszveny from './components/fogalomtar-paths/Elsobbsegi-reszveny';
import EPS from './components/fogalomtar-paths/EPS';
import Ertekpapir from './components/fogalomtar-paths/Ertekpapir';

import Fedezetipont from './components/fogalomtar-paths/Fedezeti-pont';
import Forgalom from './components/fogalomtar-paths/Forgalom';
import Forwardpe from './components/fogalomtar-paths/Forward-PE';
import Franchise from './components/fogalomtar-paths/Franchise';

import Gap from './components/fogalomtar-paths/Gap';
import Goodwill from './components/fogalomtar-paths/Goodwill';
import Greenchip from './components/fogalomtar-paths/Green-chip';

import Hedgefund from './components/fogalomtar-paths/Hedge-fund';
import Hitel from './components/fogalomtar-paths/Hitel';
import Hozam from './components/fogalomtar-paths/Hozam';

import IMF from './components/fogalomtar-paths/IMF';
import Indikator from './components/fogalomtar-paths/Indikator';
import Inflacio from './components/fogalomtar-paths/Inflacio';
import IPO from './components/fogalomtar-paths/IPO';

import Jegybank from './components/fogalomtar-paths/Jegybank';
import Jovedelmezoseg from './components/fogalomtar-paths/Jovedelmezoseg';
import Jutalek from './components/fogalomtar-paths/Jutalek';

import Keszpenz from './components/fogalomtar-paths/Keszpenz';
import Kincstarjegy from './components/fogalomtar-paths/Kincstarjegy';
import Konyvszerintiertek from './components/fogalomtar-paths/Konyv-szerinti-ertek';
import Kotveny from './components/fogalomtar-paths/Kotveny';

import Leanyvallalat from './components/fogalomtar-paths/Leanyvallalat';
import Likviditas from './components/fogalomtar-paths/Likviditas';
import Longpozicio from './components/fogalomtar-paths/Long-pozicio';

import Margin from './components/fogalomtar-paths/Margin';
import Medvepiac from './components/fogalomtar-paths/Medvepiac';
import Merleg from './components/fogalomtar-paths/Merleg';
import Mozgoatlag from './components/fogalomtar-paths/Mozgoatlag';

import Nominalishozam from './components/fogalomtar-paths/Nominalis-hozam';
import Novekedesireszveny from './components/fogalomtar-paths/Novekedesi-reszveny';
import NYSE from './components/fogalomtar-paths/NYSE';

import Opcio from './components/fogalomtar-paths/Opcio';
import Order from './components/fogalomtar-paths/Order';
import Osztalek from './components/fogalomtar-paths/Osztalek';
import OTCpiac from './components/fogalomtar-paths/OTC-piac';

import PB from './components/fogalomtar-paths/PB';
import Pe from './components/fogalomtar-paths/PE';
import Peg from './components/fogalomtar-paths/PEG';
import Piacikapitalizacio from './components/fogalomtar-paths/Piaci-kapitalizacio';
import Portfolio from './components/fogalomtar-paths/Portfolio';
import PS from './components/fogalomtar-paths/PS';

import Recesszio from './components/fogalomtar-paths/Recesszio';
import Reszveny from './components/fogalomtar-paths/Reszveny';
import ROA from './components/fogalomtar-paths/ROA';
import ROE from './components/fogalomtar-paths/ROE';
import ROI from './components/fogalomtar-paths/ROI';
import Rsiindikator from './components/fogalomtar-paths/RSI';

import Shrapemutato from './components/fogalomtar-paths/Sharpe-mutato';
import Shortpozicio from './components/fogalomtar-paths/Short-pozicio';
import Spekulacio from './components/fogalomtar-paths/Spekulacio';
import Szektor from './components/fogalomtar-paths/Szektor';

import Technikaielemzes from './components/fogalomtar-paths/Technikaielemzes';
import Tokeattetel from './components/fogalomtar-paths/Tokeattetel';
import Tozsde from './components/fogalomtar-paths/Tozsde';
import Tozsdeindex from './components/fogalomtar-paths/Tőzsdeindex';

import Valuta from './components/fogalomtar-paths/Valuta';
import Volatilitas from './components/fogalomtar-paths/Volatilitas';
import Vallalat from './components/fogalomtar-paths/Vallalat';


import Elsoallomas from './components/roadmap/elsoallomas';
import Harmadikallomas from './components/roadmap/harmadikallomas';
import Negyedikallomas from './components/roadmap/negyedikallomas';
import Hatodikallomas from './components/roadmap/hatodikallomas';
import Otodikallomas from './components/roadmap/otodikallomas';

import Kviz from './components/fogalomtar-main/kviz';

import Gyik from './components/FooterPages/gyik';
import Kapcsolat from './components/FooterPages/kapcsolat';
import Impresszum from './components/FooterPages/impresszum';
import Adatkezelesi from './components/FooterPages/adatkezelesi';
import Jogi from './components/FooterPages/jogi';

import ScrollToTop from './scrolltop';

import ErrorPage from './components/A-pages/ErrorPage';

import { HelmetProvider } from 'react-helmet-async';

axios.defaults.baseURL = 'http://localhost:8000';
axios.defaults.withCredentials = true;

function App() {
  const helmetContext = {};

  return (
    <>
      <HelmetProvider> {/* context={helmetContext} */}
        <UserContextProvider>  {/* lehet, hogy routeren belülre kell idk - ez biztosítja a cookikat az összes oldalon*/}
          <Router>
            <ScrollToTop />

            <Header />
            <Toaster position='bottom-right' toastOptions={{ duration: 2000, style: { padding: '20px', fontSize: '15px', fontWeight: '600' } }} />

            <Routes>
              <Route path='*' element={<ErrorPage />} />
              <Route exact path='/' element={<Home />} />
              <Route path='/alapszabalyok' element={<Befszabalyok />} />
              <Route path='/fogalomtar' element={<Fogalomtar />} />
              <Route path='/roadmap' element={<Roadmap />} />
              <Route path='/konyvek' element={<Konyvek />} />
              <Route path='/tanfolyam' element={<Tanfolyam />} />

              {/*
            <Route path='/register' element={<Register />} />
            <Route path='/login' element={<Login />} />
            <Route path='/dashboard' element={<Dashboard />} />
            */}

              <Route path='/roadmap/elso' element={<Elsoallomas />} />
              <Route path='/roadmap/harmadik' element={<Harmadikallomas />} />
              <Route path='/roadmap/negyedik' element={<Negyedikallomas />} />
              <Route path='/roadmap/otodik' element={<Otodikallomas />} />
              <Route path='/roadmap/hatodik' element={<Hatodikallomas />} />

              <Route path='/fogalomtar/kviz' element={<Kviz />} />

              <Route path='/gyik' element={<Gyik />} />
              <Route path='/kapcsolat' element={<Kapcsolat />} />
              <Route path='/impresszum' element={<Impresszum />} />
              <Route path='/adatkezelesi-tajekoztato' element={<Adatkezelesi />} />
              <Route path='/jogi-nyilatkozat' element={<Jogi />} />


              {/* A - maxed */}
              <Route path='/fogalomtar/akvizicio' element={<Akvizicio />} />
              <Route path='/fogalomtar/alapok-alapja' element={<Alapokalapja />} />
              <Route path='/fogalomtar/allamkotveny' element={<Allamkotveny />} />
              <Route path='/fogalomtar/arany' element={<Arany />} />
              <Route path='/fogalomtar/arbitrazs' element={<Arbitrazs />} />
              <Route path='/fogalomtar/arutozsde' element={<Arutozsde />} />
              {/* B - maxed */}
              <Route path='/fogalomtar/bankbetet' element={<Bankbetet />} />
              <Route path='/fogalomtar/befektetesi-alap' element={<Befektetesialap />} />
              <Route path='/fogalomtar/befektetesi-kockazat' element={<Befektetesikockazat />} />
              <Route path='/fogalomtar/benchmark' element={<Benchmark />} />
              <Route path='/fogalomtar/beta-mutato' element={<Betamutato />} />
              <Route path='/fogalomtar/broker' element={<Broker />} />
              {/* C - maxed */}
              <Route path='/fogalomtar/capm-modell' element={<Capmmodell />} />
              <Route path='/fogalomtar/cash-flow' element={<Cashflow />} />
              <Route path='/fogalomtar/cfd' element={<CFD />} />
              <Route path='/fogalomtar/csod' element={<Csod />} />
              {/* D - maxed */}
              <Route path='/fogalomtar/debt-equity' element={<Debtequity />} />
              <Route path='/fogalomtar/deflacio' element={<Deflacio />} />
              <Route path='/fogalomtar/derivativa' element={<Derivativa />} />
              <Route path='/fogalomtar/diverzifikacio' element={<Diverzifikacio />} />
              <Route path='/fogalomtar/duracio' element={<Duracio />} />
              {/* E - maxed */}
              <Route path='/fogalomtar/ebitda' element={<Ebitda />} />
              <Route path='/fogalomtar/elsobbsegi-reszveny' element={<Elsobbsegireszveny />} />
              <Route path='/fogalomtar/eps' element={<EPS />} />
              <Route path='/fogalomtar/ertekpapir' element={<Ertekpapir />} />
              {/* F - maxed */}
              <Route path='/fogalomtar/fedezeti-pont' element={<Fedezetipont />} />
              <Route path='/fogalomtar/forgalom' element={<Forgalom />} />
              <Route path='/fogalomtar/forward-pe' element={<Forwardpe />} />
              <Route path='/fogalomtar/franchise' element={<Franchise />} />
              {/* G - maxed */}
              <Route path='/fogalomtar/gap' element={<Gap />} />
              <Route path='/fogalomtar/green-chip' element={<Greenchip />} />
              <Route path='/fogalomtar/goodwill' element={<Goodwill />} />
              {/* H - maxed */}
              <Route path='/fogalomtar/hedge-fund' element={<Hedgefund />} />
              <Route path='/fogalomtar/hitel' element={<Hitel />} />
              <Route path='/fogalomtar/hozam' element={<Hozam />} />
              {/* I - maxed */}
              <Route path='/fogalomtar/imf' element={<IMF />} />
              <Route path='/fogalomtar/indikator' element={<Indikator />} />
              <Route path='/fogalomtar/inflacio' element={<Inflacio />} />
              <Route path='/fogalomtar/ipo' element={<IPO />} />
              {/* J - maxed */}
              <Route path='/fogalomtar/jegybank' element={<Jegybank />} />
              <Route path='/fogalomtar/jovedelmezoseg' element={<Jovedelmezoseg />} />
              <Route path='/fogalomtar/jutalek' element={<Jutalek />} />
              {/* K - maxed */}
              <Route path='/fogalomtar/keszpenz' element={<Keszpenz />} />
              <Route path='/fogalomtar/kincstarjegy' element={<Kincstarjegy />} />
              <Route path='/fogalomtar/konyv-szerinti-ertek' element={<Konyvszerintiertek />} />
              <Route path='/fogalomtar/kotveny' element={<Kotveny />} />
              {/* L - maxed */}
              <Route path='/fogalomtar/leanyvallalat' element={<Leanyvallalat />} />
              <Route path='/fogalomtar/likviditas' element={<Likviditas />} />
              <Route path='/fogalomtar/long-pozicio' element={<Longpozicio />} />
              {/* M - maxed */}
              <Route path='/fogalomtar/margin' element={<Margin />} />
              <Route path='/fogalomtar/medvepiac' element={<Medvepiac />} />
              <Route path='/fogalomtar/merleg' element={<Merleg />} />
              <Route path='/fogalomtar/mozgoatlag' element={<Mozgoatlag />} />
              {/* N - maxed */}
              <Route path='/fogalomtar/nominalis-hozam' element={<Nominalishozam />} />
              <Route path='/fogalomtar/novekedesi-reszveny' element={<Novekedesireszveny />} />
              <Route path='/fogalomtar/nyse' element={<NYSE />} />
              {/* O - maxed */}
              <Route path='/fogalomtar/opcio' element={<Opcio />} />
              <Route path='/fogalomtar/order' element={<Order />} />
              <Route path='/fogalomtar/osztalek' element={<Osztalek />} />
              <Route path='/fogalomtar/otc-piac' element={<OTCpiac />} />
              {/* P - maxed */}
              <Route path='/fogalomtar/pb' element={<PB />} />
              <Route path='/fogalomtar/pe' element={<Pe />} />
              <Route path='/fogalomtar/peg' element={<Peg />} />
              <Route path='/fogalomtar/piaci-kapitalizacio' element={<Piacikapitalizacio />} />
              <Route path='/fogalomtar/portfolio' element={<Portfolio />} />
              <Route path='/fogalomtar/ps' element={<PS />} />
              {/* Q - kimarad */}
              {/* R - maxed */}
              <Route path='/fogalomtar/recesszio' element={<Recesszio />} />
              <Route path='/fogalomtar/reszveny' element={<Reszveny />} />
              <Route path='/fogalomtar/roa' element={<ROA />} />
              <Route path='/fogalomtar/roe' element={<ROE />} />
              <Route path='/fogalomtar/roi' element={<ROI />} />
              <Route path='/fogalomtar/rsi-indikator' element={<Rsiindikator />} />
              {/* S - maxed */}
              <Route path='/fogalomtar/sharpe-mutato' element={<Shrapemutato />} />
              <Route path='/fogalomtar/short-pozicio' element={<Shortpozicio />} />
              <Route path='/fogalomtar/spekulacio' element={<Spekulacio />} />
              <Route path='/fogalomtar/szektor' element={<Szektor />} />
              {/* T - maxed */}
              <Route path='/fogalomtar/technikai-elemzes' element={<Technikaielemzes />} />
              <Route path='/fogalomtar/tokeattetel' element={<Tokeattetel />} />
              <Route path='/fogalomtar/tozsde' element={<Tozsde />} />
              <Route path='/fogalomtar/tozsdeindex' element={<Tozsdeindex />} />
              {/* U - kimarad*/}
              {/* V - maxed */}
              <Route path='/fogalomtar/vallalat' element={<Vallalat />} />
              <Route path='/fogalomtar/valuta' element={<Valuta />} />
              <Route path='/fogalomtar/volatilitas' element={<Volatilitas />} />
              {/* W - kimarad */}
              {/* X - kimarad */}
              {/* Y - kimarad */}
              {/* Z - kimarad */}
              {/* !!! Szövegek információval való folyamatos bővítése !!! */}
            </Routes>

          </Router>
        </UserContextProvider>
      </HelmetProvider>
    </>
  );
}

export default App;
