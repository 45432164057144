import React from 'react'
import './tanf-atmeneti.css'
import Footer from '../footer/footer'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

function Tanfolyam() {
    return (
        <>
        <Helmet>
        <title>Tanfolyam | Befektetésközpont</title>
        <meta name="description" content="A részvénybefektetési tanfolyamunk segítségével megtanulhatsz egy vállalatot tetőtől talpig elemezni, hogy megtalálhasd a számodra legmegfelelőbb részvényeket a portfóliódba. (Fejlesztés alatt)" data-react-helmet="true"/>
        </Helmet>
            <div className='teljeskepernyos'>
                <h2>A regisztráció a részvénybefektetési tanfolyamunkra jelenleg zárolva van.</h2>
                <div className='dobozolo'>
                    <p>Amennyiben szeretnéd, hogy értesítsünk téged a tanfolyam megnyitásakor, a <Link to='/kapcsolat' className='dezord'>kapcsolat menüpontban</Link> egy üzenet küldésével jelentkezhetsz az értesítésre.</p>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Tanfolyam
