import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Kotveny() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Kötvény - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a kötvény jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Kötvény</h1>
                            <p>Ezen az oldalon megismerheted a kötvény jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A kötvény egy olyan értékpapír, ami hitelviszonyt testesít meg az adott szervezet és a befektető között.</p>
                            <p className="majonator">A kötvény egy stabil hozamú eszköz, amely a befektető által a hitelfelvevőnek nyújtott kölcsönt képvisel. A kötvényt úgy kell elképzelni, mint egy, a hitelező és a hitelfelvevő közötti, a kölcsön és annak kifizetéseinek részleteit tartalmazó dokumentumot. A kötvényeket vállalatok és államok használják projektek és a fejlődés finanszírozására.</p>
                            <hr />
                            <h2>Kötvények típusai</h2>
                            <p className="majonatora">Kibocsátó szerv szerint megkülönböztetünk államkötvényt és vállalati kötvényt. Az <Link to='/fogalomtar/allamkotveny' className='dezord'>államkötvény</Link> az állam, a vállalati kötvény pedig a vállalat finanszírozási igényeinek kielégítésére szolgál. A vállalati kötvényeknek magasabb a kockázata, mivel a vállalatoknak általában kevésbé stabil a pénzügyi helyzetük, mint egy országnak, és a piaci feltételek, illetve a vállalati teljesítmény változása jelentősebb mértékben befolyásolhatja a kamatfizetéseket és a tőkevisszafizetést.</p>
                            <br />
                            <p className="majonator">Kamatozás szerint megkülönböztetünk fix kamatozású, változó kamatozású, emelkedő és csökkenő kamatozású, kamatos kamatozású és diszkont kötvényeket. A fix kamatozás esetén a befektető minden évben megkapja a kiszabott fix százalékú kamatot, majd az államkötvény lejárta után a befektetett pénz is kifizetésre kerül, míg változó kamatozásnál változik a kamatláb mértéke a futamidő alatt. Emelkedő és csökkenő kamatozásnál a futamidő múlásával egy adott irányba mozog a kamatláb. A kamatos kamat azt jelenti, hogy a kötvény után kapott kamat újra befektetésre kerül és kamatozik. A diszkont kötvény nem fizet kamatot, hanem névérték alatt megvásároljuk, majd névértéken eladjuk.</p>
                            <p className="majonator">Ezeken kívül megkülönböztetünk kötvényeket aszerint, hogy mikor fizetnek kamatot. Egyes kötvények csak lejáratkor fizetnek kamatot, míg mások a futamidő alatt.</p>
                            <hr />
                            <h2>Árfolyam és kamat</h2>
                            <p className="majonator">Ha egy kötvény kibocsátása után esnek a piaci hozamok és az újabb kötvényeket alacsonyabb kamat mellett bocsájtják ki, akkor a korábbi kötvény felértékelődik, és az árfolyama emelkedni fog. Ez fordítva is megtörténhet, vagyis a korábbi kötvény ára csökkenhet is. Ebből az következik, hogy minél hosszabb az átlagos hátralevő futamidő, vagyis a <Link to='/fogalomtar/duracio' className='dezord'>duráció</Link>, annál érzékenyebb lesz a kötvény a hozamváltozásra, ami magasabb volatilitást eredményez. Ha lejárat előtt szeretnénk eladni a kötvényt, akkor erre a piaci árfolyamon van lehetőség. A piaci ár azon múlik, hogy miként alakulnak a hozamok a piacon miután befektettünk. Emelkedő piaci hozamok esetén az kötvény árfolyama esik, csökkenő piaci hozamok esetén pedig emelkedik.</p>
                            <p className="majonator">A névérték az az összeg, amit a szervezet visszafizet a futamidő végén a kötvény tulajdonosának. A másodlagos piacon - ahol a kötvény kibocsátás utáni kereskedése folyik - lehet megvásárolni a kötvényt a saját árfolyamán, névérték felett vagy alatt is. A kötvény ára és hozama között inverz kapcsolat van. Ha egy kötvény ára nő, akkor a hozama csökken. Ha pedig a kötvény ára csökken, akkor a hozama nő. Ez abból fakad, hogy például egy kötvény csökkenő ára esetén egyre távolabb kerül a névértéktől, ami befektetés esetén magasabb hozamot eredményez. Vagyis ha bejelentik, hogy nőnek a kötvények hozamai, akkor valójában az árfolyamuk csökken a másodlagos piacon.</p>
                            <hr />
                            <h2>Kinek ajánlott a kötvény?</h2>
                            <p className="majonator">A kötvények azok számára lehetnek kedvező befektetési lehetőség, akik a biztonságot és az alacsonyabb kockázatot preferálják. A kötvények kevésbé kockázatosak, mint a <Link to='/fogalomtar/reszveny' className='dezord'>részvények</Link>, viszont kockázatosabbak, mint a bankbetétek. Ebből következik, hogy a hozam tekintetében is a részvények és a bankbetétek között helyezkednek el. Kötvényekkel fel lehet hígítani egy részvény <Link to='/fogalomtar/portfolio' className='dezord'>portfóliót</Link>, így létrehozva egy részvény-kötvény portfóliót, ami az egyik legnépszerűbb befektetési választás. A két instrumentum jól kiegészítik egymást. A kockázatvállalási hajlandóság mértéke szerint szokás változtatni a két eszköz közötti arányt a portfólióban. Ekkor a befektető a kötvények segítségével ellensúlyozza a részvények kockázatosságát.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Kotveny