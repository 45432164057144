import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Kincstarjegy() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Kincstárjegy - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a kincstárjegy jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Kincstárjegy</h1>
                            <p>Ezen az oldalon megismerheted a kincstárjegy jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A kincstárjegy egy állam által biztosított, hitelviszonyt megtestesítő értékpapír.</p>
                            <p className="majonator">Az adott állam feltétel nélküli kötelezettséget vállal arra, hogy a kincstárjegy névértékét a megjelölt időben és módon megfizeti. Az államkötvénytől való különbség abban áll, hogy a kincstárjegy rövidebb futamidejű (max 1 év), kisebb hozamot termel a befektetőnek és a hozamkifizetés módja is eltérő.</p>
                            <hr />
                            <h2>Működése</h2>
                            <p className="majonator">A kincstárjegy is egy állampapír fajta, az állam a finanszírozási igényeinek kielégítésére bocsátja ki. A kincstárjegy vásárlása gyakorlatilag azt jelenti, hogy az adott államnak kölcsön adod a pénzedet. Cserébe az állam ígéretet tesz arra, hogy a kibocsátott kincstárjegy névértékét kamattal együtt (ha jár hozzá) visszafizeti a futamidő lejártakor.</p>
                            <br />
                            <p className="majonatora azam">Diszkont Kincstárjegy</p>
                            <p className="majonatora">A Diszkont Kincstárjegy nem fizet kamatot. Mint a nevéből is kiderül, névérték alatt kerül forgalomba, így a befektető nem a kamatokból jut profithoz, hanem a vételi és eladási ár közötti különbségből. Vagyis a kincstárjegyet névérték alatt vásároljuk meg és a névértéken adjuk el. A Diszkont Kincstárjegy futamideje nem hosszabb 1 évnél.</p>
                            <hr />
                            <h2>Kinek ajánlott a kincstárjegy?</h2>
                            <p className="majonator">A kincstárjegyek fő előnye a biztonság és a stabil <Link to='/fogalomtar/hozam' className='dezord'>hozam</Link>, így azoknak a befektetőknek lehet jó választás, akik nem kívánnak kockázatosabb befektetéseket vállalni. Értelemszerűen mivel alacsonyabb a kockázat, ezért a hozam is alacsonyabb. Ideális eleme lehet egy kellőképpen diverzifikált és biztonságos portfóliónak. Kincstárjegy kiválasztásakor amennyiben hosszú ideig nem lesz szükségünk a pénzünkre, érdemes azt hosszabb futamidőre lekötni, mivel így nagyobb hozamot érhetünk el.</p>
                            <hr />
                            <h2>Kincstárjegy vásárlás</h2>
                            <p className="majonator">A kincstárjegy aukció útján kerül forgalomba hozatalra. A kincstárjegyek forgalmazását általában az adott ország államkincstára és elsődleges forgalmazók, mint például bankok és <Link to='/fogalomtar/broker' className='dezord'>brókercégek</Link> végzik. Magyarországon az alábbi szervezeteken keresztül lehetséges kincstárjegyet vásárolni: Magyar Államkincstár, CIB, Citibank, Deutsche Bank, Erste, Goldman Sachs, ING Bank, J.P. Morgan, K&H, MBH Bank, OTP, Raiffeisen, Unicredit.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Kincstarjegy