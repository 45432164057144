import React, { useState, useEffect, useRef } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Mozgoatlag() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    let tvScriptLoadingPromise;
    const onLoadScriptRef = useRef();

    useEffect(
        () => {
            onLoadScriptRef.current = createWidget;

            if (!tvScriptLoadingPromise) {
                tvScriptLoadingPromise = new Promise((resolve) => {
                    const script = document.createElement('script');
                    script.id = 'tradingview-widget-loading-script';
                    script.src = 'https://s3.tradingview.com/tv.js';
                    script.type = 'text/javascript';
                    script.onload = resolve;

                    document.head.appendChild(script);
                });
            }

            tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

            return () => onLoadScriptRef.current = null;

            function createWidget() {
                if (document.getElementById('tradingview_f829b') && 'TradingView' in window) {
                    new window.TradingView.widget({
                        width: 'auto',
                        height: 400,
                        symbol: "NYSE:CAT",
                        interval: "30",
                        timezone: "Etc/UTC",
                        theme: "dark",
                        style: "1",
                        locale: "en",
                        enable_publishing: false,
                        allow_symbol_change: true,
                        studies: ["STD;SMA"],
                        container_id: "tradingview_f829b"
                    });
                }
            }
        },
        []
    );

    return (
        <>
            <Helmet>
                <title>Mozgóátlag - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a mozgóátlag jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Mozgóátlag</h1>
                            <p>Ezen az oldalon megismerheted a mozgóátlag jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A mozgóátlag az árfolyam egy adott időtartamra vetített átlaga.</p>
                            <p className="majonator">A mozgóátlag egy <Link to='/fogalomtar/technikai-elemzes' className='dezord'>technikai elemzésben</Link> használatos indikátor. A kereskedők a mozgóátlagokat egy részvény trendirányának azonosítására vagy a támasz- és ellenállási szintek meghatározására használják. Az árfolyam grafikonon szokták feltüntetni, egy vonal formájában. Ha egy eszköznek csak a mozgóátlagát vizsgáljuk, az árfolyamát pedig nem, akkor a mozgóátlag által vizsgált időszakhoz képest rövid távú ingadozásokat figyelmen kívül hagyjuk. A mozgóátlagoknak több fajtája is van.</p>
                            <hr />
                            <div className='tradingview-widget-container'>
                                <div id='tradingview_f829b' />
                                <div className="tradingview-widget-copyright">
                                    <a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank"><span className="blue-text">Track all markets on TradingView</span></a>
                                </div>
                            </div>
                            <hr />
                            <h2>Működése</h2>
                            <p className="majonator">Minél hosszabb egy mozgóátlag periódusa, annál nagyobb a késleltetése. Ez abból fakad, hogy minél hosszabb a periódus, annál hosszabb időszak adatát, vagyis több adatot használunk fel az átlag számításához. A kereskedők az általuk preferált kereskedési időtartam alapján választják ki a mozgóátlag periódusszámát. Rövidtávú kereskedéshez rövid periódus, hosszú távúhoz kereskedéshez pedig hosszú periódus használata javallott.</p>
                            <p className="majonator">Technikai elemzésben pozitív jelzésként értelmezhető, ha egy rövid távú mozgóátlag egy hosszabb távú fölé emelkedik. Ezt hívják bullis crossovernek, aminek a bearish crossover az ellentéte. A mozgóátlag olyan <Link to='/fogalomtar/indikator' className='dezord'>indikátorok</Link> részét képezi, mint például a MACD, az ATR vagy a CCI.</p>
                            <p className="majonator">Amíg nem idősebb az adott instrumentum a mozgóátlag periódusánál, addig az a mozgóátlag nem létezik. Például ha csak 3 hónapja jegyzik egy részvény árfolyamát, akkor annak nem lesz 200 napos mozgóátlaga, ami mellesleg a legnépszerűbb periódusnak számít.</p>
                            <hr />
                            <h2>Mozgóátlag fajtái</h2>
                            <p className="majonator azam">Egyszerű mozgóátlag (SMA)</p>
                            <p className="majonator">Az SMA-t úgy kell kiszámolni, hogy az adott periódus alatt jegyzett árakat összeadjuk, majd elosztjuk az összeadott árak darabszámával, vagyis lényegében átlagot számolunk. Abban különbözik a többi fajtától, hogy minden érték egyforma súllyal szerepel a képletben.</p>
                            <p className="majonatora">SMA = <span class="frac"><sup>X<sub>1</sub> + X<sub>2</sub> + ... + X<sub>n</sub></sup><span>&frasl;</span><sub>X darabszáma</sub></span> &nbsp; , ahol X = eszköz ára</p>
                            <br />
                            <p className="majonator azam">Exponenciális mozgóátlag (EMA)</p>
                            <p className="majonator">Az EMA-t úgy kell kiszámolni, hogy az adott periódus alatt jegyzett árak közül nagyobb súllyal számítjuk az újabb árakat. Összeadjuk őket, majd elosztjuk az összeadott árak darabszámával. Ez azért előnyös, mert az SMA esetében a nagyon régi értékek ugyanolyan mértékben befolyásolják a képéletet, mint a mai árak, pedig kevésbé lényeges, hogy mi történt az árral sok hónappal ezelőtt.</p>
                            <p className="majonator">EMA = ( Mai árfolyam <span>&#215;</span> ( <span class="frac"><sup>( 2 / ( periódus napok + 1 ))</sup><span>&frasl;</span><sub>1 + periódus napok</sub></span> )) + előző napi EMA <span>&#215;</span> ( 1 - ( <span class="frac"><sup>( 2 / ( periódus napok + 1 ))</sup><span>&frasl;</span><sub>1 + periódus napok</sub></span> )) </p>
                            <br />
                            <p className="majonator azam">Súlyozott mozgóátlag (WMA)</p>
                            <p className="majonator">A WMA-t úgy kell kiszámolni, hogy az adott periódus alatt jegyzett árak közül nagyobb súllyal számítjuk az új, és kisebb súllyal a régi árakat, majd összeadjuk őket és elosztjuk az árak darabszámával. Ez hasonló, mint az EMA, annyi különbséggel, hogy ennek a mutatónak az esetében minden árfolyam súlyozva van. Minél régebbi egy ár, annál kisebb súlya van a képletben.</p>
                            <p className="majonator">WMA = <span class="frac"><sup>X<sub>1</sub> <span>&#215;</span> n + X<sub>2</sub> <span>&#215;</span> (n - 1) + ... + X<sub>n</sub> <span>&#215;</span> (n - 1)</sup><span>&frasl;</span><sub>( n <span>&#215;</span> ( n + 1 )) / 2</sub></span> &nbsp; , ahol X = eszköz ára, n = periódus napok</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Mozgoatlag