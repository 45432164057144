import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Likviditas() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Likviditás - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a likviditás jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>


                {/* Likvidációs érték, Likviditási gyorsráta, Likiditási terv, Likviditási ráta */}

                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Likviditás</h1>
                            <p>Ezen az oldalon megismerheted a likviditás jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A likviditás azt fejezi ki, hogy mennyire lehet könnyen egy eszközt készpénzzé átalakítani.</p>
                            <p className="majonator">Ha egy eszközt könnyen készpénzre lehet váltani, akkor likvid eszközről beszélünk. Az alacsony likviditású eszközök abból a szempontból kockázatosabbak, hogy nehezebben értékesíthetők a váratlan helyzetekben, és így az eladási áruk alacsonyabb lehet, illetve egyéb kellemetlenségeket okozhat. A leglikvidebb eszköz maga a készpénz, míg a spektrum másik végén az ingatlanok és az értéktárgyak találhatók.</p>
                            <hr />
                            <h2>Likviditás fajtái</h2>
                            <p className="majonator azam">Piaci likviditás</p>
                            <p className="majonator">A piaci likviditás arra utal, hogy egy piac milyen mértékben teszi lehetővé az eszközök stabil árakon történő kereskedését. Ha egy piacon, például egy részvénytőzsdén, amit amúgy is nagyobb likviditás jellemez, nagy a <Link to='/fogalomtar/forgalom' className='dezord'>forgalom</Link>, akkor a vevő által <Link to='/fogalomtar/reszveny' className='dezord'>részvényenként</Link> kínált ár és az eladó által elfogadni kívánt ár közel lesz egymáshoz. Amikor a vételi és az eladási árfolyam közötti különbözet csökken, akkor a piac likvidebb, amikor pedig nő, a piac illikvidebbé válik. Ha egy olyan eset áll fenn, hogy egy tranzakciót szeretnénk végrehajtani egy eszközön, de nincsen kínálat, akkor likviditási problémáról beszélünk.</p>
                            <br />
                            <p className="majonator azam">Vállalati likviditás</p>
                            <p className="majonator">A vállalati likviditás azt méri, hogy egy <Link to='/fogalomtar/vallalat' className='dezord'>vállalat</Link> milyen könnyen tudja teljesíteni a pénzügyi kötelezettségeit a rendelkezésére álló likvid eszközökből. Egy vállalat likviditásának az elemzésekor azt vizsgáljuk, hogy a vállalat elegendő likvid eszközzel rendelkezik-e ahhoz, hogy a rövid távú kötelezettségeit fedezhesse a jövőben. A vállalati likviditás mérésére likviditási mutatókat használunk.</p>
                            <hr />
                            <h2>Vállalati likviditási mutatók</h2>
                            <p className="majonator azam">Current ratio</p>
                            <p className="majonator">A current ratio a forgóeszközöket méri a rövid lejáratú kötelezettségekhez. A mutató segítségével információt kapunk arról, hogy a vállalat forgóeszközei milyen mértékben fedezik a rövid lejáratú kötelezettségeket. 1-es current ratio alatt a vállalat rövid lejáratú kötelezettségei meghaladják a forgóeszközöket. Minél kisebb ez az érték, annál kockázatosabbnak mondható a befektetés.</p>
                            <p className="majonator">Current ratio = forgóeszközök / rövid lejáratú kötelezettségek</p>
                            <br />
                            <p className="majonator azam">Quick ratio</p>
                            <p className="majonator">A quick ratio a current ratio-nál szigorúbb, mivel a vállalat pénzállományát, gyorsan pénzzé alakítható eszközeit, rövid lejáratú befektetéseit és követeléseit méri a rövid lejáratú kötelezettségekhez. 1-es érték alatt a vállalat nem képes teljes mértékben fedezni a rövid lejáratú kötelezettségeit. Itt is elmondható, hogy minél kisebb a mutató értéke, annál kockázatosabb az eszköz, illetve szintén mind a kettőre vonatkozik, hogy a mutatók átlagos értéke befektetési szektoronként eltérő, ezért érdemes a hasonló vállalatok mutatóit összehasonlítani.</p>
                            <p className="majonator">Quick ratio = (készpénz + pénzeszköz-egyenértékesek + rövid lejáratú befektetések + követelések) / rövid lejáratú kötelezettségek</p>
                            <br />
                            <p className="majonator azam">Cash ratio</p>
                            <p className="majonator">A cash ratio még szigorúbb az előző kettőnél: a készpénz, illetve a pénzeszköz-egyenértékeseket összegét méri a rövid lejáratú kötelezettségekhez. A cash ratio a vállalat azon képességét értékeli, hogy a lehető legrosszabb forgatókönyv szerint a vállalat mennyire marad fizetőképes. A vállalatok a kockázatvállalási hajlandóságuk, vagyis a megcélzott hozamszint mértéke szerint biztosítanak likviditás készpénz formájában. A kiegyensúlyozott készpénzbeli likviditás egy konzervatívabb, biztonságosabb finanszírozású vállalatra utal.</p>
                            <p className="majonator">Cash ratio = (készpénz + pénzeszköz-egyenértékesek) / rövid lejáratú kötelezettségek</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Likviditas