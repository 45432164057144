import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Leanyvallalat() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Leányvállalat - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a leányvállalat jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>


                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Leányvállalat</h1>
                            <p>Ezen az oldalon megismerheted a leányvállalat jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A leányvállalat egy másik vállalat tulajdonában lévő vállalat.</p>
                            <p className="majonator">Azt a vállalatot, amihez a leányvállalat tartozik anyavállalatnak vagy holding vállalatnak nevezik. Az anyavállalatnak elegendő részesedése van a leányvállalatban ahhoz, hogy ellenőrzést gyakoroljon felette. Ez a részesedés amerikai vállalatok esetében 50% vagy annál nagyobb. A leányvállalatok pénzügyi adatait az anyavállalat pénzügyi kimutatásában tüntetik fel.</p>
                            <hr />
                            <h2>Működése</h2>
                            <p className="majonator">Az anyavállalatnak jelentős beleszólása lehet a leányvállalat életébe. Az anya-lánya kapcsolatok <Link to='/fogalomtar/vallalat' className='dezord'>vállalatonként</Link> eltérnek. Sok esetben a leányvállalat igazgatótanácsát olyanok képezik, akik az anyavállalattól mentek át. Ha egy anyavállalatnak külföldi leányvállalata van, akkor a leányvállalatnak annak az országnak a törvényeit kell követnie, ahol bejegyezték és működik. Egy vállalat leányvállalattá alakításához vagy egy leányvállalat eladásához nem szükséges a <Link to='/fogalomtar/reszveny' className='dezord'>részvényesek</Link> jóváhagyása.</p>
                            <p className="majonator">A leányvállalat független pénzügyi kimutatást készít, amit elküld az anyavállalat részére. Az anyavállalat összevonja a leányvállalatok pénzügyi adatait és egy egységes kimutatásban összegzi őket. &nbsp; | &nbsp; Például a Walt Disney-nek számos népszerű leányvállalata van: Pixar, Lucasfilm, Marvel, ABC, The History Channel stb.</p>
                            <p className="majonator">Egy vállalatnak kétféleképpen lehet leányvállalata: Egyrészt alapíthat egy új leányvállalatot, vagy megvásárolhat egy már működő vállalatot, és így leányvállalatává teheti. A megvásárolt vállalat továbbra is önálló jogi személy marad, de az anyavállalat irányítása alá kerül.</p>
                            <hr />
                            <h2>Előnyök és hátrányok</h2>
                            <p className="majonator">Az anyavállalat a leányvállalatokon keresztül olyan előnyökhöz juthat, mint az új technológiák és gyártási folyamatok, diverzifikáltabb portfólió, versenyelőny megszerzése, költséghatékonyság, illetve adózási és jogi előnyök.</p>
                            <p className="majonator">Ami hátránynak számít az az, hogy az anyavállalatnak nehezebb dolga lesz a könyveléssel, mivel a leányvállalat pénzügyi kimutatásait bele kell számítania a saját kimutatásába. Továbbá az anyavállalat felelősséggel tartozik a leányvállalat által elkövetett visszaélésekért és csalásokért.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Leanyvallalat