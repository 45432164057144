import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function ROA() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>ROA - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a ROA jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>ROA</h1>
                            <p>Ezen az oldalon megismerheted a ROA jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A ROA (Return on Assets) egy olyan mutató, amely megmutatja, hogy egy vállalat mekkora nyereséget termel az eszközeiből.</p>
                            <p className="majonator">A ROA segítségével meghatározható, hogy egy vállalat milyen hatékonyan használja fel eszközeit a nyereségtermelésre. A mérőszámot általában százalékban fejezik ki. Minél magasabb a ROA százalék, annál eredményesebben kezeli az eszközeit a vállalat. Vállalatértékeléskor mindig az azonos iparágon belüli vállalatok ROA értékét érdemes összehasonlítani, mivel azok hasonló eszközállománnyal rendelkeznek.</p>
                            <hr />
                            <h2>Kiszámítása</h2>
                            <p className="majonator">ROA = (adózott eredmény / eszközállomány) <span>&#215;</span> 100</p>
                            <p className="majonator">Van ,,A'' és ,,B” vállalat. ,,A” vállalat eszközállománya 10 millió dollár, míg a másiké 100 millió. Ha ,,A'' vállalat 1 millió dollár adózott eredményt érne el egy adott időszakban, ,,B” pedig 8 milliót, akkor könnyen kiszámolhatjuk, hogy az ,,A” vállalat hatékonyabban kezeli az eszközeit. ,,A'': (1 / 10) <span>&#215;</span> 100 = 10% ROA, ,,B”: (8/100) <span>&#215;</span> 100 = 8% ROA</p>
                            <hr />
                            <h2>Értelmezése</h2>
                            <p className="majonator">A ROA megmutatja, hogy a <Link to='/fogalomtar/vallalat' className='dezord'>vállalat</Link> mennyire hatékonyan termel nyereséget az eszközei felhasználásával. A magas ROA hatékony eszközhasználatot jelent. A vállalatok ROA értékei iparágtól függően jelentősen eltérhetnek, ezért érdemes az azonos ágazatokon belüli társaságok ROA mutatóit összehasonlítani. A ROA mutató önmagában nem ad kellő információt egy vállalat nyereségtermelésének hatékonyságáról. Fontos, hogy részletes elemzést végezzünk, és csak egy szempont legyen a sok közül a ROA.</p>
                            <hr />
                            <h2>ROA variációk</h2>
                            <p className="majonatora">ROA = Üzemi eredmény <span>&#215;</span> (1 - adó) / eszközállomány</p>
                            <p className="majonatora">Ez a képlet az üzemi eredményt használja, nem pedig a nettó eredményt. Az üzemi eredmény az az összeg, amelyet a vállalat a tevékenységi köréből származó bevételtermeléséből generál, kamatok és adók előtt. Ez teszi a vállalatot összehasonlíthatóbbá az iparágak között, mivel kiküszöböli az egyéb finanszírozási döntések hatását.</p>
                            <br />
                            <p className="majonatora">ROA = Nettó eredmény + (kamatkiadás <span>&#215;</span> (1 - adó)) / eszközállomány</p>
                            <p className="majonatora">Ez a képlet beleszámítja az adózás utáni kamatköltségeket a számlálóba. Ha így számítjuk a ROA-t, akkor belevesszük a számításainkba azt, hogy minden vállalat különböző hitelállománnyal rendelkezik. Így ebben a számításban magasabb kamatkiadások kisebb eszközállományon való megtérülést, vagyis ROA-t eredményeznek.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default ROA