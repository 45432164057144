import React from 'react'
import './cards.css';
import { Link } from 'react-router-dom';

function Cards() {
    return (
        <>
            <div className='carddiv' id='szolgaltatasaink'>
                <div className='cardaltpad'>
                    <div className='cardalt aa01'>
                        <div className='right'>
                            <img src="/imgvideo/cumo.svg" alt="befektetési alapszabályok" className='sidepic' />
                        </div>
                        <div className='left'>
                            <h2>Roadmap</h2>
                            <p>A roadmap egy olyan tanulási terv, amin végighaladva elsajátíthatod a befektetéshez szükséges alapvető ismereteket, így közelebb kerülve ahhoz, hogy te is befektetővé válhass.</p>
                            <Link className='remove561188311'><button className='btn btn0 success' onClick={() => { window.location.href = '/roadmap'; }}>Elolvasom</button></Link>
                        </div>
                    </div>
                </div>
                <div className='cardaltpad'>
                    <div className='cardalt aa02'>
                        <div className='right'>
                            <img src="/imgvideo/secondP.svg" alt="fogalomtár" className='sidepic' />
                        </div>
                        <div className='left'>
                            <h2>Fogalomtár</h2>
                            <p>A fogalomtár egy olyan fogalmakat, kifejezéseket és mutatókat összegyűjtő és magyarázó felület, melyek megtanulásával megismerheted a befektetési világ nyelvezetét.</p>
                            <Link className='remove561188311'><button className='btn btn0 success' onClick={() => { window.location.href = '/fogalomtar'; }}>Elolvasom</button></Link>
                        </div>
                    </div>
                </div>
                <div className='cardaltpad'>
                    <div className='cardalt aa01'>
                        <div className='right'>
                            <img src="/imgvideo/thirdP.svg" alt="tanfolyam" className='sidepic' />
                        </div>
                        <div className='left'>
                            <h2>Befektetési alapszabályok</h2>
                            <p>A befektetési alapszabályok megfogadásával könnyebben elérheted a pénzügyi céljaidat és jelentős előnyt szerezhetsz magadnak a befektetésekben.</p>
                            <Link className='remove561188311'><button className='btn btn0 success' onClick={() => { window.location.href = '/alapszabalyok'; }}>Elolvasom</button></Link>
                        </div>
                    </div>
                </div>
                <div className='cardaltpad'>
                    <div className='cardalt aa03'>
                        <div className='right'>
                            <img src="/imgvideo/tanfolyam.webp" alt="befektetési alapszabályok" className='sidepic' />
                        </div>
                        <div className='left'>
                            <h2>Tanfolyam</h2>
                            <p>A részvénybefektetési tanfolyamunk segítségével megtanulhatsz egy vállalatot tetőtől talpig elemezni, hogy megtalálhasd a számodra legmegfelelőbb részvényeket a portfóliódba. (Fejlesztés alatt)</p>
                            <Link className='remove561188311'><button className='btn btn0 success' onClick={() => { window.location.href = '/tanfolyam'; }}>Elolvasom</button></Link>
                        </div>
                    </div>
                </div>
                {/* 
                <div className='cardaltpad'>
                    <div className='cardalt aa04'>
                        <div className='right'>
                            <img src="/imgvideo/fourthP.avif" alt="könyvek" className='sidepic' />
                        </div>
                        <div className='left'>
                            <h2>Könyvek</h2>
                            <p>Összegyűjtöttünk neked számos befektetéssel kapcsolatos könyvet </p>
                            <Link className='remove561188311'><button className='btn btn0 success' onClick={() => { window.location.href = '/konyvek'; }}>Elolvasom</button></Link>
                        </div>
                    </div>
                </div>
                */}

            </div>
            <footer className='footer-c-a'>
                <div className="container-a">
                    <div className="row-a">
                        <div className="footer-col-a">
                            <h3>befektetésközpont</h3>
                            <ul>
                                <li><Link className='href-a' to='/impresszum'>Impresszum</Link></li>
                                <li><Link className='href-a' to='/adatkezelesi-tajekoztato'>Adatkezelési tájékoztató</Link></li>
                            </ul>
                        </div>
                        <div className="footer-col-a">
                            <h3>segítség</h3>
                            <ul>
                                <li><Link className="href-a" to='/gyik'>GYIK</Link></li>
                                <li><Link className="href-a" to='/jogi-nyilatkozat'>Jogi nyilatkozat</Link></li>
                            </ul>
                        </div>
                        <div className="footer-col-a">
                            <h3>Kapcsolat</h3>
                            <ul>
                                <li><Link className="href-a" to='https://discord.gg/7YyQckXAxY'>Discord közösség</Link></li>
                                <li><Link className="href-a" to='/kapcsolat'>Kapcsolatfelvétel</Link></li>
                            </ul>
                        </div>
                        <div className="footer-col-a">
                            <h3>Kövess minket!</h3>
                            <div class="social-links-a">
                                <div>
                                    <Link className="href-a" to='https://www.instagram.com/befekteteskozpont/' aria-label='instagram'><i class="fab fa-instagram"></i></Link>
                                    <Link className="href-a" to='https://www.facebook.com/befekteteskozpont/' aria-label='facebook'><i class="fab fa-facebook-f"></i></Link>
                                </div>
                                <div>
                                    <Link className="href-a" to='https://twitter.com/befkozpont' aria-label='twitter'><i class="fab fa-x-twitter"></i></Link>
                                    <Link className="href-a" to='https://discord.gg/7YyQckXAxY' aria-label='discord'><i class="fab fa-discord"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Cards


/*
<div className='carddiv'>
                <div className='cardalt aa01'>
                    <div>
                        <h2>Befektetési alapszabályok</h2>
                        <Link to='/alapszabalyok'><button className='btn success'>Elolvasom</button></Link>
                    </div>
                    <div>
                        <p>Az alapszabályok elsajátítása segít a befektetőknek, hogy jobban megértsék a befektetési folyamatokat, és hogy mely tényezők befolyásolják a befektetési döntéseket.</p>
                    </div>
                </div>
                <div className='cardalt aa02'>
                    <div>
                        <h2>Fogalomtár</h2>
                        <Link to='/fogalomtar'><button className='btn success'>Elolvasom</button></Link>
                    </div>
                    <div>
                        <p>A fogalomtár segítéségvel számos pénzügyi fogalmat megtanulhatsz, melyek elengedhetetlenek a befektetői pályát válaszók számára.</p>
                    </div>
                </div>
                <div className='cardalt aa03'>
                    <div>
                        <h2>Tanfolyam</h2>
                        <Link to='/tanfolyam'><button className='btn success'>Elolvasom</button></Link>
                    </div>
                    <div>
                        <p>Az ingyenes befektetési tanfolyamunkkal létrehozhatod a biztos alapot annak érdekében, hogy egyszer te is kiváló befektetővé válhass.</p>
                    </div>
                </div>
                <div className='cardalt aa04'>
                    <div>
                        <h2>Könyvek</h2>
                        <Link to='/konyvek'><button className='btn success'>Elolvasom</button></Link>
                    </div>
                    <div>
                        <p>Összegyűjtöttük neked a lehető legtöbb pénzügyi könyvet, ami a hasznodra vállhat, és részletes leírást nyújtunk arról, hogy miben segít az adott könyv.</p>
                    </div>
                </div>
            </div>
*/