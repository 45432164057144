import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Befektetesialap() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Befektetési alap - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted az befektetési alap jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Befektetési alap</h1>
                            <p>Ezen az oldalon megismerheted az befektetési alap jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A befektetési alap egy olyan eszköz, amely összegyűjti a befektetők tőkéjét, hogy befektesse pénzügyi eszközök portfólióján keresztül.</p>
                            <p className="majonator">A befektetési alapok döntő szerepet játszanak a személyes megtakarítások felhalmozásának megkönnyítésében, akár nagyobb befektetésekben vagy a nyugdíjba vonuláshoz. Azért is fontosak, mert az intézményi és személyes megtakarításokat hitelként elérhetővé teszik a növekedéshez és a munkahelyteremtéshez hozzájáruló vállalatok és projektek számára.</p>
                            <hr />
                            <h2>Működése</h2>
                            <p className="majonator">A befektetők befektetési jegyek megvásárlásán keresztül szereznek részesedést a befektetési alapban. A befektetett tőkét az alapkezelő szigorú szabályokat követve allokálja. Ezekről a szabályokról a befektetési alapok tájékoztatóiban találsz információt. Azokat a befektetési alapokat, amik az abszolút hozam elérése törekszenek <Link to='/fogalomtar/hedge-fund' className='dezord'>hedge fundoknak</Link> nevezzük.</p>
                            <p className="majonator">Aki befektetési alapba fektet, annak alapkezelői díjat kell fizetnie, ami alaponként eltér. A díj mértékéről szintén fontos tájékozódni befektetés előtt. Az alapok befektetési jegyei nem biztosítanak semmilyen szavazati jogot, ellenben például a <Link to='/fogalomtar/reszveny' className='dezord'>részvényekkel</Link>.</p>
                            <p className="majonator">Befektetési alapok között vannak aktív, illetve passzív alapok. Az aktív alapoknak szükségük van vagyonkezelőkre, akik elvégzik az elemzést, majd szerkesztik a befektetési alap <Link to='/fogalomtar/portfolio' className='dezord'>portfóliójának</Link> összetételét. A passzív alapok egy bizonyos eszközkosarat vagy <Link to='/fogalomtar/tozsdeindex' className='dezord'>tőzsdeindexet</Link> követnek, ami a piacot vagy a piac egy szegmensét reprezentálja. Az, hogy te melyik lehetőséget választod, attól függ, hogy le szeretnéd-e győzni a piacot, vagy inkább haladsz vele együtt.</p>
                            <hr />
                            <h2>Fajtái</h2>
                            <p className="majonator azam">Nyílt- és zártvégű alapok</p>
                            <p className="majonatora">Nyíltvégű alapok esetében bármelyik kereskedési napon lehetőség van adni-venni a befektetési jegyeket, míg a zárvégű alapoknál csak a jegyzési időszakban lehet befektetni és csak a futamidő végén van lehetőség eladni. Zártvégű alapok esetében lehetőség van megszabadulni a befektetési jegytől, ha tőzsdén keresztül értékesítjük pozíciónkat.</p>
                            <br />
                            <p className="majonator azam">Részvényalap</p>
                            <p className="majonatora">Ez a befektetési alap főként részvényekbe fektet, és a részvények árfolyamának emelkedése, illetve az osztalék növeli a NAV-ot. A részvényalapok azért előnyösek, mert kis befektetett összeg mellett is <Link to='/fogalomtar/diverzifikacio' className='dezord'>diverzifikálni</Link> tudjuk a részvényportfóliónk. Mint minden alapnak, a részvényalapoknak is rögzítve van, hogy mennyi a minimális arány, amit egy befektetési eszközből tartaniuk kell.</p>
                            <br />
                            <p className="majonator azam">Kötvényalap</p>
                            <p className="majonatora">A kötvényalapok kötvényekbe fektetnek. Ha az alap portfóliójában szereplő kötvények futamideje meghaladja a 3 évet, akkor hosszú kötvényalapnak nevezzük, míg ha a futamidő 3 évnél rövidebb, akkor rövid kötvényalapnak. Ennek abból a szempontból van jelentősége, hogy minél hosszabb egy kötvény <Link to='/fogalomtar/duracio' className='dezord'>durációja</Link>, annál érzékenyebb az árfolyam a hozamok változására.</p>
                            <br />
                            <p className="majonator azam">Ingatlanalap</p>
                            <p className="majonatora">Ingatlanpiaci eszközökbe, például ingatlanokba vagy ingatlanvállalatok részvényeibe fektet. Közvetlen ingatlanalapoknak nevezzük azokat az alapokat, amelyek ingatlanokat vásárolnak és üzemeltetnek. A közvetett ingatlanalap ingatlanvállalatok részvényeit és más alapok befektetési jegyeit vásárolja meg.</p>
                            <br />
                            <p className="majonator azam">Pénzpiaci alap</p>
                            <p className="majonatora">Rövid távú befektetésekre alkalmas alap, amely leginkább alacsony kockázattal és stabil hozammal rendelkezik. Portfóliójában általában <Link to='/fogalomtar/bankbetet' className='dezord'>bankbetétek</Link> és rövid lejáratú <Link to='/fogalomtar/allamkotveny' className='dezord'>állampapírok</Link> találhatók.</p>
                            <hr />
                            <h2>Befektetési alap választás</h2>
                            <p className="majonator">Amikor befektetési alapot választunk, vizsgáljuk meg, hogy az alap befektetési stratégiája megfelel-e a mi céljainknak és kockázatvállalási hajlandóságunknak. Végezzünk elemzést az alap portfólióját alkotó eszközökről és derítsünk ki minden információt az alapkezelőről és az alapról. A befektetési alap értékelésénél vegyük figyelembe a <Link to='/fogalomtar/benchmark' className='dezord'>benchmarkot</Link> és a <Link to='https://awgmain.morningstar.com/webhelp/glossary_definitions/mutual_fund/mfglossary_MorningstarRating.html' className='dezord'>morningstar-besorolást</Link>.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Befektetesialap