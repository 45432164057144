import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Shortpozicio() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Short pozíció - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a short pozíció jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Short pozíció</h1>
                            <p>Ezen az oldalon megismerheted a short pozíció jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A short pozíció egy olyan ügylet, amely arra spekulál, hogy az eszköz ára csökkenni fog.</p>
                            <p className="majonator">Short pozíció nyitása esetén akkor érünk el árfolyamnyereséget, ha az eszköz árfolyama csökken. A kereskedő azzal a céllal ad el egy eszközt, hogy később olcsóbban visszavehesse azt. Short pozíciót a brókertől kölcsönkapott eszköz használatával lehet indítani. A short pozíció lezárására úgy is tekinthetünk, mint egy azonos összegű vételi pozíció nyitására. A short pozíció ellentéte a <Link to='/fogalomtar/long-pozicio' className='dezord'>long pozíció</Link>.</p>
                            <hr />
                            <h2>Működése</h2>
                            <p className="majonator">Short pozíció nyitása esetén a brókertől kölcsönbe kapott pénzzel megnöveljük a letétként elhelyezett összeget, így nagyobb piaci pozíciót nyitva. A kereskedőnek a befektetett tőke teljes értékével nem kell rendelkeznie, csak egy bizonyos összeget kell fedezetként elhelyeznie. Ez a <Link to='/fogalomtar/tokeattetel' className='dezord'>tőkeáttétel</Link> felerősíti mind a nyereségeket, mind a veszteségeket.</p>
                            <p className="majonator">Amennyiben a részvények visszavásárlási ára meghaladja az eredeti eladási árat, akkor veszteséget könyvelünk el. Azonban, ha a visszavásárlási ár alacsonyabb a korábbi eladási árnál, akkor nyereséget realizálhatunk. Az eladási és a vételi árfolyam különbsége határozza meg a pozíció hozamát.</p>
                            <p className="majonator">Az eszköz kölcsönzése a kereskedő számára díjjal jár. Az esetleges veszteségek okán fedezet biztosítása szükséges. Amikor egy kereskedő árfolyamvesztesége következtében a számláján található tőke és a kölcsönzött pénz együttes összege a bróker által meghatározott minimális <Link to='/fogalomtar/margin' className='dezord'>marginkövetelmény</Link> alá esik, a bróker margin call-t hajthat végre. Kedvezőbb esetben a befektető egy figyelmeztetést kap arról, hogy pótolnia kell a tőkehiányát. Rosszabb esetben a bróker azonnal zárolja a pozíciót és a befektető elveszti a pénzét.</p>
                            <hr />
                            <h2>Short kockázata</h2>
                            <p className="majonator">Egy részvény shortolása sokkal kockázatosabb, mint a hagyományos vétel. Fontos megérteni, hogy a kereskedőnek short pozíció nyitásakor véges lehetőségük van a nyereségszerzésre, és végtelen a veszteségek elszenvedésére. Vétel esetén maximum azt az összeget veszíthetjük el, amit a részvénybe befektettünk, míg a short esetében akár a befektetett összeg sokszorosától is elbúcsúzhatunk.</p>
                            <p className="majonator">A short squeeze egy shortolással kapcsolatos fontos fogalom. Ennek a jelenségnek a bekövetkezésekor egy olyan befektetési eszköznek, aminek jelentős számú shortolója van, az árfolyama hirtelen nagyot emelkedik. A kereskedők az emelkedés hatására zárolják a short pozícióikat, így még inkább bebiztosítva az áremelkedést.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Shortpozicio