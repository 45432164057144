import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Franchise() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Franchise - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a franchise szó jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Franchise</h1>
                            <p>Ezen az oldalon megismerheted a franchise szó jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A franchise egy üzleti modell, amely a vállalkozók számára lehetővé teszi, hogy egy vállalat márkaneve és adottságai alatt folytassanak üzleti folyamatokat.</p>
                            <p className="majonator">A franchise egy olyan szerződés, amely az átvevő számára hozzáférést biztosít a rendszergazda üzleti folyamataihoz és védjegyeihez, ezáltal lehetővé téve az átvevő számára, hogy a vállalat márkaneve alatt terméket vagy szolgáltatást értékesítsen. A franchise szerződésért cserébe az átvevő általában indulási díjat és éves licencdíjat fizet a rendszergazdának. A franchiseban résztvevő vállalkozók mind jogilag és pénzügyileg függetlenek egymástól. Egy adott franchise alá tartozó vállalkozók mind ugyanazt a terméket vagy szolgáltatást értékesítik. Nagy franchise rendszerrel működnek olyan vállalatok, mint a McDonald's vagy a Pizza Hut.</p>
                            <hr />
                            <h2>Működése</h2>
                            <p className="majonator">Franchise hálózatok leginkább sikeres cégekből indulnak. Hozzájuk csatlakoznak a vállalkozók, akik felveszik a <Link to='/fogalomtar/vallalat' className='dezord'>vállalat</Link> márkanevét és a termékeinek és szolgáltatásainak forgalmazásába fognak. Ennek a modellnek az a nagy előnye, hogy a mind a két félnek megéri egy ilyen szerződés aláírása. Franchise esetén megkülönböztetünk rendszergazdát és átvevőt. A két fél franchise szerződést köt, így az átvevő jogosulttá válik arra, hogy a vállalat neve alatt végezzen bizonyos tevékenységeket. Általában az átvevőknek különböző díjakat kell fizetni a rendszergazdának a franchise-ért cserébe. Az átvevő a rendszergazda folyamatos üzleti támogatása alatt áll.</p>
                            <hr />
                            <h2>Franchise előnyei</h2>
                            <p className="majonator">A franchise rendszer előnyös lehet mindkét fél számára. Az átvevők már egy meglévő, bevált üzleti modellt és márkaismertséget kapnak, ami csökkenti a kezdeti kockázatokat és a piacra való behatolási akadályokat. A rendszergazda pedig gyorsabb terjeszkedést érhet el, növelheti a márkája jelenlétét, és a franchise partnerek által befizetett licencdíjakból is bevételre tehet szert.</p>
                            <hr />
                            <h2>Franchise hátrányai</h2>
                            <p className="majonator">A franchise rendszer általában szigorú irányelveket és előírásokat tartalmaz a termék vagy szolgáltatás kinézetét, minőségét és működését illetően. Ez korlátozhatja a vállalkozó kreatív szabadságát és lehetőségét az egyedi ötletek megvalósítására. Ezen kívül a különböző átvevő által fizetendő díjak is problémát jelenthetnek.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Franchise