import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function IPO() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>IPO - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted az IPO jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>IPO</h1>
                            <p>Ezen az oldalon megismerheted az IPO jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">Az IPO (Initial Public Offering) az a folyamat amikor egy magánvállalat részvényeit először kínálják fel a nyilvánosságnak egy új részvénykibocsátás keretében.</p>
                            <p className="majonator">A tőzsdei bevezetés lehetővé teszi egy vállalat számára, hogy tőkét vonjon be a befektetőkön keresztül. Az IPO során a vállalat egy részét értékesíti a nyilvánosság számára részvények formájában, amibe lehetősége van befektetni az embereknek. A befektetőknek lehetőségük adódik ezeket a részvényeket megvásárolni még a tőzsdei kereskedés kezdete előtt. A részvény kibocsátási árát leggyakrabban a diszkontált cash flow értékelési módszer segítségével szokták meghatározni.</p>
                            <hr />
                            <h2>IPO előnyei</h2>
                            <p className="majonator">Az IPO segítségével a vállaltoknak lehetőségük adódik bővíteni a kapacitásukat, új piacokra lépniük és egyszerűbben finanszírozni a működésüket. A <Link to='/fogalomtar/tozsde' className='dezord'>tőzsdére</Link> lépés bővíti a cég hitelfelvételi lehetőségeit, így növekedési tervei könnyebben megvalósíthatóvá válhatnak. Az IPO továbbá növeli a <Link to='/fogalomtar/vallalat' className='dezord'>vállalat</Link> népszerűségét és így a befektetők köre is bővül.</p>
                            <p className="majonator">Mivel a tőzsdére bevezetett vállalat eredményei nyilvánosak, a befektetők számára is könnyebb jó döntéseket hozni. Az IPO során a befektetők lehetőséget kapnak arra, hogy korai stádiumban részesedést szerezzenek egy vállalatban, amelyben növekedési potenciált látnak. Ez lehetőséget nyújt a kedvező áron történő részesedésszerzésre, amely hosszú távon nagyobb hozamot eredményezhet. Ebben az esetben különösen fontos számolni a kockázattal, mivel ilyenkor a <Link to='/fogalomtar/reszveny' className='dezord'>részvény</Link> ára kiszámíthatatlan és volatilis.</p>
                            <hr />
                            <h2>IPO hátrányai</h2>
                            <p className="majonator">Az IPO adminisztrációs feladatokat ró a vállalatra, ami további költségeket és erőforrásokat igényel. Továbbá a tulajdonosi struktúra is megváltozhat, ami befolyásolhatja a cég irányítását és a döntéshozatali folyamatokat. Ez nem feltétlenül jelent rosszat, de könnyen balul sülhet el. Kezdetekben a vállalat részvényeinek értéke kiszámíthatatlanul változhat, ami növelheti a befektetés kockázatát.</p>
                            <hr />
                            <h2>IPO befektetés</h2>
                            <p className="majonator">Érdemes megvizsgálni olyan szempontokat, mint például, hogy mi a célja a vállalatnak a tőzsdére való belépéssel. Csak simán terjeszkedni akar a vállalat, vagy esetleg jó áron ki szeretne szállni a tulajdonos. Fontos kellő információval rendelkezni a vállalat vezetőjének személyéről, üzleti hátteréről. Mint minden befektetésnél, itt is elengedhetetlen a fundamentális elemzés, a vállalat valós értékének meghatározása. IPO-n való részvétel csak olyan <Link to='/fogalomtar/broker' className='dezord'>brókercégeken</Link> keresztül lehetséges, amiknek közvetlen tőzsdei hozzáférésük van. Ilyen például a Fidelity Investments vagy az Interactive Brokers.</p>
                            <p className="majonator">Egy IPO során előfordulhat, hogy túljegyzés alakul ki. Ez azt jelenti, hogy nem minden befektető fog tudni hozzájutni kibocsájtáskor a részvényekhez.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default IPO