import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Duracio() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Duráció - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a duráció jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Duráció</h1>
                            <p>Ezen az oldalon megismerheted a duráció jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A duráció azt az átlagidőt fejezi ki, amibe telik, hogy visszakapjuk az értékpapír árát a kifizetésein keresztül.</p>
                            <p className="majonator">Azt mutatja meg, hogy a kifizetések jelenértékének nagyságával súlyozva átlagosan mennyi idő van hátra a kötvény megtérüléséig. A durációt a kötvények kamatérzékenységének mérésére szokták használni, vagyis annak a kockázatnak a mérésére, amit a piaci kamat változása gyakorol a kötvény árfolyamára.</p>
                            <hr />
                            <h2>Kamatérzékenységi mutató</h2>
                            <p className="majonator">Minél rövidebb egy <Link to='/fogalomtar/kotveny' className='dezord'>kötvény</Link> átlagos hátralévő futamideje, vagyis durációja, annál kevésbé van kitéve a kamatváltozások által okozott volatilitásnak. Ebből következik, hogy a rövidebb futamidejű értékpapír kockázata kisebb. Például, ha 1 százalékkal nő a piaci kamat, akkor a 2 éves durációjú kötvény értéke közelítőleg 2 százalékkal, míg az 5 éves durációjú kötvényé 5 százalékkal csökken. Ha egy kötvény kibocsátása után esnek a piaci hozamok és az újabb kötvényeket alacsonyabb kamat mellett bocsájtják ki, akkor a korábbi kötvények felértékelődnek és az árfolyamuk emelkedni fog. Ez fordítva is így van.</p>
                            <p className="majonator">Minél hosszabb a kötvény lejárati ideje, annál hosszabb a durációja, illetve minél nagyobb a kamatfizetés mértéke, annál kisebb a durációja.</p>
                            <hr />
                            <h2>Kiszámítása</h2>
                            <br />
                            <img src="/imgvideo/duraciokep.svg" alt="duracio" width='250px' />
                            <br />
                            <p className="majonator">D a duráció jele. P(i) az i-edik kamatkifizetés jelenértéke. t(i) a kifizetésig hátralévő napok száma. V a kötvény ára. w(i) jelöli az adott kifizetés súlyát.</p>
                            <hr />
                            <h2>Durációra alapuló befektetési stratégia</h2>
                            <p className="majonator">Durációra alapuló befektetési stratégiának hívjuk, ha csak hosszú, vagy csak rövid durációjú kötvényekbe fektetünk. Ez alapján, ha a piacon kamatláb csökkenés várható, akkor csak hosszú durációjú kötvények vásárolandók. Ezt a stratégiát recesszió során lehet érdemes követni. Ha pedig a kamatláb növekedésére számítunk vagy bizonytalanok vagyunk a kamatlábakat illetően akkor rövid durációjú kötvények állnak a célkeresztben, ugyanis ilyenkor a tőke kötvények helyett inkább más instrumentumokba áramlik.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Duracio