import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Medvepiac() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Medvepiac - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a medvepiac jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Medvepiac</h1>
                            <p>Ezen az oldalon megismerheted a medvepiac jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A medvepiac az a részvénypiaci tendencia, amely során az árak tartósan csökkennek.</p>
                            <p className="majonator">Medvepiac akkor fordul elő, amikor az adott piac árai több mint 20%-kal csökkennek. A medvepiac hossza néhány hónaptól akár több évtizedig is eltarthat, bár az utóbbi a mai gazdaságra már nem jellemző. Negatív befektetői hangulat, romló gazdasági kilátások vagy akár recesszió kíséri. A medvepiac ellentéte a bikapiac.</p>
                            <hr />
                            <h2>Működése</h2>
                            <p className="majonator">A medvepiac során az árfolyamok csökkenő tendenciát mutatnak, hosszabb ideig jelentős esésen mennek keresztül. A medvepiac kialakulásának rengeteg okozója lehet, ilyen ok lehet például a magas <Link to='/fogalomtar/inflacio' className='dezord'>infláció</Link>, gyenge gazdaság, kamatlábak túlzott emelése, politikai probléma, természeti katasztrófa, <Link to='/fogalomtar/tozsde' className='dezord'>tőzsdei</Link> buborék stb. A kiváltó okok hatására a befektetői tömeg az eszközeik eladásába, <Link to='/fogalomtar/portfolio' className='dezord'>portfólióik</Link> kicserélésébe kezdhetnek, hogy elkerüljék a veszteséget.</p>
                            <hr />
                            <h2>Bikapiac és medvepiac</h2>
                            <p className="majonator">A bikapiac és a medvepiac egymás ellentétei. Vagyis amíg medvepiac alatt az árak tartósan csökkennek, addig bikapiac esetén tartósan növekednek. A bikapiacok hosszabb ideig tartanak, mint a medvepiacok. Elmondható, hogy medvepiac alatt az árfolyam mozgása sokkal kiszámíthatatlanabb és <Link to='/fogalomtar/volatilitas' className='dezord'>volatilisebb</Link>. Ebből fakadóan a kockázat is sokkal magasabb ez idő alatt.</p>
                            <hr />
                            <h2>Medvepiac VS Recesszió</h2>
                            <p className="majonator">A <Link to='/fogalomtar/recesszio' className='dezord'>recesszió</Link> a gazdaság jelentős visszaesése, amely legalább két egymást követő negyedévben zajlik. A recesszió alatt konkrétan a piac drasztikus esésére gondolunk, míg a medvepiac egy időszakot jelöl. A recesszió hosszát az előző bikapiac csúcspontjától a visszaesés mélypontjáig mérik. Megkülönböztetünk recesszió nélküli és recesszióval járó medvepiacot.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Medvepiac