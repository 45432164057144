import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';


function Alapokalapja() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Alapok alapja - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted az alapok alapja kifejezés jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Alapok alapja</h1>
                            <p>Ezen az oldalon megismerheted az alapok alapja kifejezés jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">Az alapok alapja (FOF) olyan befektetési alap, amely más befektetési alapok befektetési jegyeit vásárolja meg.</p>
                            <p className="majonator">Az ilyen alap nem részvényekből, kötvényekből és egyéb instrumentumokból áll, hanem más <Link to='/fogalomtar/befektetesi-alap' className='dezord'>befektetési alapokból</Link>. Az alapok alapja stratégiájának a célja a széles körű <Link to='/fogalomtar/diverzifikacio' className='dezord'>diverzifikáció</Link> útján a minimális kockázat elérése. Általában magasabb a költséghányaduk, mint a hagyományos befektetési alapoknak. Az alapok alapja befektetési alap előnyös lehet olyan befektetők számára, akik a lehető legkevesebb kockázatot szeretnék vállalni.</p>
                            <hr />
                            <h2>Előnyei</h2>
                            <p className="majonator">Az alapok alapja lehetővé teszi a korlátozott tőkével rendelkező befektetők számára, hogy egy nagymértékben diverzifikált portfólióba fektessenek be. Továbbá lehetőséget ad arra, hogy a befektetők egyszerűen hozzáférjenek a piac azon szegmenseihez, amelyekhez csak nehezebben vagy egyáltalán nem férhetnének hozzá.</p>
                            <p className="majonator">Ezek az alapok a diverzifikációnak köszönhetően nagyon alacsony kockázattal járó befektetésnek számítanak. Abból a szempontból is biztonságosnak tekinthetőek, hogy az ilyen alapok, és az általuk magukba foglalt alapok is jelentős ellenőrzés alatt állnak és szigorú szabályok vonatkoznak rájuk.</p>
                            <hr />
                            <h2>Hátrányai</h2>
                            <p className="majonator">Amikor egy normális befektetési alapba fektetünk be, akkor díjat kell fizetnünk az alapkezelőnek. Ha alapok alapjába fektetünk, akkor ez a díj megsokszorozódik és hozzáadódik az a díj is, amit az alapok alapja alapkezelőjének fizetünk. Ez azért probléma, mert rendkívül költségessé válhat a kényelem, amit az alapok alapja biztosít nekünk. Tedd fel magadnak a kérdést, hogy megéri-e neked csak azért fizetni egy alapkezelőnek, hogy helyetted vásároljon be befektetési alapokból.</p>
                            <hr />
                            <h2>Tudnivalók</h2>
                            <p className="majonator">Az alapok alapja nem túl gyakori, viszont számos olyan befektetési alappal lehet találkozni, ami a többi instrumentum mellett tartalmaz egy, vagy néhány másik alap befektetési jegyét. Azt nevezzük alapok alapjának, ami kizárólag befektetési alapokba fektet be. Ha ezeknek az alapoknak a teljesítményét szeretnénk mérni, akkor használhatjuk a <Link to='https://portal.barclayhedge.com/cgi-bin/indices/displayHfIndex.cgi?indexCat=Barclay-Hedge-Fund-Indices&indexName=Fund-of-Funds-Index' className='dezord'>Barclay FOF indexet</Link>, ami egy olyan index, ami az összes alapok alapja befektetési alap átlaghozamát méri.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Alapokalapja