import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Spekulacio() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Spekuláció - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a spekuláció jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Spekuláció</h1>
                            <p>Ezen az oldalon megismerheted a spekuláció jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A spekuláció egy olyan befektetési stratégia, amelyben a befektető egy eszköz árának jövőbeli változására fogad, és arra alapozva fektet be abba.</p>
                            <p className="majonator">A spekuláció olyan pénzügyi tranzakciót jelent, amely jelentős értékvesztés kockázatával jár, ugyanakkor jelentős nyereségre is szert lehet vele tenni. A spekuláns gyakorlatilag fogadást köt arra, hogy egy adott instrumentum árfolyama melyik irányba fog elmozdulni. Spekulációkor az eszköz árfolyama van a középpontban és a valódi érték nincs figyelembe véve. A spekuláns jellemzően inkább arra törekszik, hogy az adott eszköz piaci árának rövid távú változásából érjen el nyereséget, mintsem hosszú távú, értékalapú befektetésen elért eredményt érjen el. A spekulánsok csökkentik a vételi és az eladási ár közötti különbséget, hozzájárulnak a folyamatos piaci likviditáshoz.</p>
                            <hr />
                            <h2>Befektető VS Spekuláns</h2>
                            <p className="majonator">Benjamin Graham, akit a legtöbben máig az egyik, ha nem a legnagyobb befektetőnek tartanak így különböztette meg a befektetést a spekulációtól: A befektetők az érték elfogadott szabályai alapján ítélik meg a piaci árat, míg a spekulánsok piaci árra alapozzák az értékre vonatkozó szabályaikat. A befektetés mélyreható fundamentális elemzés alapján a befektetett tőke biztonságát és megfelelő hozamát ígéri. Az ezektől különb műveleteket spekulatívnak tekintjük.</p>
                            <p className="majonator">A nyílt spekuláció nem tisztességtelen, de a legtöbb ember számára a zsebet sem gyarapítja. A legtöbb esetben nincs értelme spekulálni. Semmiképpen nincs akkor, ha azt gondoljuk, hogy befektetünk, pedig valójában spekulálunk, vagy ha megfelelő tudás és ismeretek hiányában, nem szórakozásból tesszük, hanem komolyan spekulálunk, és ha több pénzt kockáztatunk a spekulációval, mint amennyinek az elvesztését megengedhetjük magunknak.</p>
                            <hr />
                            <h2>Így spekulálj</h2>
                            <p className="majonator">Spekulánsként a legfontosabb elsajátítani a technika elemzést. A technikai elemzés a múltbeli árfolyam és a kereskedési <Link to='/fogalomtar/forgalom' className='dezord'>volumen</Link> elemzésével próbálja megbecsülni a jövőbeli ármozgásokat. Érdemes megismerned különböző árfolyammal és piaci hangulattal kapcsolatos indikátorokat. Hasznos skillnek számít az árfolyamgrafikonok elemzése, árfolyamon megjelenő alakzatok felismerése, a trendek ismerete.</p>
                            <p className="majonator">Befektetőknek és spekulánsoknak egyaránt érdemes megfogadni azt a tanácsot, hogy kizárólag akkora összeget fektessenek be, aminek elvesztése nem okoz túl nagy gondot számunkra. Ha befektetés mellett szeretnénk valamilyen szinten spekulálni, akkor érdemes egy külön számlát nyitni, kizárólag spekulatív tranzakciókra. Mielőtt belevágunk a spekulációba, bölcsebb döntés, ha előtte egy bizonyos ideig demo számlán próbálkozunk vele, nem igazi pénzzel, így a semminél nagyobb tapasztalattal kezdhetjük el, ami kisebb veszteséget jelenthet.</p>
                            <p className="majonator">Próbálj meg nem az érzelmeid alapján döntést hozni, és jegyezd meg, hogy ha vesztesz, akkor az csakis a te hibád.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Spekulacio