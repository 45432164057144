import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Hozam() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Hozam - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a hozam jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>


                {/* hozam-abszoluthozam-hozamérték, Hozam(benne kamat) */}

                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Hozam</h1>
                            <p>Ezen az oldalon megismerheted a hozam jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A hozam a befektetésen egy időszak alatt elért tőkenövekmény.</p>
                            <p className="majonator">Egy eszközön elért hozamba beletartozik az árfolyamnövekedés, a kamat és az osztalék által elért nyereség is. A hozam mértékét százalékban szokták kifejezni. A nettó hozam az adókat és különböző díjakat levonja a bevételből, míg a bruttó hozam nem. Portfóliónk értékelésekor fontos megvizsgálni, hogy egységnyi kockázatért cserébe mekkora hozamot kapunk.</p>
                            <hr />
                            <h2>Kiszámítása</h2>
                            <p className="majonatora">Hozam = (nettó realizált hozam / befektetett összeg) <span>&#215;</span> 100</p>
                            <p className="majonatora">A nettó realizált hozam alatt nem százalékos érték értendő, hanem pénzösszeg. A nettó realizált hozamot úgy kapjuk meg, hogy összeadjuk az árfolyam-emelkedésen elért profitot, <Link to='/fogalomtar/osztalek' className='dezord'>osztalékot</Link> és a kamatokat, majd ebből az összegből levonjuk a díjak és az adók értékét.</p>
                            <p className="majonatora">Nettó realizált hozam = tőkenyereség + osztalék + kamat - díjak - adó</p>
                            <br />
                            <p className="majonator azam">Példa</p>
                            <p className="majonatora">Vásárlunk <Link to='/fogalomtar/reszveny' className='dezord'>részvényeket</Link> 1000$ áron. Két év tartás után el kell adnunk a részvényeinket, amit 1200$ áron tehetünk meg. A részvény ez idő alatt 80 dollár osztalékot fizetett nekünk. Kamatot nem fizetett a részvény, mivel nem kamatozó részvényt vásároltunk, ami amúgy is nagyon ritka. Az adó 15%, a díjak összesen csak 10 dollárt tesznek ki.</p>
                            <p className="majonatora">Nettó realizált hozam = (200 + 80 - 10) <span>&#215;</span> 0.85 = 229,5$ &nbsp; | &nbsp; Hozam = (229,5 / 1000) <span>&#215;</span> 100 = 22,95%</p>  {/* 123 * 0,15 = 18,45$ és 123 - 18,45 = 104,55 VAGY 123 * 0,85 = 104,55 --- Nettó összeg = Kezdeti összeg * (1 - Adókulcs) = 123 dollár * 0.85 = 104.55 dollár*/}
                            <hr />
                            <h2>Hozam és kockázat</h2>
                            <p className="majonator">Minél nagyobb a hozam, annál nagyobb a <Link to='/fogalomtar/befektetesi-kockazat' className='dezord'>kockázat</Link>. Ez fordítva is igaz, vagyis minél alacsonyabb a hozam, annál alacsonyabb a kockázat. A hozam és a kockázat egyenesen arányos egymással, vagyis ha nagyobb hozamot szeretnénk elérni, akkor be kell vállalnunk a vele járó nagyobb kockázatot is.</p>
                            <hr />
                            <h2>Hozam VS Kamat</h2>
                            <p className="majonator">Azzal hogy a hozam kiszámításának képletében elhelyeztük a kamatot, így már megkülönböztettük a két fogalmat egymástól és részben definiáltuk is őket. A kamat a pénz azonnali rendelkezésre állásának a díja. A kamat a befektető jutalma azért, hogy tőkéjét lekötve elhalasztja a fogyasztását. A kamat a hozam részét képezi. Ha egy befektetésen kamatot fizetnek nekünk, de az eszköz a vásárláskori ára alá csökken és a kamatkifizetés mértéke nem tudja nyereségesbe fordítani a pozíciónkat, akkor nem érünk el hozamot, vagyis veszteséges lesz a befektetés.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Hozam