import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function PE() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>P/E mutató - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a P/E jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>P/E mutató</h1>
                            <p>Ezen az oldalon megismerheted a P/E jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A P/E mutató azt fejezi ki, hogy a részvény ára hányszorosa az adott időszakban elért, egy részvényre vetített nyereségnek.</p>
                            <p className="majonator">Az árfolyam-nyereség mutató egy vállalat részvényeinek értékelésére használt arányszám. A P/E a legnépszerűbb vállalatértékelési mutató. A P/E arányt a fundamentális elemzésben arra használják, hogy meghatározzák egy vállalat részvényeinek relatív értékét az azonos ágazatban működő többi vállalatéhoz képest, illetve hogy egy vállalatot összehasonlítsanak a saját múltbeli teljesítményével. Segítségével megtudhatjuk, hogy egy vállalat részvényei alul- vagy felülértékeltek-e nyereségtermelés szempontjából.</p>
                            <hr />
                            <h2>Kiszámítása</h2>
                            <p className="majonator">P/E = Részvény piaci ára / EPS</p>
                            <p className="majonator">Az <Link to='/fogalomtar/eps' className='dezord'>EPS</Link> a vállalat egy részvényére jutó nyereségét fejezi ki. A mutató segítségével megtudhatjuk, hogy egy bizonyos időintervallum alatt a vállalat mekkora nyereséget ért el egy részvényre vetítve. Azoknak a vállalatoknak, amelyeknek nincs nyereségük, vagy amelyek veszteségesek, nincs P/E mutatójuk, mivel nincsen olyan érték, amit a nevezőbe lehetne tenni.</p>
                            <hr />
                            <h2>Értelmezése</h2>
                            <p className="majonator">A P/E mutatót trailing 12 months (TTM), vagyis elmúlt 12 hónap EPS értéke alapján számítják. A P/E mutatót úgy is lehet értelmezni, hogy amekkora értéket mutat, annyiszor fél évbe telne a vállalatnak, hogy kitermelje a részvény jelenlegi piaci értékét, ha nem változna a nyereség mértéke. A magas P/E arány azt jelentheti, hogy a vállalat részvényei túlértékeltek, vagy hogy a befektetők magas növekedési ütemet várnak a jövőben. A <Link to='/fogalomtar/peg' className='dezord'>PEG mutató</Link> a P/E mutató egy olyan módosítása, amely figyelembe veszi a vállalat jövőbeli nyereségének változását. Vagyis ha a P/E magas értéket mutat, de a vállalat növekedési üteme kellőképpen nagy, akkor nem feltétlenül túlértékelt a részvény. Az P/E mutató átlagosan 20-25 között van. A mutató átlagos értéke a <Link to='/fogalomtar/szektor' className='dezord'>befektetési szektorok</Link> között eltér.</p>
                            <p className="majonatora">Trailing P/E: az EPS-t az elmúlt időszakban elért tényleges nyereség alapján számítják ki.</p>
                            <p className="majonatora"><Link to='/fogalomtar/forward-pe' className='dezord'>Forward P/E</Link>: ebben az esetben az EPS-t a jövőbeli várható nyereségek alapján számítják ki.</p>
                            <hr />
                            <h2>P/E korlátai</h2>
                            <p className="majonatora">A P/E mutató önmagában nem nyújt teljes képet egy részvény értékéről. Kezdő befektetők körében gyakori hiba, hogy csak a P/E mutató alapján próbálják meghatározni egy részvény értékét. Fontos azonban, hogy mindig más fundamentális mutatókkal együtt elemezzünk részvényt, illetve hogy figyelembe vegyük a vállalat növekedési kilátásait, az iparági átlagokat és egyéb befolyásoló tényezőket, mint például a gazdasági környezetet. Például a P/E mutató nem veszi figyelembe a vállalat tőkeszerkezetét, mivel egy inkább hitelt finanszírozásként használó vállalat esetében a P/E mutató értéke megegyezhet egy részvényesi tőkére támaszkodó vállalatéval.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default PE