import React from "react";
import Footer from "../footer/footer";
import Konyveklist from "../konyvek/konyveklist";
import { Helmet } from "react-helmet-async";

function Konyvek() {
    return (
        <>
        <Helmet>
        <title>Könyvek | Befektetésközpont</title>
        <meta name="description" content="Befektetések klasszikusai sorozat, olyan a befektetések területén alapműnek számító művekből áll, amelyek hosszú távon kiállták az idő próbáját, és amelyekre a világgazdaság legsikeresebb szereplői, nagy egyetemei és intézményei hosszú évek óta folyamatosan hivatkoznak." data-react-helmet="true"/>
        </Helmet>
        <Konyveklist />
        <Footer />
        </>
    )
}

export default Konyvek;