import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Reszveny() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Részvény - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a részvény jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>


                {/*ideiglenes részvény, stb. részvény, részvényutalvány*/}

                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Részvény</h1>
                            <p>Ezen az oldalon megismerheted a részvény jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A részvény egy olyan értékpapír, amely egy vállalatban való tulajdoni részesedést testesít meg.</p>
                            <p className="majonator">A részvények olyan lejárat nélküli értékpapírok, amelyekkel az IPO-t követően brókerek segítségével a tőzsdén lehet kereskedni. A részvény a tulajdonost a vállalat vagyonának és nyereségének a befektető tulajdonában lévő részvények mennyiségével megegyező hányadára jogosítja fel. A vállalatok azért bocsátanak ki részvényeket, hogy tőkét vonjanak be a működés és a fejlődés biztosításához..</p>
                            <hr />
                            <h2>Elsődleges részvénykibocsátás</h2>
                            <p className="majonator">Az elsődleges részvénykibocsátás (IPO) az a folyamat amikor egy magánvállalat részvényeit először kínálják fel a nyilvánosságnak egy új részvénykibocsátás keretében. A tőzsdei bevezetés lehetővé teszi egy vállalat számára, hogy saját tőkét vonjon be befektetőkön keresztül. Az IPO során a vállalat egy részét értékesíti a nyilvánosság számára részvények formájában, amibe lehetősége van befektetni az embereknek. Az IPO-ról részletesebben <Link to='/fogalomtar/IPO' className='dezord'>itt olvashatsz</Link>.</p>
                            <hr />
                            <h2>Tulajdonrész és részvényesi jogok</h2>
                            <p className="majonator">Egy befektető tulajdonrészét a birtokolt részvények aránya határozza meg a forgalomban lévő papírokhoz képest. Amennyiben egy <Link to='/fogalomtar/vallalat' className='dezord'>vállalatnak</Link> 100 millió részvénye van, és egy befektető 1 milliót birtokol, akkor 1%-os a tulajdonrésze.</p>
                            <p className="majonator">A befektető a tulajdonrész mértékével arányosan kap szavazati jogot, vagyis beszólása lehet a vállalat ügyeibe. Olyan dolgokról hozhat döntést, mint például az osztalékfizetés mértéke. A vállalat <Link to='/fogalomtar/csod' className='dezord'>felszámolása</Link> esetén a tulajdonrész szabja meg a kifizetés elsőbbségének mértékét. A vállalat a tulajdonosa az eszközeinek, vagyis a befektetők nem jogosultak rendelkezni felettük. Továbbá a befektető jogosult a társaság eredményéből való részesedésre, az <Link to='/fogalomtar/osztalek' className='dezord'>osztalékra</Link>.</p>
                            <p className="majonator">A részvénybefektetők a részvények árfolyamának változásából és az osztalékfizetésből realizálhatnak nyereséget. Annak meghatározására, hogy egy részvény befektetésre érdemes-e számos vállalatértékelési módszert használhatunk, amik közül a legfontosabb a fundamentális elemzés. A fundamentális elemzés a vállalat pénzügyi kimutatásait és egyéb, a vállalat értékét meghatározó tényezőket vizsgál és az alapján határozza meg a részvény túl- vagy alulértékeltségét.</p>
                            <hr />
                            <h2>Részvény fajtái</h2>
                            <p className="majonator azam">Törzsrészvény</p>
                            <p className="majonator">A törzsrészvény a részvények ,,alapértelmezett" formája. Amikor a részvény szót magában használjuk, akkor a törzsrészvényekre, vagyis a hagyományos részvényekre gondolunk. A törzsrészvények vállalattól függően biztosíthatnak bizonyos mértékű osztalékot és szavazati jogot a befektetőnek. A vállalat esetleges felszámolásakor a törzsrészvényeseket fizetik ki utoljára, ha egyáltalán kifizetik őket.</p>
                            <br />
                            <p className="majonator azam">Elsőbbségi részvény</p>
                            <p className="majonator">Az <Link to='/fogalomtar/elsobbsegi-reszveny' className='dezord'>elsőbbségi részvény</Link> olyan részvény, amely valamilyen előnyben részesíti a tulajdonosát a hagyományos törzsrészvényekkel szemben. Az ilyen részvénnyel járó előny lehet extra szavazati jog, korábbi osztalékfizetés, visszavásárlási jog vagy ha a vállalatot felszámolják, akkor az elsőbbségi részvényesek gyakran előnyt élveznek a vállalat vagyonának szétosztásakor. Befektetés szempontjából azért lehet sok embernek kedvező, mert nem annyira kockázatos, mint egy részvény, viszont magasabb a hozama a <Link to='/fogalomtar/kotveny' className='dezord'>kötvényeknél</Link>.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Reszveny