import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Goodwill() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Goodwill - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a goodwill jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Goodwill</h1>
                            <p>Ezen az oldalon megismerheted a goodwill jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A goodwill az az összeg, amit egy társaság az általa felvásárol vállalat könyv szerinti értéke felett fizet.</p>
                            <p className="majonator">Egy vállalat hírnevének, márkájának, ügyfélkapcsolatainak és egyéb olyan tényezőknek az immateriális értékét jelöli, amelyek hozzájárulnak ahhoz, hogy a vállalat a tárgyi eszközökön túl bevételt tudjon termelni. A számvitelben a goodwillt általában akkor rögzítik, amikor egy vállalat egy másik vállalatot a könyv szerinti értékénél magasabb áron vásárol meg. A goodwillt a vállalati mérleg eszközoldalán találjuk meg.</p>
                            <hr />
                            <h2>Kiszámítása</h2>
                            <p className="majonator">Goodwill = vállalat vételára - eszközök piaci értéke - kötelezettségek piaci értéke</p>
                            <p className="majonator">A goodwillt úgy számítjuk ki, hogy a vételárból levonjuk az eszközök és kötelezettségek piaci értékét. Egy vállalat <Link to='/fogalomtar/konyv-szerinti-ertek' className='dezord'>könyv szerinti értéke</Link> a társaság összes eszközének és összes kötelezettségének különbsége. A könyv szerinti értéken felül fizetett összeg a goodwill. Ha a felvásárló <Link to='/fogalomtar/vallalat' className='dezord'>vállalat</Link> a könyv szerinti értéknél kevesebbet fizet, akkor negatív goodwill alakul ki. A negatív goodwill általában a nehéz helyzetbe kerülő vállalat felvásárlásánál lép fel, és bevételként kerül elszámolásra a felvásárló eredménykimutatásában.</p>
                            <hr />
                            <h2>Tudnivalók</h2>
                            <p className="majonator">A magas goodwill olyan tényezőkből eredhet, mint az erős ügyfélbázis, a jó hírű márkanév, a vállalat pozitív növekedési kilátásai, a szakképzett munkaerő, a vállalat kedvező kapcsolatai, valamint a szabadalmak. A többi immateriális eszköz előre meghatározott mértékben veszít értékéből, míg a goodwill értéke a vállalat teljesítményétől és a piaci körülményektől függően változik. Tőzsdei vállalatok mérlegében gyakran szembesülhetünk jelentős összegekkel a goodwill tétel alatt.</p>
                            <p className="majonator">A GAAP (Generally accepted accounting principles) és az IFRS (International Financial Reporting Standards) előírásai szerint a vállalatoknak legalább évente egyszer értékelniük kell a goodwill-jüket, fel kell tüntetniük az eredménykimutatásaikban és rögzíteniük kell az értékvesztését is.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Goodwill