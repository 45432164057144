import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Valuta() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Valuta - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a valuta jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>



                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Valuta</h1>
                            <p>Ezen az oldalon megismerheted a valuta jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A valuta egy ország hivatalos fizetőeszköze.</p>
                            <p className="majonator">A valuta egy általánosan elfogadott fizetési forma, amelyet általában egy kormány bocsát ki. A valuta az áruk és szolgáltatások cseréjének eszköze. Megjelenhet papírpénz, érme vagy digitális pénz formájában. A pénznyomtatást általában egy ország központi bankja vagy kincstára szabályozza annak érdekében, hogy a pénzáramlás összhangban legyen a monetáris politikával.</p>
                            <hr />
                            <h2>Áttekintés</h2>
                            <p className="majonator">A valutákra azért van szükség, hogy megkönnyítsék a kereskedelmet. Sokkal nehezebb lenne a termékekkel és szolgáltatásokkal való üzletelés, ha a régi cserekereskedelem működne még a napjainkban is. A valuták segítenek abban, hogy mérhető legyen az érték. Ez lehetővé teszi, hogy összehasonlítsuk az árukat és szolgáltatásokat, és segít a befektetési és kereskedelmi döntések meghozatalában is.</p>
                            <p className="majonator">A valuta értékét a kereslet és a kínálat határozza meg. A keresletet és a kínálatot sok tényező befolyásolja, például a gazdasági teljesítmény, az <Link to='/fogalomtar/inflacio' className='dezord'>infláció</Link>, a kamatlábak, a valutakészlet és a politikai stabilitás. Az erős valuta előnyöket nyújthat a külföldi importnak, mivel csökkentheti a behozott áruk költségeit. Azonban az erős valuta hátrányos lehet az export számára, mivel az áruk külföldön drágábbakká válhatnak, csökkentve a versenyképességet.</p>
                            <hr />
                            <h2>Valutaváltás</h2>
                            <p className="majonator">Valutaváltás alatt azt értjük, amikor egy váltóban eladunk egy valutát és vásárlunk az értékéből egy másikat. A leggyakoribb módja a valutaváltó irodák használata, viszont egyre elterjedtebbek az online valutaváltó szolgáltatások. Fontos odafigyelni a díjakra és arra, hogy a váltó milyen árfolyamon kínálja és veszi az adott valutát. A konvertibilis valuta olyan valuta, ami bármely nemzetközi pénzpiacon egy másik valutára szabadon átváltható.</p>
                            <hr />
                            <h2>Kriptovaluták</h2>
                            <p className="majonator">A kriptovaluta nem egy adott ország hivatalos fizetőeszköze. A kriptovaluta egy decentralizált digitális valuta, amelyet titkosított technológiával védenek. A kriptovaluták nem tartoznak semmilyen kormány vagy központi bank tulajdonába, és nem állítják elő őket hagyományos pénznyomtatással. Ehelyett a kriptovaluták számítógépes hálózatokon, különböző konszenzus mechanizmusok szerint állítódnak elő. A kriptovaluta egy új és izgalmas technológia, amely számos előnnyel rendelkezik a hagyományos valutákkal szemben. Befektetés szempontjából azonban rendkívül kockázatosak.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Valuta