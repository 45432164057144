import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';


function Allamkotveny() {

    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);



    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Államkötvény - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted az államkötvény jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Államkötvény</h1>
                            <p>Ezen az oldalon megismerheted az államkötvény jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">Az államkötvény az állam által kibocsátott hitelviszonyt megtestesítő értékpapír.</p>
                            <p className="majonator">Az állam az államkötvényt a finanszírozási igényeinek kielégítésére bocsátja ki. Az államok, mint gazdasági entitásoknak, gyakran szükségük van pénzre, hogy fedezzék a különböző kiadásaikat, például az infrastruktúra fejlesztését, közszolgáltatások fenntartását vagy más közösségi szükségletek kielégítését. Az államkötvény vásárlása gyakorlatilag azt jelenti, hogy az adott államnak kölcsönadod a pénzedet. Cserébe az állam ígéretet tesz arra, hogy a kibocsátott államkötvény névértékét kamattal együtt visszafizeti a futamidő lejártakor. A kamat az a jutalék, amit az állam fizet azért cserébe, hogy hozzájuk fordultál pénzügyi támogatással.</p>
                            <hr />
                            <h2>Kiknek ajánlott az államkötvény?</h2>
                            <p className="majonator">Az államkötvények népszerű befektetési lehetőségek lehetnek azok között, akik biztonságos és stabil hozamra vágynak, mivel az államoknak általában jó hitelminősítésük van, és a kockázatosságuk alacsonyabb, mint más befektetési lehetőségek esetében. Emellett sok országban adókedvezményeket is biztosítanak az államkötvényekkel kapcsolatos kamatjövedelmekre, ami vonzóbbá teszi az adófizetők számára.</p>
                            <p className="majonator">Érdemes azonban megjegyezni, hogy az államkötvények hozama általában alacsonyabb, mint más magasabb kockázatú befektetéseké, például részvényeké. A befektetőknek tehát meg kell fontolniuk a saját pénzügyi helyzetüket, céljaikat és kockázatvállalási hajlandóságukat annak érdekében, hogy eldöntsék, mely befektetési eszköz a leginkább megfelelő számukra.</p>
                            <hr />
                            <h2>Az államkötvény kamata</h2>
                            <p className="majonator">Az államkötény után kamatot kap a befektető. Ez a kamat már az államkötvény kibocsátásakor meghatározásra kerül. A kamat lehet fix, vagy változó. A fix kamatozású államkötvény a lejáratig fix kamatot fizet. Ez azt jelenti, hogy a befektető minden évben megkapja a kiszabott fix százalékú kamatot, majd az államkötvény lejárta után a névérték is kifizetésre kerül. A változó kamatozású <Link to='/fogalomtar/kotveny' className='dezord'>kötvény</Link> abban tér el, hogy a kamatozást egy változó kamatbázishoz, például az <Link to='/fogalomtar/inflacio' className='dezord'>inflációhoz</Link>, kötik. Az ilyen kötvényt nevezik inflációkövető államkötvénynek.</p>
                            <p className="majonator">Ha egy államkötvény kibocsátása után esnek a piaci hozamok és az újabb államkötvényeket alacsonyabb kamat mellett bocsájtják ki, akkor a korábbi államkötvény felértékelődik, és az árfolyama emelkedni fog. Ez fordítva is megtörténhet, vagyis a korábbi államkötvény akár értéktelenebbé is válhat. Ebből az következik, hogy minél hosszabb a hátralevő futamidő, vagyis a <Link to='/fogalomtar/duracio' className='dezord'>duráció</Link>, annál érzékenyebb lesz a kötvény a hozamváltozásra, ami magasabb <Link to='/fogalomtar/volatilitas' className='dezord'>volatilitást</Link> eredményez.</p>
                            <hr />
                            <h2>Az államkötvény árfolyama</h2>
                            <p className="majonator">Ha lejárat előtt szeretnénk eladni az államkötvényt, akkor erre a piaci árfolyamon van lehetőség. Mint fent is említve van, a piaci ár azon múlik, hogy miként alakulnak a hozamok a piacon miután befektettünk. Emelkedő piaci hozamok esetén az államkötvény árfolyama esik, csökkenő piaci hozamok esetén pedig emelkedik. A hozamváltozás árfolyamra gyakorolt hatását felerősíti a legjáratig hátralévő átlagos idő futamidő. Minél nagyobb a duráció, annál nagyobb az árfolyamra gyakorolt hatás.</p>
                            <p className="majonator">A névérték az az összeg, amit az állam visszafizet a futamidő végén a kötvény tulajdonosának. A másodlagos piacon - ahol a kötvény kibocsátás utáni kereskedése folyik - lehet megvásárolni a kötvényt a saját árfolyamán, névérték felett vagy alatt is. A kötvény ára és hozama között inverz kapcsolat van. Ha egy kötvény ára nő, akkor a hozama csökken. Ha pedig a kötvény ára csökken, akkor a hozama nő. Ez abból fakad, hogy például egy kötvény csökkenő ára esetén egyre távolabb kerül a névértéktől, ami befektetés esetén magasabb hozamot eredményez. Vagyis ha bejelentik, hogy nőnek a kötvények hozamai, akkor valójában az árfolyamuk csökken a másodlagos piacon. </p>
                            <hr />
                            <h2>Államkötvény vásárlás</h2>
                            <p className="majonator">Lehet vásárolni államkötvényt a kereskedelmi bankunknál vezetett értékpapír számlára. Továbbá van lehetőség Államkincstárnál vezetett értékpapírszámlán keresztül vásárolni, ahol díjmentesen vezetheted a számládat, míg a kereskedelmi bankoknál van számlavezetési díj. Az államkötvény vásárlás általában ingyenes.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Allamkotveny