import React from 'react'
import Szabalyok from '../szabalyok/szabalyok'
import { Helmet } from 'react-helmet-async'

function Befszabalyok() {
  return (
    <>
      <Helmet>
        <title>Befektetési alapszabályok | Befektetésközpont</title>
        <meta name="description" content="A befektetési alapszabályok megfogadásával könnyebben elérheted a pénzügyi céljaidat és jelentős előnyt szerezhetsz magadnak a befektetésekben." data-react-helmet="true" />
      </Helmet>
      <Szabalyok />
    </>
  )
}

export default Befszabalyok
