import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Hitel() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Hitel - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a hitel jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Hitel</h1>
                            <p>Ezen az oldalon megismerheted a hitel jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A hitel egy pénzügyi megállapodás, amelyben a hitelező pénzt ad a hitelfelvevőnek, aki köteles visszafizetni a hitelt meghatározott időn belül, kamattal együtt.</p>
                            <p className="majonator">A hitelezés két fél között megy végbe: hitelező és hitelfelvevő. Ha azt mondjuk, hogy valami jól hitelezik, akkor egy személy vagy vállalkozás hitelképességét minősítjük. Hitel során a pénzügyi intézmény hitelt nyújt a hitelfelvevőnek, akinek azt egy későbbi időpontban vissza kell fizetnie kamattal együtt. A hitelnek számos különböző formája létezik: autóhitelek, jelzáloghitelek, személyi kölcsönök stb, de az is hitelnek számít, ha egy eladó később kéri a fizetést. Például ha egy nagykereskedő csak egy hónappal később számlázza ki a kiskereskedésnek az eladott termékeket, akkor az is egyfajta hitelnek minősül.</p>
                            <hr />
                            <h2>Tudnivalók</h2>
                            <p className="majonator">A hitelnek akkor van a legtöbb értelme, ha eszközöket vásárlunk, nem pedig forrásokat. Az eszköz alatt olyan dolgokat kell érteni, amik pénzt termelnek, míg a források pénz fogyasztanak. A vállalatok annak érdekében vehetnek fel hitelt, hogy az összeg felhasználásával nagyobb haszonra tegyenek szert. Azonban, ha vezetési juttatásokra vagy nem befektetési ingatlanokra költik a pénz, akkor könnyen negatív pénzügyi helyzetbe sodorhatják magukat.</p>
                            <br />
                            <p className="majonator azam">THM</p>
                            <p className="majonatora">A THM, vagyis a teljes hiteldíj mutató egy százalékos érték, amely megmutatja, hogy egy hitelfelvétel mennyibe kerül az adósnak. A THM-et a hitel teljes költsége határozza meg, beleértve a kamatot, a díjakat és a költségeket. Minél magasabb a THM, annál többet kell az adósnak visszafizetnie a hitel teljes összege felett.</p>
                            <br />
                            <p className="majonator azam">Futamidő</p>
                            <p className="majonatora">A futamidő a hitelszerződésben feltüntetett időtartam, ami alatt a hitelfelvevőnek kötelessége van a teljes hitelt visszafizetni a hitelezőnek. A futamidő alatt havi törlesztőrészlet formájában kell visszafizetni a hitelt.</p>
                            <hr />
                            <h2>Áruhitel</h2>
                            <p className="majonator">Az áruhitel azt jelenti, hogy egyből megkaphatod az adott terméket fizetés nélkül, viszont az idő folyamán biztosítanod kell a törlesztőrészleteket. Sok hitelre felvehető mobiltelefon reklámot lehet látni, amin 0% THM van feltüntetve. A THM azért 0 százalék, mert a THM értékét beleszámítják a telefon árába.</p>
                            <hr />
                            <h2>Hitel VS Kölcsön</h2>
                            <p className="majonator">A hitelt és a kölcsönt sok esetben szinonimaként használjuk, pedig a valós jelentése eltér. A hitel esetében a hitelező fenntart egy hitelkeretet az igénylő számára. A hitelfelvevőnek lehetősége van felhasználni ezt a keretet, de nem kötelező. A kölcsönnél a hitelező köteles a teljes összeg kifizetésére, az igénylő pedig köteles kamattal együtt visszafizetni ezt az összeget. Például van olyan hitel, ami valójában kölcsön, ilyen például a jelzáloghitel.</p>
                            <hr />
                            <h2>Befektetés hitelből</h2>
                            <p className="majonator">Hitelből való befektetés esetén két dolgot kell figyelnünk: a hitel után fizetett kamat, illetve a befektetés <Link to='/fogalomtar/hozam' className='dezord'>hozama</Link>. Bizonyos eszközök esetében a hozam könnyebben kapcsolatba hozható a felvett hitellel. Például pontosabb számításokat tudunk végezni egy állampapír hozamával, mint egy részvényportfólió hozamával. A hitel befektetésre való felhasználása alapjában növeli a kockázatot, viszont egy kevésbé pontosan meghatározható hozamú eszköz vagy portfólió esetében ez a kockázat még magasabb. Ha hitelre szeretnénk befektetni, akkor érdemes a kevésbé kockázatos eszközöket megcélozni.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Hitel