import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Deflacio() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Defláció - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a defláció jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Defláció</h1>
                            <p>Ezen az oldalon megismerheted a defláció jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A defláció az árszínvonal csökkenését jelenti egy ország gazdaságában.</p>
                            <p className="majonator">A defláció során az áruk és szolgáltatások árai csökkennek, ami azt eredményezi, hogy a fogyasztók ugyanazért az összegért több árut vagy szolgáltatást vásárolhatnak. Ha defláció lép fel, akkor az jellemzően 1-2% közötti tartományban helyezkedik el. A defláció az infláció ellentéte. Fontos nem összekeverni a deflációt a dezinflációval. A dezinfláció az infláció csökkenését jelenti, a pozitív tartományban lévő infláció visszaesését. Defláció alatt csökkennek az árak, míg dezinfláció alatt nőnek az árak, csak lassabban, mint az inflációnál.</p>
                            <hr />
                            <h2>Gazdasági hatása</h2>
                            <p className="majonator">Míg az <Link to='/fogalomtar/inflacio' className='dezord'>infláció</Link> csökkenti a pénz vásárlóértékét azáltal, hogy növeli az árakat, a defláció növeli a pénz vásárlóértékét azáltal, hogy csökkenti az árakat. Azonban a tartós defláció negatív hatással lehet a gazdaságra, mivel a fogyasztók hajlamosak elhalasztani a vásárlásokat a várhatóan még alacsonyabb árak miatt, ami csökkenti a fogyasztást és a gazdasági aktivitást.</p>
                            <p className="majonator">A deflációt általában gazdasági visszaesés, gazdasági <Link to='/fogalomtar/recesszio' className='dezord'>recesszió</Link> vagy egyéb negatív gazdasági események kísérik, amelyek csökkentik a keresletet az áruk és szolgáltatások iránt. A kormányzatok és központi bankok általában igyekeznek megakadályozni a túlzott deflációt és támogatják az enyhe inflációt, mivel az enyhe infláció elősegítheti a gazdasági növekedést és elkerüli a túlzott elhalasztásokat a fogyasztók részéről.</p>
                            <p className="majonator">A folyamatosan csökkenő árú termékek és szolgáltatások hatására a <Link to='/fogalomtar/vallalat' className='dezord'>vállalatok</Link> bevételei visszaesnek, aminek eredményeként szükségessé válik a költségek csökkentése és leépítések végrehajtása. Az egyre több munkanélkülivé váló emberek száma tovább fokozza a fogyasztás visszaesését, ami pedig még kevesebb termék előállítására készteti a vállalatokat. Ez a leépülés tovább gyengíti a cégek profitját és negatív hatást gyakorol a <Link to='/fogalomtar/reszveny' className='dezord'>részvények</Link> árfolyamára. A részvények értéke csökken, így a befektetők nem tudnak profitot realizálni a részvényeiken.</p>
                            <hr />
                            <h2>Defláció okozói</h2>
                            <p className="majonator">Deflációt legfőbbképp a pénz és pénzügyi eszközök kínálatának csökkenése okozhat. Okozhat továbbá deflációt az áruk és szolgáltatások iránti kereslet csökkenése vagy a termelékenység növekedése. Defláció következhet a piaci kínálat növekedéséből, aminek az oka lehet az olcsóbb alapanyagok, új technológiák, piaci verseny vagy a termelékenység növekedése. Így csökkennek az árak, viszont a termelés tovább növekszik.</p>
                            <hr />
                            <h2>Defláció számítása</h2>
                            <p className="majonator">Csak mint az infláció mérésénél, a defláció mérésére a fogyasztóiár-indexet, fogyasztói kosarat használják. A fogyasztói kosár nagyjából 1000 terméket tartalmaz, melyeknek az árváltozásáról statisztikát vezetnek. A kosárban lévő termékeket, szolgáltatásokat árreprezentánsoknak nevezzük. Van a kosárban kakaós csiga, farmernadrág, Opel Astra vagy akár repülőjegy is. A statisztikusok lejegyzik, hogy egy éve mikor mennyibe kerültek a termékek, majd az ebből képzett bázisárral osztják el az idei árindexet. Ebből kapnak egy százalékos értéket, ami az inflációnak vagy deflációnak felel meg.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Deflacio