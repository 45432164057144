import React from 'react'
import './Road.css'
import Footer from "../footer/footer";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';


function Harmadikallomas() {
  return (
    <>
      <Helmet>
        <title>3. Értékítélet | Befektetésközpont</title>
        <meta name="description" content="Ezen az állomáson megtanulhatod, hogy hogyan értékelheted és választhatod ki a számodra legmegfelelőbb befektetési eszközöket. Megismerheted a pénzügyi kimutatásokat, illetve a fundamentális és technikai elemzés közötti különbséget." data-react-helmet="true" />
      </Helmet>
      <div className='allomascontent'>
        <div className='allomassection'>
          <div className='allcontentheader'>
            <h1>Értékítélet</h1>
            <p>Ezen az állomáson megtanulhatod, hogy hogyan értékelheted és választhatod ki a számodra legmegfelelőbb befektetési eszközöket. Megismerheted a pénzügyi kimutatásokat, illetve a fundamentális és technikai elemzés közötti különbséget. </p>
          </div>
          <div className='allcontentcontent'>
            <h2>Mi határozza meg egy eszköz árát és értékét?</h2>
            <p>Először is fontos tisztázni az ár és az érték közötti különbséget. Az ár az, amit a befektetők fizetnek egy befektetésért, míg az érték az a szám, ami azt írja le, hogy az eszköz valójában mennyit ér. Nem létezik olyan eszköz, ami pontosan annyiba kerül, mint amennyit ér. Egy eszköz árát a kereslet és a kínálat határozza meg, ami a befektetők és a spekulánsok értékelésein alapul. Az ár az a szám, amennyibe kerül az eszköz, amennyit az adott időpontban hajlandóak fizetni a befektetők.</p>
            <br />
            <p>Azok a szempontok, ami alapján a befektetők értékelnek egy befektetést, mind eltérőek eszközönként. Például egy részvény értékét olyan tényezők befolyásolhatják, mint a vállalati teljesítmény, a piaci körülmények változásai vagy különböző geopolitikai események. Ha egy vállalatnak csökkennek a bevételei, esetleg veszít a népszerűségéből vagy romlik a hitelminősítése, akkor értelemszerűen veszíteni fog az értékéből. Ennek az ellenkezője is igaz, vagyis minden pozitív hatásnak értéknövelő ereje lesz.</p>
            <br />
            <p>Felmerülhetett benned a kérdés, hogy miért nem annyiba kerülnek az eszközök, amennyit valójában érnek? A válasz eléggé szerteágazó. Először is, semelyik befektetési eszköznek nincsen pontosan meghatározható értéke: Vegyünk példának egy vállalatot, amiről tudjuk, hogy a teljes eszközeinek értéke 400 milliárd dollár, a kötelezettségei pedig 120 milliárd dollárra rúgnak. Ezekből az adatokból, könnyen kiszámítható, hogy vállalat könyv szerinti értéke 280 milliárd dollár, vagyis ez az az összeg, amit a befektetők között szétosztanának a vállalat felszámolása esetén, és ez reprezentálja a vállalat idézőjeles értékét.</p>
            <br />
            <p>A bökkenő viszont abban van, hogy a vállalat piaci kapitalizációja 1700 milliárd dollár, vagyis a részvényesek a hatszorosát fizetik ki a vállalati könyv szerinti értéknek. De ez miért van így? A válasz a vállalat immateriális javaiban és abban rejlik, hogy a befektetők nem a jelen értékre koncentrálnak, hanem megpróbálják megjósolni, hogy a jövőben hogyan fog teljesíteni a vállalat. Ha egy vállalatnak potenciálisan nagy esélye van arra, hogy egy kellőképpen biztonságos úton, jelenetős növekedésre tegyen szert, akkor a befektetők hajlandóak lesznek beárazni ezt a növekedést és nagyobb árat fizetni az eszközért. A másik befolyásoló tényező, az immateriális javak, amik közé olyan dolgokat sorolunk, mint például a vállalat hírneve, a szabadalmi jogok vagy az ügyfélbázis. Továbbá olyan tényezőket is meg kell vizsgálni, mint az osztalékfizetés, a piacon elfoglalt pozíció, a menedzsment vagy a jogi környezet.</p>
            <br />
            <p>Szóval egy eszköz értékét meghatározó tényezők elég sokfélék lehetnek. A leírtakból kikövetkeztethető, hogy az érték egy szubjektív fogalom és nem függ a kereslet-kínálat párostól. Ha úgy ítéljük, hogy egy eszköz értéke magasabb, mint az ára, akkor alulárazott, míg ha alacsonyabb az értéke, akkor túlárazott eszköznek nevezzük. Az ár-érték arány felállításakor azt is fontos figyelembe venni, hogy piaci viszonylatban mennyire számít alul- vagy felülértékeltnek az eszköz.</p>
            <br />
            <p>,,Az ár az, amit fizetsz, az érték az, amit kapsz." - Warren Buffett</p>
            <br />
            <h2>Hogyan értékelhetünk egy eszközt?</h2>
            <p>Értékeléskor a célunk az, hogy a lehető legpontosabban határozzuk meg az adott befektetési eszköz valós értékét. Mint ahogy az az előző bekezdésben is ki lett fejtve, az értékelési szempontok eszközönként eltérnek. Ebből kifolyólag minden eszköz értékeléséhez külön-külön módszert kell elsajátítanunk.</p>
            <br />
            <h3>Fundamentális elemzés</h3>
            <p className="h3alter">A fundamentális elemzés egy befektetés alapvető értékeit és kilátásait értékelő módszer. A lényege az, hogy az adott eszköz pénzügyi mutatóiból következtetéseket von le és azokat visszavezeti az árra, így meghatározva a túl- vagy alulértékeltséget. - ,,A piac rövid távon szavazógép, hosszú távon azonban precíz mérleg." - írja Benjamin Graham. A fundamentális elemzés egyik alappillérét képezi a gondolat, miszerint az eszköz ára hosszú távon az alapvető értékeihez igazodik.</p>
            <br />
            <h3>Technikai elemzés</h3>
            <p className="h3alter">A technikai elemzés abban különbözik a fundamentális elemzéstől, hogy míg az utóbbi az eszköz valós értéke alapján próbálja meghatározni, hogy érdemes-e befektetni az adott eszközbe, addig az előbbi az árfolyam és volumen tanulmányozásából von le következtetéseket. A technikai elemzés abból a feltételezésből indul ki, hogy egy értékpapír múltbeli kereskedési tevékenysége és árváltozása értékes mutatója lehet az értékpapír jövőbeli ármozgásának, ha megfelelő kereskedési szabályokkal párosul. A technikai elemzésnek számos eszköze van: indikátorok, mozgóátlagok, különböző alakzatok stb.</p>
            <br />
            <h3>Körülmények elemzése</h3>
            <p className="h3alter">Fontos megvizsgálni az adott eszköz értékét befolyásolható körülményeket. A vizsgálandó körülmények eszközönként eltérhetnek. Például egy részvény esetében leginkább a vállalat vezetését, a piaci potenciálját és a versenytársait érdemes vizsgálni, míg egy valuta elemzésének esetében a hangsúlyt inkább a kamatlábakra és a politikai stabilitásra helyezzük.</p>
            <br />
            <h2>Részvény értékelése</h2>
            <img src="/imgvideo/finvizvisa.png" alt="finviz visa stats" width='100%' />
            <p className='allmini'>Forrás: finviz.com</p>
            <br />
            <p>A fenti képen egy olyan táblázatot látunk, ami a Visa nevű vállalat részvényeinek egykori adatait tartalmazza. A táblázat rövid, tömör és olyan információkat hordoz magával, amik elengedhetetlenek egy részvény értékeléséhez. Azért is nagyon hasznos, mert egy könnyű és hatékony formáját biztosítja a részvények alapvető fundamentális mutatóinak a vizsgálatának. Valószínű rengeteget fogsz ilyennel találkozni. - A táblázat olyan befektetési mutatókat tartalmaz, amiknek a kiszámításához nagyrészt a vállalat pénzügyi kimutatásaiból nyer információkat. Például a P/E mutató (második oszlop, első elem), a legnépszerűbb az összes közül: a részvény árát és az egy részvényre a jutó nyereséget - ami a kimutatásban található meg - hasonlítja össze. Legfontosabb mutatók közé tartozik továbbá az EPS, a P/B, a P/S, a Debt/Eq és a ROA ROE ROI hármas. A megfelelő fundamentális mutatókat a vállalat által folytatott tevékenység alapján kell kiválasztani.</p>
            <br />
            <p>Az első sorban olyan általánosabb információkat láthatunk, mint például az indexeket, amikben benne van az adott részvény, a piaci kapitalizációt vagy az alkalmazottak számát. A következő három sorban találhatók a legfontosabb fundamentális mutatók. (Illetve odacsúszott a három mozgóátlag is az alsó sorba, viszont azok a technikai elemzésbe sorolhatóak) Ezeket az értékeket érdemes a legalaposabban megvizsgálni, mivel ők határozzák meg leginkább a vállalat pénzügyi helyzetét. Az utolsó két oszlop legnagyobbrészt az árfolyammal, a forgalommal és a volatiliással kapcsolatban tartalmaz adatokat. Fontos megjegyezni, hogy a piros színnel jelzett érték nem feltétlenül jelzi azt, hogy a részvény nem alkalmas vételre. Például ha egy részvény P/E mutatója már a 100-as tartományban a felhőket karcolja, viszont a nyereségnövekedés kellőképpen nagy, akkor a kedvező PEG mutatóból még simán következhet egy vételi jelzés a befektető számára.</p>
            <br />
            <h3>Pénzügyi kimutatások</h3>
            <p className='h3alter'><strong>Eredménykimutatás:</strong> az eredménykimutatás a három fő vállalati kimutatás egyike. A feladata, hogy információt nyújtson a befektetőknek a vállalat bevételeiről és kiadásairól. A kimutatás alapján megállapítható a vállalat nyeresége vagy vesztesége az adott időszakra vetítve. A pénzügyi elemzők minden negyedéves jelentés előtt megbecsülik a kimutatásban szereplő adatokat. A jelentést követően az ár annak függvényében változik, hogy a befektetők várakozásaihoz képest hogyan alakulnak a kimutatás értékei. Az eredménykimutatás segítségével megállapítható a költségszerkezet és a jövedelem alakulása, továbbá lehetővé teszi az eredményt befolyásoló elemek részletekre bontását. A kimutatást számos, fundamentális elemzésben használt mutató számításához felhasználják.</p>
            <br />
            <img src="/imgvideo/incstatement.png" alt="eredménykimutatás" width='70%' className='incstate' />
            <br />
            <br />
            <p className='h3alter'><strong>Mérleg:</strong> A mérleg feladata, hogy adatokat nyújtson a vállalat eszközeiről és forrásairól egy adott időszakban. A mérleg egy adott időpontra vetítve mutatja meg a vállalat vagyonszerkezetét. A mérlegnek két oldala van. Az egyik oldalon források, míg a másik oldalon az eszközök állnak. Az eszközoldalnak mindig meg kell egyeznie a mérleg másik oldalával, ahol a kötelezettségek és a saját tőke foglal helyet, vagyis a mérlegnek egyensúlyban kell lennie.</p>
            <br />
            <p className="h3alter">A mérleg a következő egyenletet követi: ,,Eszközök = Kötelezettségek + Saját tőke" - Eszközök: készpénz, készpénz-egyenértékesek, befektetések, árukészlet, követelések, immateriális javak, felszerelések, ingatlanok stb. Kötelezettségek: fizetendő adó, halaszott bevétel, vásárlói előleg, fizetés, fizetendő osztalék, kölcsönök, hitelek és egyéb díjak. Saját tőke: egy vállalkozás tulajdonosainak vagy részvényeseinek tulajdonítható pénz, ami megegyezik a vállalat teljes vagyonának és kötelezettségeinek különbségével. Ide tartozik a törzsrészvény, az elsőbbségi részvény, vagy a nyereségtartalék is. A vállalatnak minden tulajdonában lévő dologért fizetnie kell úgy, hogy kötelezettségeket vállal vagy részvényesi tőkét von be.</p>
            <br />
            <p className="h3alter">A kimutatás segítségével a vállalat fel tudja mérni, hogy nem vett-e fel túl sok kölcsönt, elég likvidek-e a tulajdonában lévő eszközök, vagy van-e elég készpénze a jelenlegi igények kielégítésére. Szintén számos, fundamentális elemzésben használt mutató számításához használják fel a benne megtalálható adatokat. A mérlegeket érdemes összehasonlítani ugyanazon szektor más vállalataival, mivel a különböző szektorokban egyedi megközelítések vannak finanszírozás tekintetében.</p>
            <br />
            <img src="/imgvideo/merleg.png" alt="merleg" width='70%' className='incstate' />
            <br />
            <br />
            <p className='h3alter'><strong>Cash flow kimutatás:</strong> A cash flow kimutatás azt méri, hogy a vállalat mennyire hatékonyan termel pénzt kötelezettségei kifizetésére és működési költségeinek finanszírozására. Összefoglalja a vállalatba belépő és onnan kilépő pénzeszközök összegét. Lehetővé teszi annak az ellenőrzését, hogy honnan és hová megy a pénz, illetve segíti a vállalatot abban, hogy lépéseket tegyen a működési hatékonyság növelése és egyéb szükségletekhez elegendő készpénz előállítása és fenntartása érdekében, valamint segít a hatékony finanszírozási döntések meghozatalában.</p>
            <br />
            <p className="h3alter">A kimutatást három részre bontjuk. Működési cash flow: az az összeg, amelyet a vállalat az üzleti tevékenységeiből, az áruk gyártásából és értékesítéséből vagy az ügyfeleknek nyújtott szolgáltatásból hoz be. Beruházási cash flow: megmutatja, hogy a vállalat egy adott időszakban mennyi pénzt termelt vagy költött különböző befektetéssel kapcsolatos tevékenységekből. A negatív cash flow gyakran a vállalat gyenge teljesítményét jelzi. Viszont a negatív beruházási cash flow akár annak is köszönhető, hogy jelentős mennyiségű pénzt fektet be a vállalat kutatásba és fejlesztésbe. Finanszírozási cash flow: a vállalat finanszírozására felhasznált pénzáramlásokat mutatja be. A finanszírozási tevékenységek közé tartoznak a kötelezettségekkel és a saját tőkével kapcsolatos tranzakciók. Betekintést nyújt a befektetőknek abba, hogy mennyire kezeli jól a vállalat a tőkeszerkezetét.</p>
            <br />
            <img src="/imgvideo/cashflow.png" alt="merleg" width='70%' className='incstate' />
            <br />
            <br />
            <p>Fontos meghatározni, hogy milyen befektetési szektorba tartozik a vállalat, és hogy milyen helyet foglal el benne a versenytársaihoz képest. Elemzéskor a vállalat iparágára jellemző mutatókat kell kiválasztani. A vállalat értékelése során, érdemes a különböző mutatókat grafikonon is megvizsgálni, így megbizonyosodva arról, hogy az esetleges eredmény nem átmeneti szerencsének tudható be, hanem valódi stabil pénzügyi helyzetet kialakításából származik.</p>
            <br />
            <p>A diszkontált cash flow (DCF modell) megpróbálja meghatározni egy befektetés mai értékét, annak előrejelzése alapján, hogy a befektetés mennyi pénzt fog termelni a jövőben. A befektetés jövőbeli pénzáramlásai (cash flow-ja) alapján próbálja megbecsülni a vállalat jelenlegi értékét. Ez egy befektetések értékeléséhez gyakran használt modell. Van kettő választási lehetőségünk: van egy vállalkozás, ami éves 5% piaci kamat mellett az első évben 200, majd a következő évben 100 dollár nyereséget termel. Van egy másik vállalkozás, ami pedig az első évben 100, majd a következőben 200 dollár nyereséget szerez. A kérdés, hogy melyikbe éri meg inkább befektetni? Ha csak ennyi információnk áll rendelkezésre a befektetésről, akkor a pénz időértékéből fakadóan éredmesebb az első vállalkozást választani. Tehát a módszer a jelenérték számításán alapul, vagyis összegezzük a jövőben a vállalathoz beáramló pénzeket, majd ennek a jelenértékét kiszámolva megkapjuk a cash flow jelenlegi értékét.</p>
            <br />
            <p>DCF = <span class="frac"><sup>CF<sub>1</sub></sup><span>&frasl;</span><sub>( 1 + r )<sup>1</sup></sub></span> + <span class="frac"><sup>CF<sub>2</sub></sup><span>&frasl;</span><sub>( 1 + r )<sup>2</sup></sub></span> + <span class="frac"><sup>CF<sub>3</sub></sup><span>&frasl;</span><sub>( 1 + r )<sup>3</sup></sub></span> + ... + <span class="frac"><sup>CF<sub>n</sub></sup><span>&frasl;</span><sub>( 1 + r )<sup>n</sup></sub></span></p>
            <br />
            <p>CF = az adott éven beáramló pénzmennyiség | r = diszkontráta (kamat, amivel a jelenértéket számítjuk) | A vállalatok általában a súlyozott átlagos tőkeköltséget (WACC) használják a diszkontrátához. Ha a DCF magasabb, mint a befektetés jelenlegi értéke, akkor az pozitív jelzésnek számít értékeléskor. Ha nem lehet megbecsülni a jövőbeli pénzáramlásokat, mert például a projekt túl összetett, a DCF nem sokat ér, és alternatív modelleket kell alkalmazni. Továbbá hátránya a modellnek, hogy jövőbeli becsléseken alapul, vagyis sose ad pontos visszajelzést a valóban bekövetkező értékről.</p>
            <br />
            <p>Egy részvény értékelésekor elengedhetetlen a vállalat körülményeinek a részletes elemzése. Érdemes áttekinteni a vállalat vezetőségét és az általa alkalmazott vállalatirányítási gyakorlatokat, illetve a vállalat osztalékpolitikáját, a múltbeli osztalékfizetéseit és a befektetőkhöz való viszonyát. Fontos megvizsgálni a vállalat piacon elfoglalt pozícióját, az iparági trendeket, illetve hogy a vállalatnak milyen előnyei és hátrányai vannak az adott piacon. Érdemes figyelemmel kísérni a gazdasági környezetet, beleértve a kamatlábakat, inflációt, munkanélküliséget és más makrogazdasági mutatókat, amelyek befolyásolhatják a vállalat teljesítményét. Az idő előrehaladtával egyre nagyobb szerepet játszik a technológia, ezért megvizsgálandó az is, hogy a vállalat hogyan alkalmazza és kezeli a jelenkor technológiáit. Ismerd meg a vállalat működési területén érvényes jogszabályokat, korlátozásokat és a politikai környezetet. Jó példák erre a kínai vállalatok. Egyre fontosabbá válnak a társadalmi és környezeti tényezők, mint például a fenntarthatóság vagy a társadalmi felelősségvállalás, így ezek is befolyásolhatják a vállalat hosszú távú kilátásait.</p>
            <br />
            <p>A vállalatok profi elemzésének elsajátításához ajánljuk a <Link to='/tanfolyam' className='dezord'>részvénybefektetési tanfolyamunkat</Link>.</p>
            <br />
            <h2>Kötvény értékelése</h2>
            <p>A kötvények és egyéb, a részvényeknél kevésbé kockázatosabb és összetettebb eszközök elemzése sokkal egyszerűbb és nem kell velük annyi időt tölteni. A kötvényeket értékelhetjük úgy, hogy a várható jövőbeli kamatfizetéseinek jelenértékét, valamint a kötvény névértékének jelenértékét számítjuk ki. A kötvény értékét úgy számítjuk ki, hogy a jövőbeli kamatfizetéseken és a névértéken egy megfelelő diszkontrátát alkalmazunk.</p>
            <br />
            <p>Kötvény jövőbeli kamatjövedelmeinek jelenértéke = <span class="frac"><sup>C</sup><span>&frasl;</span><sub>( 1 + r )<sup>1</sup></sub></span> + <span class="frac"><sup>C</sup><span>&frasl;</span><sub>( 1 + r )<sup>2</sup></sub></span> + <span class="frac"><sup>C</sup><span>&frasl;</span><sub>( 1 + r )<sup>3</sup></sub></span> + ... + <span class="frac"><sup>C</sup><span>&frasl;</span><sub>( 1 + r )<sup>t</sup></sub></span> &nbsp;&nbsp; , ahol C = kamatfizetés összege, r = diszkontráta, t = periódusok száma. &nbsp; | &nbsp; Névérték jelenértéke = <span class="frac"><sup>F</sup><span>&frasl;</span><sub>( 1 + r )<sup>T</sup></sub></span> &nbsp;&nbsp; , ahol F = névérték, r = diszkontráta, T = hátralévő idő a futamidő végéig.</p>
            <br />
            <p>A duráció (az értékpapír átlagos hátralévő futamideje) képes kifejezni a kötvények kamatérzékenységét, vagyis azt a kockázatot, amit a kamat változása gyakorol a kötvény árfolyamára. Minél hosszabb a duráció, annál volatilisebbé teszi a kötvény árfolyamát a kamatváltozás. Ez fordítva is igaz, vagyis minél rövidebb egy kötvény durációja, annál kevésbé van kitéve a hozamváltozások által okozott volatilitásnak. Durációra alapozott befektetési stratégiának hívjuk azt amikor csak hosszú, vagy csak rövid futamidejű kötvényekbe fektetünk. A lényege az, hogy ha a piacon kamatláb csökkenés várható, akkor csak hosszú durációjú kötvényeket vásárolunk, ha pedig a kamatláb növekedésére számítunk akkor a rövid durációjú kötvényeket részesítjük előnyben.</p>
            <br />
            <p>A kibocsátó hitelminősége meghatározza a kötvények kockázati szintjét. Azok a kötvények, amelyeket stabil és megbízható kibocsátók bocsátanak ki, általában alacsonyabb kockázatúak. A befektetőknek értékelniük kell a kibocsátó pénzügyi helyzetét, a hitelezői besorolásokat és az adott gazdasági környezetet. A kötvényvásárláskor az egyik fontos vizsgálandó tényező a nemfizetési kockázat. Szerencsére ez a kockázat a hitelminősítő cégek segítségével könnyen mérhető.</p>
            <br />
            <p>A kötvényeket úgy értékelhetjük, hogy az újonnan kibocsájtott kötvények hozamait hasonlítjuk össze a régi kötvényekkel. Ha az újonnan kibocsátott kötvények hozamai növekednek, akkor a korábban kibocsájtottak árfolyama esni fog, és ez fordítva is így van. &nbsp;&nbsp;&nbsp; A kötvény kamata annak függvényében változik, hogy az adott szervezet kötvényei iránt mekkora a kereslet. Nagy kereslet esetén sokan szeretnének kötvényekbe fektetni, ami a kamatok csökkenését eredményezi. Ha a szervezet pénzügyi helyzete kedvező, esetleg más kötvények kamatjai alacsonyabbak vagy a piacon uralkodó hangulat jobb, akkor az adott kötvény iránt várhatóan nőni fog a kereslet. Ebből kifolyólag ezeknek a tényezőknek is fontos a vizsgálata.</p>
            <br />
            <h2>Befektetési alap értékelése</h2>
            <p>A befektetési alapok értékelésének egyik legfontosabb szempontja a múltbeli teljesítmény. Érdemes grafikonon is megvizsgálni, hogy az alapnak mennyire stabil a növekedése és hogy miként reagál a gazdasági környezet változásaira. Fontos azonban megjegyezni, hogy a múltbeli eredmények nem garantálják a jövőbeli teljesítményt, csupán növelik az esélyét a hasonló eredményeknek.</p>
            <br />
            <p>A befektetési alapok úgy keresnek pénzt, hogy díjakat számítanak fel. A különböző kezelési költségek jelentős hatással lehetnek a hozamunkra, ezért fontos alaposan tanulmányoznunk az alap költségstruktúráját, és figyelnünk az esetleges rejtett költségekre is. A pénzkeresés mellett a forgalom visszaszorítása és az adminisztratív költségek fedezése érdekében a legtöbb befektetési alap díjat számít fel a befektetési jegy eladásakor és/vagy megvásárlásakor. Fontos elolvasni az alap tájékoztatóját.</p>
            <br />
            <p>Értékeléskor azt is figyelembe kell venni, hogy az alap befektetési stratégiája mennyire felel meg a mi céljainknak és kockázatvállalási hajlandóságunknak. Az egyes alapok eltérő befektetési stratégiákat követnek és más-más eszközökből építik fel a portfóliójukat. Érdemes megvizsgálni az alap portfóliójának összetételét, a befektetési szektorok megoszlását, az eszközök értékét. Továbbá fontos információt szereznünk az alapkezelőkről, hogy megbizonyosodjunk a megbízhatóságukról.</p>
            <br />
            <p>Az alapkezelők értékeléséhez egy másik szempont az adott alap referencia indexével, vagyis benchmarkjával való összehasonlítás, ugyanis ez az alapkezelőknek meghatározott túlteljesítendő cél a hozamra vonatkozóan. Nem szabad elfelejteni, hogy akkor is sikeresnek mondható egy alapkezelő, ha az adott piac csökkenése alatt képes kisebb csökkenést elérni. Vagyis nem felététlenül kell mindig nyereségesnek lenni a kezelőnek.</p>
            <br />
            <p>Fontos tényező lehet az alap mérete is. A nettó eszközérték (Net Asset Value - NAV) a befektetési alap eszközeinek nettó értéke a kötelezettségekkel csökkentve. A stabil, megbízható és magas növekedési potenciál mellett egy jó pont lehet a magas NAV érték, mivel ez általában a megbízható alapok egy tulajdonsága.</p>
            <br />
            <p>A befektetési alapok egy népszerű értékelési szempontja a Morningstar nevű befektetési kutatóvállalat által kibocsájtott csillagos minősítés, a morningstar-besorolás. A vállalat egy megbízható értékelési rendszer segítségével minősíti az alapokat egy 5 csillagos skálán. A besorolás jobb áttekintést ad az alap jövőbeli sikeréről, mint a múltbeli hozam alapján való megítélés.</p>
            <br />
            <div className='lowernavbar'>
              <button className='btn btn0 success' onClick={() => { window.location.href = '/roadmap'; }}>Vissza a Roadmap-re</button>
              <button className='btn btn0 success' onClick={() => { window.location.href = '/roadmap/negyedik'; }}>Következő állomás</button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Harmadikallomas
