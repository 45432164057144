import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Forwardpe() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Forward P/E - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a forward P/E jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Forward P/E</h1>
                            <p>Ezen az oldalon megismerheted a forward P/E jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A forward P/E mutató arról ad információt, hogy a részvény árának hányszorosát fizetjük ki a jövőbeli egy részvényre vetített nyereséghez képest.</p>
                            <p className="majonator">A forward P/E a legismertebb mutató, az árfolyam-nyereség arány (P/E) egy olyan változata, amelyben a P/E-számításához az előre jelzett nyereséget használja. Segítségével kiszámítható, hogy hányszorosát fizetnénk ki a részvénynek a jövőbeli jövedelmezőképességéhez képest.</p>
                            <hr />
                            <h2>Kiszámítása</h2>
                            <p className="majonator">Forward P/E = Részvény piaci ára / becsült jövőbeli EPS érték</p>
                            <p className="majonator">Az <Link to='/fogalomtar/eps' className='dezord'>EPS</Link> (Earnings Per Share) olyan mutató, ami a vállalat egy részvényére jutó, nyereségét fejezi ki. A <Link to='/fogalomtar/pe' className='dezord'>P/E</Link> és a forward P/E mutató kiszámítása közötti különbség csupán annyi, hogy míg a P/E mutatóban a jelenlegi EPS értékkel számolunk, addig a forwardban a jövőbelivel. Azoknak a vállalatoknak, amelyeknek nincs nyereségük, vagy amelyek veszteségesek, nincs P/E mutatójuk, mivel nincsen olyan érték, amit a nevezőbe lehetne tenni. Mivel a forward P/E becsült EPS-t használ, szinte soha nem fog tudni pontos értéket jósolni. A befektetők legnagyobb része hajlamos felülbecsülni a növekedés mértékét.</p>
                            <hr />
                            <h2>Használata</h2>
                            <p className="majonator">Több, ugyanazon az iparágon belüli vállalat forward P/E értékének összehasonlítása képet adhat arról, hogy egy részvény mennyire túlértékelt vagy alulértékelt. Ha az egyik részvény P/E-je jóval magasabb, mint a társaiké, akkor az a részvény nem biztos, hogy a legjobb befektetés. A P/E mutató önmagában nem nyújt teljes képet egy részvény értékéről. A mutatót mindig más pénzügyi mutatókkal és tényezőkkel együtt kell értelmezni, például a vállalat növekedési kilátásaival, iparági átlagokkal és gazdasági környezettel összehasonlítva.</p>
                            <p className="majonator">Ha egy vállalat részvényeinek jelenlegi ára 50 dollár és a becslések szerint a nyereség jövőre megduplázódik 5 dollárról 10-re, akkor a forward P/E arány 5 lesz, míg a normál P/E 10. Ha a forward P/E alacsonyabb, mint a jelenlegi P/E arány, az azt jelenti, hogy az elemzők a nyereség növekedésére számítanak.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Forwardpe