import React from "react";
import { Link } from 'react-router-dom';

function Sidebarcomp() {
    return (
        <>
            <div className='butyok'>
                <div className="sidebar" id='scrollergyerek'>
                    <h1>Fogalomtár</h1>
                    {/* A */}
                    <Link to='/fogalomtar/akvizicio' className='hrefb'>Akvizíció</Link>
                    <Link to='/fogalomtar/alapok-alapja' className='hrefb'>Alapok alapja</Link>
                    <Link to='/fogalomtar/allamkotveny' className='hrefb'>Államkötvény</Link>
                    <Link to='/fogalomtar/arany' className='hrefb'>Arany</Link>
                    <Link to='/fogalomtar/arbitrazs' className='hrefb'>Arbitrázs</Link>
                    <Link to='/fogalomtar/arutozsde' className='hrefb'>Árutőzsde</Link>
                    <br />
                    {/* B */}
                    <Link to='/fogalomtar/bankbetet' className='hrefb'>Bankbetét</Link>
                    <Link to='/fogalomtar/befektetesi-alap' className='hrefb'>Befektetési alap</Link>
                    <Link to='/fogalomtar/befektetesi-kockazat' className='hrefb'>Befektetési kockázat</Link>
                    <Link to='/fogalomtar/benchmark' className='hrefb'>Benchmark</Link>
                    <Link to='/fogalomtar/beta-mutato' className='hrefb'>Béta mutató</Link>
                    <Link to='/fogalomtar/broker' className='hrefb'>Bróker</Link>
                    <br />
                    {/* C */}
                    <Link to='/fogalomtar/capm-modell' className='hrefb'>CAPM modell</Link>
                    <Link to='/fogalomtar/cash-flow' className='hrefb'>Cash flow</Link>
                    <Link to='/fogalomtar/cfd' className='hrefb'>CFD</Link>
                    <Link to='/fogalomtar/csod' className='hrefb'>Csőd</Link>
                    <br />
                    {/* D */}
                    <Link to='/fogalomtar/debt-equity' className='hrefb'>Debt/Equity</Link>
                    <Link to='/fogalomtar/deflacio' className='hrefb'>Defláció</Link>
                    <Link to='/fogalomtar/derivativa' className='hrefb'>Derivatíva</Link>
                    <Link to='/fogalomtar/diverzifikacio' className='hrefb'>Diverzifikáció</Link>
                    <Link to='/fogalomtar/duracio' className='hrefb'>Duráció</Link>
                    <br />
                    {/* E */}
                    <Link to='/fogalomtar/ebitda' className='hrefb'>EBITDA</Link>
                    <Link to='/fogalomtar/elsobbsegi-reszveny' className='hrefb'>Elsőbbségi részvény</Link>
                    <Link to='/fogalomtar/eps' className='hrefb'>EPS</Link>
                    <Link to='/fogalomtar/ertekpapir' className='hrefb'>Értékpapír</Link>
                    <br />
                    {/* F */}
                    <Link to='/fogalomtar/fedezeti-pont' className='hrefb'>Fedezeti pont</Link>
                    <Link to='/fogalomtar/forgalom' className='hrefb'>Forgalom</Link>
                    <Link to='/fogalomtar/forward-pe' className='hrefb'>Forward P/E</Link>
                    <Link to='/fogalomtar/franchise' className='hrefb'>Franchise</Link>
                    <br />
                    {/* G */}
                    <Link to='/fogalomtar/gap' className='hrefb'>Gap</Link>
                    <Link to='/fogalomtar/goodwill' className='hrefb'>Goodwill</Link>
                    <Link to='/fogalomtar/green-chip' className='hrefb'>Green chip</Link>
                    <br />
                    {/* H */}
                    <Link to='/fogalomtar/hedge-fund' className='hrefb'>Hedge fund</Link>
                    <Link to='/fogalomtar/hitel' className='hrefb'>Hitel</Link>
                    <Link to='/fogalomtar/hozam' className='hrefb'>Hozam</Link>
                    <br />
                    {/* I */}
                    <Link to='/fogalomtar/imf' className='hrefb'>IMF</Link>
                    <Link to='/fogalomtar/indikator' className='hrefb'>Indikátor</Link>
                    <Link to='/fogalomtar/inflacio' className='hrefb'>Infláció</Link>
                    <Link to='/fogalomtar/ipo' className='hrefb'>IPO</Link>
                    <br />
                    {/* J */}
                    <Link to='/fogalomtar/jegybank' className='hrefb'>Jegybank</Link>
                    <Link to='/fogalomtar/jovedelmezoseg' className='hrefb'>Jövedelmezőség</Link>
                    <Link to='/fogalomtar/jutalek' className='hrefb'>Jutalék</Link>
                    <br />
                    {/* K */}
                    <Link to='/fogalomtar/keszpenz' className='hrefb'>Készpénz</Link>
                    <Link to='/fogalomtar/kincstarjegy' className='hrefb'>Kincstárjegy</Link>
                    <Link to='/fogalomtar/konyv-szerinti-ertek' className='hrefb'>Könyv szerinti érték</Link>
                    <Link to='/fogalomtar/kotveny' className='hrefb'>Kötvény</Link>
                    <br />
                    {/* L */}
                    <Link to='/fogalomtar/leanyvallalat' className='hrefb'>Leányvállalat</Link>
                    <Link to='/fogalomtar/likviditas' className='hrefb'>Likviditás</Link>
                    <Link to='/fogalomtar/long-pozicio' className='hrefb'>Long pozíció</Link>
                    <br />
                    {/* M */}
                    <Link to='/fogalomtar/margin' className='hrefb'>Margin</Link>
                    <Link to='/fogalomtar/medvepiac' className='hrefb'>Medvepiac</Link>
                    <Link to='/fogalomtar/merleg' className='hrefb'>Mérleg</Link>
                    <Link to='/fogalomtar/mozgoatlag' className='hrefb'>Mozgóátlag</Link>
                    <br />
                    {/* N */}
                    <Link to='/fogalomtar/nominalis-hozam' className='hrefb'>Nominális hozam</Link>
                    <Link to='/fogalomtar/novekedesi-reszveny' className='hrefb'>Növekedési részvény</Link>
                    <Link to='/fogalomtar/nyse' className='hrefb'>NYSE</Link>
                    <br />
                    {/* O */}
                    <Link to='/fogalomtar/opcio' className='hrefb'>Opció</Link>
                    <Link to='/fogalomtar/order' className='hrefb'>Order</Link>
                    <Link to='/fogalomtar/osztalek' className='hrefb'>Osztalék</Link>
                    <Link to='/fogalomtar/otc-piac' className='hrefb'>OTC piac</Link>
                    <br />
                    {/* P */}
                    <Link to='/fogalomtar/pb' className='hrefb'>P/B</Link>
                    <Link to='/fogalomtar/pe' className='hrefb'>P/E</Link>
                    <Link to='/fogalomtar/peg' className='hrefb'>PEG</Link>
                    <Link to='/fogalomtar/piaci-kapitalizacio' className='hrefb'>Piaci kapitalizáció</Link>
                    <Link to='/fogalomtar/portfolio' className='hrefb'>Portfolió</Link>
                    <Link to='/fogalomtar/ps' className='hrefb'>P/S</Link>
                    <br />
                    {/* R */}
                    <Link to='/fogalomtar/recesszio' className='hrefb'>Recesszió</Link>
                    <Link to='/fogalomtar/reszveny' className='hrefb'>Részvény</Link>
                    <Link to='/fogalomtar/roa' className='hrefb'>ROA</Link>
                    <Link to='/fogalomtar/roe' className='hrefb'>ROE</Link>
                    <Link to='/fogalomtar/roi' className='hrefb'>ROI</Link>
                    <Link to='/fogalomtar/rsi-indikator' className='hrefb'>RSI indikátor</Link>
                    <br />
                    {/* S */}
                    <Link to='/fogalomtar/sharpe-mutato' className='hrefb'>Sharpe mutató</Link>
                    <Link to='/fogalomtar/short-pozicio' className='hrefb'>Short pozíció</Link>
                    <Link to='/fogalomtar/spekulacio' className='hrefb'>Spekuláció</Link>
                    <Link to='/fogalomtar/szektor' className='hrefb'>Szektor</Link>
                    <br />
                    {/* T */}
                    <Link to='/fogalomtar/technikai-elemzes' className='hrefb'>Technikai elemzés</Link>
                    <Link to='/fogalomtar/tokeattetel' className='hrefb'>Tőkeáttétel</Link>
                    <Link to='/fogalomtar/tozsde' className='hrefb'>Tőzsde</Link>
                    <Link to='/fogalomtar/tozsdeindex' className='hrefb'>Tőzsdeindex</Link>
                    <br />
                    {/* V */}
                    <Link to='/fogalomtar/vallalat' className='hrefb'>Vállalat</Link>
                    <Link to='/fogalomtar/valuta' className='hrefb'>Valuta</Link>
                    <Link to='/fogalomtar/volatilitas' className='hrefb'>Volatilitás</Link>
                    {/* Q és U és W, X, Y, Z kimarad */}
                    <br />
                </div>
            </div>
        </>
    )
}

export default Sidebarcomp;