import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function ROE() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>ROE - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a ROE jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>ROE</h1>
                            <p>Ezen az oldalon megismerheted a ROE jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A ROE (Return On Equity) egy olyan mutató, amely megmutatja, hogy a vállalat mekkora jövedelmet termel egységnyi saját tőkére vetítve.</p>
                            <p className="majonator">A ROE a vállalat jövedelmezőségének, a nyereségtermelés hatékonyságának mérésére használatos. Minél magasabb a ROE, annál hatékonyabb a vállalat vezetése abban, hogy a saját tőkéből nyereséget termeljen. A mérőszámot általában százalékban fejezik ki. Mindig az azonos iparágon belüli vállalatok ROE értékét a leghasznosabb összehasonlítani.</p>
                            <hr />
                            <h2>Kiszámítása</h2>
                            <p className="majonator">ROE = (nettó eredmény / átlagos saját tőke) <span>&#215;</span> 100</p>
                            <p className="majonator">A nettó eredmény az egy vállalat által egy meghatározott időszakban elért összes bevétel és költség különbsége. Egy vállalat saját tőkéje az összes eszközének és az összes kötelezettségének különbsége. Az átlagos saját tőke a mérlegben lévő saját tőke átlaga az adott időszakban. Például ha egy vállalat nettó eredménye 10 millió dollár, és átlagos saját tőkéje 50 millió dollár, akkor a ROE értéke 20%. Ez azt jelenti, hogy a vállalat minden egyes dollár saját tőkéjéből 0,2 dollár profitot termel.</p>
                            <hr />
                            <h2>Értelmezése</h2>
                            <p className="majonator">A ROE megmutatja, hogy a vállalat mennyire hatékonyan termel nyereséget a saját tőke felhasználásával. A vállalatok ROE értékei iparágtól függően jelentősen eltérhetnek, ezért érdemes azonos szektoron belüli vállalatok ROE mutatóit összehasonlítani.</p>
                            <p className="majonator">A <Link to='/fogalomtar/roa' className='dezord'>ROA</Link> és a ROE közötti különbség az, hogy a ROA a vállalat teljes eszközállományát veszi figyelembe, míg a ROE a saját tőkét, vagyis a kötelezettségekkel csökkentett eszközöket. Ezért a ROE előnye, hogy figyelembe veszi a vállalat kötelezettségeit a ROA mutatóval ellentétben. A ROE mutató önmagában nem ad kellő információt egy vállalat hatékonyságáról. Fontos a részletes fundamentális elemzést elvégzése, és hogy a ROE mutató csak egy szempont legyen a sok közül.</p>
                            <hr />
                            <h2>DuPont</h2>
                            <p className="majonator">ROE = <span class="frac"><sup>nettó eredmény</sup><span>&frasl;</span><sub>árbevétel</sub></span> <span>&#215;</span> <span class="frac"><sup>árbevétel</sup><span>&frasl;</span><sub>átlagos teljes eszközállomány</sub></span> <span>&#215;</span> <span class="frac"><sup>átlagos teljes eszközállomány</sup><span>&frasl;</span><sub>átlagos saját tőke</sub></span></p>
                            <p className="majonator">A DuPont elemzés egy olyan módszer, amely segít megmagyarázni a nyereségesség változásának okait. Az elemzés megmutatja, hogy mely tényezők járulnak hozzá a leginkább, illetve legkevésbé egy vállalat ROE mutatójának változásához, így segítve a befektetőknek abban, hogy megértsék, hogy egy vállalat hogyan tudná növelni a nyereségességét a jövőben.</p>
                            <p className="majonator">A képlet első eleme a (nettó eredmény / árbevétel), ami lényegében a haszonkulcsot jelenti. Megmutatja, hogy a vállalat egységnyi tőkén mekkora hasznot képes húzni. A második elem az (árbevétel / átlagos teljes eszközállomány), ami azt mutatja meg, hogy a vállalat mennyire hatékonyan használja fel az eszközeit bevételtermelésre.</p>
                            <p className="majonator">A harmadik az (átlagos teljes eszközállomány / átlagos saját tőke), ami azt fejezi ki, hogy egy vállalat hogyan használja fel a adósságállományát eszközei finanszírozására. Mivel saját tőke = (összes eszköz - összes kötelezettség), ezért a megnövekedett adósság hatására a saját tőke mértéke csökkeni fog, így ha a vállalat több hitelt vesz fel az eszközök finanszírozására, akkor ennek az elemnek az értéke emelkedni fog. Ezt azért fontos megvizsgálni, mert a magas ROE érték akár túlzott tőkeáttét használatából is származhat.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default ROE