import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Nominalishozam() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Nominális hozam - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a nominális hozam jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Nominális hozam</h1>
                            <p>Ezen az oldalon megismerheted a nominális hozam jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A nominális hozam alatt a teljes hozamot, vagyis az adófizetés és az értékcsökkenés beleszámítása nélküli hozamot értjük.</p>
                            <p className="majonator">A különbség a reálhozam és a nominális hozam között az, hogy a nominális hozam nincs korrigálva az inflációval. A befektetők számára érdemesebb a reálhozammal számolni, mivel figyelembe veszi az inflációt, így pontosabb képet adva a befektetés valós megtérüléséről.</p>
                            <hr />
                            <h2>Jelentősége</h2>
                            <p className="majonator">A nominális <Link to='/fogalomtar/hozam' className='dezord'>hozam</Link> lehetővé teszi különböző befektetési eszközök teljesítményének összehasonlítását az <Link to='/fogalomtar/inflacio' className='dezord'>inflációtól</Link> függetlenül. A nominális hozam alapján könnyebb összehasonlítani különböző befektetéseket és pénzügyi lehetőségeket, különösen akkor, ha az infláció hatása jelentéktelen vagy stabil. Két különböző befektetés nominális hozamának összehasonlítása nem feltétlenül ad pontos képet a befektetések közötti különbségekről, különösen akkor, ha az infláció jelentősen eltér.</p>
                            <hr />
                            <h2>Nominális hozam - példa</h2>
                            <p className="majonator">Egy befektetési alapban elhelyezünk 1 millió forintot. A kamatnak köszönhetően az idő elteltével ebből a pénzből 1,1 millió forint lett. Ha a befektetett összeget kivonjuk a pozíciónk jelenlegi értékéből, majd ez elosztjuk a befektetett összeggel, akkor megkapjuk a nominális hozamot.</p>
                            <p className="majonator">(1,1 millió - 1 millió) / 1 millió = 0.1, majd ez szorozva 100-zal: 10%</p>
                            <hr />
                            <h2>Reálhozam - példa</h2>
                            <p className="majonator">Az előző példánál maradunk, csak most reálhozamot számolunk. A nominális hozamunk 100.000 forint lett. Az 5% infláció miatt a forint vesztett az értékéből, vagyis a hozamunk is vesztett pont ennyit az értékéből. Így 100.000 - (100.000 <span>&#215;</span> 0.05) =  95.000 Ft lett a reálhozamunk.</p>
                            <p className="majonator">Reálhozam = Nominális hozam - infláció</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Nominalishozam