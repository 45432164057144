import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';


function Arbitrazs() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Arbitrázs - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted az arbitrázs szó jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Arbitrázs</h1>
                            <p>Ezen az oldalon megismerheted az arbitrázs szó jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">Az arbitrázs olyan ügylet, amelyben a befektető egy adott befektetési eszköz, két különböző piacon kialakult eltérő árából hajt hasznot.</p>
                            <p className="majonator">A pénzügyi piacok nem tökéletesek, és az árak egymáshoz képest ingadozhatnak. Az arbitrázs kihasználja ezeket a különbségeket, mivel ha egy eszköz alacsonyabb áron értékesíthető egy piacon, mint amennyiért egy másikon megvásárolható, akkor az arbitrázsügylet lehetőséget kínál egy ,,kockázatmentes" nyereségre.</p>
                            <hr />
                            <h2>Működése</h2>
                            <p className="majonator">Az arbitrázs általában nagyon rövid távú tranzakciókat jelent, mivel a piacok gyorsan reagálnak az ilyen lehetőségekre, és az árkülönbségek gyorsan kiegyenlítődhetnek. Ennek eredményeként az arbitrázs szerepe a piacok hatékonyságának növelésében áll, mivel a kereskedők az árakat közelítik egymáshoz, így minimalizálva az árkülönbségeket. Az arbitrázsőrök a vételt és az eladást azonos időben bonyolítják le, így nem vállalnak az idő múlásából eredő kockázatnak kitett nyitott pozíciót.</p>
                            <hr />
                            <h2>Arbitrázs módszerek</h2>
                            <p className="majonator">Arbitrázs ügyletet lehet végezni tőzsdén, amennyiben egy termék több tőzsdén is elérhető. Az arbitrázsőr megvárja amíg két tőzsde között árazási eltérés alakul ki egy adott terméken, majd pozíciót nyit. A pozíciót akkor zárja le, amikor az árak visszaállnak egyensúlyba, az arbitrázsőr profitja pedig a két ár közötti eltérés lesz.</p>
                            <p className="majonator">Ha három deviza között a devizapárok árfolyamában különbség lép fel, akkor elvégezhető egy olyan művelet, amit <em>háromszög arbitrázsnak</em> hívnak. Tegyük fel, hogy EUR/HUF = 400, USD/HUF = 380, USD/EUR = 0.98. Ebben az esetben kapunk egy USD/EUR = 0.95 és egy USD/EUR = 0.98 értéket. Ilyenkor az arbitrázsőr nyithat a magasabb értékre egy <Link to='/fogalomtar/short-pozicio' className='dezord'>short pozíciót</Link>, az alacsonyabbra pedig egy <Link to='/fogalomtar/long-pozicio' className='dezord'>long pozíciót</Link>.</p>
                            <p className="majonator">Megeshet, hogy két különböző termék között rendszeres az árkülönbség, de időben folyton változik. Ilyen esetben ki kell számolni a két eszköz közötti átlagos árkülönbséget, majd pozíciót nyitni, ha eltérést tapasztalunk ettől az átlagtól.</p>
                            <p className="majonator">A kriptovaluták abból a szempontból érdekesek, hogy óriási áreltérések alakulhatnak ki. Két különböző tőzsdén vagy váltón akár 20%-os eltérést is tapasztalhatunk. Vannak olyan esetek, amikor az eltérés irreális százalékokat mutat fel. Ebben az esetben nagyon fontos meggyőződni arról, hogy az ügyletre felhelyezett pénzünk kellő biztonságban van, a már amúgy is eléggé kockázatos kriptovaluták piacán.</p>
                            <p className="majonator">A <em>pairs trading arbitrázs</em> lényege az, hogy találni kell két olyan terméket, amiknek az ára hosszú távon korrelált. Amint árkülönbséget tapasztalunk, már nyithatjuk is a pozíciókat. Erről a módszerről elmondható, hogy kockázatosabb, mint a többi arbitrázs típus.</p>
                            <p className="majonator">Egy másik módja az arbitrázsnak, ha abból szeretnék hasznot húzni, hogy az egyik vállalat kivásárolja a másik vállalat részvényeseit. Tegyük fel, hogy az egyik vállalat részvényei 100$, míg a másiké 250$. Az olcsóbb részvényekkel rendelkező vállalat ajánlatot tesz a drágább felvásárlására. 3db 100$-os részvényt hajlandó adni egy 250$ részvényért, így az arány 1.2 lesz. A 100$-os részvényt kell eladni és a 250$-os részvényből vásárolni.</p>
                            <hr />
                            <h2>Kockázat</h2>
                            <p className="majonator">Bár az arbitrázs elméletileg kockázatmentes nyereséget ígér, a gyakorlatban mindig vannak kockázatok. A piacok nagyon gyorsan reagálhatnak, és az árkülönbségek sokszor időlegesek, vagy csak nagyon kis mértékűek. Az arbitrázsügyletek végrehajtása közben piaci ingadozások, tranzakciós költségek és végrehajtási nehézségek előfordulhatnak, amelyek csökkenthetik vagy akár negatívvá tehetik a nyereséget.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Arbitrazs