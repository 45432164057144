import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function OTCpiac() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>OTC piac - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted az OTC piac jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>OTC piac</h1>
                            <p>Ezen az oldalon megismerheted az OTC piac jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">Az OTC piac egy nem központosított pénzügyi piac, ahol a tranzakciókat a résztvevők közvetlenül egymás között kötik meg.</p>
                            <p className="majonator">Ha egy vállalat nem felel meg a tőzsdék előírásainak, jegyzési követelményeiknek, akkor lehetőségük adódik az értékpapírjaikkal a tőzsdén kívül is kereskedni. A tőzsdén kívüli kereskedés magában foglalhat értékpapírokat, derivatívákat, devizákat vagy akár a kriptovalutákat is. A tőzsdén kívüli eszközökkel való kereskedést a tőzsdén kívüli piacokra szakosodott kereskedő vagy bróker, legtöbb esetben egy bank segítheti elő.</p>
                            <hr />
                            <h2>Működése</h2>
                            <p className="majonator">Az OTC eszközökre lehet, hogy nem vonatkoznak <Link to='/fogalomtar/tozsde' className='dezord'>tőzsdei</Link> szabályok, de a SEC által meghatározott alkalmassági követelmények továbbra is vonatkoznak rájuk. Az OTC Markets Group egy amerikai pénzügyi piac, amely közel 10 000 tőzsdén kívüli értékpapírra vonatkozóan nyújt árinformációkat. Ez a vállalat működteti a legnagyobb OTC hálózatokat, mint például az OTCQX vagy az OTCQB. Az utóbbi a fejlődő vállalatok részvényeit foglalja magában, amik magas kockázattal járnak, míg az OTCQX részben olyan, szigorúbban szabályozott vállalatokat tartalmaz, amik a jövőben akár <Link to='/fogalomtar/nyse' className='dezord'>NYSE</Link> vagy Dasdaq <Link to='/fogalomtar/ipo' className='dezord'>IPO-t</Link> terveznek.</p>
                            <hr />
                            <h2>OTC előnyei</h2>
                            <p className="majonator">Az OTC piacok lehetőséget nyújtanak befektetni olyan vállalatokba, amik nem felelnek meg a tőzsdék követelményeinek. Így például a kisebb vállalatok is könnyebben pénzhez juthatnak. Az OTC piac ideális hely a spekulánsok számára. Az OTC piacok általában alacsonyabb költségekkel járnak a tőzsdékhez képest. Ez annak köszönhető, hogy nincsen közvetítő, aki jutalékot számítana fel a tranzakciókért. A kereskedők rugalmasabban tudnak tárgyalni a tranzakciók feltételeiről, mint a tőzsdéken. Ez lehetőséget ad a befektetőknek arra, hogy olyan feltételeket kössenek ki, amelyek megfelelnek az ő igényeiknek.</p>
                            <hr />
                            <h2>OTC hátrányai</h2>
                            <p className="majonator">Az OTC piacon sokkal kockázatosabb elvégezni egy tranzakciót, mint egy tőzsdén és az elérhető befektetési eszközök is kockázatosabbak. A csalások mértéke is sokkal magasabb, mint a szabályozott piacokon. Az alacsonyabb szabályozottság miatt egy ügyletkor felmerülő esetleges hiba esetén sokkal nehezebb megoldani a problémákat. Az OTC eszközök az alacsony <Link to='/fogalomtar/forgalom' className='dezord'>volumen</Link> miatt kisebb <Link to='/fogalomtar/likviditas' className='dezord'>likviditással</Link> járnak, ami nagy spreadeket eredményezhet.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default OTCpiac