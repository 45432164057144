import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Jovedelmezoseg() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Jövedelmezőség - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a jövedelmezőség jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Jövedelmezőség</h1>
                            <p>Ezen az oldalon megismerheted a jövedelmezőség jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A jövedelmezőség egy mutató, mely információt ad arról, hogy egy vállalat mennyire hatékonyan termel nyereséget az erőforrásaiból.</p>
                            <p className="majonator">Vagyis egy adott időszakban elért eredmény és az erre elköltött erőforrások arányát vizsgálja. Fontos megjegyezni, hogy egy vállalat a nyereség mértékétől függetlenül veszteséges maradhat. A jövedelmezőség vizsgálására számos mutatót hoztak létre, mint például a ROA, ROE vagy ROI.</p>
                            <hr />
                            <h2>Népszerű jövedelmezőségi mutatók</h2>
                            <p className="majonator azam">ROE (Return on Equity)</p>
                            <p className="majonator">A <Link to='/fogalomtar/roe' className='dezord'>ROE</Link> megmutatja, hogy a vállalat mekkora nyereséget termelt egységnyi saját tőkére vetítve. Minél magasabb a ROE, annál hatékonyabb a vállalat abban, hogy saját tőkéből jövedelmet termeljen. Az átlagos ROE érték befektetési szektoronként jelentősen eltérhet, így érdemes a hasonló vállalatok ROE mutatóit összehasonlítani. Ugyanez igaz a többi jövedelmezőségi mutatóra is. A mérőszámot általában százalékban fejezik ki.</p>
                            <br />
                            <p className="majonator azam">ROA (Return on Assets)</p>
                            <p className="majonator">A <Link to='/fogalomtar/roa' className='dezord'>ROA</Link> megmutatja, hogy a vállalat mekkora nyereséget termel az eszközeiből. A ROA segítségével meghatározható, hogy egy vállalat milyen hatékonyan használja fel az eszközeit nyereségtermelésre. Minél magasabb a ROA érték, annál eredményesebben kezeli az eszközeit a vállalat. A ROA-t is általában százalékban fejezik ki.</p>
                            <br />
                            <p className="majonator azam">ROI (Return on Investment)</p>
                            <p className="majonator">A <Link to='/fogalomtar/roi' className='dezord'>ROI</Link> egy olyan mutató, amely azt kifejezi ki, hogy egy befektetés mennyire volt sikeres. A ROI megmutatja, hogy a vállalat mekkora nyereséget termel a befektetéseiből, így képet adva arról, hogy mennyire kezeli hatékonyan a befektetéseit. A ROA, ROE, ROI hármas közül ez a legritkábban használt mutató.</p>
                            <br />
                            <p className="majonator azam">EBITDA (Earnings Before Interest, Taxes, Depreciation and Amortization)</p>
                            <p className="majonator">Az <Link to='/fogalomtar/ebitda' className='dezord'>EBITDA</Link> a kamatok, az adózás és az értékcsökkenési leírás előtti eredményt fejezi ki. Ez egy olyan mutató, amelyet a vállalatok működési teljesítményeinek mérésére használnak. Eltünteti a vállalatok közötti eltérő adózásból, kamatfizetésből és beruházásokból származó különbségeket, így lehetővé téve, hogy közvetlenül az üzleti folyamatok váljanak összehasonlíthatóvá.</p>
                            <br />
                            <p className="majonator azam">Profit Margin</p>
                            <p className="majonator">A profit margin azt mutatja meg, hogy a vállalat a teljes árbevétel hány százalékát tartja meg nyereségként. Például ha egy vállalat profit margin-ja 15% akkor 1 dollár bevételen 15 cent profitot képes realizálni. Részvénykiválasztás során érdemes a minimum iparági átlag feletti profit marginnal rendelkező vállalatokba fektetni.</p>
                            <hr />
                            <h2>Jövedelmezőségi index</h2>
                            <p className="majonator">A jövedelmezőségi index (PI) a jövőbeli hozamok összesített jelenértékének és a projektbe fektetett kezdeti tőkének az aránya. Minél magasabb a PI értéke, annál vonzóbb befektetésnek számít. Az 1-es PI a legalacsonyabb elfogadható érték az indexen, mivel minden ennél alacsonyabb érték azt jelzi, hogy a befektetés többe kerül, mint amennyi hozamot fog tudni termelni.</p>
                            <p className="majonatora">PI = jövőbeli hozamok összesített jelenértéke / kezdeti befektetés</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Jovedelmezoseg