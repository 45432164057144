import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function IMF() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>IMF - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted az IMF jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>


                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>IMF</h1>
                            <p>Ezen az oldalon megismerheted az IMF jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">Az IMF (International Monetary Fund) a nemzetközi pénzügyi együttműködést és stabilitást elősegítő, tagállamokból álló nemzetközi szervezet.</p>
                            <p className="majonator">Az IMF (magyarul Nemzetközi Valutaalap) célja a globális gazdasági növekedés és pénzügyi stabilitás bebiztosítása, illetve a nemzetközi kereskedelem ösztönzése. Az IMF legfontosabb feladata a pénzügyi válságok megelőzése és enyhítése. Az IMF 190 tagországból áll.</p>
                            <hr />
                            <h2>Működése</h2>
                            <p className="majonator">Az IMF tagjai gazdasági súlyuk mértéke szerint járulnak hozzá az alaphoz, és a szervezettől kölcsönöket vehetnek fel adósságaik törlesztéséhez. Az IMF hatalmas mennyiségű adatot gyűjt a nemzetgazdaságról, a nemzetközi kereskedelemről és ezek alapján gazdasági előrejelzéseket készít. Az IMF kvóták és a tagországok által fizetett tagdíjak révén jut pénzhez.</p>
                            <p className="majonator">Az IMF a pénzügyi válságok megelőzése vagy enyhítése érdekében kölcsönöket nyújt a gazdasági nehézségekkel küzdő országoknak. Továbbá segítséget, képzést és politikai tanácsadást nyújt a tagországoknak. Az IMF-források feltétele gyakran az, hogy a kedvezményezettek reformokat és változtatásokat hajtsanak végre növekedési potenciáljuk és pénzügyi stabilitásuk növelése érdekében. Ezeket a feltételeket gyakran kritika éri, mivel bizonyos esetekben inkább kárt okoznak, mint segítenek így a kölcsönök.</p>
                            <p className="majonator">Az Valutaalap székhelye Washingtonban található. A szervezetet 190 tagország képezi, amik meghatározott kvóta mértéke szerint képviseltetik magukat az igazgatótanácsban. A kvóta mértékét az adott ország gazdasági súlya alapján határozzák meg. Az alábbi országok nem tagjai az IMF-nek: Kuba, Észak-Korea, Monaco, Liechtenstein. Tajvan úgy van nyilvántartva, mint „Tajvan, kínai tartomány”.</p>
                            <hr />
                            <h2>Története</h2>
                            <p className="majonator">Az IMF-et eredetileg 1945-ben hozták létre a Bretton Woods-i megállapodás részeként. A konferencián részt vevő 44 állam a rögzített árfolyamokon konvertibilis <Link to='/fogalomtar/valuta' className='dezord'>valuták</Link> rendszerének kialakítását akarták bevezetni, hogy elkerüljék az 1930-as évek gazdasági világválságával járó valutaleértékelési verseny megismétlődését. A Bretton Woods-i rendszer 1970-es évekbeli összeomlása óta az IMF olyan árfolyamrendszert támogat, ami esetében a valuták egymáshoz viszonyított értékét a piaci erők határozzák meg.</p>
                            <hr />
                            <h2>SDR</h2>
                            <p className="majonator">Az SDR nemzetközi tartalékeszköz. Az SDR nem egy valuta, hanem értéke öt valuta - az amerikai dollár, az euró, a kínai jüan, a japán jen és a brit font - kosarán alapul. Az SDR-t az IMF tagországainak jegybankjai használhatják fel egymás között. A tagországok a kvótájuk nagyságának kétszeresét vehetik fel kölcsönként. Minél nagyobb a kvóta, annál több SDR-t oszt ki az IMF az adott tagországnak. Az SDR felhasználható devizákra való átváltásra, <Link to='/fogalomtar/hitel' className='dezord'>hitelek</Link> törlesztésére, kvótaemelések kifizetésére, kötelezettségek kifizetésére, hitelkamat fizetésére.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default IMF