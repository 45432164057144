import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Technikaielemzes() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Technikai elemzés - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a technikai elemzés jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Technikai elemzés</h1>
                            <p>Ezen az oldalon megismerheted a technikai elemzés jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A technikai elemzés egy olyan módszer, ami segítségével a kereskedő valószínűségi előrejelzéseket ad az ármozgás változásairól.</p>
                            <p className="majonator">A technikai elemzést a kereskedők szokták alkalmazni, illetve minimális szinten a befektetők is pontosabb képet kaphatnak az árfolyam jövőbeli alakulásáról. A technikai elemzés lényege, hogy az árfolyam, különböző indikátorok, alakzatok, támaszpontok és trendek alapján fordulópontokat és célárakat lehessen kiszámítani.</p>
                            <hr />
                            <h2>Értelmezése</h2>
                            <p className="majonator">A technikai elemzés abban különbözik a fundamentális elemzéstől, hogy míg az utóbbi az eszköz valós értéke alapján próbálja meghatározni, hogy érdemes-e befektetni az adott eszközbe, addig az előbbi az árfolyam és volumen tanulmányozásából von le következtetéseket.</p>
                            <p className="majonator">A technikai elemzés abból a feltételezésből indul ki, hogy egy eszköz múltbeli kereskedési tevékenysége és árváltozása értékes mutatója lehet az eszköz jövőbeli ármozgásának, ha megfelelő kereskedési szabályokkal párosul. A technikai elemzés alkalmazói teljesen más megközelítést alkalmaznak befektetéskor, mint az értékalapú befektetők. Számos befektető azt vallja, hogy egy eszköz múltbeli árváltozásából nem lehet valószínűségi következtetéseket felállítani az ármozgásra. Ami viszont már többek által elfogadott tény, az a trendek és támaszpontok pszichológiai hatása, aminek árfolyam megváltoztató hatása lehet. Ha például egy csökkenő trendvonal fölé lép az árfolyam, akkor a hírekben be lehet jelenteni, hogy már nem a megszokott ütemben csökken az eszköz árfolyama, ami pozitív hatással lehet az árra. Napjainkra a technikai elemzés több száz mintát és jelzést tartalmaz, amelyeket több éves kutatás során fejlesztettek ki.</p>
                            <hr />
                            <h2>Technikai elemzés eszközei</h2>
                            <p className="majonator azam">Indikátorok</p>
                            <p className="majonator">Az <Link to='/fogalomtar/indikator' className='dezord'>indikátor</Link> egy olyan mutató, amely a múltbeli árfolyamadatokból levezetett információkat használja a piac jövőbeli mozgásának előrejelzésére. Az indikátorok széles skálája áll rendelkezésre, amelyek különböző tényezőket vesznek figyelembe, például az árfolyam mozgásának irányát, a volatilitást és a trend erejét. Példák: <Link to='/fogalomtar/rsi-indikator' className='dezord'>RSI</Link>, MACD, OBV, ADX, Aroon.</p>
                            <br />
                            <p className="majonator azam">Mozgóátlagok</p>
                            <p className="majonator">A <Link to="/fogalomtar/mozgoatlag" className='dezord'>mozgóátlag</Link> az árfolyam egy adott időtartamra vetített átlaga. A kereskedők a mozgóátlagokat egy eszköz trendirányának azonosítására vagy a támasz- és ellenállási szintek meghatározására használják. Az árfolyam grafikonon szokták feltüntetni, egy vonal formájában. Technikai elemzésben pozitív jelzésként értelmezhető, ha egy rövid távú mozgóátlag egy hosszabb távú fölé emelkedik. Ezt hívják bullis crossovernek, aminek a bearish crossover az ellentéte.</p>
                            <br />
                            <p className="majonator azam">Trendek</p>
                            <p className="majonator">A trendek a múltbeli árfolyamadatokból levezethető információk alapján azonosítható árfolyammozgási irányok. Megkülönböztetünk emelkedő, csökkenő és oldalazó trendet. A trendek erőssége a mozgások amplitúdójától függ. Megkülönböztetünk rövid-, közép- vagy hosszú távú trendeket. A trendek idővel megfordulhatnak.</p>
                            <br />
                            <p className="majonator azam">Alakzatok</p>
                            <p className="majonator">Az alakzatok különböző mintázatok és formák, amik az árfolyam grafikonon rajzolódnak ki. Az alakzatokat a kereskedők gyakran használják a trendek előrejelzésére és a fordulópontok azonosítására. Például: head & shoulders, rising wedge, douple top. Az alakzatok alapján történő kereskedés rendkívül kockázatos.</p>
                            <br />
                            <p className="majonator azam">Támaszpontok</p>
                            <p className="majonator">A támaszpontok akkor alakulnak ki az árfolyamon, amikor az árfolyamok egy bizonyos szinten megállnak, és elkezdenek emelkedni. A támaszpontok gyakran a korábbi csúcsoknál vagy a technikai indikátorok fontos szintjeinél alakulnak ki, így egy még erősebb támaszt eredményezve.</p>
                            <br />
                            <p className="majonator azam">Volumen</p>
                            <p className="majonator">A <Link to="/fogalomtar/forgalom" className='dezord'>volumen</Link> (forgalom) egy eszköz azon mennyisége, amely egy bizonyos idő alatt gazdát cserél. Minden egyes tranzakció, amely egy eszköz vevője és eladója között létrejön, hozzájárul az adott eszköz volumenének számához. A volumen számos kereskedési stratégia alapját képezi. Például a "Volume Spread Analysis" (VSA) egy technikai elemzési módszer, amely a volumen és az árak közötti összefüggéseket vizsgálja, hogy kereskedési szignálokat és trendváltozásokat azonosítson. Ezen kívül, a Relative Volume vagy például az On-Balance Volume (OBV) indikátor is segíthetnek a kereskedőknek a volumenmozgások értelmezésében.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Technikaielemzes