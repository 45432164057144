import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import './tanf-atmeneti.css';
import { Link } from 'react-router-dom';

const ErrorPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const handleMouseMove = (event) => {
            const eyes = document.querySelectorAll('.eye');
            eyes.forEach((eye) => {
                const eyeRect = eye.getBoundingClientRect();
                const eyeCenterX = eyeRect.left + eyeRect.width / 2;
                const eyeCenterY = eyeRect.top + eyeRect.height / 2;
                const angle = Math.atan2(event.pageX - eyeCenterX, event.pageY - eyeCenterY) * (180 / Math.PI) * -1 + 180;
                eye.style.transform = `rotate(${angle}deg)`;
            });
        };

        document.addEventListener('mousemove', handleMouseMove);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    return (
        <>
            <Helmet>
                <title>ERROR 404</title>
            </Helmet>
            <div className="kiemelo">

                <h1>404 Error - Page Not Found</h1>
                <h1>Az oldalt törölték vagy sosem létezett.</h1>
                <br />
                <button className='btn kovaszos' onClick={() => navigate('/')}>Vissza a főoldalra</button>
                <br />
                <br />
                <br />
                <br />
                <section className="move-area">
                    <div className='container55'>
                        <div className='eye'></div>
                        <div className='eye'></div>
                    </div>
                </section>
            </div>
            <footer className='roadalt'>
                <div className="container">
                    <div className="row">
                        <div className="footer-col">
                            <h3>befektetésközpont</h3>
                            <ul>
                                <li><Link className='href' to='/impresszum'>Impresszum</Link></li>
                                <li><Link className='href' to='/adatkezelesi-tajekoztato'>Adatkezelési tájékoztató</Link></li>
                            </ul>
                        </div>
                        <div className="footer-col">
                            <h3>segítség</h3>
                            <ul>
                                <li><Link className="href" to='/gyik'>GYIK</Link></li>
                                <li><Link className="href" to='/jogi-nyilatkozat'>Jogi nyilatkozat</Link></li>
                            </ul>
                        </div>
                        <div className="footer-col">
                            <h3>Kapcsolat</h3>
                            <ul>
                                <li><Link className="href" to='https://discord.gg/7YyQckXAxY'>Discord közösség</Link></li>
                                <li><Link className="href" to='/kapcsolat'>Kapcsolatfelvétel</Link></li>
                            </ul>
                        </div>
                        <div className="footer-col">
                            <h3>Kövess minket!</h3>
                            <div class="social-links">
                                <Link className="href minilogo" to='https://www.instagram.com/befekteteskozpont/' aria-label='instagram'><i class="fab fa-instagram"></i></Link>
                                <Link className="href minilogo" to='https://www.facebook.com/befekteteskozpont/' aria-label='facebook'><i class="fab fa-facebook-f"></i></Link>
                                <Link className="href minilogo" to='https://twitter.com/befkozpont' aria-label='twitter'><i class="fab fa-x-twitter"></i></Link>
                                <Link className="href minilogo" to='https://discord.gg/7YyQckXAxY' aria-label='discord'><i class="fab fa-discord"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default ErrorPage;