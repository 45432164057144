import React from "react";
import './Road.css'
import Footer from "../footer/footer";
import { Link } from "react-router-dom";

function Road() {
    return (
        <>
            <div className="savas">
                <div className="negyszog">
                    <div className="fuggvas">
                        <div className="cumo">
                            <div className="topwrapper">
                                <div className="fuggvas2">
                                    <div className="padlas">
                                        <div className="padlasajto">
                                            <h1 className="roadtitle">Befektetői roadmap</h1>
                                        </div>
                                    </div>
                                    <div className="pince">
                                        <div className="pinceajto">
                                            <img src="./imgvideo/circle.webp" alt="" /> {/* circle.png */}
                                            <div className="allomas">
                                                <div className="intheallomas">
                                                    <div className="allszam">1</div>
                                                </div>
                                                <div className="alltarto1 terminal" id="egygy">
                                                    <h3>Bevezetés a befektetésbe</h3>
                                                    <button className='btn btn0 success' onClick={() => { window.location.href = '/roadmap/elso'; }}>Vágjunk bele</button>
                                                </div>
                                            </div>
                                            <div className="allomas2">
                                                <div className="intheallomas">
                                                    <div className="allszam">2</div>
                                                </div>
                                                <div className="alltarto2 terminal" id="kettoto">
                                                    <h3>Fogalmak elsajátítása</h3>
                                                    <button className='btn btn0 success' onClick={() => { window.location.href = '/fogalomtar'; }}>Kezdés</button>
                                                </div>
                                            </div>
                                            <div className="allomas3">
                                                <div className="intheallomas">
                                                    <div className="allszam">3</div>
                                                </div>
                                                <div className="alltarto3 terminal" id="haromom">
                                                    <h3>Értékítélet</h3>
                                                    <button className='btn btn0 success' onClick={() => { window.location.href = '/roadmap/harmadik'; }}>Kezdés</button>
                                                </div>
                                            </div>
                                            <div className="allomas4">
                                                <div className="intheallomas">
                                                    <div className="allszam">4</div>
                                                </div>
                                                <div className="alltarto4 terminal" id="negyegy">
                                                    <h3>A személyiség és a befektetés kapcsolata</h3>
                                                    <button className='btn btn0 success' onClick={() => { window.location.href = '/roadmap/negyedik'; }}>Kezdés</button>
                                                </div>
                                            </div>
                                            <div className="allomas5">
                                                <div className="intheallomas">
                                                    <div className="allszam">5</div>
                                                </div>
                                                <div className="alltarto5 terminal" id="otot">
                                                    <h3>Befektetési stratégiák és Portfóliókezelés</h3>
                                                    <button className='btn btn0 success' onClick={() => { window.location.href = '/roadmap/otodik'; }}>Kezdés</button>
                                                </div>
                                            </div>
                                            <div className="allomas6">
                                                <div className="intheallomas">
                                                    <div className="allszam">6</div>
                                                </div>
                                                <div className="alltarto6 terminal" id="hatat">
                                                    <h3>Hogyan maradjunk naprakészek</h3>
                                                    <button className='btn btn0 success' onClick={() => { window.location.href = '/roadmap/hatodik'; }}>Kezdés</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default Road