import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import './header.css';
import { useLocation, useHistory, useNavigate } from 'react-router-dom';

function Header() {

    const navigate = useNavigate();

    const [nyilacska, setNyilacska] = useState(null);
    const [egyebp, setEgyebp] = useState(null);
    useEffect(() => {
        setNyilacska(document.querySelector("#nyil1"));
        setEgyebp(document.querySelector("#egyp"))
    }, []);
    const handleClick = () => {
        if (nyilacska.classList.contains("fa-caret-down")) {
            nyilacska.classList.remove("fa-caret-down");
            nyilacska.classList.add("fa-caret-up");
        } else {
            nyilacska.classList.remove("fa-caret-up");
            nyilacska.classList.add("fa-caret-down");
        }
    };
    const handleClickalt = () => {
        if (nyilacska.classList.contains("fa-caret-up")) {
            nyilacska.classList.remove("fa-caret-up");
            nyilacska.classList.add("fa-caret-down");
            egyebp.classList.remove("egypan-active");
            egyebp.classList.add("egypan");
        }
    };

    const [hambi, setHambi] = useState(null);
    const [hamnav, setHamnav] = useState(null);
    const [fejes, setFejes] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber"));
        setHamnav(document.querySelector("#hnav"));
        setFejes(document.querySelector("#header"));
    }, []);
    const handleClick2 = () => {
        if (hambi.classList.contains("hamburger")) {
            hamnav.classList.remove("hamnav");
            hamnav.classList.add("hamnav-active")
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            fejes.classList.remove("transgyerek");
            fejes.classList.add("greygyerek");
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.add("hamburger");
            hamnav.classList.remove("hamnav-active");
            hamnav.classList.add("hamnav");
            fejes.classList.remove("greygyerek");
            fejes.classList.add("transgyerek");
        }
    };
    const handleClick3 = () => {
        if (hamnav.classList.contains("hamnav-active")) {
            hamnav.classList.remove("hamnav-active")
            hamnav.classList.add("hamnav")
            hambi.classList.remove("hamburger-active");
            hambi.classList.add("hamburger");
            fejes.classList.remove("greygyerek");
        }
    }

    const location = useLocation();
    const isOnMain = location.pathname === '/';
    const isOnFogalomtar = location.pathname.includes('/fogalomtar');
    const isOnSzabalyok = location.pathname === '/alapszabalyok';
    const isOnKonyvek = location.pathname === '/konyvek';
    const isOnRoadmap = location.pathname === '/roadmap';
    const isOnLogin = location.pathname === '/login';
    const isOnRegister = location.pathname === '/register';
    const isOnKviz = location.pathname === '/fogalomtar/kviz';
    const isOnGyik = location.pathname === '/gyik';
    const isOnKapcs = location.pathname === '/kapcsolat';
    const isOnAdat1 = location.pathname === '/impresszum';
    const isOnAdat2 = location.pathname === '/adatkezelesi-tajekoztato';
    const isOnAdat3 = location.pathname === '/jogi-nyilatkozat';

    const isOnTanfolyam = location.pathname === '/tanfolyam';



    const isOnRoadmap1 = location.pathname === '/roadmap/elso';

    const isOnRoadmap3 = location.pathname === '/roadmap/harmadik';
    const isOnRoadmap4 = location.pathname === '/roadmap/negyedik';
    const isOnRoadmap5 = location.pathname === '/roadmap/otodik';
    const isOnRoadmap6 = location.pathname === '/roadmap/hatodik';



    const [isHovered, setIsHovered] = useState(false);
    const handleHover = () => { setIsHovered(!isHovered); };

    /**/
    const rootos = document.querySelector("body")
    /*
    const [rootos, setRootos] = useState(null);
    useEffect(() => {
        setRootos(document.querySelector("#root"));
    }, []); 
    */
    if (isOnKonyvek === true) {
        rootos.classList.remove("sima");
        rootos.classList.remove("zold");
        rootos.classList.remove("lila");
        rootos.classList.remove("sotet")
        rootos.classList.add("kek");
    } else if (isOnSzabalyok === true) {
        rootos.classList.remove("sima");
        rootos.classList.remove("kek");
        rootos.classList.remove("lila");
        rootos.classList.remove("sotet");
        rootos.classList.add("zold");
    } else if (isOnFogalomtar === true) {
        rootos.classList.remove("sima");
        rootos.classList.remove("kek");
        rootos.classList.remove("zold");
        rootos.classList.remove("sotet");
        rootos.classList.add("lila");
    } else if (isOnRoadmap === true || isOnRoadmap1 === true || isOnRoadmap3 === true || isOnRoadmap4 === true || isOnRoadmap5 === true || isOnRoadmap6 === true) {
        rootos.classList.remove("sima");
        rootos.classList.remove("kek");
        rootos.classList.remove("zold");
        rootos.classList.remove("lila");
        rootos.classList.add("sotet");
    } else {
        rootos.classList.remove("kek");
        rootos.classList.remove("zold");
        rootos.classList.remove("lila");
        rootos.classList.remove("sotet");
        rootos.classList.add("sima");
    }





    return (
        <>
            <header id="header" className={`${isOnFogalomtar ? 'fogalomtar' : ''} ${isOnSzabalyok ? 'szabalyok' : ''} ${isOnKonyvek ? 'konyvek' : ''} ${isOnKviz ? 'konyvek' : ''} ${isOnGyik ? 'konyvek' : ''} ${isOnKapcs ? 'konyvek' : ''} ${isOnAdat1 ? 'konyvek' : ''} ${isOnAdat2 ? 'konyvek' : ''} ${isOnTanfolyam ? 'konyvek' : ''} ${isOnAdat3 ? 'konyvek' : ''}`.trim()}>
                <Link to='/' className="ttl" onClick={() => { handleClick3(); handleClickalt(); }}>Befektetésközpont</Link>
                <Link to='/alapszabalyok' className="linktrans" onClick={handleClickalt}>Befektetési alapszabályok</Link>
                <Link to='/fogalomtar' className="linktrans" onClick={handleClickalt}>Fogalomtár</Link>
                <Link to='/tanfolyam' className="linktrans" onClick={handleClickalt}>Tanfolyam</Link>
                <Link to='/roadmap' className="linktrans" onClick={handleClickalt}>Roadmap</Link>
                <div className="dropfall13" onMouseEnter={() => { handleHover(); handleClick(); }} onMouseLeave={() => { handleHover(); handleClick(); }}>
                    <a href="#" className="nyil">Egyéb&nbsp;
                        <i className="fa fa-caret-down" id="nyil1"></i>
                        <div className={` ${isOnSzabalyok ? 'dropcolorszabalyok' : ''} ${isOnMain ? 'transdropcolor' : ''} dropdown12`.trim()}>
                            <Link to='/konyvek' className="drop12alt" onClick={handleClickalt}>Könyvek</Link>
                        </div>
                    </a>
                </div>

                <img src="/imgvideo/egyik2.svg" alt="egyik" width='25px'/> {/* onClick={() => navigate('/login')} */}

                <div className="flexfasz">
                    <div className="hamburger" onClick={handleClick2} id="hamber">
                        <span className="bar"></span>
                        <span className="bar"></span>
                        <span className="bar"></span>
                    </div>
                </div>
            </header>

            <div className={` ${isOnFogalomtar ? 'kkkdc' : ''} hamnav`.trim()} id="hnav">
                <Link to='/alapszabalyok' className="hamref" onClick={handleClick2}>Befektetési alapszabályok</Link>
                <Link to='/fogalomtar' className="hamref" onClick={handleClick2}>Fogalomtár</Link>
                <Link to='/tanfolyam' className="hamref" onClick={handleClick2}>Tanfolyam</Link>
                <Link to='/roadmap' className="hamref" onClick={handleClick2}>Roadmap</Link>
                <Link to='/konyvek' className="hamref" onClick={handleClick2}>Könyvek</Link>
            </div>
        </>
    )
}

export default Header;