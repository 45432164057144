import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Elsobbsegireszveny() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Elsőbbségi részvény - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted az elsőbbségi részvény jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Elsőbbségi részvény</h1>
                            <p>Ezen az oldalon megismerheted az elsőbbségi részvény jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">Az elsőbbségi részvény olyan részvény, amely valamilyen előnyben részesíti a tulajdonosát a hagyományos törzsrészvényekkel szemben.</p>
                            <p className="majonator">Az ilyen részvénnyel járó előny lehet extra szavazati jog, korábbi osztalékfizetés, visszavásárlási jog vagy ha a vállalatot felszámolják, akkor az elsőbbségi részvényesek gyakran előnyt élveznek a vállalat vagyonának szétosztásakor. Befektetés szempontjából azért lehet sok embernek kedvező, mert nem annyira kockázatos, mint egy hagyományos részvény, viszont magasabb a hozama a kötvényeknél.</p>
                            <hr />
                            <h2>Fajtái</h2>
                            <p className="majonator azam">Osztalékelsőbbségi részvény</p>
                            <p className="majonator">Az osztalékelsőbbségi <Link to='/fogalomtar/reszveny' className='dezord'>részvény</Link> tulajdonosa elsőbbséget élvez <Link to='/fogalomtar/osztalek' className='dezord'>osztalékfizetéskor</Link>. Egy vállalaton belül az osztalékelsőbbségi részvények és a törzsrészvények árfolyama eltér. Tulajdonosai szavazati joggal általában nem rendelkeznek. Az amerikai részvénypiacon bizonyos árfolyamon a részvénytársaság visszahívja az osztalékelsőbbségi részvényeket. Az osztalékelsőbbségi részvények vállalattól függően átalakíthatók törzsrészvényekké.</p>
                            <br />
                            <p className="majonator azam">Likvidációs hányadhoz kapcsolódó elsőbbségi részvény</p>
                            <p className="majonator">Az elsőbbségi részvény tulajdonosát a vállalat felszámolása esetén nagyobb eséllyel és elsőként fizetik ki. Felszámolási eljárás esetében a vállalat összes eszközét arra fordítják, hogy kifizessék az eljárás költségét. A fennmaradó összegből a hitelezőket, részvényeseket és kötvényeseket kárpótolják. A hitelezők elsőbbséget élveznek a kötvényesek és részvényesek felett. A kötvényesek elsőbbséget élveznek a részvényesek felett, vagyis a részvényes jár a legrosszabbul.</p>
                            <hr />
                            <h2>Kumulatívitás és visszahívás</h2>
                            <p className="majonator">Megkülönböztetünk kumulatív és nem kumulatív részvényt.  Kumulatív részvény esetén, ha elmarad az osztalékfizetés, akkor a vállalatnak azt később pótolnia kell, míg a nem kumulatív esetében nem áll kötelességében pótolnia.</p>
                            <p className="majonator">Az elsőbbségi részvények ára kisebb mértékben emelkedhet, mint a törzsrészvényeké, és általában a kibocsátási árfolyamuktól számított néhány dolláron belül kereskednek velük, leggyakrabban 25 dollár körül. Az, hogy a 25 dollárhoz képest diszkontot vagy prémiumot fizetnek a befektetők, a társaság hitelképességétől és a kibocsátás sajátosságaitól függ: például attól, hogy a részvények kumulatívak-e vagy visszahívhatóak-e. Ha a részvények visszahívhatóak, a kibocsátó egy meghatározott időpont után névértéken visszavásárolhatja azokat. Ha például csökkennek a piaci kamatok, a társaság lehívhatja részvényeit, és újabb sorozatot bocsáthat ki alacsonyabb hozamon.</p> {/* Gondolkozz */}
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Elsobbsegireszveny