import React from 'react'
import Fogmain from '../fogalomtar-main/fogmain'
import { Helmet } from 'react-helmet-async'

function Fogalomtar() {
    return (
        <>
            <Helmet>
                <title>Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="A fogalomtár egy olyan fogalmakat, kifejezéseket és mutatókat összegyűjtő és magyarázó felület, melyek megtanulásával megismerheted a befektetési világ nyelvezetét." data-react-helmet="true" />
            </Helmet>
            <Fogmain />
        </>
    )
}

export default Fogalomtar
