import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Osztalek() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Osztalék - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted az osztalék jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>


                {/*
                Mivel az osztalékot a vállalat nyereségéből fizetik, a magasabb osztalékfizetés azt jelentheti, hogy a vállalat bevételei emelkednek, ami magasabb részvényárfolyamokat eredményezhet. A magasabb részvényárfolyamokkal járó magasabb osztalékoknak a hozam folyamatos vagy csekély mértékű emelkedéséhez kell vezetniük. A részvényárfolyam emelkedése nélküli jelentős hozamnövekedés azonban azt jelentheti, hogy a vállalat a nyereség növekedése nélkül fizet osztalékot, és ez rövid távú cash flow-problémákra utalhat.
                */}

                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Osztalék</h1>
                            <p>Ezen az oldalon megismerheted az osztalék jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">Az osztalék az a kifizetett összeg, amit egy vállalat a nyereségéből a részvényesek között szétoszt.</p>
                            <p className="majonator">Az osztalékot leggyakrabban negyedévente fizetik ki. Az osztalékfizetés mértékét a vállalat igazgatótanácsa határozza meg. Sok vállalat nem fizet osztalékot, ehelyett visszatartja a nyereséget, hogy azt a vállalatba fektesse vissza. Minden vállalat saját osztalékfizetési politikával rendelkezik. Az osztalékfizetés gyakorisága és mértéke változhat a gazdasági ciklusok során. Például recesszió idején a vállalatok hajlamosak csökkenteni vagy megszüntetni az osztalékfizetést.</p>
                            <hr />
                            <h2>Működése</h2>
                            <p className="majonator">Minden részvénytársaságnak lehetősége van dönteni, hogy az adott nyereséget teljes mértékben visszaforgatja a <Link to='/fogalomtar/vallalat' className='dezord'>vállalatba</Link>, vagy esetleg kifizeti egy részét a részvényeseknek. Az osztalék a vállalatban birtokolt hányad aranyában kerül kiosztásra a részvényesek között. Minél kedvezőbb növekedési ütemet tud felmutatni egy vállalat, annál inkább készek a befektetők elfogadni az alacsonyabb osztalékfizetési politikát.</p>
                            <br />
                            <p className="majonator azam">Osztalékhozam</p>
                            <p className="majonator">Az osztalékhozam az egy <Link to='/fogalomtar/reszveny' className='dezord'>részvényre</Link> jutó osztalékot fejezi ki százalékban, amit úgy kapunk meg, hogy a részvényenként fizetett osztalékot elosztjuk a részvény árával, majd megszorozzuk 100-zal. Például, ha egy részvényre 200 forintot fizetnek osztalékként, és a részvény árfolyama 5000 forint, akkor az osztalékhozam 4%. Az átlagos osztalékhozam 2% körül szokott mozogni.</p>
                            <hr />
                            <h2>Osztalékfizetési dátumok</h2>
                            <p className="majonatora">Announcement date: Ekkor jelenti be a vállalat az osztalékfizetést és annak időpontját</p>
                            <p className="majonatora">Ex-dividend date: Ezen a napon jár le az osztalékra való jogosultság. Aki e nap után vásárol részvényt, az nem kap osztalékot.</p>
                            <p className="majonatora">Record date: Eddig a napig kell birtokolnod a részvényt, hogy kapj az osztalékból. Ezen a napon írja össze a vállalat, hogy kik jogosultak az osztalék megszerzésére.</p>
                            <p className="majonatora">Payment date: Ekkor írják jóvá a pénz a befektetők számláján.</p>
                            <hr />
                            <h2>Osztalékfizető vállalatok</h2>
                            <p className="majonator">Amikor a hangsúlyt egy vállalat elsődlegesen nem a növekedésre helyezi, és a részvényt ,,jövedelemtermelő papírnak" minősítik a befektetők, akkor az osztalék mértéke válik a piaci árfolyamot meghatározó első számú tényezővé. A skála másik végén a gyorsan növekvő kategóriába tartozó részvények állnak, a <Link to='/fogalomtar/novekedesi-reszveny' className='dezord'>növekedési részvények</Link>. A növekedési vállalatokat elsősorban a következő évtizedre várt növekedési ütem alapján értékeli a piac, az osztalék mértéke többé-kevésbé lényegtelen. Számos vállalat a növekedési és a nem növekedési vállalatok közötti tartományban helyezkedik el.</p>
                            <p className="majonator">Az osztalékfizetés leginkább azokra a vállalatokra jellemző, amik az alábbi befektetési szektorokba tartoznak: energia, közmű, pénzügy, alapanyag és fogyasztói. A fejlődés korai szakaszában lévő vállalatok között nem valószínű az osztalékfizetés.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Osztalek