import React, { useState } from 'react';
import Footer from '../footer/footer';
import './footerpages.css'
import axios from 'axios';
import { toast } from 'react-hot-toast';
import ReCAPTCHA from "react-google-recaptcha"
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';


const Kapcsolat = () => {

    const [data, setData] = useState({
        email: '',
        uzenet: '',
        capVal: null,
        adatk: false
    })

    const UzenetKuldes = async (e) => {
        e.preventDefault()

        //data destructure - backendből jövő data ?!
        const { email, uzenet, capVal, adatk } = data
        try {
            const { data } = await axios.post('/kapcsolat', { email, uzenet, capVal, adatk });

            if (data.error) {
                toast.error(data.error);
            } else {
                setData(prevState => ({ ...prevState, email: '', uzenet: '', capVal: null, adatk: null }));
                // ReCAPTCHA újraállítása
                if (window.grecaptcha) {
                    window.grecaptcha.reset();
                }
                toast.success('Üzenet sikeresen elküldve!');
            }
        } catch (error) {
            console.error(error);
            toast.error('A szerver fejlesztés alatt áll. Kérjük lépjen kapcsolatba velünk a befekteteskozpont@gmail.com címen.');
        }
    }

    return (
        <>
            <Helmet>
                <title>Kapcsolat | Befektetésközpont</title>
            </Helmet>
            <div className='kapcscikk'>
                <div className='kapcsolatkeret'>
                    <h1>Kapcsolat</h1>
                </div>
                <form className='formaloember' onSubmit={UzenetKuldes}>
                    <input type="email" class="form__input" name="email" placeholder="Email" value={data.email} onChange={(e) => setData({ ...data, email: e.target.value })} />
                    <textarea placeholder="Üzenet" name="uzenet" class="form__input" maxlength="1000" value={data.uzenet} onChange={(e) => setData({ ...data, uzenet: e.target.value })}></textarea>
                    <br />
                    <div className='adatkbox checkbox-wrapper-2'>
                        <p>Az <Link to='/adatkezelesi-tajekoztato' className='dezord'>adatkezelési tájékoztatót</Link> elolvastam és elfogadom.</p>
                        <input type="checkbox" name="adatkezeles" value="Bike" class="sc-gJwTLC ikxBAC" checked={data.adatk} onChange={(elfogad) => setData({ ...data, adatk: elfogad.target.checked })} />
                    </div>
                    <br />
                    <div className='wowowoweeee'>
                        <ReCAPTCHA sitekey='6LcRhIspAAAAAHvKtIQM16ao2N0dBhh4PkfiSdMa' className='g-recaptcha' onChange={(value) => setData({ ...data, capVal: value })} />
                        <br />
                        <button class="btn form__submit respbtner" type="submit">Elküld</button>
                    </div>
                </form>
            </div>
            <Footer />
        </>
    );

};

export default Kapcsolat;