import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Diverzifikacio() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Diverzifikáció - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a diverzifikáció jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Diverzifikáció</h1>
                            <p>Ezen az oldalon megismerheted a diverzifikáció jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A diverzifikáció a kockázat megosztásának azon módja, amikor a tőkét több különböző befektetési eszközben osztjuk szét.</p>
                            <p className="majonator">A kockázatcsökkentés egyik hatékony eszköze a diverzifikáció. Lehet diverzifikálni eszközök és eszközosztályok között vagy például földrajzi elosztás szerint is. Fontos azonban megjegyezni, hogy érdemesebb kevesebb eszközre koncentrálni a portfóliónk, amennyiben olyan elemek alkotják melyekről tudjuk, hogy kellőképpen jövedelmezőek lesznek. Így nem kényszerítjük csupán a diverzifikálás kedvéért kisebb hozamra a portfóliónk.</p>
                            <hr />
                            <h2>Módszerek</h2>
                            <p className="majonator azam">Eszközosztályok között</p>
                            <p className="majonator">A diverzifikáció ennek a formája azt jelenti, hogy a befektetéseinket nem egy adott eszközosztályra korlátozzuk, hanem többet választunk ki és azokban szórjuk szét a tőkét. Például ha nem csak részvényeket tartunk a <Link to='/fogalomtar/portfolio' className='dezord'>portfóliónkban</Link>, hanem kötvényt, ingatlant, nemesfémeket, különböző devizákat stb.</p>
                            <br />
                            <p className="majonator azam">Eszközosztályon belül</p>
                            <p className="majonator">Az eszközosztályon belüli diverzifikáció azt jelenti, hogy egy adott eszközosztályon belül több különböző befektetési eszközbe fektetünk be. Például ha az eszközosztály a részvény, akkor nem csak egyetlen részvénybe fektetünk, hanem több különbözőbe. Olyan formában is növelhető a diverzifikáció, hogy több különböző <Link to='/fogalomtar/szektor' className='dezord'>befektetési szektorban</Link> szórjuk szét a tőkénk, például technológia, egészségügy, közmű, ipar stb.</p>
                            <hr />
                            <h2>Szemléltetés példán keresztül</h2>
                            <p className="majonator">Van 2 portfólióm. Az egyik portfólióban 1 adott részvénybe (A) fektettem 1 millió forintot. A másik portfólióban 4 különböző részvényen (A, B, C, D) osztottam szét az 1 millió forintot. Amennyiben ,,A" részvény árfolyama 20%-ot esik, akkor az első portfóliómmal 200.000 forintot vesztek, míg a második portfólióval csak 50.000 forintot. Itt azonban fontos megjegyezni, hogy 20% növekedés esetén már az első portfólió járna jobban.</p>
                            <hr />
                            <h2>Előnyök és hátrányok</h2>
                            <p className="majonator">A diverzifikáció legfőbb előnye és egyben a célja is, a kockázat csökkentése. Egy megfelelően diverzifikált portfólióval csökkenthetjük a <Link to='/fogalomtar/volatilitas' className='dezord'>volatilitás</Link> és a veszteség portfóliónkra gyakorolt hatását. Továbbá a diverzifikáltabb portfóliók hosszú távon potenciálisan nagyobb hozamot eredményeznek. Hátránynak számít azonban, hogy sokszor rövid távon magas hozamokról kell lemondanunk. A diverzifikálás költségei könnyen magasak lehetnek és sok időt felemészthet a portfólió részletes kezelése.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Diverzifikacio