import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Portfolio() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Portfólió - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a portfólió jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Portfólió</h1>
                            <p>Ezen az oldalon megismerheted a portfólió jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A portfólió olyan befektetési eszközök gyűjteménye, melyet egy befektető a pénzügyi céljai elérése érdekében kezel és/vagy kezeltet.</p>
                            <p className="majonator">A portfólió tükrözni a befektető kockázattűrő képességét, hozamcéljait, időkeretét, adózási helyzetét, likviditási igényeit és egyéb egyedi tényezőket. A portfólió összetételétől függetlenül minden portfólióban elengedhetetlen egy bizonyos fokú diverzifikáció. A részvényeket és a kötvényeket általában a portfóliók alapvető elemeinek tekintik, bár rengeteg más alkotóelemeik lehetnek, sőt számos befektető se részvényekkel, se kötvényekkel nem rendelkezik.</p>
                            <hr />
                            <h2>Portfólió elemei</h2>
                            <p className="majonator">Egy portfólió olyan elemeket tartalmazhat, mint a részvények, kötvények, készpénz, befektetési jegyek, ingatlanok, alapanyagok vagy akár különböző értéktárgyak stb. A portfólió összetétele a befektető kockázatvállalási hajlandóságától és befektetési céljaitól függ. A legnépszerűbb megközelítés a részvény-kötvény arány kialakítása egy portfólióban. Ebben a modellben a befektető a preferenciái szerint kötvényekkel ellensúlyozza a részvények kockázatát. Például egy kockázatkerülőbb befektetőnek lehet 30% részvény 70% kötvény portfóliója.</p>
                            <hr />
                            <h2>Portfóliókezelés</h2>
                            <p className="majonator">A befektető dönthet úgy, hogy saját maga tartja és kezeli portfólióját, vagy megbízhat egy pénzügyi tanácsadót vagy más pénzügyi szakembert portfóliójának kezelésével.</p>
                            <p className="majonator">A portfóliók kezelése egy összetett folyamat, amely számos tényezőt figyelembe vesz. A befektetők számára fontos, hogy megértsék ezeket a tényezőket, hogy hatékonyan tudják kezelni a portfóliójukat. A megfelelő instrumentumok kiválasztása előtt érdemes felmérni a kockázatvállalási toleranciánkat és hogy valójában mik is a befektetéssel kapcsolatos terveink. Ezt követően elemezni kell az adott befektetési eszközt. Fontos levonni olyan következtetéseket, hogy alul- vagy felülértékelt-e egy instrumentum, vagy hogy milyen mértékben alakítja át a portfóliónkat, ha kiválasztjuk. Ezekben segítségünkre lehet a fundamentális elemzés.</p>
                            <p className="majonator">A portfóliók kialakításában fontos szerepet játszik a diverzifikáció. A diverzifikáció a kockázat megosztásának azon módja, amikor a tőkét több különböző befektetési eszközben osztjuk szét. Lehet diverzifikálni eszközosztályon belül és eszközosztályok között, vagy akár földrajzi elosztás szerint is. A diverzifikációról bővebben <Link to='/fogalomtar/diverzifikacio' className='dezord'>itt olvashatsz</Link>.</p>
                            <hr />
                            <h2>Portfóliók típusai</h2>
                            <p className="majonatora azam">Agresszív portfólió</p>
                            <p className="majonatora">Magasabb kockázatú eszközöket tartalmaz a nagyobb hozam elérése érdekében. Általában pénzügyileg stabil, magas volatilitású befektetések alkotják, mint például a növekedési részvények. Olyan embereknek érdemes választaniuk, akik a nagyobb hozamok érdekében képesek az átlagosnál jóval magasabb kockázatot vállalni.</p>
                            <br />
                            <p className="majonatora azam">Defenzív portfólió</p>
                            <p className="majonatora">A célja az, hogy egy olyan portfóliót képezzen, ami bármilyen gazdasági körülmény között megállja a helyét. Jellemzően alacsony kockázatú befektetésekből épül fel, mint például kötvények, kincstárjegyek vagy blue-chip részvények. Ideális választás lehet kockázatkerülőbb, stabilabb befektetést preferáló befektetők számára.</p>
                            <br />
                            <p className="majonatora azam">Spekulatív portfólió</p>
                            <p className="majonatora">Ebben az esetben a kockázatvállalási hajlandóság hatalmas. A portfóliót olyan elemek építik fel, mint például IPO részvények, nagyon alacsony kapitalizációjú vállalatok részvényei vagy kisebb kriptovaluták. Spekulatív portfólió mellett csak akkor szabad dönteni, ha a befektető tisztában van a kockázatokkal és megfelelően felkészült a veszteségekre.</p>
                            <br />
                            <p className="majonatora azam">Jövedelemtermelő portfólió</p>
                            <p className="majonatora">A jövedelemtermelő portfóliót alacsony kockázatú, stabil hozamú befektetések alkotják. Alkotóelemei lehetnek osztalékrészvények, kötvények, ingatlanok. Ez részben beletartozik a defenzív portfólió kategóriába. Ideális lehet azok számára, akik egy alacsony kockázatú, stabil és állandó jövedelmet termelő eszközre vágynak.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Portfolio