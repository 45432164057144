import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Order() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Order - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted az order jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Order</h1>
                            <p>Ezen az oldalon megismerheted az order jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">Az order, vagyis tőzsdei megbízás, egy megrendelés valamilyen tőzsdei tranzakció elvégzéséhez.</p>
                            <p className="majonator">A megbízás egy bróker számára adott utasítás, hogy a befektető nevében vásároljon vagy adjon el egy eszközt. A megbízásoknak 4 alap fajtája van: market, limit, stop, stop-limit. Ezeket a megbízásokat ma már jellemzően elektronikus úton végzik, automatizált rendszereken keresztül. A különböző megbízások lényege, hogy a kereskedő úgy befolyásolhatja a tranzakciót, hogy megváltoztatja a megbízás teljesítésének árát és idejét.</p>
                            <hr />
                            <h2>Order működése</h2>
                            <p className="majonator">A kereskedő egy order formájában utasítást ad ki a <Link to='/fogalomtar/broker' className='dezord'>brókernek</Link>, hogy a nevében vásároljon vagy adjon el eszközöket. A megbízás típusa azt határozza meg, hogy a brókernek a vétel vagy eladás mellett milyen egyéb utasítást kell elvégeznie. </p>
                            <p className="majonator">A tőzsdén az eladáshoz kell lennie egy vevőnek, aki hajlandó kifizetni az eladási árat. Vásárláshoz pedig kell lennie egy eladónak, aki hajlandó eladni a vevő árán. Ha a vevő és az eladó nem találkozik ugyanazon az áron, akkor nem jön létre tranzakció. Ahogy teljesülnek a megbízások, úgy változnak az árszintek. Például, ha egy részvény árfolyama emelkedik és van rá 10 és 10,01 dolláros ajánlat, majd az összes 10 dolláros megbízást teljesítették, akkor a legolcsóbb ajánlat 10,01 dollár lesz. A piaci ár mozgását a vételi és az eladási megbízások számának és méretének megoszlása dönti el.</p>
                            <hr />
                            <h2>Order fajtái</h2>
                            <p className="majonator azam">Market order</p>
                            <p className="majonator">Market order esetén a bróker feladata a vételi vagy eladási megbízást a lehető legjobb áron való teljesítenie. A market megbízásokat azonnal végrehajtják, amint a befektető utasítást ad rá, kivéve ha <Link to='/fogalomtar/likviditas' className='dezord'>likviditási</Link> problémák merülnek fel az eszköznél. Erre szoktunk a ,,hagyományos, normális'' megbízásként tekinteni.</p>
                            <br />
                            <p className="majonator azam">Limit order</p>
                            <p className="majonator">A limit megbízásnál egy bizonyos árhoz kötjük egy új pozíció vételének vagy eladásának elvégzését. Limit vétel során azt a legmagasabb árat adjuk meg, amin még hajlandóak vagyunk végrehajtani a tranzakciót. A limit megbízás a megadott árnál magasabban nem, vagyis csak az alatt teljesülhet. Ugyanez igaz fordítva, a limit eladásra is. A limit megbízások addig maradhatnak érvényben, amíg végre nem hajtják őket, le nem járnak, vagy nem törlik őket.</p>
                            <br />
                            <p className="majonator azam">Stop order</p>
                            <p className="majonator">A stop megbízás lényege, hogy egy bizonyos ár elérése után aktiválódik egy market order. A stop vétel a kedvező áron való long pozíció, míg a stop eladás egy kedvező short pozíció indításához használatos. A stop megbízások addig maradhatnak érvényben, amíg végre nem hajtják őket, le nem járnak, vagy nem törlik őket.</p>
                            <p className="majonator">A stop order láthatatlan a piac számára, míg a limit megbízások nyilvánosak. A limit ordernél megadott érték jelzi a utolsó, még elfogadható árat, amin végbemehet a tranzakció, míg a stop order esetében amikor az ár eléri a megadott értéket, akkor elindít egy market megbízást. </p>
                            <br />
                            <p className="majonator azam">Stop-limit order</p>
                            <p className="majonator">A stop-limit order a nevéből is utal arra, hogy a stop és a limit megbízás kombinációja. A stop-limit order egy meghatározott stop-áron elindít egy megbízást, de csak akkor hajtja végre a tranzakciót, ha az egy megadott limitáron teljesíthető. A stop-limit order során előfordulhat, hogy a tranzakció nem, vagy csak részben teljesül. Ez a megbízás a kereskedési tranzakciók kockázatcsökkentésére használatos.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Order