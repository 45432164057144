import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Akvizicio() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Akvizíció - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted az akvizíció szó jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Akvizíció</h1>
                            <p>Ezen az oldalon megismerheted az akvizíció szó jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className='majonator aza'>Az akvizíció egy vállalat részéről, egy másik vállalatban való részesedés, illetve ellenőrzés megszerzését jelenti.</p>
                            <p className='majonator'>Ez úgy megy végbe, hogy az akvizíciót indító vállalat kellő mennyiséget vásárol a másik vállalat részvényeiből ahhoz, hogy a felvásárló fél többségi résztulajdont szerezzen, ami által befolyást nyer a szervezet irányításában. Egy akvizíció leggyakoribb okai között ott van az új technológia megszerzése, a piaci részesedés növelése, a földrajzi diverzifikáció, az adóoptimalizálás és a versenytársak ellehetetlenítése is.</p>
                            <hr />
                            <h2>Baráti vagy ellenséges akvizíció</h2>
                            <p className='majonator'>Baráti akvizíció esetén a felvásárlóra nem tekintenek ellenségesen, a résztvevő vállalatok együttműködnek, így a folyamat gond nélkül végbemehet. Ellenséges akvizíciónál azonban a felvásárolt vállalat ellenséges hozzáállást tanúsít az akvizíciót indító vállalattal szemben, nem hajlandó együttműködni azzal.</p>
                            <p className='majonator'>Ellenséges akvizíció esetén a folyamat rendkívül nehézzé és időigényessé válhat. Sok esetben ilyenkor a felvásárolandó vállalt megpróbálja megnehezíteni a felvásárló dolgát. Olyan eszközökhöz folyamodhatnak, mint például egy másik felvásárló keresése, szabályozó hatóságok vagy a felvásárló vállalat részvényeinek manipulálása. Az akvizíciót indító vállalat a részvények felvásárlása során egyre nagyobb befolyást szerez a vállalatban, míg végül az irányítása alá nem kerül. Minél nagyobb egy vállalat, annál nehezebb felvásárolni.</p>
                            <hr />
                            <h2>Árfolyamra gyakorolt hatása</h2>
                            <p className='majonator'>A legtöbb esetben a bejelentést követően az akvizíciót indító vállalat részvényárfolyama esésbe kezd. Ez abból fakad, hogy a felvásárló fél prémiumot fizet a megvásárolandó vállalat részvényeiért. Minél nagyobb prémiumot fizet a felvásárló, annál negatívabb hatással lehet a saját részvényárfolyamára. Ellenséges akvizíció esetén minden, árfolyamra ható negatív hatás felerősödik.</p>
                            <p className="majonator">A felvásárolandó vállalat részvényárfolyama a legtöbb esetben emelkedik, ami szintén a prémiumból fakad. Itt azért fontos kiemelni, hogy a legtöbb esetben, mert jelentősen függ attól, hogy melyik vállalat a felvásárló, és hogy milyen szándékkal teszi azt.</p>
                            <p className="majonator">A részvényenként fizetendő prémiumon kívül hatással lehet mind a két fél részvényeinek árfolyamára a szabályozó hatóság beavatkozása, a megtérülés mértéke és az, hogy mennyire sikeres a felvásárolandó vállalat integrálása a felvásárló vállalat struktúrájában.</p>
                            <hr />
                            <h2>Társaságok kategorizálása akvizícióhoz való hozzáállás szerint</h2>
                            <p className='majonatora azam'>Növekedésorientált vállalat:</p>
                            <p className='majonatora'>Ezt az elvet követő vállalatok azt vallják, hogy mindegy mit vesznek csak a társaság nagyobbnak tűnjön.</p>
                            <br />
                            <p className="majonatora azam">Versenyelőny-orientált vállalat:</p>
                            <p className="majonatora">Ebben az esetben a vállalat vezetősége alaposan átvizsgáltja az akvizíció életképességét és megtérülését.</p>
                            <hr />
                            <h2>Egyesülés</h2>
                            <p className="majonator">Az egyesülés az akvizíció egy olyan formája, amiben a két vállalat egybeolvad, így egyetlen szervezetet alkotva. Ilyen ügylet általában olyan felek között megy végbe, akiknek a méretük, az ügyfélkörül, illetve a működési elvük megegyezik vagy hasonlóak. Az egyesítések célja leggyakrabban a nagyobb piaci részesedés szerzése, a működési költségek csökkentése, az új területekre való terjeszkedés vagy a rossz állapotban lévő vállalatok életképesebbé tétele. Ezek a célok mind előnyösek a vállalatok részvényesei számára. Az egyesülés után az újonnan alakult vállalat részvényeit mindkét vállalat meglévő részvényesei között osztják szét. A két részvény konvertálását ki lehet használni <Link to='/fogalomtar/arbitrazs' className='dezord'>arbitrázs</Link> ügyletekkel.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Akvizicio