import React from 'react'
import '../../App.css'
import './Topvideo.css';

function Topvideo() {
  return (
    <div className='vid-container'>
      <video src='/imgvideo/Untitled.mp4' autoPlay loop muted/>
      <div className='centerer'>
        <h1 fetchpriority='high'>Vállj te is befektetővé!</h1>
      </div>
    </div>
  )
}

export default Topvideo