import React from 'react'
import Footer from '../footer/footer'
import './footerpages.css'
import { Helmet } from 'react-helmet-async'

function Jogi() {
    return (
        <>
            <Helmet>
                <title>Jogi nyilatkozat | Befektetésközpont</title>
            </Helmet>
            <div className='szovegwrappener'>
                <div className='cimwrappener'>
                    <h1>Jogi nyilatkozat</h1>
                </div>
                <div className='contentumwrap'>
                    <p><strong>Kockázati felhívás az oldalra felkerülő tartalmak miatt</strong></p>
                    <p>Az oldalon megjelenő tartalom magánvélemény, kérjük ne alapozz rá semmilyen befektetési döntést! Az oldalra felkerülő tartalom kizárólag információs célt szolgál és nincs figyelemmel egyetlen felhasználó befektetési céljaira sem, és nem minősül befektetési tanácsadásnak. Az itt található információ csak jelzés értékűnek tekinthető, mivel annak pontossága és teljessége nem garantált. A kereskedési, illetve kockázatkezelési döntések meghozatalakor nem javasolt az oldalon található információkra támaszkodni, az elhangzott vélemények és értelmezések előzetes értesítés nélkül bármikor módosíthatók. Weboldalunkon publikált tartalomnak nem célja a részvény és értékpapírvásárlásra való ösztönzés. Az oldal készítői semmilyen felelősséget nem vállalnak a részvény és értékpapírvásárlásból eredő károkért!</p>
                    <p>Az oldalon található tartalom semmilyen esetben nem minősíthető befektetésre való ösztönzésnek, értékpapírvásárlásra vagy -eladására vonatkozó felhívásnak, még abban az esetben sem, ha valamely befektetési módszerrel kapcsolatos leírás eladás vagy vétel mellett foglal állást! A jelen oldalon található információk és elemzések a szerzők magánvéleményét tükrözik. A jelen oldalon megjelenő írások nem valósítanak meg a 2007. évi CXXXVIII törvény (Bszt.) 4. § (2). bek 8. pontja szerinti befektetési elemzést és a 9. pont szerinti befektetési tanácsadást. Az előbb írtakra tekintettel az oldal üzemeltetője, szerkesztői, és szerzői kizárják mindennemű felelősségüket az oldalon megjelenő információra vagy adatra alapított egyes befektetési döntésekből származó bármilyen közvetlen vagy közvetett kárért vagy költségért.</p>
                    <p>A jelentések, cikkek tartalma reklámnak minősül és nem valósít meg befektetési tanácsadást, illetve befektetési elemzést. Az oldalon leírtak szakértőink egyéni álláspontját tükrözi és nem veszi figyelembe az azt olvasó egyének személyes körülményeit, befektetési tapasztalatait vagy aktuális pénzügyi helyzetét. Továbbá, a jelentés nem a befektetési elemzés függetlenségét célzó jogi követelmények szerint készült. A jelentést használó személyeknek tisztában kell lenniük azzal, hogy akár jelentős veszteségeket szenvedhetnek el. Mindezek alapján  a hírt tovább közlő üzemeltető nem vállal semmilyen felelősséget a kereskedők veszteségeiért, melyek a jelentés tartalmának felhasználásából fakadnak.</p>
                    <p>A múltbeli adatokból nem lehet teljes biztonsággal a jövőre vonatkozó megállapításokat tenni. A bemutatott technikák elemei is a múltbeli árfolyam adatok felhasználásából lettek kialakítva, így semmi garancia nincs arra, hogy a jövőben eredményesen működtethetők az egyes kereskedési technikák. Kizárólag saját felelősségre, demó, nem valós pénzt tartalmazó számlán javasolt a használata.</p>
                    <p><strong>Kockázati felhívás az oktatóanyagokkal, tanfolyamokkal  kapcsolatosan</strong></p>
                    <p>A fentiekben ismertetett kockázatok miatt az oktatáson alkalmazott módszereket demó kereskedési számlán alkalmazza. Az előadás szervezője, az oktató, az oldal üzemeltetője kizárják mindennemű felelősségüket az oktatóanyagban esetleg megjelenő információra vagy adatra alapított egyes befektetési döntésekből származó bármilyen közvetlen vagy közvetett kárért vagy költségért. A weboldalon található információk és elemzések a szerzők magánvéleményét tükrözik, nem valósítanak meg a 2007. évi CXXXVIII törvény (Bszt.) 4. § (2). bek 8. pontja szerinti befektetési elemzést és a 9. pont szerinti befektetési tanácsadást.</p>
                    <p><strong>Kockázati felhívás az oldalon használt beépülő alkalmazásokkal kapcsolatban</strong></p>
                    <p>Az oldalunk egyes domain-jein és aldomain-jein külső weboldalak alkalmazásai futnak, például Tradingview árfolyam ablak vagy a Discord. Ezek használatával elhagyja oldalunkat, így az ott megjelenő tartalomért, reklámokért felelősséget nem tudunk vállalni.</p>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Jogi
