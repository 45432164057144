import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import './footerpages.css';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Fade from '@mui/material/Fade';
import Footer from '../footer/footer';
import { Helmet } from 'react-helmet-async';

function Gyik() {

    const [expanded, setExpanded] = React.useState(false);

    const handleExpansion = () => {
        setExpanded((prevExpanded) => !prevExpanded);
    };

    return (
        <>
            <Helmet>
                <title>GYIK | Befektetésközpont</title>
            </Helmet>
            <div className='kerdescont'>
                <div className='gyikcim'>
                    <h1>GYIK</h1>
                </div>
                <div className='kerdesek'>
                    <Accordion
                        expanded={expanded}
                        onChange={handleExpansion}
                        sx={{
                            backgroundColor: '#1e1e1e',
                            color: 'white',
                            width: ['400px', '550px', '800px'],
                            '@media (max-width: 600px)': {
                                width: '100%',
                            },
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            className='spandd'
                        >
                            <Typography>Mi az a Befektetésközpont?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className='leiras'>
                                A Befektetésközpont egy olyan weboldal, ami számos szolgáltatásával segít az embereknek bevezetni őket a befektetések világába. Az oldal fő célja, hogy támogassa a befektetőket, és hogy egy közösséget teremtsen nekik.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{
                        backgroundColor: '#1e1e1e',
                        color: 'white',
                        width: ['400px', '550px', '800px'],
                        '@media (max-width: 600px)': {
                            width: '100%',
                        }
                    }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                            className='spandd'
                        >
                            <Typography>Hogyan lehetséges a Befektetésközpont közösségéhez csatlakozni?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className='leiras'>
                                Amennyiben te is a közösségünk tagjává szeretnél válni, azt megteheted a discord szerverünkhöz való csatlakozással. <Link to='https://discord.gg/4fGXtZESFQ' className='dezord'>Ide kattintva</Link> beléphetsz a discord közösségbe.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{
                        backgroundColor: '#1e1e1e',
                        color: 'white',
                        width: ['400px', '550px', '800px'],
                        '@media (max-width: 600px)': {
                            width: '100%',
                        }
                    }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                            className='spandd'
                        >
                            <Typography>Mi az a Tanfolyam menüpont és mikor válik elérhetővé?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className='leiras'>
                                A tanfolyam menüpont egy jelenleg fejlesztés alatt álló részvénybefektetési kurzusra irányít át. Mivel a Befektetésközpont még nagyon fiatal, ezért a fő hangsúly a weboldal optimalizálására van helyezve. A tanfolyam várhatóan 2024 szeptemberéig lesz publikálva.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{
                        backgroundColor: '#1e1e1e',
                        color: 'white',
                        width: ['400px', '550px', '800px'],
                        '@media (max-width: 600px)': {
                            width: '100%',
                        }
                    }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                            className='spandd'
                        >
                            <Typography>Miért nem lehet regisztrálni a weboldalon?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className='leiras'>
                                A Befektetésközpont idővel folyamatosan fejlődik és bővül újabb és újabb szolgáltatásokkal. A regisztrációs funkció átmenetileg ki van kapcsolva. Amint megérkeznek az első szolgáltatások melyek igénylik a felhasználó bejelentkezését, a regisztráció újra elérhetővé lesz téve.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Gyik
