import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function ROI() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>ROI - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a ROI jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>ROI</h1>
                            <p>Ezen az oldalon megismerheted a ROI jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A ROI egy olyan mutató, amely megmutatja, hogy egy befektetés mennyire volt sikeres.</p>
                            <p className="majonator">A mutató segítségével lehetővé válik egy befektetés jövedelmezőségének értékelése. A ROI egy adott befektetés megtérülését próbálja közvetlenül mérni a befektetés költségéhez viszonyítva. A ROI segítésével képet kaphatunk arról, hogy egy vállalat mennyire hatékonyan kezeli a befektetéseit. A mérőszámot általában százalékban fejezik ki.</p>
                            <hr />
                            <h2>Kiszámítása</h2>
                            <p className="majonator">ROI = ((befektetés jelenlegi értéke - befektetés értéke vételkor) / befektetés értéke vételkor) <span>&#215;</span> 100</p>
                            <p className="majonator">Tegyük fel, hogy régebben vásároltunk egy részvényből 100$ áron. Jelenleg a részvény ára 150$, mi pedig úgy ítéljük meg, hogy a vállalat túlértékelté vált, ezért eladjuk a részvényt. ((150$ - 100$) / 100$ ) <span>&#215;</span> 100 = 50% &nbsp; | &nbsp; A példában egy magánszemély befektetésének a ROI-ja volt bemutatva. Egy vállalat ROI mutatója azt fejezi ki, hogy a társaság mennyire hatékonyan kezeli az összes befektetését.</p>
                            <hr />
                            <h2>Értelmezése</h2>
                            <p className="majonator">A magasabb hozam magasabb kockázattal jár, ezért két megegyező ROI érték esetén az általunk kevésbé kockázatosnak tekintett eszközt érdemes választani. Ha egy befektetés ROI-ja nettó pozitív, akkor valószínűleg megéri befektetni, viszont fontos belevenni a számításainkba a befektetés által felemésztett időt, illetve az alternatív költséget is. Azt, hogy valami jó megtérülést biztosít-e vagy sem, más rendelkezésre álló befektetési lehetőségekkel való összehasonlítás után lehet megállapítani.</p>
                            <p className="majonator">A ROI megmutatja, hogy a vállalat mennyi nyereséget termel a befektetéseiből egy adott idő alatt. A ROI mutató önmagában nem ad kellő információt egy vállalat hatékonyságáról. Fontos, hogy részletes elemzést végezzünk, és hogy a ROI csak egy szempont legyen a sok közül, amikor egy vállalat részvényeit elemezzük.</p>
                            <hr />
                            <h2>ROI szektorok szerint</h2>
                            <p className="majonator">Az S&P 500 átlagos megtérülése körülbelül 10% évente. A ROI értéke <Link to='/fogalomtar/szektor' className='dezord'>szektoronként</Link> eltér, és a szektorok átlagos ROI szintje is nagyon változékony lehet. Egy gyorsan növekvő szektor általában magasabb ROI-t kínál, mint a lassan növekvő vagy a stagnáló. A magas kockázatú eszközök gyakran magasabb ROI-val járnak, mivel nagyobb a potenciális nyereségük.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default ROI