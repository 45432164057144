import React, { useState, useEffect, useRef } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';


function Arany() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    let tvScriptLoadingPromise;
    const onLoadScriptRef = useRef();
    useEffect(
        () => {
            onLoadScriptRef.current = createWidget;

            if (!tvScriptLoadingPromise) {
                tvScriptLoadingPromise = new Promise((resolve) => {
                    const script = document.createElement('script');
                    script.id = 'tradingview-widget-loading-script';
                    script.src = 'https://s3.tradingview.com/tv.js';
                    script.type = 'text/javascript';
                    script.onload = resolve;

                    document.head.appendChild(script);
                });
            }

            tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

            return () => onLoadScriptRef.current = null;

            function createWidget() {
                if (document.getElementById('tradingview_594e0') && 'TradingView' in window) {
                    new window.TradingView.widget({
                        width: 'auto',
                        height: 400,
                        symbol: "TVC:GOLD",
                        interval: "W",
                        timezone: "Europe/Budapest",
                        theme: "dark",
                        style: "1",
                        locale: "en",
                        toolbar_bg: "#f1f3f6",
                        enable_publishing: false,
                        allow_symbol_change: true,
                        container_id: "tradingview_594e0"
                    });
                }
            }
        },
        []
    );

    return (
        <>
            <Helmet>
                <title>Arany - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted az arany jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Arany</h1>
                            <p>Ezen az oldalon megismerheted az arany jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">Az arany a természetben elemi állapotban előforduló, jellegzetesen sárga nemesfém.</p>
                            <p className="majonator">Az arany egy régóta ismert és értékes nemesfém, amelyet az emberek évszázadok óta használnak ékszerek készítésére, régebben pedig pénzverésre is. Azonban az arany nemcsak díszítésre és pénzkészítésre szolgál, hanem befektetési eszközként is jelentős szerepet tölt be a globális pénzügyi piacokon.</p>
                            <hr />
                            <div className='tradingview-widget-container'>
                                <div id='tradingview_594e0' />
                                <div className="tradingview-widget-copyright">
                                    <a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank"><span className="blue-text">Track all markets on TradingView</span></a>
                                </div>
                            </div>
                            <hr />
                            <h2>Érdemes aranyba fektetni?</h2>
                            <p className="majonator azam">Előnyei</p>
                            <p className="majonator">Az arany hosszú távon képes megőrizni az értékét. Az <Link to='/fogalomtar/inflacio' className='dezord'>infláció</Link> hatására az általános árszint növekszik, így a pénz értéke csökken. Az arany azonban érzékeny a gazdasági és politikai bizonytalanságokra, így azokban az időkben gyakran értékállóbb alternatíva lehet a hagyományos pénzügyi eszközökkel szemben.</p>
                            <p className="majonator">Az arany alkalmas a befektetési portfólió <Link to='/fogalomtar/diverzifikacio' className='dezord'>diverzifikálására</Link>. Mivel az arany árfolyama gyakran korrelálhat a hagyományos eszközökkel ellentétes irányban, ezáltal csökkentheti a <Link to='/fogalomtar/portfolio' className='dezord'>portfólió</Link> teljes kockázatát. Az arany gyakran szolgál bizalmi menedéknek olyan időkben, amikor a globális gazdaság instabil vagy a tőzsdei piacok <Link to='/fogalomtar/volatilitas' className='dezord'>volatilitása</Link> magas. Egyes befektetők az aranyhoz menekülnek, mert úgy vélik, hogy stabilitást és védelmet nyújthat a pénzügyi válságok idején.</p>
                            <br />
                            <p className="majonator azam">Hátrányai</p>
                            <p className="majonator">Az arany nem termel kamatot vagy <Link to='/fogalomtar/osztalek' className='dezord'>osztalékot</Link>, így nincs potenciális bevétel belőle, mint például a részvényeknél vagy kötvényeknél. A befektetőknek kizárólag az árfolyamváltozásokból származó nyereségre kell támaszkodniuk. Az aranyat fizikai formában is meg lehet vásárolni, de ezek tárolása és biztonságos kezelése magas költségekkel járhat.</p>
                            <hr />
                            <h2>Így fektethetünk aranyba</h2>
                            <p className="majonator">Vásárolhatunk aranyat fizikai úton, kereskedőkön keresztül. Ennek a módszernek az egyetlen előnye az, hogy súlyos gazdasági válság vagy összeomlás, esetleg háborús konfliktus esetén biztonságban és kellőkép elérhető helyen van az arany. A hátránya az, hogy többletköltségekkel jár a tárolás és az eladási árfolyam között nagyobb a különbség.</p>
                            <p className="majonator">Hozzájuthatunk aranyhoz értékpapírokon keresztül is. Ez azzal a hátránnyal jár, hogy vészhelyzet esetén nincsen kéznél. Befektethetünk arany ETF-be, hagyományos arany alapba, aranyszámlán keresztül és aranybánya vállalatok részvényeibe. Ezek közül a legjobb befektetési formának az arany ETF-ek mondhatók. Kivéve, ha évtizedeken átívelő aranybefektetést tervezel, mert ebben az esetben könnyen meglehet, hogy a fizikai arany jobban megéri.</p>
                            <p className="majonator">Talán a legkevésbé népszerű formája az aranyba való befektetésnek a stabil coinok. Lehetőség van aranyba fektetni olyan aranyfedezetű kriptovalutákon keresztül, mint a PAX Gold.</p>
                            <hr />
                            <h2>Warren Buffet az aranyról</h2>
                            <p className="majonator">,,Amikor átvettük a Berkshire irányítását, 15 dollárért adták részvényeinket, az aranyat pedig 20 dollárért árulták unciánként. Az arany most 1600 dollár, a Berkshire részvény pedig 120 000. Vegyünk egy szemléletesebb példát. Ha ma veszel egy uncia aranyat és 100 évig tartod, akkor minden nap odamehetsz hozzá és simogathatod, és 100 év múlva lesz egy uncia aranyad és az nem tett semmit érted ez alatt az idő alatt. Ha veszel 100 hektár termőföldet, az minden évben termelni fog neked. Vehetsz még több termőföldet, mindenfélét és 100 év múlva is megmarad 100 hektár termőfölded. 1900 elején 66 dollárért megvehetted volna a Dow Jones ipari átlagát. Az arany akkor 20 dollár volt. A Dow Jones a végén 11 400 dollár lett, ráadásul kaptál volna 100 évig osztalékot. Tehát egy tisztességes termelőeszköz képes kivégezni egy nem termelő eszközt."</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Arany