import React, { useState, useEffect } from "react";
import './konyveklist.css'

function Konyveklist() {

    return (
        <>
            <div className="konyvwrapper">
                <div className="konyvcim">
                    <h1>Befektetési könyvek</h1>
                </div>
                <hr />
                <hr />
                <div className="bookshelf">
                    <div className="book kivetel111 coloringer">
                        <div className="bookcontent">
                            <h2>A Befektetések klasszikusai sorozat</h2>
                            <p>Befektetések klasszikusai sorozat, olyan a befektetések területén alapműnek számító művekből áll, amelyek hosszú távon kiállták az idő próbáját, és amelyekre a világgazdaság legsikeresebb szereplői, nagy egyetemei és intézményei hosszú évek óta folyamatosan hivatkoznak.</p>
                            <div className="sorozatdiv elsosorozat">
                                <h3>Benjamin Graham: Az intelligens befektető</h3>
                                <div className="a1b2ss">
                                    <div className="fifty">
                                        <div>
                                            <p>„Egyértelműen a legjobb könyv amit a befektetésekről valaha is írtak!”- ezekkel a szavakkal méltatta Warren Buffet , a világ legnagyobb befektetési guruja tanárának és mentorának örök érvényű pénzügyi bibliáját. Benjamin Graham amerikai közgazdász és befektető, az érték alapú befektetés atyja, 1949-ben írta mára már klasszikussá vált könyvét.</p>
                                            <p>Hogyan lehet időszerű egy több mint fél évszázada írt pénzügyi mű? Graham többször, utoljára 1973-ban dolgozta át munkáját. Jason Zweig pénzügyi szakíró 2003-ban lábjegyzetekkel egészítette ki Graham eredeti szövegét, illetve minden fejezethez kommentárokat írt. Másrészt a szerző nagyon éles különbséget tesz a spekuláns és a befektető között. Könyve nem azoknak szól, akik azt szeretnék megtudni, hogy miként lehet a pénzügyi piacokon egy hónapon, egy éven vagy akár három éven belül meggazdagodni. Éppen ellenkezőleg, azok fogják a legnagyobb haszonnal forgatni, akik élethosszig tartó befektetéseikhez kívánnak iránymutatást kapni - vagyis nem spekulánsként, hanem intelligens befektetőként tevékenykedni</p>
                                        </div>
                                    </div>
                                    <div className="nembaj">
                                        <img src="./imgvideo/ib-kep.jpg" alt="fasz" width='100%' className="kiv11122" />
                                    </div>
                                </div>
                            </div>
                            <div className="last8" id="lastk">
                                <div className="sorozatdiv">
                                    <h3>Philip A. Fisher: Közönséges részvények - rendkívüli profitok</h3>
                                    <div className="a1b2ss">
                                        <div className="fifty">
                                            <div>
                                                <p>A széles körben ismert és elismert Philip Fisher minden idők legbefolyásosabb befektetői közé tartozik. A modern befektetés elmélet egyik úttörőjeként tartják számon, akinek több mint ötven éve napvilágot látott befektetési filozófiáját korunk pénzügyi szakemberei nemcsak tanulmányozzák és alkalmazzák, hanem sokan közülük „szentírásnak” is tekintik. Befektetési elveit Fisher a Közönséges részvények - rendkívüli profitok című könyvében vetette papírra, amelyet 1958-as megjelenésekor felbecsülhetetlen értékű olvasmánynak tartottak, és amely mind a mai napig kihagyhatatlan alapműnek számít.</p>
                                                <p>„Phil Fishert a Közönséges részvények - rendkívüli profitok elolvasása után kerestem fel… Találkozásunkkor az ember és az ötletei egyaránt lenyűgöztek. Ha Phil módszereinek alkalmazásával mélyrehatóan megértjük a vállalatok és az üzlet működését, intelligens befektetési döntéseket hozhatunk” - Warren Buffett</p>
                                            </div>
                                        </div>
                                        <div className="nembaj">
                                            <img src="./imgvideo/kr-kep.jpg" alt="fasz" width='100%' />
                                        </div>
                                    </div>
                                </div>
                                <div className="sorozatdiv">
                                    <h3>Warren Buffett, Lawrence A. Cunningham: Warren Buffett jelenti</h3>
                                    <div className="a1b2ss">
                                        <div className="fifty">
                                            <div>
                                                <p>Warren Buffett kétségkívül az elmúlt fél évszázad legsikeresebb gyakorló befektetője. Elismert piaci szereplő, akinek befektetési filozófiája kiállta az idő próbáját: az 1973-as olajválság, az 1987. októberi „fekete hétfő”, az 1990-es évek végének technológiai lufija és annak kipukkadása, a 2008-as összeomlás mind-mind olyan piaci sokk, amelyből Buffett és vállalata, a Berkshire Hathaway eredményesen jött ki. Ötven éven át nem tart ki a szerencse, egy-két jól sikerült húzással nem lehet a részvénypiaci indexeket tartósan felülteljesíteni, ehhez több kell - olyasvalami, amiért a szerző méltán érdemelte ki az „omahai bölcs” elnevezést.</p>
                                                <p>Buffett „üzlettársai” - a Berkshire részvényesei - évről évre felbecsülhetetlen értékű informális oktatásban részesülnek a közvetlen és nyílt hangvételű levelekből, amelyekben elnökük a vállalat eredményeiről számol be, s őszintén vall befektetési filozófiájáról. Jelen kötet az elmúlt mintegy három évtized ilyen írásaiból áll össze a befektetések és a vállalatvezetés fontos témaköreit taglaló folyamatos olvasmánnyá. </p>
                                            </div>
                                        </div>
                                        <div className="nembaj">
                                            <img src="./imgvideo/wbj-kep.jpg" alt="fasz" width='100%' />
                                        </div>
                                    </div>
                                </div>
                                <div className="sorozatdiv">
                                    <h3>Edwin Lefévre: Egy spekuláns feljegyzései</h3>
                                    <div className="a1b2ss">
                                        <div className="fifty">
                                            <div>
                                                <p>Az Egy spekuláns feljegyzései Jesse Livermore, a legendás amerikai spekuláns regényes életrajza. Az eredetileg 1923-ban megjelent könyv nemcsak lebilincselő és szórakoztató olvasmány: a spekuláció művészetének klasszikus tankönyve.</p>
                                                <p>Befektetők nemzedékei forgatták haszonnal, mint a spekulációs hibák, trükkök, fortélyok kiapadhatatlan tárházát. Nem véletlen, hogy egy körkérdés során a jelen kiemelkedő amerikai alapkezelői közül legtöbben ezt a könyvet jelölték meg, mint legfontosabb, ma is nélkülözhetetlen tőzsdei irodalmat. A livermore-i spekulációs módszer azért lehet időtálló, mert abból indul ki, hogy a spekuláció alapvető feltételei minden korban változatlanok, hiszen az ember természet nem változik. Tulajdonképpen az emberi természet szélsőségeinek, a félelemnek és a reménynek volt alapos tanulmányozója és ismerője Jesse Livermore, aki egy állandó, s olykor költséges tanulási folyamatnak fogta fel a spekulációt, amely során egyre többet tudhatunk meg - magunkról.</p>
                                            </div>
                                        </div>
                                        <div className="nembaj">
                                            <img src="./imgvideo/esf-kep.jpg" alt="fasz" width='100%' />
                                        </div>
                                    </div>
                                </div>
                                <div className="sorozatdiv">
                                    <h3>Jack D. Schwager: A piac varázslói</h3>
                                    <div className="a1b2ss">
                                        <div className="fifty">
                                            <div>
                                                <p>Mi különbözteti meg az elképesztően sikeres piaci kereskedőket - a piac varázslóit - a hétköznapi traderektől? Milyen közös jellemvonásaik vannak? Mit tanulhat az átlagkereskedő azoktól, akik rendkívül szigorú kockázatkezeléssel évtizedeken keresztül kimagasló hozamot értek el? A piaci legendákkal készített interjúiban Jack Schwager ezekre a kérdésekre keresett választ.</p>
                                                <p>Az elmúlt 25 év többtucatnyi beszélgetésének leiratából született a Market Wizards sorozat négy kötete, amelyek közül az elsőt most végre a magyar olvasók is kezükbe vehetik. Az interjúkötetek töretlen népszerűsége leginkább talán annak köszönhető, hogy nem kész - és könnyen avuló - receptet kínálnak. Egyetlen beszélgetőpartner szájából sem hangzik el: „csináld azt, amit én, és nyerni fogsz.” Éppen ellenkezőleg - szinte egyhangúlag vallják, hogy mindenkinek meg kell találnia a személyiségéhez illő módszertant, s fegyelmezetten és következetesen ragaszkodni ahhoz.</p>
                                            </div>
                                        </div>
                                        <div className="nembaj">
                                            <img src="./imgvideo/apv-kep.jpg" alt="fasz" width='100%' />
                                        </div>
                                    </div>
                                </div>
                                <div className="sorozatdiv">
                                    <h3>Jack D. Schwager: A piac varázslói 2.</h3>
                                    <div className="a1b2ss">
                                        <div className="fifty">
                                            <div>
                                                <p>„Találkoztunk az ellenséggel, és mi vagyunk azok.” Az idézet a kereskedés művészetének talán legjobb egysoros összefoglalását adja, A piac varázslói két kötetének interjúalanyai újra meg újra a lélektani elemeknek a kereskedési sikerben betöltött kritikus szerepére hívják fel a figyelmet. A kérdésre, hogy mi a fontos a sikerhez, a piac varázslói sohasem indikátorokat vagy módszereket emlegetnek, hanem olyan tényezőket, mint a fegyelem, a türelem, érzelmeik kontroll alatt tartása, illetve a veszteségekhez való hozzáállásuk. Az üzenet egyértelmű: a nyerés kulcsát magunkban hordozzuk, nem a kinti világban kell keresnünk.</p>
                                                <p>A piac varázslói első kötete után végre a sorozat második részét is kezébe veheti a magyar közönség. A szerző ott folytatja, ahol az előző könyvében abbahagyta: találó kérdéseivel a kiemelkedő eredményeket elért kereskedők elméjébe igyekszik beférkőzni, hogy mi, olvasók, minél kézzelfoghatóbb választ kapjunk a legfontosabb kérdésre: mi szükségeltetik a pénzügyi piacokon a nyeréshez.</p>
                                            </div>
                                        </div>
                                        <div className="nembaj">
                                            <img src="./imgvideo/apv2-kep.jpg" alt="fasz" width='100%' />
                                        </div>
                                    </div>
                                </div>
                                <div className="sorozatdiv">
                                    <h3>Lowell Miller: A létező legbiztosabb befektetés</h3>
                                    <div className="a1b2ss">
                                        <div className="fifty">
                                            <div>
                                                <p>Könyvemben egy egyszerű és magától értetődő módszert fogok részletesen bemutatni, amellyel hosszú távon a létező legalacsonyabb kockázat mellett érhetünk el tisztes hozamot befektetéseinken. Ez a stratégia nem trükk, nem kell hozzá új nyelvet elsajátítanunk, vagy opciókkal, határidős vagy egyéb egzotikus instrumentumokkal spekulálnunk, így mi magunk is képesek lehetünk a megvalósítására.</p>
                                                <p>A létező legbiztosabb befektetésben megmutatom, hogyan választhatjuk ki a magas osztalékjövedelmet termelő és a piaci átlagokat hosszú távon felülmúló részvényeket. Megközelítésemhez nincs szükség piaci időzítésre, nem kell befektetési guruk szavain csüggenünk vagy a vállalati beszámolók apró betűs sorai között böngésznünk. Könyvemben olyan egyszerű szabályokat fogalmazok meg, amelyeket bármely befektető képes követni.</p>
                                            </div>
                                        </div>
                                        <div className="nembaj">
                                            <img src="./imgvideo/allb-kep.jpg" alt="fasz" width='100%' />
                                        </div>
                                    </div>
                                </div>
                                <div className="sorozatdiv">
                                    <h3>Jesse Livermore: Hogyan kereskedjünk részvényekkel</h3>
                                    <div className="a1b2ss">
                                        <div className="fifty">
                                            <div>
                                                <p>Jesse Livermore-ra még a Wall Street jelenkori nagyágyúi is úgy tekintenek, mint a valaha élt legnagyobb spekulánsra. Livermore a tőzsdei kereskedés igazi úttörője volt: a részvény- és árupiacokon mindenre kiterjedő figyelme, az itt alkalmazott időzítési technikája, befektetési szabályrendszere forradalminak bizonyult, ma is érvényes és alkalmazható.</p>
                                                <p>Livermore 1891-ben, 14 évesen menekült el otthonról 5 dollárral a zsebében, és irodai kisegítőként a Paine Weber bróker irodánál azonnal munkába állt. Egy vagyont keresett az 1907-es tőzsdekrachon, amit később elvesztett, hogy aztán mindezt még számos alkalommal megismételje. Az 1907-es pánik során J. P. Morgan személyesen könyörgött neki, hogy hagyja abba a piac shortolását és teljes bedöntését. Ekkor egyetlen nap 3 millió dollárt kasszírozott. 1929-ben is shortolta a piacot, aminek következtében 100 millió dollár készpénzzel vágott neki a Nagy Válságnak.</p>
                                            </div>
                                        </div>
                                        <div className="nembaj">
                                            <img src="./imgvideo/hkr-kep.jpg" alt="fasz" width='100%' />
                                        </div>
                                    </div>
                                </div>
                                <div className="sorozatdiv">
                                    <h3>John C. Bogle: Vagyonépítés befektetési alapokkal</h3>
                                    <div className="a1b2ss">
                                        <div className="fifty">
                                            <div>
                                                <p>„Kedves Jack: végigolvastam a könyvedet az elsőtől az utolsó oldalig és rettenetesen élveztem. Őszinte, intelligens és olvasmányos- igen ritka kombináció. Ez a könyv mindig is alapvetés lesz a befektetési alapok ügyfelei számára” -Warren E. Buffett</p>
                                                <p>John C. Bogle annak a Vanguard Csoportnak elismert alapítója, melynek működési elvei az általa vallott üzleti értékeken nyugszanak: őszinteség, tisztesség és alacsony költségek. Kezében a „Vagyonépítés befektetési alapokkal - Új lehetőségek intelligens befektetőknek” című könyvvel, a szakértelem és gyakorlat bármely lépcsőfokán álló befektető megismerheti John C. Bogle évek alatt folyamatosan és eredményesen visszaigazolt befektetői megközelítését. Bármi legyen is egy befektető hosszú távú célja, a „Vagyonépítés befektetési alapokkal” segít kialakítani egy olyan portfólió stratégiát, ami a Bogle féle örök szentháromságra épül: hozam, kockázat és költség. Olyan modelleket mutat be melyek segítenek intelligens befektetői döntéseket hozni.</p>
                                            </div>
                                        </div>
                                        <div className="nembaj">
                                            <img src="./imgvideo/vba-kep.jpg" alt="fasz" width='100%' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <img src="/imgvideo/backlight.png" className="herhet"/>
                    </div>
                    <div className="book coloringer">
                        <h2 className="morgan">Morgan Housel: A pénz pszichológiája</h2>
                        <div className="bookcontent">
                            <div className="a1b2ss">
                                <div className="fifty">
                                    <div>
                                        <p>A pénz életünk minden területén jelen van, mindannyiunkat befolyásol, és legtöbbünket összezavar. Mindenki kicsit másképpen gondolkodik róla. Az, hogy mennyire bánsz jól a pénzzel, nem szükségszerűen azon múlik, amit tudsz. Sokkal inkább azon, hogyan viselkedsz. A helyes viselkedés pedig - még az igazán okos embereknek is - nehezen tanítható.</p>
                                        <p>A pénzre - így a befektetésre, a személyes pénzügyekre és az üzleti döntésekre - általában matematikai alapú területként gondolunk, és ekként tanítjuk. Olyan területként, ahol az adatok és a képletek egyértelműen megmondják, mit kell tenni. Ám a valóságban az emberek nem Excel-táblában hozzák meg pénzügyi döntéseiket, hanem a családi asztalnál vagy üzleti tárgyalásokon, ahol személyes történetük, egyedi világnézetük, egojuk, büszkeségük és fura ösztönzők keverednek egymással. A pénzügyi siker nem egzakt tudomány. Egyfajta szociális készség, amelynél a viselkedésed a tudásodnál is többet számít.</p>
                                    </div>
                                </div>
                                <div className="nembaj">
                                    <img src="./imgvideo/app-img.jpg" alt="A pénz pszichológiája borítókép" width='100%' />
                                </div>
                            </div>
                        </div>
                        <img src="/imgvideo/backlight2.webp" className="herhet"/>
                    </div>
                    <div className="book coloringer">
                        <h2 className="morgan">Ben Carlson: Befektetés józan ésszel</h2>
                        <div className="bookcontent">
                            <div className="a1b2ss">
                                <div className="fifty">
                                    <div>
                                        <p>A Befektetés józan ésszel útmutatást ad az ilyen egyéni befektetőknek (megtakarítóknak) ahhoz, hogyan állítsák össze portfóliójukat, hogyan álljanak a pénzügyi piacok szeszélyes viselkedéséhez, hogyan tartsák kontroll alatt érzelmeiket – és ami talán a legfontosabb, hogyan profitáljanak megfelelően kialakított portfóliójukból, a piac szeszélyeiből és érzelmeik kordában tartásából.</p>
                                        <p>Ben Carlson könyvéből megtudhatjuk, hogy a pénzügyi piacokon való befektetés során hogyan fordítsuk javunkra a józan ész nyújtotta előnyöket; mire érdemes odafigyelnünk és miket kell teljes mértékben figyelmen kívül hagynunk; hogyan álljunk a piacok rövid távú ingadozásaihoz; hogyan tartsuk kordában érzelmeinket és őrizzük meg fegyelmezettségünket; milyen segítséget nyújthat számunkra egy hozzáértő pénzügyi tanácsadó, mit várhatunk tőle, és hogyan válasszuk ki a számunkra megfelelő szakembert. A praktikus tanácsokban bővelkedő kötet kézzel fogható és gyakorlati segítséget nyújt hosszú távú befektetési eredményeink javításához, illetve ahhoz, hogy bármilyen piaci körülmények között racionális döntéseket tudjunk hozni.</p>
                                    </div>
                                </div>
                                <div className="nembaj">
                                    <img src="./imgvideo/bje-img.jpg" alt="" width='100%' />
                                </div>
                            </div>
                        </div>
                        <img src="/imgvideo/backlight3.webp" className="herhet"/>
                    </div>
                    <div className="book coloringer">
                        <h2 className="morgan">George S. Clason: Babilon leggazdagabb embere</h2>
                        <div className="bookcontent">
                            <div className="a1b2ss">
                                <div className="fifty">
                                    <div>
                                        <p>Jövőd ígérete úgy vetül eléd, ahogy a távolba vesző út kanyarog a vándor előtt. Utad becsvággyal van kikövezve, s vágyakkal, melyeket meg akarsz valósítani. Ahhoz, hogy valóra váltsd az álmaidat és a vágyaidat, meg kell tanulnod bánni a pénzzel, Ehhez ajánlom a következő oldalakon található pénzügyi útmutatót.</p>
                                        <p>Hagyd, hogy a bölcs emberek tanácsai vezessenek utadon, s hozzásegítsenek ahhoz, hogy sovány erszényed megteljen, és elérhetővé váljon számodra a gazdagság reményével kecsegtető, boldog jövő. A könyvben található szabályok örök érvényűek, akár a gravitáció törvénye, ezért előtted már sok más embert segítettek hozzá, bankszámlája feltöltéséhez és pénzügyi sikerek eléréséhez.</p>
                                    </div>
                                </div>
                                <div className="nembaj">
                                    <img src="./imgvideo/ble-img.jpg" alt="" width='100%' />
                                </div>
                            </div>
                        </div>
                        <img src="/imgvideo/backlight4.webp" className="herhet"/>
                    </div>
                    <div className="book coloringer">
                        <h2 className="morgan">Peter Lynch: Sikerrel a tőzsdén</h2>
                        <div className="bookcontent">
                            <div className="a1b2ss">
                                <div className="fifty">
                                    <div>
                                        <p>„Ha pénzügy szakos diákoknak azt a feladatot adjuk, hogy folytassák a következő felsorolást: Benjamin Graham, David Dodd, Warren Buffett … A megoldás, ami minden – kicsit is a befektetések világával barátkozó – diáknak eszébe jut, Peter Lynch, a világ legnagyobb aktívan kezelt befektetési alapjának, a Magellánnak a legendás befektetési alapkezelője. Lynch ahhoz a patinás értékcentrikus befektetési közösséghez tartozik, amelyet a huszadik században a fenti nevek tettek közismertté és méltán sikeressé.</p>
                                        <p>Egy-két évig bárki lehet nagyon eredményes befektető, de évtizedeken keresztül kimagasló eredményeket elérő portfóliókezelőről nem sokról tudunk. Peter Lynch kétségkívül ebbe az elit klubba tartozik és mint azt már a hozzá hasonló embereknél megszoktuk, pénzügyi tehetségéhez, tapasztalatához szórakoztató, közérthető és – hadd következzen itt egy talán meglepő kifejezés – humanista írói képesség tartozik. A tehetségek ilyen kombinációja miatt úgy gondolom, minden értékcentrikus olvasó számára egyértelmű lesz, hogy a könyv megvétele és elolvasása az egyik legjobb befektetése lehet mindenkinek, aki pénzzel foglalkozik vagy csak kicsit többet akar megérteni a világ működéséből.” (Jaksity György, A pénz természete című könyv szerzője, a Concorde Értékpapír Zrt. ügyvezető igazgatója)</p>
                                    </div>
                                </div>
                                <div className="nembaj">
                                    <img src="./imgvideo/sikbef-book.jpg" alt="" width='100%' />
                                </div>
                            </div>
                        </div>
                        <img src="/imgvideo/backlight5.webp" className="herhet"/>
                    </div>
                    <div className="book coloringer">
                        <h2 className="morgan">André Kostolany: Tőzsdepszichológia</h2>
                        <div className="bookcontent">
                            <div className="a1b2ss">
                                <div className="fifty">
                                    <div>
                                        <p>André Kostolany egész életét a tőzsdének, ennek a titokzatos és szeszélyes világnak szentelte, ahol a gazdasági tények fantáziadús történetekkel, gyakran célzatosan terjesztett rémhírekkel, féligazságokkal és tippekkel keverednek. A könyv a sikeres tőzsdések és tőkebefektetők „kötelező irodalma”, mert amellett, hogy tőzsdei titkokat szellőztet önálló, független gondolkodásra is nevel. nem tankönyvet tart kezében az olvasó, hanem inkább egy olyan személy elbeszéléseit, aki nem tanít, csak mesél.</p>
                                    </div>
                                </div>
                                <div className="nembaj">
                                    <img src="./imgvideo/tozsdepszi.jpg" alt="" width='100%' />
                                </div>
                            </div>
                        </div>
                        <img src="/imgvideo/backlight6.png" className="herhet"/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Konyveklist