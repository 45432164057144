import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Cashflow() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Cash flow - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a cash flow jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Cash flow</h1>
                            <p>Ezen az oldalon megismerheted a cash flow jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A cash flow egy adott időszak alatt egy vállalat vagy magánszemély pénzügyeibe be- és onnan kiáramló pénzmozgásokat írja le.</p>
                            <p className="majonator">Ez egy kulcsfontosságú pénzügyi mérőszám, amelyet a szervezetek vagy a személyes pénzügyi helyzet egészségének és fenntarthatóságának értékelésére használnak. Cash flow kimutatást és tervet szokás készíteni, ami tartalmazza a vállalat vagy az egyén pénzmozgásának részletes információit egy adott időszakra vetítve. A cash flow összesített információt tartalmaz az üzleti tevékenységekből származó pénzmozgásokról. Ami egy befektetőt érdekel az a pénz be- és kiáramlásának módja a vállalatban.</p>
                            <hr />
                            <h2>Jelentősége</h2>
                            <p className="majonator">A pozitív cash flow azt jelzi, hogy a vállalat likvid eszközei növekednek, ami lehetővé teszi a kötelezettségek fedezését, az üzleti tevékenységbe történő újrabefektetést, a részvényeseknek történő kifizetéseket, a kiadások fizetését és a jövőbeli pénzügyi kihívások elleni kitartás biztosítását. Az erős pénzügyi rugalmassággal rendelkező vállalatok jobban boldogulnak egy esetleges visszaesés során, mivel elkerülik a pénzügyi nehézségekkel járó költségeket.</p>
                            <p className="majonator">A vállalatvezetés, az elemzők és a befektetők a cash flow kimutatást használják annak megállapítására, hogy egy vállalat mennyire hatékonyan termel pénzt kötelezettségei kifizetésére és működési költségeinek finanszírozására. A <Link to='/fogalomtar/merleg' className='dezord'>mérleg</Link>, az eredménykimutatás és a cash flow kimutatás alkotja a vállalatok által kiadott pénzügyi jelentéseket. A cash flow kimutatást három kategóriára bontjuk.</p>
                            <hr />
                            <h2>Kategóriák</h2>
                            <p className="majonator azam">Működési cash flow (Operating Cash Flow - OCF)</p>
                            <p className="majonator">Bemutatja a vállalat üzleti tevékenységéből származó pénzáramlásokat. A működési cash flow azt mutatja meg, hogy mennyi pénz folyt be és ki a vállalat mindennapi működése során az adott időszak alatt. Általában az OCF pozitív értékű, mivel a bevételek legtöbbször meghaladják a kiadásokat az alapvető üzleti tevékenység folytatása során.</p>
                            <br />
                            <p className="majonator azam">Beruházási cash flow (Investing Cash Flow - ICF)</p>
                            <p className="majonator">A jelentés ezen része tartalmazza azokat a pénzáramlásokat, amelyek a vállalat befektetési tevékenységeivel kapcsolatosak. Ide tartozik például az értékpapírokkal, ingatlannal és egyéb más eszközökkel való kereskedés. A fejlesztésekre és új beruházásokra elköltött összegeket CapEx néven találjuk a kimutatásban. A negatív beruházási cash flow nem feltétlenül rossz, ugyanis akár annak is köszönhető, hogy jelentős mennyiségű pénzt fektet be a vállalat kutatásba és fejlesztésbe. A CapEx az az összeg, amit azért költ el cég, hogy a termelési, szolgáltatási kapacitása fejlődjön.</p>
                            <br />
                            <p className="majonator azam">Finanszírozási cash flow (Financing Cash Flow - FCF)</p>
                            <p className="majonator">Az FCF a vállalat finanszírozásához használt pénzáramlásokat mutatja be. A finanszírozási tevékenységek közé tartoznak a kötelezettségekkel kapcsolatos tranzakciók, mint például a hitelfelvétel, az adósság visszafizetése vagy az osztalékfizetés.</p>
                            <hr />
                            <h2>Free cash flow</h2>
                            <p className="majonator">A free cash flow azután marad meg, hogy a vállalat kifizette működési kiadásait és a CapEx-et. A vállalatok szabadon használhatják az FCF-et. Fontos különbséget tenni aközött, hogy a vállalatnak azért nem maradt szabad pénze, mert fejlesztésekre és beruházásokra költötte vagy azért, mert nem áramol be elég pénz a vállalatba.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Cashflow