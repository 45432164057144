import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function EPS() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>EPS - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted az EPS jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>EPS</h1>
                            <p>Ezen az oldalon megismerheted az EPS jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">Az EPS (Earnings Per Share) olyan mutató, ami a vállalat egy részvényére jutó nyereségét fejezi ki.</p>
                            <p className="majonator">A mutató segítségével megtudhatjuk, hogy egy bizonyos időintervallum alatt a vállalat mekkora nyereséget ért el egy részvényre vetítve. Vagyis az EPS megmutatja, hogy 1 részvény mekkora jövedelmet termelt a vállalatban.</p>
                            <hr />
                            <h2>Kiszámítása</h2>
                            <p className="majonator">EPS = nyereség / részvények száma</p>
                            <p className="majonator">Az egy <Link to='/fogalomtar/reszveny' className='dezord'>részvényre</Link> jutó nyereséget úgy számítjuk ki, hogy a vállalat adott időszakra eső nyereségét elosztjuk a rendelkezésre álló részvények számával. Az eredménykimutatásokban fel szokták tüntetni az EPS-t, ezért nekünk már nem kell bajlódni a kiszámításával.</p>
                            <hr />
                            <h2>Használata</h2>
                            <p className="majonator">Az EPS abszolút értelemben vett összehasonlításnál hasznosabb lehet a befektetők számára, ha az EPS-t a részvény árfolyamával hasonlítják össze. Az EPS az <Link to='/fogalomtar/pe' className='dezord'>árfolyam/nyereség (P/E)</Link> arány kiszámításának fontos összetevője, ahol a P/E-ben az E az EPS-re utal. Ezenkívül az EPS-t számos más befektetési mutatóban is használják. Az EPS-t összehasonlíthatjuk az azonos iparágban tevékenykedő, hasonló vállalatok EPS statisztikáival, illetve egy vállalat EPS grafikonja önmagában is sok információt ad át a befektetőnek.</p>
                            <hr />
                            <h2>Alap és hígított EPS</h2>
                            <p className="majonator">A vállalatok eredménykimutatásában a részvények száma alatt két különböző értéket találunk: basic (alap) és diluted (hígított). Ez alapján megkülönböztetünk alap és hígított EPS-t.</p>
                            <p className="majonator">Az alap EPS a vállalat törzsrészvényeit veszi figyelembe, míg a hígított EPS az összes átváltható értékpapírt, például az átváltható kötvényeket vagy az átváltható elsőbbségi részvényeket is beleveszi a számításba. Ha egy vállalat tőkeszerkezete olyan tételeket tartalmaz, mint a részvényopciók, akkor azok növelhetik a forgalomban lévő részvények teljes számát. Az ilyen további értékpapírok részvényenkénti eredményére gyakorolt hatásának jobb szemléltetésére a vállalatok a hígított EPS-t is megjelentik. Előfordulhat például, hogy egy vállalat újabb részvénykibocsátásokat tervez. Ilyenkor a részvények alap darabszámához gyakran hozzáadják a még ki nem bocsájtott részvényeket is, ami a hígított részvényszámot fogja alkotni.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default EPS