import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Debtequity() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Debt/Equity - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a Debt/Equity jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Debt/Equity</h1>
                            <p>Ezen az oldalon megismerheted a Debt/Equity jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A Debt/Equity (D/E) ráta egy olyan pénzügyi mutatószám, amely a vállalat összes adósságát hasonlítja össze az összes saját tőkéjével. </p>
                            <p className="majonator">Ez a mutató azt fejezi ki, hogy a vállalat finanszírozásának mekkora hányada származik adósságból a saját tőkéhez viszonyítva. A D/E arányt a vállalatba való befektetés kockázatosságának értékelésére is használni szokták. A D/E átlagos értéke befektetési szektoronként eltérő. Érdemes a hasonló vállalatok D/E értékét összehasonlítani. Az egyik variációja a LT Debt/Equity, ami a hosszú lejáratú kötelezettségeket veszi figyelembe, amik nagyobb kockázatot hordoznak magukban.</p>
                            <hr />
                            <h2>Működése</h2>
                            <p className="majonator">A D/E rátát úgy kapjuk meg, hogy a társaság összes adósságát elosztjuk a összes saját tőkéjével. A saját tőke alatt az eszközök teljes értéke és a kötelezettségeket különbségét értjük. Minél magasabb ez az érték, annál magasabb az eladósodottság. Ha az érték 1, akkor a <Link to='/fogalomtar/vallalat' className='dezord'>vállalatnak</Link> pont annyi adóssága van, mint amennyi saját tőkéje.</p>
                            <p className="majonator">Az általában magas Debt/Equity értékkel rendelkező vállalatok <Link to='/fogalomtar/hitel' className='dezord'>hitelfinanszírozásra</Link> támaszkodnak. Ezek a vállalatok nagyobb bevételhez képesek jutni, viszont ezzel együtt a kockázat is nő. Ha egy ilyen vállalat kedvezőtlen körülmények közé kerül, akkor megeshet, hogy a nyereségnövekedés nem haladja meg az adósság költségit.</p>
                            <hr />
                            <h2>Magas Debt/Equity</h2>
                            <p className="majonator">A magas D/E ráta nem feltétlenül jelent rosszat. Egy alaposan megfontolt hitelfelvétellel a vállalat magasabb eredményeket érhet el. Sok esetben megéri a vállalatnak egy átgondolt hitelfelvételt véghez vinnie. Adósságot használva a vállalat nagyobb tőkeállományt használhat fel beruházásokra, növekedési lehetőségekre, ami segíthet az üzleti növekedésben.</p>
                            <p className="majonator">Magas adósságszint esetén a vállalat nagyobb mértékben ki van téve a kamatkockázatnak. A kamatköltségek növekedése a jövedelmezőséget és a pénzügyi stabilitást veszélyeztetheti. A magas D/E mutató esetén a vállalat hitelminősítése könnyen romolhat, ami nehezíti az hitelfelvételt és növeli a kölcsönkamatokat.</p>
                            <hr />
                            <h2>Megfelelő Debt/Equity</h2>
                            <p className="majonator">A megfelelő adósság-részvény arány az iparágtól és a vállalat sajátos körülményeitől függően változik. A különböző iparágak eltérő mértékű adósságtűrő képességgel rendelkeznek tőkeszükségletük, növekedési kilátásaik és kockázati profiljuk alapján.</p>
                            <p className="majonator">Az optimális debt/equity arány iparáganként nagymértékben eltérhet, de konszenzus szerint nem lehet 2-nél magasabb. Bár néhány nagy, eszközigényes iparágban (például a bányászatban vagy a feldolgozóiparban) 2-nél magasabb rátával is rendelkezhetnek a vállalatok. Ez egyfajta kivétel a szabály alól. Minél alacsonyabb az érték, annál kevésbé kockázatos befektetni D/E szempontjából.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Debtequity