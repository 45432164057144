import React, { useState, useEffect } from 'react';
import '../fogalomtar-main/fogmain.css'
import { Link } from 'react-router-dom';
import Sidebarcomp from '../fogalomtar-main/sidebarcomp';
import Fogfooter from '../fogalomtar-main/fogfooter';
import { Helmet } from 'react-helmet-async';

function Shrapemutato() {

    const [hambi, setHambi] = useState(null);
    const [minisidebar, setMinisidebar] = useState(null);

    useEffect(() => {
        setHambi(document.querySelector("#hamber2"));
        setMinisidebar(document.querySelector("#sidebarid"))
    }, []);
    const Lecsusszan = () => {
        if (hambi.classList.contains("hamburger")) {
            hambi.classList.remove("hamburger");
            hambi.classList.add("hamburger-active");
            hambi.classList.add("hamburger-active2");
            minisidebar.classList.remove("sidebarwrapper")
            minisidebar.classList.add("minisidebar")
        } else {
            hambi.classList.remove("hamburger-active");
            hambi.classList.remove("hamburger-active2");
            hambi.classList.add("hamburger");
            minisidebar.classList.remove("minisidebar")
            minisidebar.classList.add("sidebarwrapper")
        }
    };

    useEffect(() => {
        const handleScrollOutsideSidebar = () => {
            const sidebar = document.getElementById("sidebarid");
            const hambi = document.getElementById("hamber2");

            if (sidebar && hambi && hambi.classList.contains("hamburger-active")) {
                hambi.classList.remove("hamburger-active");
                hambi.classList.remove("hamburger-active2");
                hambi.classList.add("hamburger");
                minisidebar.classList.remove("minisidebar");
                minisidebar.classList.add("sidebarwrapper");
            }
        };

        document.addEventListener("scroll", handleScrollOutsideSidebar);
        return () => {
            document.removeEventListener("scroll", handleScrollOutsideSidebar);
        };
    }, [minisidebar]);

    return (
        <>
            <Helmet>
                <title>Sharpe mutató - Fogalomtár | Befektetésközpont</title>
                <meta name="description" content="Ezen az oldalon megismerheted a sharpe mutató jelentését és a befektetéssel való kapcsolatát." data-react-helmet="true" />
            </Helmet>
            <div className="alatti">
                <div class="flexnuni">
                    <div class="hamburger" onClick={Lecsusszan} id="hamber2">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='sidebarwrapper' id='sidebarid'>
                    <Sidebarcomp />
                </div>




                <div className='fo'>
                    <div className='upper'>
                        <div className='content'>
                            <h1>Sharpe mutató</h1>
                            <p>Ezen az oldalon megismerheted a sharpe mutató jelentését és a befektetéssel való kapcsolatát.</p>
                            <hr />
                            <p className="majonator aza">A sharpe mutató azt fejezi ki, hogy egységnyi kockázatra mekkora hozam jut.</p>
                            <p className="majonator">A sharpe mutató a realizált hozam és egy referenciaérték különbségét hasonlítja össze a portfólió hozamának szórásával ugyanez idő alatt. Minél nagyobb értéket mutat, annál kedvezőbb, mivel akkor egységnyi kockázatra nagyobb hozam jut. A mutató segítségével megtudhatjuk, hogy az elért hozam mekkora része származik magasabb kockázatvállalásból. </p>
                            <hr />
                            <h2>Értelmezése</h2>
                            <p className="majonator">Egy portfólió értékelésekor fontos, hogy ne csak a hozamot, hanem a vele járó kockázatot is mérjük, mivel szoros összefüggésben van a kettő. A nagyobb hozam nagyobb kockázattal jár. Vagyis, ha nagyobb hozamot szeretnénk elérni, akkor be kell vállalnunk az vele járó nagyobb kockázatot. Ha egy portfóliónak csak a hozamát vizsgáljuk, akkor az teljes mértékben elvakít, mert akár óriási mértékű kockázat vállalása is állhat az elért eredmény mögött. Ennek a mérésére szolgál a sharpe mutató. Minél magasabb a sharpe mutató, annál nagyobb hozamot kapunk egységi kockázatért, vagyis annál kedvezőbb befektetni.</p>
                            <hr />
                            <h2>Kiszámítása</h2>
                            <p className="majonator">Sharpe mutató = (Portfólió hozama - Kockázatmentes hozam) / Portfólió hozamának szórása</p>
                            <p className="majonator">A kockázatmentes hozam alatt egy referenciaérték hozama értendő. Például egy amerikai befektetési alap referenciaértéke az S&P 500, vagyis ennek kell a hozamát megmérni és így megkapjuk a kockázatmentes hozamot. A szórást a hozamok változékonyságából, vagyis a kockázatból származtatjuk az adott időtávra vetítve.</p>
                            <br />
                            <p className="majonatora azam">Példa</p>
                            <p className="majonatora">Shrape<sub>1</sub> = (30% - 10%) / 15% = 1,33 &nbsp; | &nbsp; Sharpe<sub>2</sub> = (20% - 10%) / 5% = 2</p>
                            <p className="majonatora">A Shrape<sub>1</sub>-hez tartozó portfólió hozama 10 százalékponttal magasabb, mint a másik portfólióé. Viszont mivel a szórása is magasabb, ezért az első portfóliót választóknak nagyobb kockázatot kell vállalniuk. A Shrape<sub>2</sub> portfóliót választók egységnyi kockázatért magasabb hozamot kapnak, mint az első portfóliót választók.</p>
                            <hr />
                            <h2>Sharpe variációk</h2>
                            <p className="majonatora azam">Sortino mutató</p>
                            <p className="majonator">A sortino mutató a sharpe mutató egy olyan változata, amely a realizált hozamot és egy referenciaérték különbségét hasonlítja össze a portfólió hozamának visszaeséséből származó szórásával ugyanez idő alatt. Az eredeti sharpe mutató képletén annyit kell módosítani, hogy a portfólió hozamcsökkenésének szórását kell a nevezőbe helyezni. A sortino mutató abból indul ki, hogy a pozitív <Link to='/fogalomtar/volatilitas' className='dezord'>volatilitás</Link> alapvetően előnyös, ezért ha csak a negatív árfolyamváltozásra fókuszál, akkor pontosabb képet tud adni a portfólió kockázattal korrigált teljesítményéről.</p>
                            <p className="majonatora azam">Treynor mutató</p>
                            <p className="majonator">A treynor mutató a sharpe mutató egy olyan változata, amely a realizált hozamot és egy referenciaérték különbségét hasonlítja össze a portfólió béta mutatójával ugyanez idő alatt. A sharpe képletétől annyiban tér el, hogy a béta mutatót kell a nevezőbe helyezni. A <Link to='/fogalomtar/beta-mutato' className='dezord'>béta mutató</Link> azt fejezi ki, hogy az adott instrumentum mennyire volatilis a piaci átlaghoz képest.</p>
                            <hr />
                            <h2>Hátrányok</h2>
                            <p className="majonator">A hozammérési intervallumok módosításával a portfóliókezelők könnyen manipulálhatják a sharpe mutató értékét. A vizsgált időtartam meghosszabbításával csökkenhet a számított volatilitás, vagyis a kockázat. Egy másik népszerű módja a manipulálásnak, amikor konkrétan egy kedvezőbb időintervallumon végzik el a számítást.</p>
                        </div>
                        <div className='addv'>

                        </div>
                    </div>
                    <Fogfooter />
                </div>
            </div>
        </>
    )
}

export default Shrapemutato